//General
$chip-bg-color: $grey-300 !default;
$chip-hover-bg-color: darken($grey-300, 4%) !default;
$chip-focus-bg-color: darken($grey-300, 12%) !default;
$chip-choice-active-bg-color: $accent !default;
$chip-active-bg-color: darken($grey-300, 14%) !default;
$chip-focus-active-bg-color: $chip-focus-bg-color !default;
$chip-choice-focus-active-bg-color: lighten($accent, 12%) !default;
$chip-pressed-bg-color: darken($grey-300, 16%) !default;
$chip-disabled-bg-color: rgba($grey-light-font, .12) !default;
$chip-pressed-active-bg-color: rgba($accent, .16) !default;
$chip-avatar-bg-color: darken($chip-bg-color, 12%) !default;
$chip-avatar-hover-bg-color: darken($chip-hover-bg-color, 12%) !default;
$chip-avatar-focus-bg-color: darken($chip-focus-bg-color, 12%) !default;
$chip-avatar-active-bg-color: darken($chip-active-bg-color, 12%) !default;
$chip-avatar-choice-active-bg-color: darken($chip-choice-active-bg-color, 12%) !default;
$chip-avatar-focus-active-bg-color: darken($chip-focus-active-bg-color, 12%) !default;
$chip-avatar-choice-focus-active-bg-color: darken($chip-choice-focus-active-bg-color, 12%) !default;
$chip-avatar-pressed-bg-color: darken($chip-pressed-bg-color, 12%) !default;
$chip-avatar-pressed-active-bg-color: darken($chip-pressed-active-bg-color, 12%);
$chip-avatar-disabled-bg-color: darken($chip-disabled-bg-color, 12%) !default;
$chip-font-color: rgba($grey-light-font, .87) !default;
$chip-hover-font-color: rgba($grey-light-font, .87) !default;
$chip-focus-font-color: rgba($grey-light-font, .87) !default;
$chip-active-font-color: $chip-focus-font-color !default;
$chip-choice-active-font-color: $accent-font !default;
$chip-focus-active-font-color: $chip-focus-font-color !default;
$chip-choice-focus-active-font-color: $accent-font !default;
$chip-pressed-font-color: rgba($grey-light-font, .87) !default;
$chip-disabled-font-color: rgba($grey-light-font, .26) !default;
$chip-close-icon-font-color: rgba($grey-light-font, .54) !default;
$chip-close-icon-hover-font-color: rgba($grey-light-font, .7) !default;
$chip-close-icon-pressed-font-color: $grey-light-font !default;
$chip-icon-font-color: rgba($grey-light-font, .54) !default;
$chip-icon-hover-font-color: rgba($grey-light-font, .54) !default;
$chip-icon-focus-font-color: rgba($grey-light-font, .54) !default;
$chip-icon-active-font-color: $chip-icon-focus-font-color !default;
$chip-icon-focus-active-font-color: $chip-icon-focus-font-color !default;
$chip-choice-icon-focus-active-font-color: $accent-font !default;
$chip-icon-pressed-font-color: rgba($grey-light-font, .54) !default;
$chip-avatar-font-color: $chip-icon-font-color !default;
$chip-avatar-hover-font-color: $chip-icon-hover-font-color !default;
$chip-avatar-focus-font-color: $chip-icon-focus-font-color !default;
$chip-avatar-active-font-color: $chip-icon-active-font-color !default;
$chip-avatar-choice-active-font-color: $chip-choice-active-font-color !default;
$chip-avatar-focus-active-font-color: $chip-icon-focus-active-font-color !default;
$chip-avatar-choice-focus-active-font-color: $chip-choice-icon-focus-active-font-color !default;
$chip-avatar-pressed-font-color: $chip-icon-pressed-font-color !default;
$chip-avatar-pressed-active-font-color: $chip-icon-pressed-font-color !default;
$chip-avatar-disabled-font-color: $chip-disabled-font-color !default;
$chip-border-color: transparent !default;
$chip-hover-border-color: transparent !default;
$chip-focus-border-color: transparent !default;
$chip-choice-active-border-color: transparent !default;
$chip-active-border-color: transparent !default;
$chip-pressed-border-color: transparent !default;
$chip-focus-active-border-color: $chip-focus-border-color !default;
$chip-choice-focus-active-border-color: $chip-focus-border-color !default;
$chip-disabled-border-color: transparent !default;
$chip-focus-box-shadow: none !default;
$chip-pressed-box-shadow: 0 2px 1px -6px rgba(0, 0, 0, .2),
  0 3px 4px 0 rgba(0, 0, 0, .14),
  0 1px 8px 0 rgba(0, 0, 0, .12) !default;
$chip-list-padding: 4px !default;
$chip-height: 28px !default;
$chip-border-radius: 14px !default;
$chip-box-shadow: none !default;
$chip-avatar-wrapper-border-radius: 14px !default;
$chip-avatar-border-radius: 50% !default;
$chip-leading-icon-border-radius: 50% !default;
$chip-delete-icon-border-radius: 50% !default;
$chip-padding: 0 10px !default;
$chip-margin: 4px !default;
$chip-overflow: hidden !default;
$chip-border-size: 0 !default;
$chip-font-size: 13px !default;
$chip-line-height: 1.5em !default;
$chip-font-weight: 400 !default;
$chip-avatar-size: 28px !default;
$chip-avatar-margin: 0 8px 0 -10px !default;
$chip-avatar-content-font-size: 13px !default;
$chip-leading-icon-font-size: 14px !default;
$chip-leading-icon-size: 20px !default;
$chip-leading-icon-margin: 0 8px 0 -6px !default;
$chip-multi-selection-icon-margin: 0 4px 0 -6px !default;
$chip-multi-selection-icon-margin-top: 0 !default;
$chip-delete-icon-font-size: 14px !default;
$chip-delete-icon-size: 14px !default;
$chip-delete-icon-margin: 0 -2px 0 8px !default;

//RTL
$chip-rtl-avatar-wrapper-border-radius: 14px !default;
$chip-rtl-avatar-margin: 0 -10px 0 8px !default;
$chip-rtl-leading-icon-margin: 0 -6px 0 8px !default;
$chip-rtl-delete-icon-margin: 0 8px 0 -2px !default;

//Touch
$chip-touch-border-radius: 16px !default;
$chip-touch-avatar-wrapper-border-radius: 16px !default;
$chip-touch-font-size: 14px !default;
$chip-touch-height: 32px !default;
$chip-touch-padding: 0 12px !default;
$chip-touch-avatar-size: 32px !default;
$chip-touch-avatar-margin: 0 8px 0 -12px !default;
$chip-touch-avatar-content-font-size: 15px !default;
$chip-touch-leading-icon-font-size: 16px !default;
$chip-touch-leading-icon-size: 24px !default;
$chip-touch-leading-icon-margin: 0 8px 0 -8px !default;
$chip-touch-multi-selection-icon-margin: 0 4px 0 -8px !default;
$chip-touch-delete-icon-font-size: 16px !default;
$chip-touch-delete-icon-size: 18px !default;
$chip-touch-delete-icon-margin: 0 -4px 0 8px !default;

//Touch-RTL
$chip-touch-rtl-avatar-wrapper-border-radius: 16px !default;
$chip-touch-rtl-avatar-margin: 0 -12px 0 8px !default;
$chip-touch-rtl-leading-icon-margin: 0 -8px 0 8px !default;
$chip-touch-rtl-delete-icon-margin: 0 8px 0 -4px !default;

//outline
$chip-outline-hover-bg-color: rgba($grey-black, .04) !default;
$chip-outline-focus-bg-color: rgba($grey-black, .12) !default;
$chip-outline-choice-active-bg-color: $accent !default;
$chip-outline-active-bg-color: rgba($grey-black, .14) !default;
$chip-outline-focus-active-bg-color: $chip-outline-focus-bg-color !default;
$chip-outline-choice-focus-active-bg-color: lighten($accent, 12%) !default;
$chip-outline-pressed-bg-color: rgba($grey-black, .16) !default;
$chip-outline-pressed-active-bg-color: rgba($accent, .16) !default;
$chip-outline-disabled-bg-color: transparent !default;
$chip-outline-avatar-bg-color: $grey-400 !default;
$chip-outline-avatar-hover-bg-color: $chip-outline-avatar-bg-color !default;
$chip-outline-avatar-focus-bg-color: darken($chip-outline-focus-bg-color, 12%) !default;
$chip-outline-avatar-active-bg-color: darken($chip-outline-active-bg-color, 12%) !default;
$chip-outline-avatar-choice-active-bg-color: darken($chip-outline-choice-active-bg-color, 12%) !default;
$chip-outline-avatar-focus-active-bg-color: darken($chip-outline-focus-active-bg-color, 12%) !default;
$chip-outline-avatar-choice-focus-active-bg-color: darken($chip-outline-choice-focus-active-bg-color, 12%) !default;
$chip-outline-avatar-pressed-bg-color: darken($chip-outline-pressed-bg-color, 12%) !default;
$chip-outline-avatar-pressed-active-bg-color: darken($chip-outline-pressed-active-bg-color, 12%) !default;
$chip-outline-avatar-disabled-bg-color: darken($chip-outline-disabled-bg-color, 12%) !default;
$chip-outline-font-color: rgba($grey-light-font, .87) !default;
$chip-outline-hover-font-color: rgba($grey-light-font, .87) !default;
$chip-outline-focus-font-color: rgba($grey-light-font, .87) !default;
$chip-outline-active-font-color: $chip-outline-focus-font-color;
$chip-outline-choice-active-font-color: $accent-font !default;
$chip-outline-focus-active-font-color: $chip-outline-focus-font-color !default;
$chip-outline-choice-focus-active-font-color: $accent-font !default;
$chip-outline-pressed-font-color: rgba($grey-light-font, .87) !default;
$chip-outline-disabled-font-color: rgba($grey-light-font, .26) !default;
$chip-outline-close-icon-font-color: $chip-close-icon-font-color !default;
$chip-outline-close-icon-hover-font-color: $chip-close-icon-hover-font-color !default;
$chip-outline-close-icon-pressed-font-color: $chip-close-icon-pressed-font-color !default;
$chip-outline-icon-font-color: rgba($grey-light-font, .54) !default;
$chip-outline-icon-hover-font-color: rgba($grey-light-font, .54) !default;
$chip-outline-icon-focus-font-color: rgba($grey-light-font, .54) !default;
$chip-outline-icon-active-font-color: $chip-outline-icon-focus-font-color !default;
$chip-outline-icon-focus-active-font-color: $chip-outline-icon-focus-font-color !default;
$chip-outline-choice-icon-focus-active-font-color: $accent-font !default;
$chip-outline-icon-pressed-font-color: rgba($grey-light-font, .54) !default;
$chip-outline-avatar-font-color: $chip-outline-focus-font-color !default;
$chip-outline-avatar-hover-font-color: $chip-outline-focus-font-color !default;
$chip-outline-avatar-focus-font-color: $chip-outline-icon-focus-font-color !default;
$chip-outline-avatar-active-font-color: $chip-outline-icon-active-font-color !default;
$chip-outline-avatar-choice-active-font-color: $chip-outline-choice-active-font-color !default;
$chip-outline-avatar-focus-active-font-color: $chip-outline-icon-focus-active-font-color !default;
$chip-outline-avatar-choice-focus-active-font-color: $chip-outline-choice-icon-focus-active-font-color !default;
$chip-outline-avatar-pressed-font-color: $chip-outline-icon-pressed-font-color !default;
$chip-outline-avatar-pressed-active-font-color: $chip-outline-icon-pressed-font-color;
$chip-outline-avatar-disabled-font-color: $chip-disabled-font-color !default;
$chip-outline-border-color: $grey-400 !default;
$chip-outline-hover-border-color: $grey-400 !default;
$chip-outline-focus-border-color: $grey-400 !default;
$chip-outline-choice-active-border-color: $accent !default;
$chip-outline-active-border-color: $grey-400 !default;
$chip-outline-focus-active-border-color: $chip-outline-focus-border-color !default;
$chip-outline-choice-focus-active-border-color: transparent !default;
$chip-outline-pressed-border-color: $grey-400 !default;
$chip-outline-disabled-border-color: rgba($grey-light-font, .26) !default;
$chip-outline-delete-icon-margin: 0 -4px 0 4px !default;
$chip-outline-border-size: 1px !default;
$chip-outline-avatar-margin: 0 4px 0 -13px !default;
$chip-outline-icon-margin: 0 4px 0 -4px !default;

//outline-rtl
$chip-outline-rtl-avatar-margin: 0 -12px 0 4px !default;
$chip-outline-rtl-icon-margin: 0 -4px 0 4px !default;
$chip-outline-rtl-delete-icon-margin: 0 4px 0 -4px !default;

//theme
//primary
$chip-primary-bg-color: $accent !default;
$chip-primary-hover-bg-color: lighten($chip-primary-bg-color, 8%) !default;
$chip-primary-focus-bg-color: lighten($chip-primary-bg-color, 12%) !default;
$chip-primary-active-bg-color: lighten($chip-primary-bg-color, 16%) !default;
$chip-primary-focus-active-bg-color: $chip-primary-focus-bg-color !default;
$chip-primary-pressed-bg-color: lighten($chip-primary-bg-color, 16%) !default;
$chip-primary-disabled-bg-color: $chip-disabled-bg-color !default;
$chip-primary-avatar-bg-color: darken($chip-primary-bg-color, 12%) !default;
$chip-primary-avatar-hover-bg-color: darken($chip-primary-hover-bg-color, 12%) !default;
$chip-primary-avatar-focus-bg-color: darken($chip-primary-focus-bg-color, 12%) !default;
$chip-primary-avatar-active-bg-color: darken($chip-primary-active-bg-color, 12%) !default;
$chip-primary-avatar-focus-active-bg-color: darken($chip-primary-focus-active-bg-color, 12%) !default;
$chip-primary-avatar-pressed-bg-color: darken($chip-primary-pressed-bg-color, 12%) !default;
$chip-primary-avatar-disabled-bg-color: darken($chip-primary-disabled-bg-color, 12%) !default;
$chip-primary-font-color: $accent-font !default;
$chip-primary-hover-font-color: $chip-primary-font-color !default;
$chip-primary-focus-font-color: $chip-primary-font-color !default;
$chip-primary-active-font-color: $chip-primary-font-color !default;
$chip-primary-focus-active-font-color: $chip-primary-focus-font-color !default;
$chip-primary-pressed-font-color: $chip-primary-font-color !default;
$chip-primary-disabled-font-color: $chip-disabled-font-color !default;
$chip-primary-close-icon-font-color: rgba($chip-primary-font-color, .8) !default;
$chip-primary-close-icon-hover-font-color: $chip-primary-hover-font-color !default;
$chip-primary-close-icon-pressed-font-color: $chip-primary-pressed-font-color !default;
$chip-primary-avatar-font-color: $chip-primary-font-color !default;
$chip-primary-avatar-hover-font-color: $chip-primary-hover-font-color !default;
$chip-primary-avatar-focus-font-color: $chip-primary-focus-font-color !default;
$chip-primary-avatar-active-font-color: $chip-primary-active-font-color !default;
$chip-primary-avatar-focus-active-font-color: $chip-primary-focus-active-font-color !default;
$chip-primary-avatar-pressed-font-color: $chip-primary-pressed-font-color !default;
$chip-primary-avatar-disabled-font-color: $chip-primary-disabled-font-color !default;
$chip-primary-border-color: transparent !default;
$chip-primary-hover-border-color: transparent !default;
$chip-primary-focus-border-color: transparent !default;
$chip-primary-active-border-color: transparent !default;
$chip-primary-focus-active-border-color: $chip-primary-focus-border-color !default;
$chip-primary-pressed-border-color: transparent !default;
$chip-primary-disabled-border-color: transparent !default;

//success
$chip-success-bg-color: #4d841d !default;
$chip-success-hover-bg-color: lighten($chip-success-bg-color, 8%) !default;
$chip-success-focus-bg-color: lighten($chip-success-bg-color, 12%) !default;
$chip-success-active-bg-color: lighten($chip-success-bg-color, 16%) !default;
$chip-success-focus-active-bg-color: $chip-success-focus-bg-color !default;
$chip-success-pressed-bg-color: lighten($chip-success-bg-color, 16%) !default;
$chip-success-disabled-bg-color: $chip-disabled-bg-color !default;
$chip-success-avatar-bg-color: darken($chip-success-bg-color, 12%) !default;
$chip-success-avatar-hover-bg-color: darken($chip-success-hover-bg-color, 12%) !default;
$chip-success-avatar-focus-bg-color: darken($chip-success-focus-bg-color, 12%) !default;
$chip-success-avatar-active-bg-color: darken($chip-success-active-bg-color, 12%) !default;
$chip-success-avatar-focus-active-bg-color: darken($chip-success-focus-active-bg-color, 12%) !default;
$chip-success-avatar-pressed-bg-color: darken($chip-success-pressed-bg-color, 12%) !default;
$chip-success-avatar-disabled-bg-color: darken($chip-success-disabled-bg-color, 12%) !default;
$chip-success-font-color: #fff !default;
$chip-success-hover-font-color: $chip-success-font-color !default;
$chip-success-focus-font-color: $chip-success-font-color !default;
$chip-success-active-font-color: $chip-success-font-color !default;
$chip-success-focus-active-font-color: $chip-success-focus-font-color !default;
$chip-success-pressed-font-color: $chip-success-font-color !default;
$chip-success-disabled-font-color: $chip-disabled-font-color !default;
$chip-success-close-icon-font-color: rgba($chip-success-font-color, .8) !default;
$chip-success-close-icon-hover-font-color: $chip-success-hover-font-color !default;
$chip-success-close-icon-pressed-font-color: $chip-success-pressed-font-color !default;
$chip-success-avatar-font-color: $chip-success-font-color !default;
$chip-success-avatar-hover-font-color: $chip-success-hover-font-color !default;
$chip-success-avatar-focus-font-color: $chip-success-focus-font-color !default;
$chip-success-avatar-active-font-color: $chip-success-active-font-color !default;
$chip-success-avatar-focus-active-font-color: $chip-success-focus-active-font-color !default;
$chip-success-avatar-pressed-font-color: $chip-success-pressed-font-color !default;
$chip-success-avatar-disabled-font-color: $chip-success-disabled-font-color !default;
$chip-success-border-color: transparent !default;
$chip-success-hover-border-color: transparent !default;
$chip-success-focus-border-color: transparent !default;
$chip-success-active-border-color: transparent !default;
$chip-success-focus-active-border-color: $chip-success-focus-border-color !default;
$chip-success-pressed-border-color: transparent !default;
$chip-success-disabled-border-color: transparent !default;

//info
$chip-info-bg-color: #0378d5 !default;
$chip-info-hover-bg-color: lighten($chip-info-bg-color, 8%) !default;
$chip-info-focus-bg-color: lighten($chip-info-bg-color, 12%) !default;
$chip-info-active-bg-color: lighten($chip-info-bg-color, 16%) !default;
$chip-info-focus-active-bg-color: $chip-info-focus-bg-color !default;
$chip-info-pressed-bg-color: lighten($chip-info-bg-color, 16%) !default;
$chip-info-disabled-bg-color: $chip-disabled-bg-color !default;
$chip-info-avatar-bg-color: darken($chip-info-bg-color, 12%) !default;
$chip-info-avatar-hover-bg-color: darken($chip-info-hover-bg-color, 12%) !default;
$chip-info-avatar-focus-bg-color: darken($chip-info-focus-bg-color, 12%) !default;
$chip-info-avatar-active-bg-color: darken($chip-info-active-bg-color, 12%) !default;
$chip-info-avatar-focus-active-bg-color: darken($chip-info-focus-active-bg-color, 12%) !default;
$chip-info-avatar-pressed-bg-color: darken($chip-info-pressed-bg-color, 12%) !default;
$chip-info-avatar-disabled-bg-color: darken($chip-info-disabled-bg-color, 12%) !default;
$chip-info-font-color: #fff !default;
$chip-info-hover-font-color: $chip-info-font-color !default;
$chip-info-focus-font-color: $chip-info-font-color !default;
$chip-info-active-font-color: $chip-info-font-color !default;
$chip-info-focus-active-font-color: $chip-info-focus-font-color !default;
$chip-info-pressed-font-color: $chip-info-font-color !default;
$chip-info-disabled-font-color: $chip-disabled-font-color !default;
$chip-info-close-icon-font-color: rgba($chip-info-font-color, .8) !default;
$chip-info-close-icon-hover-font-color: $chip-info-hover-font-color !default;
$chip-info-close-icon-pressed-font-color: $chip-info-pressed-font-color !default;
$chip-info-avatar-font-color: $chip-info-font-color !default;
$chip-info-avatar-hover-font-color: $chip-info-hover-font-color !default;
$chip-info-avatar-focus-font-color: $chip-info-focus-font-color !default;
$chip-info-avatar-active-font-color: $chip-info-active-font-color !default;
$chip-info-avatar-focus-active-font-color: $chip-info-focus-active-font-color !default;
$chip-info-avatar-pressed-font-color: $chip-info-pressed-font-color !default;
$chip-info-avatar-disabled-font-color: $chip-info-disabled-font-color !default;
$chip-info-border-color: transparent !default;
$chip-info-hover-border-color: transparent !default;
$chip-info-focus-border-color: transparent !default;
$chip-info-active-border-color: transparent !default;
$chip-info-focus-active-border-color: $chip-info-focus-border-color !default;
$chip-info-pressed-border-color: transparent !default;
$chip-info-disabled-border-color: transparent !default;

//warning
$chip-warning-bg-color: #c15700 !default;
$chip-warning-hover-bg-color: lighten($chip-warning-bg-color, 8%) !default;
$chip-warning-focus-bg-color: lighten($chip-warning-bg-color, 12%) !default;
$chip-warning-active-bg-color: lighten($chip-warning-bg-color, 16%) !default;
$chip-warning-focus-active-bg-color: $chip-warning-focus-bg-color !default;
$chip-warning-pressed-bg-color: lighten($chip-warning-bg-color, 16%) !default;
$chip-warning-disabled-bg-color: $chip-disabled-bg-color !default;
$chip-warning-avatar-bg-color: darken($chip-warning-bg-color, 12%) !default;
$chip-warning-avatar-hover-bg-color: darken($chip-warning-hover-bg-color, 12%) !default;
$chip-warning-avatar-focus-bg-color: darken($chip-warning-focus-bg-color, 12%) !default;
$chip-warning-avatar-active-bg-color: darken($chip-warning-active-bg-color, 12%) !default;
$chip-warning-avatar-focus-active-bg-color: darken($chip-warning-focus-active-bg-color, 12%) !default;
$chip-warning-avatar-pressed-bg-color: darken($chip-warning-pressed-bg-color, 12%) !default;
$chip-warning-avatar-disabled-bg-color: darken($chip-warning-disabled-bg-color, 12%) !default;
$chip-warning-font-color: #fff !default;
$chip-warning-hover-font-color: $chip-warning-font-color !default;
$chip-warning-focus-font-color: $chip-warning-font-color !default;
$chip-warning-active-font-color: $chip-warning-font-color !default;
$chip-warning-focus-active-font-color: $chip-warning-focus-font-color !default;
$chip-warning-pressed-font-color: $chip-warning-font-color !default;
$chip-warning-disabled-font-color: $chip-disabled-font-color !default;
$chip-warning-close-icon-font-color: rgba($chip-warning-font-color, .8) !default;
$chip-warning-close-icon-hover-font-color: $chip-warning-hover-font-color !default;
$chip-warning-close-icon-pressed-font-color: $chip-warning-pressed-font-color !default;
$chip-warning-avatar-font-color: $chip-warning-font-color !default;
$chip-warning-avatar-hover-font-color: $chip-warning-hover-font-color !default;
$chip-warning-avatar-focus-font-color: $chip-warning-focus-font-color !default;
$chip-warning-avatar-active-font-color: $chip-warning-active-font-color !default;
$chip-warning-avatar-focus-active-font-color: $chip-warning-focus-active-font-color !default;
$chip-warning-avatar-pressed-font-color: $chip-warning-pressed-font-color !default;
$chip-warning-avatar-disabled-font-color: $chip-warning-disabled-font-color !default;
$chip-warning-border-color: transparent !default;
$chip-warning-hover-border-color: transparent !default;
$chip-warning-focus-border-color: transparent !default;
$chip-warning-active-border-color: transparent !default;
$chip-warning-focus-active-border-color: $chip-warning-focus-border-color !default;
$chip-warning-pressed-border-color: transparent !default;
$chip-warning-disabled-border-color: transparent !default;

//danger
$chip-danger-bg-color: #d64113 !default;
$chip-danger-hover-bg-color: lighten($chip-danger-bg-color, 8%) !default;
$chip-danger-focus-bg-color: lighten($chip-danger-bg-color, 12%) !default;
$chip-danger-active-bg-color: lighten($chip-danger-bg-color, 16%) !default;
$chip-danger-focus-active-bg-color: $chip-danger-focus-bg-color !default;
$chip-danger-pressed-bg-color: lighten($chip-danger-bg-color, 16%) !default;
$chip-danger-disabled-bg-color: $chip-disabled-bg-color !default;
$chip-danger-avatar-bg-color: darken($chip-danger-bg-color, 12%) !default;
$chip-danger-avatar-hover-bg-color: darken($chip-danger-hover-bg-color, 12%) !default;
$chip-danger-avatar-focus-bg-color: darken($chip-danger-focus-bg-color, 12%) !default;
$chip-danger-avatar-active-bg-color: darken($chip-danger-active-bg-color, 12%) !default;
$chip-danger-avatar-focus-active-bg-color: darken($chip-danger-focus-active-bg-color, 12%) !default;
$chip-danger-avatar-pressed-bg-color: darken($chip-danger-pressed-bg-color, 12%) !default;
$chip-danger-avatar-disabled-bg-color: darken($chip-danger-disabled-bg-color, 12%) !default;
$chip-danger-font-color: #fff !default;
$chip-danger-hover-font-color: $chip-danger-font-color !default;
$chip-danger-focus-font-color: $chip-danger-font-color !default;
$chip-danger-active-font-color: $chip-danger-font-color !default;
$chip-danger-focus-active-font-color: $chip-danger-focus-font-color !default;
$chip-danger-pressed-font-color: $chip-danger-font-color !default;
$chip-danger-disabled-font-color: $chip-disabled-font-color !default;
$chip-danger-close-icon-font-color: rgba($chip-danger-font-color, .8) !default;
$chip-danger-close-icon-hover-font-color: $chip-danger-hover-font-color !default;
$chip-danger-close-icon-pressed-font-color: $chip-danger-pressed-font-color !default;
$chip-danger-avatar-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-hover-font-color: $chip-danger-hover-font-color !default;
$chip-danger-avatar-focus-font-color: $chip-danger-focus-font-color !default;
$chip-danger-avatar-active-font-color: $chip-danger-active-font-color !default;
$chip-danger-avatar-focus-active-font-color: $chip-danger-focus-active-font-color !default;
$chip-danger-avatar-pressed-font-color: $chip-danger-pressed-font-color !default;
$chip-danger-avatar-disabled-font-color: $chip-danger-disabled-font-color !default;
$chip-danger-border-color: transparent !default;
$chip-danger-hover-border-color: transparent !default;
$chip-danger-focus-border-color: transparent !default;
$chip-danger-active-border-color: transparent !default;
$chip-danger-focus-active-border-color: $chip-danger-focus-border-color !default;
$chip-danger-pressed-border-color: transparent !default;
$chip-danger-disabled-border-color: transparent !default;

//outline-theme
//primary
$chip-outline-primary-bg-color: transparent !default;
$chip-outline-primary-hover-bg-color: rgba($accent, .12) !default;
$chip-outline-primary-focus-bg-color: $chip-primary-focus-bg-color !default;
$chip-outline-primary-active-bg-color: $chip-primary-active-bg-color !default;
$chip-outline-primary-focus-active-bg-color: $chip-outline-primary-focus-bg-color !default;
$chip-outline-primary-pressed-bg-color: $chip-primary-pressed-bg-color !default;
$chip-outline-primary-disabled-bg-color: $chip-outline-disabled-bg-color !default;
$chip-outline-primary-avatar-bg-color: $chip-primary-bg-color !default;
$chip-outline-primary-avatar-hover-bg-color: $chip-outline-primary-avatar-bg-color !default;
$chip-outline-primary-avatar-focus-bg-color: darken($chip-outline-primary-focus-bg-color, 12%) !default;
$chip-outline-primary-avatar-active-bg-color: darken($chip-outline-primary-active-bg-color, 12%) !default;
$chip-outline-primary-avatar-focus-active-bg-color: darken($chip-outline-primary-focus-active-bg-color, 12%) !default;
$chip-outline-primary-avatar-pressed-bg-color: darken($chip-outline-primary-pressed-bg-color, 12%) !default;
$chip-outline-primary-avatar-disabled-bg-color: darken($chip-outline-primary-disabled-bg-color, 12%) !default;
$chip-outline-primary-font-color: $chip-primary-bg-color !default;
$chip-outline-primary-hover-font-color: $chip-primary-bg-color !default;
$chip-outline-primary-focus-font-color: $chip-primary-font-color !default;
$chip-outline-primary-active-font-color: $chip-primary-font-color !default;
$chip-outline-primary-focus-active-font-color: $chip-outline-primary-focus-font-color !default;
$chip-outline-primary-pressed-font-color: $chip-primary-font-color !default;
$chip-outline-primary-disabled-font-color: $chip-outline-disabled-font-color !default;
$chip-outline-primary-close-icon-font-color: rgba($chip-outline-primary-font-color, .8) !default;
$chip-outline-primary-close-icon-hover-font-color: $chip-outline-primary-hover-font-color !default;
$chip-outline-primary-close-icon-pressed-font-color: $chip-outline-primary-pressed-font-color !default;
$chip-outline-primary-avatar-font-color: $chip-outline-primary-focus-font-color !default;
$chip-outline-primary-avatar-hover-font-color: $chip-outline-primary-focus-font-color !default;
$chip-outline-primary-avatar-focus-font-color: $chip-outline-primary-focus-font-color !default;
$chip-outline-primary-avatar-active-font-color: $chip-outline-primary-active-font-color !default;
$chip-outline-primary-avatar-focus-active-font-color: $chip-outline-primary-focus-active-font-color !default;
$chip-outline-primary-avatar-pressed-font-color: $chip-outline-primary-pressed-font-color !default;
$chip-outline-primary-avatar-disabled-font-color: $chip-outline-primary-disabled-font-color !default;
$chip-outline-primary-border-color: $chip-primary-bg-color !default;
$chip-outline-primary-hover-border-color: $chip-primary-bg-color !default;
$chip-outline-primary-focus-border-color: transparent !default;
$chip-outline-primary-active-border-color: transparent !default;
$chip-outline-primary-focus-active-border-color: $chip-outline-primary-focus-border-color !default;
$chip-outline-primary-pressed-border-color: transparent !default;
$chip-outline-primary-disabled-border-color: $chip-outline-disabled-border-color !default;

//success
$chip-outline-success-bg-color: transparent !default;
$chip-outline-success-hover-bg-color: rgba($chip-success-bg-color, .12) !default;
$chip-outline-success-focus-bg-color: $chip-success-focus-bg-color !default;
$chip-outline-success-active-bg-color: $chip-success-active-bg-color !default;
$chip-outline-success-focus-active-bg-color: $chip-outline-success-focus-bg-color !default;
$chip-outline-success-pressed-bg-color: $chip-success-pressed-bg-color !default;
$chip-outline-success-disabled-bg-color: $chip-outline-disabled-bg-color !default;
$chip-outline-success-avatar-bg-color: $chip-success-bg-color !default;
$chip-outline-success-avatar-hover-bg-color: $chip-outline-success-avatar-bg-color !default;
$chip-outline-success-avatar-focus-bg-color: darken($chip-outline-success-focus-bg-color, 12%) !default;
$chip-outline-success-avatar-active-bg-color: darken($chip-outline-success-active-bg-color, 12%) !default;
$chip-outline-success-avatar-focus-active-bg-color: darken($chip-outline-success-focus-active-bg-color, 12%) !default;
$chip-outline-success-avatar-pressed-bg-color: darken($chip-outline-success-pressed-bg-color, 12%) !default;
$chip-outline-success-avatar-disabled-bg-color: darken($chip-outline-success-disabled-bg-color, 12%) !default;
$chip-outline-success-font-color: $chip-success-bg-color !default;
$chip-outline-success-hover-font-color: $chip-success-bg-color !default;
$chip-outline-success-focus-font-color: $chip-success-font-color !default;
$chip-outline-success-active-font-color: $chip-success-font-color !default;
$chip-outline-success-focus-active-font-color: $chip-outline-success-focus-font-color !default;
$chip-outline-success-pressed-font-color: $chip-success-font-color !default;
$chip-outline-success-disabled-font-color: $chip-outline-disabled-font-color !default;
$chip-outline-success-close-icon-font-color: rgba($chip-outline-success-font-color, .8) !default;
$chip-outline-success-close-icon-hover-font-color: $chip-outline-success-hover-font-color !default;
$chip-outline-success-close-icon-pressed-font-color: $chip-outline-success-pressed-font-color !default;
$chip-outline-success-avatar-font-color: $chip-outline-success-focus-font-color !default;
$chip-outline-success-avatar-hover-font-color: $chip-outline-success-focus-font-color !default;
$chip-outline-success-avatar-focus-font-color: $chip-outline-success-focus-font-color !default;
$chip-outline-success-avatar-active-font-color: $chip-outline-success-active-font-color !default;
$chip-outline-success-avatar-focus-active-font-color: $chip-outline-success-focus-active-font-color !default;
$chip-outline-success-avatar-pressed-font-color: $chip-outline-success-pressed-font-color !default;
$chip-outline-success-avatar-disabled-font-color: $chip-outline-success-disabled-font-color !default;
$chip-outline-success-border-color: $chip-success-bg-color !default;
$chip-outline-success-hover-border-color: $chip-success-bg-color !default;
$chip-outline-success-focus-border-color: transparent !default;
$chip-outline-success-active-border-color: transparent !default;
$chip-outline-success-focus-active-border-color: $chip-outline-success-focus-border-color !default;
$chip-outline-success-pressed-border-color: transparent !default;
$chip-outline-success-disabled-border-color: $chip-outline-disabled-border-color !default;

//info
$chip-outline-info-bg-color: transparent !default;
$chip-outline-info-hover-bg-color: rgba($chip-info-bg-color, .12) !default;
$chip-outline-info-focus-bg-color: $chip-info-focus-bg-color !default;
$chip-outline-info-active-bg-color: $chip-info-active-bg-color !default;
$chip-outline-info-focus-active-bg-color: $chip-outline-info-focus-bg-color !default;
$chip-outline-info-pressed-bg-color: $chip-info-pressed-bg-color !default;
$chip-outline-info-disabled-bg-color: $chip-outline-disabled-bg-color !default;
$chip-outline-info-avatar-bg-color: $chip-info-bg-color !default;
$chip-outline-info-avatar-hover-bg-color: $chip-outline-info-avatar-bg-color !default;
$chip-outline-info-avatar-focus-bg-color: darken($chip-outline-info-focus-bg-color, 12%) !default;
$chip-outline-info-avatar-active-bg-color: darken($chip-outline-info-active-bg-color, 12%) !default;
$chip-outline-info-avatar-focus-active-bg-color: darken($chip-outline-info-focus-active-bg-color, 12%) !default;
$chip-outline-info-avatar-pressed-bg-color: darken($chip-outline-info-pressed-bg-color, 12%) !default;
$chip-outline-info-avatar-disabled-bg-color: darken($chip-outline-info-disabled-bg-color, 12%) !default;
$chip-outline-info-font-color: $chip-info-bg-color !default;
$chip-outline-info-hover-font-color: $chip-info-bg-color !default;
$chip-outline-info-focus-font-color: $chip-info-font-color !default;
$chip-outline-info-active-font-color: $chip-info-font-color !default;
$chip-outline-info-focus-active-font-color: $chip-outline-info-focus-font-color !default;
$chip-outline-info-pressed-font-color: $chip-info-font-color !default;
$chip-outline-info-disabled-font-color: $chip-outline-disabled-font-color !default;
$chip-outline-info-close-icon-font-color: rgba($chip-outline-info-font-color, .8) !default;
$chip-outline-info-close-icon-hover-font-color: $chip-outline-info-hover-font-color !default;
$chip-outline-info-close-icon-pressed-font-color: $chip-outline-info-pressed-font-color !default;
$chip-outline-info-avatar-font-color: $chip-outline-info-focus-font-color !default;
$chip-outline-info-avatar-hover-font-color: $chip-outline-info-focus-font-color !default;
$chip-outline-info-avatar-focus-font-color: $chip-outline-info-focus-font-color !default;
$chip-outline-info-avatar-active-font-color: $chip-outline-info-active-font-color !default;
$chip-outline-info-avatar-focus-active-font-color: $chip-outline-info-focus-active-font-color !default;
$chip-outline-info-avatar-pressed-font-color: $chip-outline-info-pressed-font-color !default;
$chip-outline-info-avatar-disabled-font-color: $chip-outline-info-disabled-font-color !default;
$chip-outline-info-border-color: $chip-info-bg-color !default;
$chip-outline-info-hover-border-color: $chip-info-bg-color !default;
$chip-outline-info-focus-border-color: transparent !default;
$chip-outline-info-active-border-color: transparent !default;
$chip-outline-info-focus-active-border-color: $chip-outline-info-focus-border-color !default;
$chip-outline-info-pressed-border-color: transparent !default;
$chip-outline-info-disabled-border-color: $chip-outline-disabled-border-color !default;

//warning
$chip-outline-warning-bg-color: transparent !default;
$chip-outline-warning-hover-bg-color: rgba($chip-warning-bg-color, .12) !default;
$chip-outline-warning-focus-bg-color: $chip-warning-focus-bg-color !default;
$chip-outline-warning-active-bg-color: $chip-warning-active-bg-color !default;
$chip-outline-warning-focus-active-bg-color: $chip-outline-warning-focus-bg-color !default;
$chip-outline-warning-pressed-bg-color: $chip-warning-pressed-bg-color !default;
$chip-outline-warning-disabled-bg-color: $chip-outline-disabled-bg-color !default;
$chip-outline-warning-avatar-bg-color: $chip-warning-bg-color !default;
$chip-outline-warning-avatar-hover-bg-color: $chip-outline-warning-avatar-bg-color !default;
$chip-outline-warning-avatar-focus-bg-color: darken($chip-outline-warning-focus-bg-color, 12%) !default;
$chip-outline-warning-avatar-active-bg-color: darken($chip-outline-warning-active-bg-color, 12%) !default;
$chip-outline-warning-avatar-focus-active-bg-color: darken($chip-outline-warning-focus-active-bg-color, 12%) !default;
$chip-outline-warning-avatar-pressed-bg-color: darken($chip-outline-warning-pressed-bg-color, 12%) !default;
$chip-outline-warning-avatar-disabled-bg-color: darken($chip-outline-warning-disabled-bg-color, 12%) !default;
$chip-outline-warning-font-color: $chip-warning-bg-color !default;
$chip-outline-warning-hover-font-color: $chip-warning-bg-color !default;
$chip-outline-warning-focus-font-color: $chip-warning-font-color !default;
$chip-outline-warning-active-font-color: $chip-warning-font-color !default;
$chip-outline-warning-focus-active-font-color: $chip-outline-warning-focus-font-color !default;
$chip-outline-warning-pressed-font-color: $chip-warning-font-color !default;
$chip-outline-warning-disabled-font-color: $chip-outline-disabled-font-color !default;
$chip-outline-warning-close-icon-font-color: rgba($chip-outline-warning-font-color, .8) !default;
$chip-outline-warning-close-icon-hover-font-color: $chip-outline-warning-hover-font-color !default;
$chip-outline-warning-close-icon-pressed-font-color: $chip-outline-warning-pressed-font-color !default;
$chip-outline-warning-avatar-font-color: $chip-outline-warning-focus-font-color !default;
$chip-outline-warning-avatar-hover-font-color: $chip-outline-warning-focus-font-color !default;
$chip-outline-warning-avatar-focus-font-color: $chip-outline-warning-focus-font-color !default;
$chip-outline-warning-avatar-active-font-color: $chip-outline-warning-active-font-color !default;
$chip-outline-warning-avatar-focus-active-font-color: $chip-outline-warning-focus-active-font-color !default;
$chip-outline-warning-avatar-pressed-font-color: $chip-outline-warning-pressed-font-color !default;
$chip-outline-warning-avatar-disabled-font-color: $chip-outline-warning-disabled-font-color !default;
$chip-outline-warning-border-color: $chip-warning-bg-color !default;
$chip-outline-warning-hover-border-color: $chip-warning-bg-color !default;
$chip-outline-warning-focus-border-color: transparent !default;
$chip-outline-warning-active-border-color: transparent !default;
$chip-outline-warning-focus-active-border-color: $chip-outline-warning-focus-border-color !default;
$chip-outline-warning-pressed-border-color: transparent !default;
$chip-outline-warning-disabled-border-color: $chip-outline-disabled-border-color !default;

//danger
$chip-outline-danger-bg-color: transparent !default;
$chip-outline-danger-hover-bg-color: rgba($chip-danger-bg-color, .12) !default;
$chip-outline-danger-focus-bg-color: $chip-danger-focus-bg-color !default;
$chip-outline-danger-active-bg-color: $chip-danger-active-bg-color !default;
$chip-outline-danger-focus-active-bg-color: $chip-outline-danger-focus-bg-color !default;
$chip-outline-danger-pressed-bg-color: $chip-danger-pressed-bg-color !default;
$chip-outline-danger-disabled-bg-color: $chip-outline-disabled-bg-color !default;
$chip-outline-danger-avatar-bg-color: $chip-danger-bg-color !default;
$chip-outline-danger-avatar-hover-bg-color: $chip-outline-danger-avatar-bg-color !default;
$chip-outline-danger-avatar-focus-bg-color: darken($chip-outline-danger-focus-bg-color, 12%) !default;
$chip-outline-danger-avatar-active-bg-color: darken($chip-outline-danger-active-bg-color, 12%) !default;
$chip-outline-danger-avatar-focus-active-bg-color: darken($chip-outline-danger-focus-active-bg-color, 12%) !default;
$chip-outline-danger-avatar-pressed-bg-color: darken($chip-outline-danger-pressed-bg-color, 12%) !default;
$chip-outline-danger-avatar-disabled-bg-color: darken($chip-outline-danger-disabled-bg-color, 12%) !default;
$chip-outline-danger-font-color: $chip-danger-bg-color !default;
$chip-outline-danger-hover-font-color: $chip-danger-bg-color !default;
$chip-outline-danger-focus-font-color: $chip-danger-font-color !default;
$chip-outline-danger-active-font-color: $chip-danger-font-color !default;
$chip-outline-danger-focus-active-font-color: $chip-outline-danger-focus-font-color !default;
$chip-outline-danger-pressed-font-color: $chip-danger-font-color !default;
$chip-outline-danger-disabled-font-color: $chip-outline-disabled-font-color !default;
$chip-outline-danger-close-icon-font-color: rgba($chip-outline-danger-font-color, .8) !default;
$chip-outline-danger-close-icon-hover-font-color: $chip-outline-danger-hover-font-color !default;
$chip-outline-danger-close-icon-pressed-font-color: $chip-outline-danger-pressed-font-color !default;
$chip-outline-danger-avatar-font-color: $chip-outline-danger-focus-font-color !default;
$chip-outline-danger-avatar-hover-font-color: $chip-outline-danger-focus-font-color !default;
$chip-outline-danger-avatar-focus-font-color: $chip-outline-danger-focus-font-color !default;
$chip-outline-danger-avatar-active-font-color: $chip-outline-danger-active-font-color !default;
$chip-outline-danger-avatar-focus-active-font-color: $chip-outline-danger-focus-active-font-color !default;
$chip-outline-danger-avatar-pressed-font-color: $chip-outline-danger-pressed-font-color !default;
$chip-outline-danger-avatar-disabled-font-color: $chip-outline-danger-disabled-font-color !default;
$chip-outline-danger-border-color: $chip-danger-bg-color !default;
$chip-outline-danger-hover-border-color: $chip-danger-bg-color !default;
$chip-outline-danger-focus-border-color: transparent !default;
$chip-outline-danger-active-border-color: transparent !default;
$chip-outline-danger-focus-active-border-color: $chip-outline-danger-focus-border-color !default;
$chip-outline-danger-pressed-border-color: transparent !default;
$chip-outline-danger-disabled-border-color: $chip-outline-disabled-border-color !default;
