.e-tooltip-close::before {
  content: '\e7e9';
  font-size: 16px;
}

.e-arrow-tip-inner.e-tip-right::before {
  content: '\e848';
}

.e-arrow-tip-inner.e-tip-top::before {
  content: '\e918';
}

.e-arrow-tip-inner.e-tip-bottom::before {
  content: '\e919';
}

.e-arrow-tip-inner.e-tip-left::before {
  content: '\e84b';
}
