@include export-module('pager-material-icons') {

  /*! component icons */

  #{&}.e-pager,
  .e-grid-menu {

    .e-icon-last::before {
      content: '\e897';
    }

    .e-icon-first::before {
      content: '\e896';
    }

    .e-icon-prev::before {
      content: '\e904';
    }

    .e-icon-next::before {
      content: '\e913';
    }
    &.e-rtl {
      & .e-icon-last.e-lastpage::before,
      & .e-icon-last.e-lastpage:hover,
      & .e-icon-last.e-lastpagedisabled::before {
        content: '\e896';
      }
      & .e-icon-first.e-firstpage::before,
      & .e-icon-first.e-firstpage:hover,
      & .e-icon-first.e-firstpagedisabled::before {
        content: '\e897';
      }
      & .e-icon-prev.e-prevpage::before,
      & .e-icon-prev.e-prevpage:hover,
      & .e-icon-prev.e-prevpagedisabled::before {
        content: '\e913';
      }
      & .e-icon-next.e-nextpage::before,
      & .e-icon-next.e-nextpage:hover,
      & .e-icon-next.e-nextpagedisabled::before {
        content: '\e904';
      }
    }
  }
}
