$btn-color: rgba($grey-black, .87) !default;
$btn-font-weight: 500 !default;
$btn-border: 1px solid !default;
$btn-border-color: transparent !default;
$btn-padding: 6px 12px 4px !default;
$btn-small-padding: 2px 12px 1px !default;
$btn-bigger-padding: 4px 16px 2px !default;
$btn-bigger-small-padding: 3px 16px 1px !default;
$btn-icon-padding: 6px 7px 4px !default;
$btn-small-font-size: 12px !default;
$btn-small-icon-padding: 2px 5px 1px !default;
$btn-bigger-icon-padding: 4px 11px 2px !default;
$btn-bigger-small-icon-padding: 3px 10px 1px !default;
$btn-top-icon-padding: 12px 12px !default;
$btn-top-icon-bigger-padding: 16px 16px !default;
$btn-icon-top-bottom-padding: 6px !default;
$btn-small-icon-top-bottom-padding: 6px !default;
$btn-bigger-icon-top-bottom-padding: 8px !default;
$btn-bgcolor: $grey-50 !default;
$btn-border-radius: 2px !default;
$btn-hover-border-color: $btn-border-color !default;
$btn-active-border-color: $btn-border-color !default;
$btn-hover-color: $btn-color !default;
$btn-focus-color: $btn-color !default;
$btn-active-color: $btn-color !default;
$btn-focus-border-color: $btn-border-color !default;
$btn-text-transform: uppercase !default;
$btn-box-shadow: 0 3px 1px -2px rgba($grey-black, .2), 0 2px 2px 0 rgba($grey-black, .14), 0 1px 5px 0 rgba($grey-black, .12) !default;
$btn-hover-focus-box-shadow: 0 2px 4px -1px rgba($grey-black, .2), 0 4px 5px 0 rgba($grey-black, .14), 0 1px 10px 0 rgba($grey-black, .12) !default;
$btn-hover-bgcolor: mix($btn-color, $btn-bgcolor, 12%) !default;
$btn-focus-bgcolor: rgba($grey-light-font, .18) !default;
$btn-active-bgcolor: mix($btn-color, $btn-bgcolor, 32%) !default;
$btn-active-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$btn-disabled-color: rgba($grey-black, .26) !default;
$btn-disabled-bgcolor: rgba($grey-black, .12) !default;
$btn-disabled-border-color: $btn-border-color !default;
$btn-primary-color: $accent-font !default;
$btn-primary-hover-color: $btn-primary-color !default;
$btn-primary-focus-color: $btn-primary-color !default;
$btn-primary-active-color: $btn-primary-color !default;
$btn-primary-bgcolor: $accent !default;
$btn-primary-hover-bgcolor: mix($btn-primary-color, $btn-primary-bgcolor, 12%) !default;
$btn-primary-focus-bgcolor: mix($btn-primary-color, $btn-primary-bgcolor, 24%) !default;
$btn-primary-active-bgcolor: mix($btn-primary-color, $btn-primary-bgcolor, 32%) !default;
$btn-primary-border-color: $btn-border-color !default;
$btn-primary-hover-border-color: $btn-border-color !default;
$btn-primary-focus-border-color: $btn-border-color !default;
$btn-primary-active-border-color: $btn-border-color !default;
$btn-success-color: #fff !default;
$btn-success-bgcolor: #4d841d !default;
$btn-success-border-color: $btn-border-color !default;
$btn-success-hover-border-color: $btn-border-color !default;
$btn-success-focus-border-color: $btn-border-color !default;
$btn-success-active-border-color: $btn-border-color !default;
$btn-success-active-color: $btn-success-color !default;
$btn-info-border-color: $btn-border-color !default;
$btn-info-hover-border-color: $btn-border-color !default;
$btn-info-focus-border-color: $btn-border-color !default;
$btn-info-active-border-color: $btn-border-color !default;
$btn-info-bgcolor: #0378d5 !default;
$btn-warning-bgcolor: #c15700 !default;
$btn-warning-hover-color: #fff !default;
$btn-warning-border-color: $btn-border-color !default;
$btn-warning-hover-border-color: $btn-border-color !default;
$btn-warning-focus-border-color: $btn-border-color !default;
$btn-warning-active-border-color: $btn-border-color !default;
$btn-danger-border-color: $btn-border-color !default;
$btn-danger-hover-border-color: $btn-border-color !default;
$btn-danger-focus-border-color: $btn-border-color !default;
$btn-danger-active-border-color: $btn-border-color !default;
$btn-danger-color: #fff !default;
$btn-danger-bgcolor: #d64113 !default;
$btn-danger-active-color: $btn-danger-color !default;
$btn-flat-color: $btn-color !default;
$btn-flat-border: $btn-border !default;
$btn-flat-border-color: transparent !default;
$btn-flat-hover-color: $btn-flat-color !default;
$btn-flat-focus-color: $btn-flat-color !default;
$btn-flat-active-color: $btn-flat-color !default;
$btn-flat-bgcolor: transparent !default;
$btn-flat-disabled-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-primary-color: $btn-primary-bgcolor !default;
$btn-flat-hover-bgcolor: mix($btn-flat-color, $btn-flat-bgcolor, 4%) !default;
$btn-flat-focus-bgcolor: mix($btn-flat-color, $btn-flat-bgcolor, 12%) !default;
$btn-flat-active-bgcolor: mix($btn-flat-color, $btn-flat-bgcolor, 24%) !default;
$btn-flat-primary-hover-bgcolor: mix($btn-flat-primary-color, $btn-flat-bgcolor, 4%) !default;
$btn-flat-primary-focus-bgcolor: mix($btn-flat-primary-color, $btn-flat-bgcolor, 12%) !default;
$btn-flat-primary-active-bgcolor: mix($btn-flat-primary-color, $btn-flat-bgcolor, 24%) !default;
$btn-flat-success-hover-bgcolor: mix($btn-success-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-success-focus-bgcolor: mix($btn-success-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-success-active-bgcolor: mix($btn-success-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-info-hover-bgcolor: mix($btn-info-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-info-focus-bgcolor: mix($btn-info-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-info-active-bgcolor: mix($btn-info-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-warning-hover-bgcolor: mix($btn-warning-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-warning-focus-bgcolor: mix($btn-warning-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-warning-active-bgcolor: mix($btn-warning-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-danger-hover-bgcolor: mix($btn-danger-bgcolor, $btn-flat-bgcolor, 4%) !default;
$btn-flat-danger-focus-bgcolor: mix($btn-danger-bgcolor, $btn-flat-bgcolor, 12%) !default;
$btn-flat-danger-active-bgcolor: mix($btn-danger-bgcolor, $btn-flat-bgcolor, 24%) !default;
$btn-flat-box-shadow: none !default;
$btn-outline-color: $btn-color !default;
$btn-outline-bgcolor: transparent !default;
$btn-outline-hover-color: $btn-color !default;
$btn-outline-focus-color: $btn-color !default;
$btn-outline-active-color: $btn-color !default;
$btn-outline-border-color: #adadad !default;
$btn-outline-hover-bgcolor: rgba($grey-black, .12) !default;
$btn-outline-focus-bgcolor: rgba($grey-black, .24) !default;
$btn-outline-active-bgcolor: rgba($grey-black, .32) !default;
$btn-outline-default-focus-border-color: $btn-hover-border-color !default;
$btn-font-size: 14px !default;
$btn-bigger-font-size: $btn-font-size !default;
$btn-bigger-small-font-size: $btn-font-size !default;
$btn-icon-font-size: 12px !default;
$btn-small-icon-font-size: 11px !default;
$btn-bigger-icon-font-size: 12px !default;
$btn-bigger-small-icon-font-size: 12px !default;
$btn-round-bgcolor: $btn-bgcolor !default;
$btn-round-color: $btn-color !default;
$btn-round-border-color: $btn-border-color !default;
$btn-round-hover-bgcolor: rgba($grey-black, .12) !default;
$btn-round-hover-color: $btn-color !default;
$btn-round-height: 3em !default;
$btn-round-width: 3em !default;
$btn-round-bigger-height: 3.7143em !default;
$btn-round-bigger-width: 3.7143em !default;
$btn-round-small-height: 2.5em !default;
$btn-round-small-width: 2.5em !default;
$btn-round-bigger-small-height: 2.8572em !default;
$btn-round-bigger-small-width: 2.8572em !default;
$btn-round-font-size: 14px !default;
$btn-small-round-font-size: 12px !default;
$btn-bigger-round-font-size: 16px !default;
$btn-bigger-small-round-font-size: 14px !default;
$btn-success-hover-bgcolor: mix($btn-success-color, $btn-success-bgcolor, 12%) !default;
$btn-success-focus-bgcolor: mix($btn-success-color, $btn-success-bgcolor, 24%) !default;
$btn-success-active-bgcolor: mix($btn-success-color, $btn-success-bgcolor, 32%) !default;
$btn-info-color: #fff !default;
$btn-info-hover-bgcolor: mix($btn-info-color, $btn-info-bgcolor, 12%) !default;
$btn-info-focus-bgcolor: mix($btn-info-color, $btn-info-bgcolor, 24%) !default;
$btn-info-active-bgcolor: mix($btn-info-color, $btn-info-bgcolor, 32%) !default;
$btn-warning-color: #fff !default;
$btn-warning-hover-bgcolor: mix($btn-warning-color, $btn-warning-bgcolor, 12%) !default;
$btn-warning-focus-bgcolor: mix($btn-warning-color, $btn-warning-bgcolor, 24%) !default;
$btn-warning-active-bgcolor: mix($btn-warning-color, $btn-warning-bgcolor, 32%) !default;
$btn-danger-hover-bgcolor: mix($btn-danger-color, $btn-danger-bgcolor, 12%) !default;
$btn-danger-focus-bgcolor: mix($btn-danger-color, $btn-danger-bgcolor, 24%) !default;
$btn-danger-active-bgcolor: mix($btn-danger-color, $btn-danger-bgcolor, 32%) !default;
$btn-flat-success-color:  $btn-success-bgcolor !default;
$btn-flat-info-color:  $btn-info-bgcolor !default;
$btn-flat-warning-color:  $btn-warning-bgcolor !default;
$btn-flat-danger-color:   $btn-danger-bgcolor !default;
$btn-flat-primary-hover-color: $btn-primary-bgcolor !default;
$btn-flat-success-hover-color: $btn-success-bgcolor !default;
$btn-flat-info-hover-color: $btn-info-bgcolor !default;
$btn-flat-warning-hover-color: $btn-warning-bgcolor !default;
$btn-flat-danger-hover-color: $btn-danger-bgcolor !default;
$btn-flat-primary-focus-color: $btn-primary-bgcolor !default;
$btn-flat-success-focus-color: $btn-success-bgcolor !default;
$btn-flat-info-focus-color: $btn-info-bgcolor !default;
$btn-flat-warning-focus-color: $btn-warning-bgcolor !default;
$btn-flat-danger-focus-color: $btn-danger-bgcolor !default;
$btn-flat-primary-active-color: $btn-primary-bgcolor !default;
$btn-flat-success-active-color: $btn-success-bgcolor !default;
$btn-flat-info-active-color: $btn-info-bgcolor !default;
$btn-flat-warning-active-color: $btn-warning-bgcolor !default;
$btn-flat-danger-active-color: $btn-danger-bgcolor !default;
$btn-icon-margin: -.6667em !default;
$btn-small-icon-margin: -.7273em !default;
$btn-bigger-icon-margin: -1em !default;
$btn-bigger-small-icon-margin: -1em !default;
$btn-icon-btn-width: 1em !default;
$btn-icon-small-width: 1.091em !default;
$btn-icon-bigger-width: 1em !default;
$btn-icon-bigger-small-width: 1em !default;
$btn-icon-width: 2.25em !default;
$btn-icon-margin-top: -2px !default;
$btn-small-icon-width: 2.182em !default;
$btn-bigger-icon-width: 3em !default;
$btn-bigger-small-icon-width: 2.6667em !default;
$btn-round-icon-line-height: 2.8572em !default;
$btn-small-round-icon-line-height: 2.3334em !default;
$btn-bigger-round-icon-line-height: 3.125em !default;
$btn-bigger-small-round-icon-line-height: 2.7143em !default;
$btn-text-line-height: 1.143em !default;
$btn-bigger-text-line-height: 2em !default;
$btn-small-text-line-height: 1.5834em !default;
$btn-bigger-small-text-line-height: 2em !default;
$btn-primary-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-success-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-info-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-warning-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-danger-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-primary-disabled-color: $btn-disabled-color !default;
$btn-success-disabled-color: $btn-disabled-color !default;
$btn-info-disabled-color: $btn-disabled-color !default;
$btn-warning-disabled-color: $btn-disabled-color !default;
$btn-danger-disabled-color: $btn-disabled-color !default;
$btn-primary-disabled-border-color: $btn-disabled-border-color !default;
$btn-success-disabled-border-color: $btn-disabled-border-color !default;
$btn-info-disabled-border-color: $btn-disabled-border-color !default;
$btn-warning-disabled-border-color: $btn-disabled-border-color !default;
$btn-danger-disabled-border-color: $btn-disabled-border-color !default;
$btn-flat-disabled-color: $btn-disabled-color !default;
$btn-flat-primary-disabled-color: $btn-disabled-color !default;
$btn-flat-success-disabled-color: $btn-disabled-color !default;
$btn-flat-info-disabled-color: $btn-disabled-color !default;
$btn-flat-warning-disabled-color: $btn-disabled-color !default;
$btn-flat-danger-disabled-color: $btn-disabled-color !default;
$btn-outline-primary-disabled-color: $btn-disabled-color !default;
$btn-outline-success-disabled-color: $btn-disabled-color !default;
$btn-outline-info-disabled-color: $btn-disabled-color !default;
$btn-outline-warning-disabled-color: $btn-disabled-color !default;
$btn-outline-danger-disabled-color: $btn-disabled-color !default;
$btn-outline-primary-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-success-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-info-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-warning-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-danger-disabled-border-color: rgba($grey-black, .26) !default;
$btn-outline-active-box-shadow: $btn-flat-box-shadow !default;
$btn-flat-primary-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-success-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-info-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-warning-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-danger-bgcolor: $btn-flat-bgcolor !default;
$btn-flat-primary-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-success-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-info-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-warning-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-danger-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-flat-hover-border-color: $btn-flat-border-color !default;
$btn-flat-active-border-color: $btn-flat-border-color !default;
$btn-flat-focus-border-color: $btn-flat-border-color !default;
$btn-flat-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-primary-border-color: $btn-flat-border-color !default;
$btn-flat-primary-hover-border-color: $btn-flat-border-color !default;
$btn-flat-primary-active-border-color: $btn-flat-border-color !default;
$btn-flat-primary-focus-border-color: $btn-flat-border-color !default;
$btn-flat-primary-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-success-border-color: $btn-flat-border-color !default;
$btn-flat-success-hover-border-color: $btn-flat-border-color !default;
$btn-flat-success-active-border-color: $btn-flat-border-color !default;
$btn-flat-success-focus-border-color: $btn-flat-border-color !default;
$btn-flat-success-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-info-border-color: $btn-flat-border-color !default;
$btn-flat-info-hover-border-color: $btn-flat-border-color !default;
$btn-flat-info-active-border-color: $btn-flat-border-color !default;
$btn-flat-info-focus-border-color: $btn-flat-border-color !default;
$btn-flat-info-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-warning-border-color: $btn-flat-border-color !default;
$btn-flat-warning-hover-border-color: $btn-flat-border-color !default;
$btn-flat-warning-active-border-color: $btn-flat-border-color !default;
$btn-flat-warning-focus-border-color: $btn-flat-border-color !default;
$btn-flat-warning-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-danger-border-color: $btn-flat-border-color !default;
$btn-flat-danger-hover-border-color: $btn-flat-border-color !default;
$btn-flat-danger-active-border-color: $btn-flat-border-color !default;
$btn-flat-danger-focus-border-color: $btn-flat-border-color !default;
$btn-flat-danger-disabled-border-color: $btn-flat-border-color !default;
$btn-flat-active-box-shadow: $btn-flat-box-shadow !default;
$btn-outline-hover-border-color: $btn-outline-border-color !default;
$btn-outline-focus-border-color: $btn-outline-border-color !default;
$btn-outline-active-border-color: $btn-outline-border-color !default;
$btn-outline-primary-hover-border-color: $btn-primary-hover-border-color !default;
$btn-outline-primary-focus-border-color: $btn-primary-focus-border-color !default;
$btn-outline-primary-active-border-color: $btn-primary-active-border-color !default;
$btn-round-hover-border-color: $btn-round-hover-bgcolor !default;
$btn-round-focus-color: $btn-focus-color !default;
$btn-round-active-color: $btn-active-color !default;
$btn-warning-active-color: $btn-warning-color !default;
$btn-success-hover-color: #fff !default;
$btn-success-focus-color: $btn-success-hover-color !default;
$btn-info-active-color: $btn-info-color !default;
$btn-ripple-bgcolor: rgba($btn-color, .24) !default;
$btn-ripple-primary-bgcolor: rgba($btn-primary-color, .24) !default;
$btn-ripple-success-bgcolor: rgba($btn-success-color, .24) !default;
$btn-ripple-info-bgcolor: rgba($btn-info-color, .24) !default;
$btn-ripple-warning-bgcolor: rgba($btn-warning-color, .24) !default;
$btn-ripple-danger-bgcolor: rgba($btn-danger-color, .24) !default;
$btn-ripple-flat-bgcolor: rgba($btn-color, .12) !default;
$btn-ripple-flat-primary-bgcolor: rgba($btn-primary-bgcolor, .12) !default;
$btn-ripple-flat-success-bgcolor: rgba($btn-success-bgcolor, .12) !default;
$btn-ripple-flat-info-bgcolor: rgba($btn-info-bgcolor, .12) !default;
$btn-ripple-flat-warning-bgcolor: rgba($btn-warning-bgcolor, .12) !default;
$btn-ripple-flat-danger-bgcolor: rgba($btn-danger-bgcolor, .12) !default;
$btn-outline-primary-color: $btn-primary-bgcolor !default;
$btn-outline-primary-focus-bgcolor: $btn-primary-focus-bgcolor !default;
$btn-outline-primary-focus-color: $btn-primary-focus-color !default;
$btn-focus-outline: $btn-bgcolor 0 solid !default;
$btn-focus-outline-offset: 0 !default;
$btn-active-outline: $btn-bgcolor 0 solid !default;
$btn-active-outline-offset: 0 !default;
$btn-focus-outline-round: $btn-bgcolor 0 solid !default;
$btn-primary-outline: $btn-bgcolor 0 solid !default;
$btn-ripple-primary-bgcolor: transparent !default;
$btn-ripple-success-bgcolor: transparent !default;
$btn-info-hover-color: #fff !default;
$btn-danger-hover-color: #fff !default;
$btn-link-bgcolor: transparent !default;
$btn-link-border-color: transparent !default;
$btn-link-color: #0d47a1 !default;
$btn-link-hover-color: #0a3576 !default;
$btn-link-disabled-bgcolor: transparent !default;
$btn-focus-box-shadow: none !default;
