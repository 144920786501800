/*! calendar material theme variables */
$calendar-title-hover-color: rgba($grey-light-font, .75) !default;
$calendar-icon-hover-color: rgba($grey-light-font, .75) !default;
$calendar-default-border-color: none !default;
$calendar-icon-hover-border-color: none !default;
$calendar-light-font: rgba($grey-light-font, .87) !default;
$calendar-bg-color: $grey-white !default;
$calendar-active-font-color: $accent-font !default;
$calendar-active-hover-font-color: $accent-font !default;
$calendar-active-today-font-color: $accent-font !default;
$calendar-active-today-hover-font-color: $accent-font !default;
$calendar-active-bg-color: $accent !default;
$calendar-active-bg-border-color: 1px solid $accent !default;
$calendar-active-bg-box-shadow: none !default;
$calendar-hover-color: $grey-200 !default;
$calendar-header-font-color: rgba($grey-light-font, .87) !default;
$calendar-header-icon-color: rgba($grey-light-font, .54) !default;
$calendar-week-header-font-color: rgba($grey-light-font, .54) !default;
$calendar-title-font-color: rgba($calendar-header-icon-color, .75) !default;
$calendar-title-decoration-style: none !default;
$calendar-icon-font-color: rgba($calendar-header-icon-color, .75) !default;
$calendar-active-icon-color: $calendar-icon-font-color !default;
$calendar-active-state-icon-bg-color: $grey-white !default;
$calendar-icon-hover-bg-color: none !default;
$calendar-device-icon-hover-bg-color: none !default;
$calendar-focus-date-color: rgba($accent, .12) !default;
$calendar-other-month-date: rgba($grey-light-font, .38) !default;
$calendar-text-color: rgba($grey-light-font, .87) !default;
$calendar-hover-text: $calendar-text-color !default;
$calendar-focus-bg-color: $grey-200 !default;
$calendar-border-style: 1px solid rgba($grey-black, .12) !default;
$calendar-box-shadow: none !default;
$calendar-none-style: none !default;
$calendar-cursor-default-style: default !default;
$calendar-cursor-pointer-style: pointer !default;
$calendar-block-style: block !default;
$calendar-inline-block-style: inline-block !default;
$calendar-display-style: flex !default;
$calendar-wrapper-border-radius: 2px !default;
$calendar-full-width: 100% !default;
$calendar-lg-day-header-format-max-width: 100% !default;
$calendar-lg-day-header-format-min-width: 540px !default;
$calendar-header-font-size: 13px !default;
$calendar-title-font-size: 14px !default;
$calendar-focused-state-color: $grey-200 !default;
$calendar-bigger-header-padding: 10px 10px 0 16px !default;
$calendar-bigger-icon-size: 48px !default;
$calendar-icon-line-height: 16px !default;
$calendar-bigger-max-width: 296px !default;
$calendar-bigger-min-width: 296px !default;
$calendar-bigger-month-view-height: 36px !default;
$calendar-bigger-day-header-height: $calendar-bigger-month-view-height !default;
$calendar-bigger-year-decade-height: 60px !default;
$calendar-bigger-header-height: 48px !default;
$calendar-bigger-today-button-height: 48px !default;
$calendar-normal-header-padding: 10px 10px 0 10px !default;
$calendar-normal-max-width: 246px !default;
$calendar-normal-min-width: 240px !default;
$calendar-small-max-width: $calendar-normal-max-width !default;
$calendar-small-min-width: $calendar-normal-min-width !default;
$calendar-weeknumber-min-width: 262px !default;
$calendar-weeknumber-bigger-width: 320px !default;
$calendar-normal-month-view-height: 30px !default;
$calendar-normal-month-view-line-height: $calendar-normal-month-view-height !default;
$calendar-normal-day-header-height: $calendar-normal-month-view-height !default;
$calendar-normal-day-header-text: none !default;
$calendar-normal-year-decade-height: 45px !default;
$calendar-normal-header-height: 40px !default;
$calendar-normal-icon-size: 36px !default;
$calendar-normal-today-button-height: 36px !default;
$calendar-border-radius: 50% !default;
$calendar-pointer-events: none !default;
$calendar-float-right-style: right !default;
$calendar-icon-padding-top: 0 !default;
$calendar-float-left-style: left !default;
$calendar-table-padding: 0 10px 10px !default;
$calendar-zero-value: 0 !default;
$calendar-next-prev-icon-size: 14px !default;
$calendar-prev-icon: '\e910' !default;
$calendar-next-icon: '\e916' !default;
$calendar-date-font-size: 13px !default;
$calendar-yeardecade-bg-color: $content-bg-color !default;
$calendar-yeardecade-hover-color: $content-bg-color !default;
$calendar-title-font-weight-style: 500 !default;
$calendar-spanicon-font-weight-style: 500 !default;
$calendar-title-margin-left-style: 5px !default;
$calendar-decade-title-left-margin-style: 5px !default;
$calendar-normal-year-decade-padding: 6px !default;
$calendar-icon-font-size-style: 15px !default;
$calendar-link-font-weight-style: normal !default;
$calendar-yeardeacde-span-padding: 2px !default;
$calendar-yeardecade-padding: 10px !default;
$calendar-focused-date-bg-style: $grey-200 !default;
$calendar-focused-today-bg-style: $grey-200 !default;
$calendar-focused-today-border-style: 1px solid $accent !default;
$calendar-focused-today-box-shadow: none !default;
$calendar-yeardecade-header-padding: 10px 10px 0 10px !default;
$calendar-week-header-bg-style: none !default;
$calendar-today-bg-style: none !default;
$calendar-disable-font-weight-style: normal !default;
$calendar-other-month-display-style: none !default;
$calendar-other-month-row-display-style: none !default;
$calendar-week-number-font-style: italic !default;
$calendar-week-number-font-color: $calendar-text-color !default;
$calendar-week-number-color-style: rgba($grey-light-font, .54) !default;
$calendar-week-number-font-size-style: 12px !default;
$calendar-normal-month-cell-padding: 2px !default;
$calendar-bigger-table-month-padding: 0 10px 10px !default;
$calendar-bigger-table-yeardecade-padding: 0 10px 10px !default;
$calendar-bigger-header-font-size: 16px !default;
$calendar-bigger-month-font-size: 13px !default;
$calendar-bigger-yeardecade-header-padding: 10px 10px 0 16px !default;
$calendar-bigger-year-decade-padding: 2px !default;
$calendar-yeardecade-hover-bg: $calendar-hover-color !default;
$calendar-bigger-yeardecade-font-size: $calendar-bigger-month-font-size !default;
$calendar-bigger-icon-font-size: 16px !default;
$calendar-bigger-yeardecade-font-weight: normal !default;
$calendar-normal-day-header-font-weight: normal !default;
$calendar-other-month-date-hover-bg: $calendar-other-month-date !default;
$calendar-yeardecade-selected-hover-bg: $calendar-active-bg-color !default;
$calendar-bigger-day-font-size: 13px !default;
$calendar-yeardecade-font-weight: normal !default;
$calendar-today-color: $calendar-active-bg-color !default;
$calendar-today-focused-font-color: $accent !default;
$calendar-today-focus-color: $accent !default;
$calendar-bigger-month-view-padding: 2px !default;
$calendar-bigger-week-header-font-size: 14px !default;
$calendar-today-border-color: 1px solid $accent !default;
$calendar-today-box-shadow: none !default;
$calendar-disabled-today-box-shadow: inset 1px 0 $accent, inset 0 1px $accent, inset -1px 0 $accent, inset 0 -1px $accent !default;
$calendar-disabled-today-font-color: #ccc !default;
$calendar-focus-border-color: none !default;
$calendar-focus-box-shadow: none !default;
$calendar-hover-border-color: none !default;
$calendar-normal-month-view-width: $calendar-normal-month-view-height !default;
$calendar-normal-year-decade-width: $calendar-normal-year-decade-height !default;
$calendar-popup-padding: 0 !default;
$calendar-popup-bigger-padding: 0 !default;
$calendar-thead-padding: 0 !default;
$calendar-week-normal-max-width: $calendar-normal-max-width !default;
$calendar-week-normal-min-width: $calendar-normal-min-width !default;
$calendar-week-bigger-max-width: $calendar-bigger-max-width !default;
$calendar-week-bigger-min-width: $calendar-bigger-min-width !default;
$calendar-bigger-month-view-width: $calendar-bigger-month-view-height !default;
$calendar-bigger-month-view-line-height: 34px !default;
$calendar-bigger-year-decade-width: $calendar-bigger-year-decade-height !default;
$calendar-today-bg-hover-color: $grey-200 !default;
$calendar-disable-font-color: $calendar-light-font !default;
$calendar-disable-opacity: .35 !default;
$calendar-active-hover-bg-color: darken($calendar-active-bg-color, 10%) !default;
$calendar-other-decade-cell-color: $calendar-other-month-date !default;
$calendar-icon-padding: 10px !default;
$calendar-icon-button-margin: 0 !default;
$calendar-bigger-icon-padding: 15px !default;
$calendar-bigger-icon-margin: 0 !default;
$calendar-selected-box-shadow: inset 0 0 0 2px $grey-white !default;
$calendar-selected-border-color: none !default;
$calendar-week-number-font-weight: 500 !default;
$calendar-header-title-padding: 0 5px !default;

// Focus related styles
$calendar-title-focus-box-shadow: 0 0 0 1px $grey-900 !default;
$calendar-title-focus-background: none !default;
$calendar-title-active-background: $grey-white !default;
$calendar-icon-focus-box-shadow: 0 0 0 1px $grey-900 !default;
$calendar-title-border-radius: 4px !default;
$calendar-title-hover-bg-color: none !default;
$calendar-focused-cell-box-shadow: 0 0 0 1px $grey-900 !default;

//today
$calendar-footer-container-padding: 0 10px 10px 10px !default;
$calendar-footer-border: none !default;
$calendar-footer-background: $calendar-bg-color !default;
$calendar-bigger-footer-container-padding: 0 10px 10px 10px !default;
$calendar-bigger-footer-border: none !default;
$calendar-today-disabled-background-style: transparent !default;
$calendar-today-disabled-border-style: transparent !default;
$calendar-today-disabled-box-shadow: none !default;
$calendar-today-disabled-color: rgba($grey-black, .26) !default;

//table related styles
$calendar-rtl-text-indent: 4px !default;

// small size
$calendar-small-icon-size: 28px !default;
$calendar-small-icon-font-size-style: 12px !default;
$calendar-small-title-font-size: 13px !default;
$calendar-small-icon-padding: 6px !default;
$calendar-small-header-height: 32px !default;
$calendar-small-day-header-height: 24px !default;
$calendar-small-month-view-height: 24px !default;
$calendar-small-month-view-line-height: 22px !default;
$calendar-small-date-font-size: 12px !default;
$calendar-small-month-view-width: 24px !default;
$calendar-small-popup-padding: 0 !default;
$calendar-small-title-font-color: rgba($grey-light-font, .87) !default;
$calendar-bigger-small-table-padding: 0 10px 10px !default;
$calendar-bigger-small-yeardecade-font-size: 13px !default;
$calendar-small-font-size: 12px !default;
