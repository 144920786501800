$progress-btn-color: rgba($grey-black, .87) !default;
$progress-btn-bgcolor: rgba($grey-black, .2) !default;
$progress-btn-bgcolor-normal: rgba($grey-black, .2) !default;
$progress-btn-warning-progress-color: $progress-btn-bgcolor !default;
$progress-btn-circle-opacity: 0 !default;

$progress-btn-transition: all .3s linear !default;

$progress-btn-spinner-padding: 12px !default;
$progress-btn-spinner-padding-small: 10px !default;
$progress-btn-spinner-padding-bigger: 14px !default;
$progress-btn-spinner-padding-bigger-small: 12px !default;

$progress-btn-spin-btn-padding: 16px !default;
$progress-btn-small-spin-btn-padding: 14px !default;
$progress-btn-bigger-spin-btn-padding: 20px !default;
$progress-btn-bigger-small-spin-btn-padding: 16px !default;

$progress-btn-flat-primary-progress-color: $accent !default;
$progress-btn-flat-success-progress-color: #4d841d !default;
$progress-btn-flat-info-progress-color: #0378d5 !default;
$progress-btn-flat-warning-progress-color: #c15700 !default;
$progress-btn-flat-danger-progress-color: #d64113 !default;
$btn-flat-primary-path-arc: none;
