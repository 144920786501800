@include export-module('carousel-material-icons') {
  .e-carousel {

    .e-previous-icon::before {
      content: '\e904';
    }

    .e-next-icon::before {
      content: '\e913';
    }

    .e-play-icon::before {
      content: '\e324';
    }

    .e-pause-icon::before {
      content: '\e326';
    }

    &.e-rtl {
      .e-previous-icon::before {
        content: '\e913';
      }

      .e-next-icon::before {
        content: '\e904';
      }
    }
  }
}
