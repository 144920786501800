@include export-module('toolbar-theme') {

  /*! toolbar theme */
  /* stylelint-disable property-no-vendor-prefix */
  .e-toolbar {
    -webkit-tap-highlight-color: $tbar-tab-highlight-color;
    background: $tbar-default-bg;
    border: $tbar-border-size $tbar-border-type $tbar-default-border;
    box-shadow: $tbar-box-shadow;

    .e-toolbar-items {
      background: $tbar-default-bg;
    }

    .e-toolbar-item {

      .e-tbar-btn {
        background: $tbar-items-default-bg;
        box-shadow: $tbar-btn-box-shadow;
        color: $tbar-default-font;
        border: $tbar-btn-border;

        .e-icons {
          color: $tbar-default-icon-color;
        }

        .e-tbar-btn-text {
          color: $tbar-btn-text-color;
        }

        &.e-flat.e-active {
          background: $tbar-btn-pressed-bg;
          box-shadow: $tbar-flat-btn-active-box-shadow;
          .e-tbar-btn-text {
            color: $tbar-btn-pressed-text-color;
          }

          &:focus {
            box-shadow: $tbar-btn-pressed-focus-box-shadow;
          }
        }
  
        &:focus {
          background: $tbar-focus-bg;
          border-radius: $tbar-btn-border-radius;
          color: $tbar-hover-font;
          border-color: $tbar-btn-focus-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-btn-hover-border-size;
          box-shadow: $tbar-ext-btn-focus-box-shadow;

          .e-icons {
            color: $tbar-btn-icons-focus-color;
          }
  
          .e-tbar-btn-text {
            color: $tbar-btn-text-focus-color;
            
            @if $skin-name == 'bootstrap5' {
              color: $tbar-btn-icons-focus-color;
            }
          }
        }
  
        &:hover {
          background: $tbar-hover-bg;
          border-color: $tbar-hover-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-btn-hover-border-size;
          border-radius: $tbar-btn-border-radius;
          color: $tbar-hover-font;

          .e-icons {
            color: $tbar-btn-icons-focus-color;
            
            @if $skin-name == 'bootstrap5' {
              color: $tbar-btn-text-focus-color;
            }
          }

          .e-tbar-btn-text {
            color: $tbar-btn-text-focus-color;
          }

          &:active {
            .e-icons {
              color: $tbar-btn-hover-active-icons-color;
            }

            .e-tbar-btn-text {
              color: $tbar-btn-hover-active-text-color;
            }
          }
        }

        &:active {
          background: $tbar-btn-active-bg;
          border-color: $tbar-press-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-btn-hover-border-size;
          border-radius: $tbar-btn-border-radius;
          box-shadow: $tbar-btn-pressed-box-shadow;
          color: $tbar-press-font;

          .e-icons {
            color: $tbar-btn-active-icons-color;
          }
  
          .e-tbar-btn-text {
            color: $tbar-btn-active-text-color;
          }
        }
      }

      &.e-separator {
        border: $tbar-separator-border-type $tbar-separator-border;
        border-width: $tba-horizontal-separator;
      }

      &.e-overlay {
        background: $tbar-default-bg;
        opacity: $tbar-btn-overlay-opacity;
        color: $tbar-default-icon-overlay;

        .e-tbar-btn-text {
          color: $tbar-default-font-overlay;
        }

        .e-icons {
          color: $tbar-default-icon-overlay;
        }
      }
    }

    &.e-vertical {

      .e-hor-nav {
        border: $tbar-border-nav-type $tbar-default-border;
        border-width: $tbar-popup-vertical-border-width;
      }

      &.e-rtl .e-hor-nav {
        border: $tbar-border-nav-type $tbar-default-border;
        border-width: $tbar-popup-vertical-rtl-border-width;
      }

      .e-toolbar-items .e-toolbar-item {

        &.e-separator {
          border-width: $tba-vertical-separator;
        }
      }
    }

    .e-hor-nav {
      background: $tbar-default-bg;
      border: $tbar-border-nav-type $tbar-default-border;
      border-width: $tbar-popup-border-width;

      &::after {
        @include tbar-btn-animation;
      }

      &.e-nav-active {
        &:active,
        &:focus,
        &:hover {
          border-bottom-right-radius: $tbar-popup-nav-active-border-bottom-right-radius;
        }
      }

      &:active {
        border: $tbar-nav-press-border;
        box-shadow: $tbar-nav-pressed-box-shadow;
        color: $tbar-select-font;
        background: $tbar-popup-nav-active-bg;

        .e-icons {
          color: $tbar-popup-nav-active-icons-color;
        }

        &::after {
          @include tbar-btn-animation-after;
        }
      }

      &:hover {
        background: $tbar-popup-nav-hover-bg;
        border-left: $tbar-nav-hover-border;
        color: $tbar-popup-nav-hover-color;

        .e-icons {
          color: $tbar-popup-nav-hover-icons-color;
        }

        &:not(.e-nav-active) {
          border-color: $tbar-popup-nav-hover-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-popup-nav-hover-border-size;
        }

        &:active {
          background: $tbar-popup-nav-hover-active-bg;
          border-color: $tbar-popup-nav-hover-active-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-popup-nav-hover-active-border-size;
        }
      }

      &:focus {
        background: $tbar-popup-nav-focus-bg;
        color: $tbar-popup-nav-focus-color;
        border-color: $tbar-popup-nav-focus-border-color;
        border-style: $tbar-border-nav-type;
        border-width: $tbar-popup-nav-focus-border-size;

        .e-icons {
          color: $tbar-popup-nav-hover-icons-color;
        }
      }
    }

    &.e-toolpop {

      .e-hor-nav.e-nav-active,
      .e-hor-nav.e-nav-active:not(.e-expended-nav) {
        background: $tbar-pressed-bg;
        box-shadow: $tbar-nav-pressed-box-shadow;
        border-color: $tbar-popup-nav-pressed-border-color;
        border-style: $tbar-border-nav-type;
        border-width: $tbar-popup-nav-pressed-border-size;

        &:focus {
          border-color: $tbar-popup-nav-pressed-focus-border-color;
          border-style: $tbar-border-nav-type;
          border-width: $tbar-popup-nav-pressed-focus-border-size;
        }

        .e-icons {
          color: $tbar-active-font-color;

          &:active {
            color: $tbar-popup-nav-pressed-icons-active-color;
          }
        }
      }
    }

    .e-toolbar-pop {
      background: $tbar-pop-bg;
      border: $tbar-border-size $tbar-border-type $tbar-default-border;
      box-shadow: $tbar-pop-box-shadow;

      .e-toolbar-item {

        .e-tbar-btn {
          background: $tbar-popup-btn-bg;

          &:hover {
            background: $tbar-popup-btn-hover-bg;
            box-shadow: $tbar-popup-btn-hover-box-shadow;
            border-color: $tbar-hover-border-color;
            border-style: $tbar-border-nav-type;
            border-width: $tbar-popup-btn-hover-border-size;
          }

          &:active {
            background: $tbar-popup-btn-active-bg;
            box-shadow: $tbar-popup-btn-active-box-shadow;
            border-color: $tbar-press-border-color;
            border-style: $tbar-border-nav-type;
            border-width: $tbar-popup-btn-hover-border-size;
          }

          &:focus {
            background: $tbar-popup-btn-focus-bg;
            box-shadow: $tbar-popup-btn-focus-box-shadow;
            border-color: $tbar-btn-focus-border-color;
            border-style: $tbar-border-nav-type;
            border-width: $tbar-popup-btn-hover-border-size;
          }
        }

        .e-btn:focus {
          outline: $tbar-popup-btn-focus-outline;
        }

        &:not(.e-separator) {
          background: $tbar-item-pop-bg-color;
        }
      }
    }

    &.e-extended-toolbar {

      .e-toolbar-extended {

        .e-toolbar-item {

          .e-tbar-btn {
            background: $tbar-default-bg;
            box-shadow: $tbar-btn-box-shadow;
            border: $tbar-ext-btn-border;
  
            &:focus {
              background: $tbar-focus-bg;
              border-radius: $tbar-btn-border-radius;
              border-color: $tbar-btn-focus-border-color;
              border-style: $tbar-border-nav-type;
              border-width: $tbar-btn-hover-border-size;
              box-shadow: $tbar-ext-btn-focus-box-shadow;
            }
  
            &:hover {
              background: $tbar-hover-bg;
              border-radius: $tbar-btn-border-radius;
              border-color: $tbar-ext-btn-hover-border-color;
              border-style: $tbar-border-nav-type;
              border-width: $tbar-btn-hover-border-size;
            }
  
            &:active {
              background: $tbar-btn-press-bg;
              border-radius: $tbar-btn-border-radius;
              box-shadow: $tbar-btn-pressed-box-shadow;
              border-color: $tbar-press-border-color;
              border-style: $tbar-border-nav-type;
              border-width: $tbar-btn-hover-border-size;
            }
          }

          &.e-separator {
            border: $tbar-separator-border-type $tbar-separator-border;
            border-width: $tba-horizontal-separator;
          }

          &.e-overlay {
            background: $tbar-default-bg;
          }
        }
      }

      .e-toolbar-pop {
        background: $tbar-default-bg;
      }
    }

    &.e-rtl .e-hor-nav {
      background: $tbar-default-bg;
      border: $tbar-border-nav-type $tbar-default-border;
      border-width: $tbar-popup-rtl-border-width;

      &:not(.e-nav-active):hover {
        background: $tbar-hover-bg;
        color: $tbar-hover-font;
        border: $tbar-border-nav-type $tbar-default-border;
        border-width: $tbar-popup-rtl-border-width;
      }
    }
  }
}
