$fab-offset: 16px !default;

$fab-border-radius: 9999px !default;
$fab-min-height: 40px !default;
$fab-min-width: 40px !default;
$fab-padding: 0 15px !default;
$fab-icon-font-size: 14px !default;

$fab-small-border-radius: $fab-border-radius !default;
$fab-small-min-height: 32px !default;
$fab-small-min-width: 32px !default;
$fab-small-padding: 0 11px !default;
$fab-small-icon-font-size: 12px !default;

$fab-bigger-border-radius: $fab-border-radius !default;
$fab-bigger-min-height: 52px !default;
$fab-bigger-min-width: 52px !default;
$fab-bigger-padding: 0 21px !default;
$fab-bigger-icon-font-size: 16px !default;

$fab-bigger-small-border-radius: $fab-border-radius !default;
$fab-bigger-small-min-height: 46px !default;
$fab-bigger-small-min-width: 46px !default;
$fab-bigger-small-padding: 0 17px !default;
$fab-bigger-small-icon-font-size: 14px !default;

$fab-box-shadow:  0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px rgba(0, 0, 0, .14), 0 1px 18px rgba(0, 0, 0, .12) !default;
