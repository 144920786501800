#{&}.e-chip-list {
  .e-chip-delete {
    &.e-dlt-btn::before {
      content: '\e208';
    }
  }

  &.e-multi-selection .e-chip {
    &::before {
      content: '\e933';
    }
  }
}
