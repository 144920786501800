﻿@include export-module('timepicker-layout') {
  //   timepicker layout
  .e-input-group.e-control-wrapper.e-time-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-time-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
    display: flex;
  }

  .e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-time-wrapper {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-tap-highlight-color: transparent;

    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-normal-font-size;
      @if $timepicker-skin-name == 'material' or $timepicker-skin-name == 'material-dark' or $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-normal-input-min-height;
        min-width: $timepicker-normal-input-min-width;
      }
      @if $timepicker-skin-name == 'Material3' {
        border-radius: $timepicker-normal-icon-border-radius;
        margin: $timepicker-icon-margin;
      }
    }

    #{if(&, '&', '*')} .e-time-icon.e-icons.e-disabled {
      pointer-events: none;
    }

    #{if(&, '&', '*')} span {
      cursor: pointer;
    }
  }

  #{&}.e-timepicker.e-time-modal {
    @if $timepicker-skin-name != 'Material3' {
      background-color: $timepicker-default-overlay;
    }
    @if $timepicker-skin-name == 'Material3' {
      background: $timepicker-default-overlay;
    }
    height: 100%;
    left: 0;
    opacity: .5;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  #{&}.e-timepicker.e-popup {
    border-style: solid;
    border-width: 1px;
    overflow: auto;

    #{if(&, '&', '*')} .e-content {
      position: relative;
    }

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      margin: 0;
      padding: $timepicker-list-normal-padding 0;

      #{if(&, '&', '*')} .e-list-item {
        cursor: default;
        font-size: $timepicker-list-normal-font-size;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
      }

      #{if(&, '&', '*')} .e-list-item.e-hover {
        cursor: pointer;
      }
    }
  }

  //normal styles

  #{&}.e-timepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      line-height: $timepicker-list-normal-line-height;
      text-indent: $timepicker-list-normal-text-indent;
    }
  }

  //bigger styles
  .e-bigger .e-time-wrapper,
  #{if(&, '&', '*')}.e-bigger.e-time-wrapper,
  #{if(&, '&', '*')}.e-bigger .e-control-wrapper .e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-bigger.e-time-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-bigger-font-size;
      @if $timepicker-skin-name == 'material' or $timepicker-skin-name == 'material-dark' or $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-bigger-input-min-height;
        min-width: $timepicker-bigger-input-min-width;
      }
      @if $timepicker-skin-name == 'Material3' {
        border-radius: $timepicker-bigger-icon-border-radius;
        margin: $timepicker-bigger-icon-margin;
      }
    }
  }

  .e-bigger #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-bigger#{&}.e-timepicker.e-popup {

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      padding: $timepicker-list-bigger-padding 0;

      #{if(&, '&', '*')} .e-list-item {
        font-size: $timepicker-list-bigger-font-size;
        line-height: $timepicker-list-bigger-line-height;
        text-indent: $timepicker-list-bigger-text-indent;
      }
    }
  }

  //mouse small size
  .e-small #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-small#{&}.e-timepicker.e-popup {

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      #{if(&, '&', '*')} .e-list-item {
        font-size: $timepicker-list-small-font-size;
        line-height: $timepicker-list-small-line-height;
        text-indent: $timepicker-list-small-text-indent;
      }
    }
  }

  .e-small .e-time-wrapper,
  #{if(&, '&', '*')}.e-small.e-time-wrapper,
  #{if(&, '&', '*')}.e-small .e-control-wrapper.e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-small.e-time-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-small-font-size;
      @if $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-small-input-min-height;
        min-width: $timepicker-small-input-min-width;
        border-radius: $timepicker-small-icon-border-radius;
        margin: $timepicker-time-small-icon-margin;
      }
    }
  }

  //mouse small size
  .e-small.e-bigger #{&}.e-timepicker.e-popup,
  #{if(&, '&', '*')}.e-small.e-bigger#{&}.e-timepicker.e-popup {

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      #{if(&, '&', '*')} .e-list-item {
        font-size: $timepicker-list-bigger-small-font-size;
        line-height: $timepicker-list-bigger-small-line-height;
        text-indent: $timepicker-list-bigger-small-text-indent;
      }
    }
  }

  .e-small.e-bigger .e-time-wrapper,
  #{if(&, '&', '*')}.e-small.e-bigger.e-time-wrapper,
  #{if(&, '&', '*')}.e-small.bigger .e-control-wrapper.e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-small.bigger.e-time-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons {
      font-size: $timepicker-icon-bigger-small-font-size;
      @if $timepicker-skin-name == 'Material3' {
        min-height: $timepicker-bigger-small-input-min-height;
        min-width: $timepicker-bigger-small-input-min-width;
        border-radius: $timepicker-bigger-small-icon-border-radius;
        margin: $timepicker-bigger-small-icon-margin;
      }
    }
  }

  .e-content-placeholder.e-timepicker.e-placeholder-timepicker {
    background-size: 250px 33px;
    min-height: 33px;
  }

  .e-bigger .e-content-placeholder.e-timepicker.e-placeholder-timepicker,
  .e-bigger.e-content-placeholder.e-timepicker.e-placeholder-timepicker {
    background-size: 250px 40px;
    min-height: 40px;
  }
}

/* stylelint-disable */

.e-time-overflow {
  overflow: hidden;
}

.e-timepicker-mob-popup-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;

  .e-timepicker.e-popup.e-lib.e-control.e-popup-open {
    left: 0 !important;
    position: relative;
    top: 0 !important;
  }
}

.e-timepicker-mob-popup-wrap .e-popup-expand.e-timepicker.e-popup,
.e-datetimepicker.e-popup-expand.e-timepicker.e-popup,
.e-timepicker-mob-popup-wrap .e-popup-expand.e-datetimepicker.e-popup,
.e-datetimepicker.e-popup-expand.e-popup {
  border-radius: 0;
}

.e-timepicker-mob-popup-wrap .e-popup-expand,
.e-datetimepicker.e-popup-expand {

    #{if(&, '&', '*')} .e-model-header {
      height: $modal-header-height;
      padding: $modal-header-padding;
      display: $modal-header-display-style;
      align-items: $modal-header-content-align;
      font-size: $modal-header-portrait-font-size;
      border-bottom: $modal-header-border-bottom;

      #{if(&, '&', '*')} .e-popup-close {
        float: $modal-close-icon-float;
        padding: $modal-portrait-content-padding;
      }

      #{if(&, '&', '*')} .e-model-title {
        padding: $modal-portrait-content-padding;
        text-transform: $modal-header-title-transform;
        font-weight: 500;
      }

      #{if(&, '&', '*')} .e-btn.e-popup-close {
        font-size: $modal-header-portrait-font-size;
      }
    }

    #{if(&, '&', '*')} .e-content {
      height: $modal-content-height;
      overflow: $modal-content-overflow;

      #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
        padding: $modal-list-item-padding;
        line-height: $modal-list-line-height;

        @media (max-device-width: 768px) {
          font-size: $modal-mobile-font-size;
        }

        @media (min-device-width: 768px) {
          font-size: $modal-tablet-font-size;
        }
      }
    }
}

@media screen and (orientation: landscape) {
  .e-timepicker-mob-popup-wrap .e-popup-expand,
  .e-datetimepicker.e-popup-expand {
  
      #{if(&, '&', '*')} .e-model-header {
        height: $modal-header-landscape-height;
        font-size: $modal-header-landscape-font-size;
  
        #{if(&, '&', '*')} .e-popup-close {
          padding: $modal-landscape-padding;
        }
  
        #{if(&, '&', '*')} .e-model-title {
          padding: $modal-landscape-padding;
        }

        #{if(&, '&', '*')} .e-btn.e-popup-close {
          font-size: $modal-header-landscape-font-size;
        }
      }
  
      #{if(&, '&', '*')} .e-content {
        height: $modal-content-landscape-height;
  
        #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
          padding: $modal-list-item-padding;
          line-height: $modal-landscape-list-line-height;
        }
      }
    }
}
/* stylelint-enable */
