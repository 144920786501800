@include export-module('numerictextbox-material-icons') {
  .e-input-group-icon.e-spin-up::before {
    content: '\e834';
    font-family: 'e-icons';
  }

  .e-input-group-icon.e-spin-down::before {
    content: '\e83d';
    font-family: 'e-icons';
  }
}
