/* stylelint-disable */
$ddt-skin-name: 'material' !default;
$ddt-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 0px rgba(0, 0, 0, 0.14), 0 3px 14px 0px rgba(0, 0, 0, 0.12) !default;
$ddt-close-icon-min-height: 22px !default;
$ddt-close-icon-bigger-min-height: 26px !default;
$ddt-close-icon-width: 26px !default;
$ddt-close-icon-bigger-width: 32px !default;
$ddt-close-icon-bottom: 0 !default;
$ddt-spin-icon-margin: inherit !default;
$ddt-dd-icon-bottom: 2px !default;
$ddt-dd-icon-bigger-bottom: 3px !default;
$ddt-dd-icon-width: 18px !default;
$ddt-dd-icon-bigger-width: 20px !default;
$ddt-filter-box-shadow-color: rgba(0, 0, 0, .3) !default;
$ddt-filter-border: 0 !default;
$ddt-filter-top-border: 0 !default;
$ddt-filter-padding: 0 !default;
$ddt-filter-box-shadow: 0 1.5px 5px -2px $ddt-filter-box-shadow-color !default;
$ddt-filter-background-color: $grey-100 !default;
$ddt-list-filter-text-indent: 8px 16px 8px !default;
$ddt-rtl-close-left: -7px !default;
$ddt-chip-margin: 3px 1px !default;
$ddt-chip-padding: 0 4px 0 4px !default;
$ddt-chip-bg-color: $grey-200 !default;
$ddt-chip-radius: 16px !default;
$ddt-chip-height: 24px !default;
$ddt-chip-bigger-height: 31px !default;
$ddt-chip-content-padding: 0 4px 0 4px !default;
$ddt-chip-close-font: 16px !default;
$ddt-chip-close-height: 16px !default;
$ddt-chip-close-width: 16px !default;
$ddt-chip-icon-line-height: 16px !default;
$ddt-last-chip-right-margin: 45px !default;
$ddt-last-chip-bigger-right-margin: 52px !default;
$ddt-select-all-height: 36px !default;
$ddt-select-all-checkbox-margin: 10px !default;
$ddt-select-all-text-indent: 38px !default;
$ddt-select-all-bigger-text-indent: 43px !default;
$ddt-select-all-text-font-size: 14px !default;
$ddt-popup-reorder-border: rgba($grey-light-font, .12) !default;
$ddt-treeview-padding: 0 8px !default;
$ddt-treeview-fullrow-height: 36px !default;
$ddt-treeview-fullrow-bigger-height: 44px !default;
$ddt-treeview-content-padding-top: 2px !default;
$ddt-treeview-content-padding-bottom: 2px !default;
$ddt-popup-background-color: $grey-white !default;
$ddt-popup-border-color: transparent !default;
$ddt-chip-close:  rgba($grey-light-font, .54) !default;
$ddt-chip-font-color:  rgba($grey-light-font, .87) !default;
$ddt-chip-hover-bg-color: darken($grey-300, 4%) !default;
$ddt-filled-chip-bg-color: darken($grey-300, 7%) !default;
$ddt-chip-hover-font-color: rgba($grey-light-font, .87) !default;
$ddt-nodata-font-color: $grey-black !default;
$ddt-select-all-font-color: rgba($grey-light-font, .87) !default;
$ddt-chip-width: calc(100% - 2px) !default;
$ddt-chip-ddi-width: calc(100% - 19px) !default;
$ddt-chip-ci-width: calc(100% - 27px) !default;
$ddt-chip-ddi-ci-width: calc(100% - 45px) !default;
$ddt-big-chip-ddi-width: calc(100% - 21px) !default;
$ddt-big-chip-ci-width: calc(100% - 33px) !default;
$ddt-big-chip-ddi-ci-width: calc(100% - 53px) !default;
$ddt-remains-font-color:  rgba($grey-light-font, .54) !default;
$ddt-remains-padding: 0 0 0 16px !default;
$ddt-rtl-remains-padding: 0 16px 0 0 !default;
$ddt-chip-font-size: 13px !default;
$ddt-overflow-count-padding-top: 6px !default;
$ddt-delim-padding : 6px 0 0 0 !default;
$ddt-delim-bigger-padding: 8px 0 0 0 !default;
$ddt-remains-count-padding-top: 6px !default;
$ddt-outline-padding: 10px 12px !default;
$ddt-outline-chip-padding: 4px 12px !default;
$ddt-outline-bigger-padding: 15px 16px !default;
$ddt-bigger-outline-chip-padding: 8px 15px !default;
$ddt-item-hover-bg: $grey-100 !default;