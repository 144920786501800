$switch-wrapper-width: 34px !default;
$switch-wrapper-height: 12px !default;
$switch-font-family: '' !default;
$switch-font-size: small !default;
$switch-off-opacity: .42 !default;
$switch-inner-width: 100% !default;
$switch-handle-position: 18px !default;
$switch-active-on-opacity: .54 !default;
$switch-active-on-position-left: 0 !default;
$switch-transition: transform 90ms cubic-bezier(.4, 0, .2, 1) !default;
$switch-label-text-indent: -9999px !default;
$switch-handle-radius: 50% !default;
$switch-border-radius: 20px !default;
$switch-handle-active: 100% !default;
$switch-handle-margin-left: -18px !default;
$switch-handle-margin-left-rtl: 18px !default;
$switch-handle-height: 18px !default;
$switch-handle-width: 18px !default;
$switch-handle-top: 0 !default;
$switch-handle-bottom: 0 !default;
$switch-handle-left: 0 !default;
$switch-inner-height: 100% !default;
$switch-inner-top: 0 !default;
$switch-inner-left: 0 !default;
$switch-on-position-left: -100% !default;
$switch-off-position-left: 0 !default;
$switch-transition-delay: .05s !default;
$switch-inner-border-style: none !default;
$switch-line-height: 0 !default;
$switch-handle-margin: auto 0 !default;
$switch-handle-transition: all .2s linear !default;
$switch-inner-transition: all .08s linear !default;
$switch-inner-active: $accent !default;
$switch-off-active-left: 100% !default;
$switch-off-text-indent: -9999px !default;
$switch-on-text-indent: -9999px !default;
$switch-on-off-disabled-bg-color-opacity: .12 !default;
$switch-focused-outline: none !default;
$switch-focused-outline-active: none !default;
$switch-focused-outline-offset: initial !default;
$switch-on-off-hover-bg-color: rgba($accent, .54) !default;
$switch-inner-focus-bg-color: transparent !default;
$switch-checked-ripple-bg-color: rgba($grey-black, .12) !default;
$switch-inner-bg: initial !default;
$switch-hover-bg-color: transparent !default;
$switch-inner-focus-off-bg: transparent !default;
$switch-ripple-bg-color: rgba($accent, .12) !default;
$switch-active-background: $accent !default;
$switch-on-bg-color: $accent !default;
$switch-active-handle-bg: $accent !default;
$switch-handle-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2),
  0 2px 2px 0 rgba(0, 0, 0, .14),
  0 1px 5px 0 rgba(0, 0, 0, .12);
$switch-handle-bg-color: $grey-100 !default;
$switch-off-bg-color: $grey-black !default;
$switch-inner-active-bg: #fff !default;
$switch-inner-on-font-color: #fff !default;
$switch-inner-off-font-color: #fff !default;
$switch-inner-hover-on-font-color: #fff !default;
$switch-inner-active-border-color: transparent !default;
$switch-hover-border: inherit !default;
$switch-handle-disabled-bg-color: #bdbdbd !default;
$switch-handle-on-disabled-bg-color: $switch-handle-disabled-bg-color !default;
$switch-on-off-disabled-bg-color: $grey-black !default;
$switch-on-disabled-bg-color: $switch-on-off-disabled-bg-color !default;
$switch-on-disabled-bg-color: $grey-black !default;
$switch-handle-hover-bg-color: $accent !default;
$switch-on-hover-bg-color: $accent !default;
$switch-on-position-left-rtl: 0 !default;
$switch-off-position-left-rtl: -100% !default;
$switch-on-rtl: 100% !default;
$switch-off-rtl: 0 !default;
$switch-disabled-font-color: transparent !default;
$switch-border-disabled-bg-color: transparent !default;
$switch-inner-hover-bg-color: transparent !default;
$switch-inner-hover-border-color: transparent !default;
$switch-inner-focus-border: transparent !default;
$switch-small-on-text-indent: -9999px !default;
$switch-small-off-text-indent: -9999px !default;
$switch-small-wrapper-height: 10px !default;
$switch-small-wrapper-width: 26px !default;
$switch-small-handle-width: 16px !default;
$switch-small-handle-height: 16px !default;
$switch-small-ripple-height: 36px !default;
$switch-small-ripple-left: -10px !default;
$switch-small-ripple-width: 36px !default;
$switch-small-ripple-top: -10px !default;
$switch-small-handle-active-left: 100% !default;
$switch-small-handle-margin-left: -16px !default;
$switch-small-handle-active-left-rtl: 16px !default;
$switch-small-font-size: 9px !default;
$switch-small-line-height: 14px !default;
$switch-small-on-rtl: 100% !default;
$switch-small-off-rtl: 0 !default;
$switch-small-on-position-left-rtl: 0 !default;
$switch-small-off-position-left-rtl: -100% !default;
$switch-handle-shadow-disabled: none !default;
$switch-focus-inner-box-shadow: none !default;
$switch-focus-border-color-active: transparent !default;
$switch-handle-off-hover-bg-color: $grey-100 !default;

$switch-bigger-wrapper-height: 14px !default;
$switch-bigger-wrapper-width: 36px !default;
$switch-bigger-handle-width: 20px !default;
$switch-bigger-handle-height: 20px !default;
$switch-bigger-handle-left: 0 !default;
$switch-bigger-handle-active-left: 100% !default;
$switch-bigger-handle-margin-left: -20px !default;
$switch-bigger-handle-margin-left-rtl: 20px !default;
$switch-bigger-handle-active-left-rtl: 20px !default;
$switch-bigger-font-size: 0 !default;
$switch-bigger-line-height: 0 !default;
$switch-bigger-on-text-indent: -9999px !default;
$switch-bigger-off-text-indent: -9999px !default;
$switch-bigger-on-rtl: 100% !default;
$switch-bigger-off-rtl: 0 !default;
$switch-bigger-on-position-left-rtl: 0 !default;
$switch-bigger-off-position-left-rtl: -100% !default;
$switch-bigger-handle-top: 0 !default;
$switch-bigger-ripple-height: 52px !default;
$switch-small-disabled-rtl-active-bg-color: $grey-black !default;
$switch-bigger-ripple-left: -16px !default;
$switch-bigger-ripple-top: -16px !default;
$switch-bigger-ripple-width: 52px !default;

$switch-bigger-small-wrapper-height: 12px !default;
$switch-bigger-small-wrapper-width: 34px !default;
$switch-bigger-small-handle-width: 18px !default;
$switch-bigger-small-handle-height: 18px !default;
$switch-bigger-small-handle-left: 0 !default;
$switch-bigger-small-ripple-height: 50px !default;
$switch-bigger-small-ripple-left: -16px !default;
$switch-bigger-small-ripple-width: 50px !default;
$switch-bigger-small-ripple-top: -16px !default;
$switch-bigger-small-handle-active-left: 100% !default;
$switch-bigger-small-handle-margin-left: -18px !default;
$switch-bigger-small-handle-margin-left-rtl: 18px !default;
$switch-bigger-small-handle-active-left-rtl: 18px !default;
$switch-bigger-small-off-position-left-rtl: -100% !default;
$switch-bigger-small-font-size: 9px !default;
$switch-bigger-small-line-height: 14px !default;
$switch-bigger-small-on-rtl: 100% !default;
$switch-bigger-small-on-text-indent: -9999px !default;
$switch-bigger-small-off-text-indent: -9999px !default;
$switch-bigger-small-off-rtl: 0 !default;
$switch-bigger-small-on-position-left-rtl: 0 !default;
$switch-bigger-small-handle-to: 0 !default;
