// uploader with material theme
$zero-value: 0 !default;
$upload-root-font-family: $font-family !default;
$upload-element-opacity: $zero-value !default;
$upload-element-width: $zero-value !default;
$select-file-margin: $zero-value !default;
$select-file-padding: $zero-value !default;
$list-items-font-size: 15px !default;
$list-item-height: 35px !default;
$list-item-margin-right: 90px !default;
$list-item-name-width: 75% !default;
$delete-icon-font-size: 13px !default;
$delete-icon-opacity: 1 !default;
$float-right: right !default;
$float-left: left !default;
$right: 100% !default;
$file-status-rtl-top-margin: 7% !default;
$drop-zone-margin: 3px !default;
$remove-icon-before: 4px !default;
$file-size-top: $zero-value !default;
$action-buttons-margin: 10px !default;
$progress-bar-wrap-top: 34px !default;
$file-drop-rtl-smaller: -10px !default;
$file-drop-rtl-bigger: -10px !default;
$footer-height: 50px !default;
$file-name-top: $zero-value !default;
$file-container-top-bigger: $zero-value !default;
$remove-icon-rtl: 20px !default;
$reload-btn-right: 36px !default;
$icons-small-ie-padding: 18px 13px 18px 23px !default;
$icons-bigger-ie-padding: 20px 17px 20px 26px !default;

// Smaller values
$list-items-height-smaller: 60px !default;
$list-item-status-margin-smaller: 8px !default;
$progress-bar-top-smaller: $zero-value !default;
$file-status-top-smaller: 19px !default;
$list-item-status-top-smaller: 10px !default;
$file-size-top-smaller: 10px !default;
$file-size-left-smaller: 10px !default;
$file-type-top-smaller: $zero-value !default;
$file-size-rtl-right: 10px !default;
$list-item-size-top: 30px !default;
$file-container-height: 100% !default;
$progress-bar-paddding: 6px 6px !default;
$remove-icon-before-bigger: 15px !default;
$remove-icon-rtl-bigger: $zero-value !default;
$margin-rtl-header-smaller: 10px !default;

// Smaller RTL values
$file-status-rtl-smaller: 21px !default;
$rtl-progress-left-smaller: -23px !default;
$remove-icon-top-bigger: 10px !default;

// themes files
$progress-bar-transition: width 2s !default;
$remove-icon-disabled-color:  $zero-value !default;
$pause-play-button-right-value: 36px !default;
$pause-play-button-right-value-bigger: 45px !default;
$pause-play-button-right-value-bigger-rtl: 41px !default;
$file-container-left: 60px !default;
$ie-icons-position-value: 10px !default;
$ie-abort-icon-position-value: 12px !default;

$upload-font-family: inherit !default;
$drop-area-padding-left: 16px !default;
$drop-area-font-size: 15px !default;
$file-name-font-size: 15px !default;
$list-item-margin-left: 16px !default;
$remove-icon-height: 18px !default;
$remove-icon-width: 18px !default;
$remove-bigger-icon-height: 18px !default;
$remove-bigger-icon-width: 18px !default;
$remove-icon-before-left: 10px !default;
$remove-icon-before-top: 9px !default;
$remove-icon-margin: 16px !default;
$list-items-height: 100px !default;
$list-item-status-font-size: 12px !default;
$list-item-status-bigger-font-size: $list-item-status-font-size !default;
$list-line-height: normal !default;
$drop-text-color: rgba($grey-light-font, .87) !default;
$file-drop-text-color: $drop-text-color !default;
$drag-hover-border: 2px dashed $grey-500 !default;
$file-size-color: rgba($grey-light-font, .54) !default;
$progress-bar-height: 3px !default;
$progress-bar-text-right: 10px !default;
$progress-bar-text-top: -33px !default;
$margin-rtl: 16px !default;
$left: $zero-value !default;
$remove-icon-font-size: 12px !default;
$file-status-rtl: 54px !default;
$progress-bar-wrapper-width: 98% !default;
$progress-bar-wrapper-height: 10px !default;
$progress-inner-wrap-height: 3px !default;
$progress-inner-wrap-top: 3px !default;
$upload-success-color: $success-font-color !default;
$upload-failure-color: $error-font-color !default;
$upload-in-progress-color: $accent !default;
$file-status-top-bigger: 54px !default;
$disabled-state: rgba($grey-light-font, .38) !default;
$progress-bar-wrapper-width-bigger: 97% !default;
$footer-buttons-height: 31px !default;
$footer-buttons-height-bigger: 36px !default;
$footer-buttons-margin: 15px !default;
$header-padding: 16px 0 16px 12px !default;
$header-padding-bigger: 20px 0 20px 16px !default;
$header-padding-rtl-bigger: 20px 16px 20px $zero-value !default;
$header-padding-rtl-smaller: 16px 12px 16px $zero-value !default;
$delete-icon-padding: 20px !default;
$delete-icon-padding-smaller: 18px !default;
$remove-icon-before-left-bigger: 10px !default;
$remove-icon-before-top-bigger: 10px !default;
$file-name-font-family: inherit !default;
$file-container-top: 3px !default;
$remove-icon-top: 50% !default;
$clear-icon-focus-color: rgba($grey-black, .12) !default;
$remove-icon-margin-top: -24px !default;
$remove-icon-padding-smaller: 18px !default;

//smaller

$drop-area-font-size-smaller: 14px !default;
$drop-area-padding-left-smaller: 12px !default;
$list-items-font-size-smaller: 14px !default;
$list-item-margin-left-smaller: 12px !default;
$list-item-status-font-size-smaller: 11px !default;
$progress-inner-wrap-height-smaller: 2px !default;
$progress-bar-wrapper-height-smaller: 8px !default;
$progress-bar-height-smaller: 2px !default;
$progress-bar-text-right-smaller: 6px !default;
$progress-bar-text-top-smaller: -27px !default;
$remove-icon-font-size-smaller: 11px !default;
$remove-icon-margin-smaller: 0 12px !default;
$delete-icon-font-size-smaller: 11px !default;
$abort-icon-font-size: 18px !default;
$abort-icon-font-size-smaller: 15px !default;
$action-buttons-margin-smaller: 12px !default;
$remove-icon-top-smaller: 50% !default;
$file-name-font-size-smaller: 14px !default;
$remove-icon-right: $zero-value !default;
$li-min-height: 90px !default;
$margin-rtl-header: $zero-value !default;
$progress-text-rtl-smaller: -8px !default;
$file-name-padding-top: 16px !default;
$file-size-padding: 10px $zero-value !default;
$file-name-padding-top-smaller: 12px !default;
$file-size-padding-smaller: 8px $zero-value !default;
$footer-height-smaller: 39px !default;
$progress-bar-top-paddding: 6px !default;
$progress-bar-bottom-paddding: 6px !default;
$inner-wrap-radius: $zero-value !default;
$progress-bar-radius: $zero-value !default;

//Smaller RTL

$margin-rtl-smaller: 12px !default;
$progress-bar-wrap-top-smaller: 46px !default;
$remove-icon-rtl-top-smaller: 50% !default;
$progress-bar-width-rtl: 89% !default;
$rtl-progress-top: -25px !default;
$progress-background-color: $grey-300 !default;
$progress-text-color: $drop-text-color !default;

$remove-icon-margin-top-smaller: -18px !default;
$remove-icon-margin-top: -20px !default;
$upload-border: 1px solid rgba($grey-light-font, .12) !default;
$list-bottom-border: 1px solid rgba($grey-light-font, .12) !default;

$remove-icon-color: $grey-light-font !default;
$upload-hover-icon-color: $grey-light-font !default;
$uploader-icons-hover-radius: 50% !default;

// mouse small
$header-padding-small: 16px 0 16px 12px !default;
$drop-area-small-font-size: 13px !default;
$file-small-name-font-size: 12px !default;
$file-small-name-padding-top: 10px !default;
$list-small-item-status-font-size: 11px !default;
$file-small-size-padding: 5px 0 !default;
$remove-small-icon-font-size: 10px !default;
$remove-small-icon-height: 24px !default;
$delete-small-icon-padding: 12px !default;
$remove-small-icon-width: 24px !default;
$progress-bar-bottom-paddding-small: 3px !default;
$drop-small-area-padding-left: 12px !default;
$li-min-height-small: 76px !default;
$header-padding-rtl-small: 16px 12px 16px 0 !default;

// form upload - normal
$form-upload-file-name-line-height: 1.5 !default;
$form-upload-file-list-height: $list-item-height !default;
$form-upload-file-list-padding-top: $file-name-padding-top-smaller - 4 !default;
$form-upload-file-list-padding-bottom: $file-name-padding-top-smaller !default;
$form-upload-file-name-padding: 4px !default;
$form-upload-file-status-font-size: $list-item-status-font-size-smaller !default;
$form-upload-remove-icon-top: 32px !default;

// form upload - bigger
$form-upload-bigger-file-name-line-height: 1.5 !default;
$form-upload-bigger-file-list-height: $list-item-height !default;
$form-upload-bigger-file-list-padding-top: $file-name-padding-top - 4 !default;
$form-upload-bigger-file-list-padding-bottom: $file-name-padding-top !default;
$form-upload-bigger-file-name-padding: 4px !default;
$form-upload-bigger-file-status-font-size: $list-item-status-font-size !default;
$form-upload-bigger-remove-icon-top: 42px !default;

// form upload - small
$form-upload-small-file-name-line-height: 1.5 !default;
$form-upload-small-file-list-height: $list-item-height !default;
$form-upload-small-file-list-padding-top: $file-small-name-padding-top - 4 !default;
$form-upload-small-file-list-padding-bottom: $file-small-name-padding-top !default;
$form-upload-small-file-name-padding: 4px !default;
$form-upload-small-file-status-font-size: $list-item-status-font-size-smaller !default;
$form-upload-small-remove-icon-top: 34px !default;
