$theme: 'Material' !default;
$spin-overlay-background: rgba($grey-black, .4) !default;
$spin-overlay-stroke-color: $accent !default;
$spin-overlay-font-color: $grey-white !default;
$spin-material-stroke-color: $accent !default;
$spin-boot4-stroke-color: $accent !default;
$spin-boot4-stroke-width: 4 !default;
$spin-fabric-stroke-color: rgba($accent, .4) !default;
$spin-fabric-stroke-width: 1.5 !default;
$spin-fabric-arc-color: $accent !default;
$spin-bootstrap-stroke-color: $accent !default;
$spin-label-font-family: 'Roboto', Segoe UI !default;
$spin-label-font-size: 13px !default;
$spin-label-margin-top: 16px !default;
$spin-label-color: rgba($grey-light-font, .87) !default;
$spin-padding: 10px !default;
$spin-boot5-stroke-width: 4;
$spin-tailwind-stroke-width: 4.5;
$spin-boot5-stroke-color: $accent;
$spin-tailwind-arc-color: $accent;
$spin-tailwind-stroke-color: rgba($accent, .4);

@mixin boot5-spinner-rotate {
  animation: material-spinner-rotate .75s linear infinite;
}

@mixin tw-spinner-rotate {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(.21, .21, .21, .21);
}

@mixin mat-spinner-rotate {
  animation: material-spinner-rotate 1568.63ms linear infinite;
}

@mixin fb-spinner-rotate {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(.53, .21, .29, .67);
}

@mixin boot4-spinner-rotate {
  animation: material-spinner-rotate .75s linear infinite;
}

/* stylelint-disable property-no-vendor-prefix */
@keyframes material-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fabric-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
