@include export-module('dropdowntree-material-icons') {
  .e-ddt {
    .e-ddt-icon::before {
      content: '\e969';
    }

    .e-chips-close::before {
      content: '\e7e9';
    }
  }
}
