$menu-bigger-caret-font-size: $cmenu-bigger-caret-font-size !default;
$menu-bigger-caret-li-padding: 36px !default;
$menu-bigger-caret-right: 16px !default;
$menu-bigger-font-size: 14px !default;
$menu-bigger-li-right-padding: 16px !default;
$menu-bigger-li-height: 56px !default;
$menu-bigger-li-padding: 0 16px !default;
$menu-bigger-li-text-indent: 16px !default;
$menu-bigger-icon-margin-right: 10px !default;
$menu-color: $cmenu-color !default;
$menu-blank-icon: 44px !default;
$menu-blank-icon-bigger: 50px !default;
$menu-caret-font-size: 10px !default;
$menu-caret-icon: '\e94d' !default;
$menu-caret-icon-rtl: '\e937' !default;
$menu-caret-right: 12px !default;
$menu-caret-li-padding: 30px !default;
$menu-ul-padding: 0 !default;
$menu-separator-padding: 0 !default;
$menu-ul-header-padding: $menu-ul-padding !default;
$menu-caret-padding-left: 10px !default;
$menu-disable-opacity: .38 !default;
$menu-font-size: 13px !default;
$menu-icon-margin-right: 8px !default;
$menu-li-border-style: solid !default;
$menu-li-border-width: 1px !default;
$menu-li-selection-font-color: $cmenu-li-selection-font-color !default;
$menu-li-height: 42px !default;
$menu-li-padding: 0 12px !default;
$menu-li-text-indent: 12px !default;
$menu-li-right-padding: 12px !default;
$menu-li-bgcolor: $cmenu-li-bgcolor !default;
$menu-li-selection-bgcolor: $grey-300 !default;
$menu-li-selected-outline: 0 solid $menu-li-selection-bgcolor !default;
$menu-icon-font-size: 14px !default;
$menu-icon-bigger-font-size: 16px !default;
$menu-icon-width: 24px !default;
$menu-separator-bigger-padding: 0 !default;
$menu-seperator-border-color: rgba($grey-light-font, .12) !default;
$menu-sub-ul-bgcolor: $grey-white !default;
$menu-sub-li-border-color: rgba($grey-light-font, .12) !default;
$menu-ul-bgcolor: $grey-50 !default;
$menu-ul-border: none !default;
$menu-ul-bigger-header-padding: 0 !default;
$menu-li-hover-outline-offset: 0 !default;
$menu-li-selected-outline-offset: 0 !default;
$menu-selected-color: $cmenu-selected-color !default;
$menu-li-border-color: $cmenu-li-border-color !default;
$menu-li-hover-outline: 0 solid $menu-li-border-color !default;
$menu-hscroll-nav-size: 30px !default;
$menu-vscroll-nav-size: 16px !default;
$menu-scroll-nav-icon-size: 10px !default;
$menu-bigger-hscroll-nav-size: 36px !default;
$menu-bigger-vscroll-nav-size: 24px !default;
$menu-bigger-scroll-nav-icon-size: 12px !default;
$menu-srollbar-ul-border: none !default;
$submenu-caret-font-size: $cmenu-caret-font-size !default;
$submenu-bigger-caret-font-size: $cmenu-bigger-caret-font-size !default;
$submenu-color: $cmenu-color !default;
$menu-icon-color: $cmenu-caret-color !default;
$menu-icon-hover-color: initial !default;
$menu-icon-focussed-color: $cmenu-caret-color !default;
$menu-caret-color: $cmenu-caret-color !default;
$menu-header-icon-padding: 0 16px !default;
