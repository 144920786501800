$datepicker-icon-color: $accent !default;
$datepicker-active-icon-color: $datepicker-icon-color !default;
$datepicker-popup-box-shadow: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$datepicker-input-border-style: none !default;
$datepicker-calendar-border-style: none !default;
$datepicker-icon: '\e901' !default;
$datepicker-popup-bg-color: $grey-white !default;
$datepicker-popup-border: none !default;
$datepicker-icon-font-size: 16px !default;
$datepicker-icon-container-min-height: 24px !default;
$datepicker-icon-container-min-width: 24px !default;
$datepicker-bigger-icon-container-min-height: 26px !default;
$datepicker-bigger-icon-container-min-width: 26px !default;
$datepicker-bigger-icon-font-size: 18px !default;
$datepicker-overlay: rgba($grey-black, .6) !default;
$datepicker-icon-normal-margin: 0 0 4px 0 !default;
$datepicker-icon-bigger-margin: 5px 0 6px 0 !default;
$datepicker-icon-hover-color: $hover-bg-color !default;
$datepicker-icon-border-radius: 50% !default;
$datepicker-popup-border-radius: 0 !default;
$datepicker-box-sizing: border-box !default;
$datepicker-othermonth-row: none !default;
$datepicker-modal-header-bg: $accent !default;
$datepicker-modal-header-color: $grey-white !default;
$datepicker-modal-header-display: block !default;
$datepicker-calendar-tbody-landscape-height: 130px !default;

// mouse small icon
$datepicker-small-icon-font-size: 14px !default;

// Touch small icon
$datepicker-bigger-small-icon-font-size: 18px !default;
$zero-value: 0 !default;
$modal-year-font-size: 14px !default;
$modal-year-font-weight: 500 !default;
$modal-year-line-height: 32px !default;
$modal-month-font-size: 20px !default;
$modal-month-font-weight: 500 !default;
$modal-month-line-height: 32px !default;

// modal dialog dimensions and styles
$modal-portrait-year-font-size: 4vw !default;
$modal-portrait-header-year-margin: 12vh 0 0 0 !default;
$modal-portrait-month-font-size: 5vw !default;
$modal-portrait-header-padding: 2vh 6vw !default;
$modal-portrait-month-header-padding: 2vh 2vw !default;
$modal-portrait-month-header-vertical-align: middle !default;
$modal-month-header-line-height: 5vh !default;
$modal-month-header-title-line-height: inherit !default;

$modal-header-border-style: none !default;
$modal-header-border-width: 0 !default;

// Touch modal popup cancel icon style
$modal-portrait-cancel-icon-float-style: left !default;
$modal-portrait-cancel-icon-padding: 2px !default;
$modal-portrait-prev-icon-margin: 0 !default;
$modal-portrait-next-icon-margin: 0 !default;
$modal-portrait-header-title-margin: 5vw !default;
$modal-portrait-header-title-position: inherit !default;
$modal-portrait-header-title-text: left !default;
$modal-portrait-header-title-width: 60vw !default;
$modal-portrait-cancel-icon-color: inherit !default;
$modal-header-day-line-height: 6vw !default;

//Touch modal popup today styles
$modal-portrait-today-float-style: right !default;
$modal-today-text-color: inherit !default;
$modal-portrait-footer-display-style: none !default;
$modal-header-border-color: rgba($grey-black, .12) !default;
$modal-portrait-icon-float: right !default;

// Touch modal popup styles
$modal-portrait-header-height: 20vh !default;
$modal-portrait-header-month-height: 10vh !default;
$modal-portrait-calendar-container-height: 79vh !default;
$modal-portrait-calendar-min-height: 100% !default;
$modal-portrait-calendar-min-width: 100% !default;
$modal-portrait-calendar-height: 100% !default;
$modal-portrait-calendar-content-height: 69vh !default;
$modal-portrait-calendar-tabel-height: 69vh !default;

// Touch modal popup landscape styles
$datepicker-modal-popup-landscape-max-width: 100% !default;
$modal-landscape-header-height: 30vh !default;
$modal-landscape-header-big-height: 25vh !default;
$modal-landscape-calendar-content-height: 60vh !default;
$modal-landscape-calendar-content-table-height: 65vh !default;
$modal-landscape-calendar-content-big-height: 65vh !default;
$modal-landscape-header-year-margin: 12vh 0 0 0 !default;
$modal-landscape-prev-icon-margin: $modal-portrait-prev-icon-margin !default;
$modal-year-landscape-font-size: 3vw !default;
$modal-month-landscape-font-size: 4vw !default;
$datepicker-modal-landscape-overflow: visible !default;
$modal-month-landscape-title-line-height: 12vh !default;

// tablet device style changes for modal popup
$modal-tablet-font-size: 18px !default;
$modal-tablet-content-dimension: 64px !default;
$modal-tablet-table-header-height: 48px !default;
