@import "../../node_modules/@syncfusion/ej2-base/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-angular-grids/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-angular-navigations/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-grids/styles/material.scss";
@import "../../node_modules/@syncfusion/ej2-icons/styles/material.scss";

/* global syncfusion styling */

ejs-grid div.e-numericcontainer a.e-link.e-numericitem.e-active,
ejs-treegrid div.e-numericcontainer a.e-link.e-numericitem.e-active {
    background-color: var(--accent-color);
}
