@include export-module('check-box-material-icons') {
  .e-checkbox-wrapper,
  .e-css.e-checkbox-wrapper {
    @at-root {
      & .e-check {
        &::before {
          content: '\e933';
        }
      }

      & .e-stop {
        &::before {
          content: '\e934';
        }
      }
    }
  }
}
