$radio-btn-background-color: $grey-white !default;
$radio-btn-border: 2px solid !default;
$radio-btn-border-color: $grey-600 !default;
$radio-btn-bigger-font-size: 14px !default;
$radio-btn-bigger-height: 22px !default;
$radio-btn-bigger-line-height: 22px !default;
$radio-btn-bigger-padding: 34px !default;
$radio-btn-bigger-ripple-position: -10px !default;
$radio-btn-bigger-ripple-size: 42px !default;
$radio-btn-bigger-small-ripple-size: 40px !default;
$radio-btn-bigger-small-height: 20px !default;
$radio-btn-bigger-small-line-height: 20px !default;
$radio-btn-bigger-small-padding: 32px !default;
$radio-btn-bigger-small-width: 20px !default;
$radio-btn-bigger-width: 22px !default;
$radio-btn-checked-border-color: $accent !default;
$radio-btn-checked-color: $accent !default;
$radio-btn-checked-background-color: $radio-btn-background-color !default;
$radio-btn-checked-ripple-bgcolor: rgba($accent, .26) !default;
$radio-btn-check-transition: transform ease 280ms, background-color ease 280ms !default;
$radio-btn-disabled-border-color: $grey-400 !default;
$radio-btn-disabled-checked-color: $grey-400 !default;
$radio-btn-disabled-checked-border-color: $radio-btn-disabled-border-color !default;
$radio-btn-disabled-background-color: transparent !default;
$radio-btn-disabled-color: $grey-400 !default;
$radio-btn-focus-ripple-bgcolor: rgba($grey-black, .12) !default;
$radio-btn-focussed-box-shadow: none !default;
$radio-btn-font-color: rgba($grey-light-font, .87) !default;
$radio-btn-font-size: 13px !default;
$radio-btn-focussed-box-shadow: none !default;
$radio-btn-hover-bgcolor: $accent-font !default;
$radio-btn-hover-border-color: $grey-600 !default;
$radio-btn-hover-check-bg-color: $accent !default;
$radio-btn-hover-check-border-color: $accent !default;
$radio-btn-height: 18px !default;
$radio-btn-width: 18px !default;
$radio-btn-small-height: 14px !default;
$radio-btn-small-width: 14px !default;
$radio-btn-icon-left: 5px !default;
$radio-btn-icon-top: 5px !default;
$radio-btn-icon-right: 5px !default;
$radio-btn-small-icon-left: 4px !default;
$radio-btn-small-icon-top: 4px !default;
$radio-btn-small-icon-right: 4px !default;
$radio-btn-bigger-icon-left: 6px !default;
$radio-btn-bigger-icon-top: 6px !default;
$radio-btn-bigger-icon-right: 6px !default;
$radio-btn-bigger-small-icon-left: 6px !default;
$radio-btn-bigger-small-icon-top: 6px !default;
$radio-btn-bigger-small-icon-right: 6px !default;
$radio-btn-icon-height: 8px !default;
$radio-btn-icon-width: 8px !default;
$radio-btn-small-icon-height: 6px !default;
$radio-btn-small-icon-width: 6px !default;
$radio-btn-bigger-icon-height: 10px !default;
$radio-btn-bigger-icon-width: 10px !default;
$radio-btn-bigger-small-icon-height: 8px !default;
$radio-btn-bigger-small-icon-width: 8px !default;
$radio-btn-line-height: 18px !default;
$radio-btn-padding-left: 28px !default;
$radio-btn-ripple-bgcolor: rgba($grey-black, .12) !default;
$radio-btn-ripple-position: -8px !default;
$radio-btn-ripple-size: 34px !default;
$radio-btn-ripple-size: 34px !default;
$radio-btn-small-line-height: 14px !default;
$radio-btn-small-padding: 24px !default;
$radio-btn-small-ripple-position: -10px !default;
$radio-btn-focus-check-bg-color: $radio-btn-hover-check-bg-color !default;
$radio-btn-focus-check-border-color: $radio-btn-hover-check-border-color !default;
$radio-btn-focus-outline: $radio-btn-background-color 0 solid !default;
$radio-btn-focus-outline-offset: 0 !default;
