$listbox-font-family: $font-family !default;
$listbox-text-color: rgba($grey-light-font, .87) !default;
$listbox-text-disabled: $grey-light-font !default;
$listbox-icon-color: rgba($grey-light-font, .54) !default;
$listbox-icon-disabled: $grey-light-font !default;
$listbox-icon-margin-right: 16px !default;
$listbox-icon-line-height: 34px !default;
$listbox-icon-back-margin: 2px !default;
$listbox-rtl-icon-back-margin: -2px !default;
$listbox-background: $grey-white !default;
$listbox-line-color: $grey-black !default;
$listbox-item-hover-bg: $grey-200 !default;
$listbox-item-height: 36px !default;
$listbox-item-line-height: 1 !default;
$listbox-item-padding: 11px 16px !default;
$listbox-bigger-item-padding: 12px 16px !default;
$listbox-text-hover-color: rgba($grey-light-font, .87) !default;
$listbox-item-active-bg: $grey-100 !default;
$listbox-text-active-color: rgba($grey-light-font, .87) !default;
$listbox-header-text-color: rgba($grey-light-font, .87) !default;
$listbox-header-text-padding: 16px !default;
$listbox-header-text-disabled: $grey-light-font !default;
$listbox-header-bg: $grey-white !default;
$listbox-header-icon-color: $grey-light-font !default;
$listbox-header-icon-disabled: $grey-light-font !default;
$listbox-header-border: rgba($grey-black, .12) !default;
$listbox-header-font-size: 20px !default;
$listbox-header-height: 48px !default;
$listbox-header-font-weight: bold !default;
$listbox-header-line-height: inherit !default;
$listbox-header-padding-bottom: 0 !default;
$listbox-touch-back-icon-padding: 0 !default;
$checkmark-bgcolor: $accent !default;
$checkmark-border-color: transparent !default;
$checkmark-color: $accent-font !default;
$listbox-border-bottom: 0 !default;
$listbox-border-top: 0 !default;
$listbox-border-left: 0 !default;
$listbox-border-right: 0 !default;
$listbox-hover-border-color: transparent !default;
$listbox-border-bottom-color: transparent !default;
$listbox-border-top-color: transparent !default;
$listbox-border-right-color: transparent !default;
$listbox-border-left-color: transparent !default;
$listbox-group-first-border-bottom: 0 !default;
$listbox-group-border-top: 1px !default;
$listbox-group-border-bottom: 0 !default;
$listbox-groupheader-text-color: rgba($grey-light-font, .54) !default;
$listbox-groupheader-bg: $grey-50 !default;
$listbox-groupheader-border: $grey-black !default;
$listbox-groupheader-icon: $grey-light-font !default;
$listbox-groupheader-item-height: 36px !default;
$listbox-groupheader-item-line-height: 16px !default;
$listbox-groupheader-font-size: 15px !default;
$listbox-border-size: 0 !default;
$listbox-font-size: 15px !default;
$listbox-back-padding-right: 30px !default;
$listbox-touch-item-height: 48px !default;
$listbox-touch-item-line-height: 1.6 !default;
$listbox-touch-item-font-size: 15px !default;
$listbox-touch-groupheader-height: 48px !default;
$listbox-touch-groupheader-line-height: 25px !default;
$listbox-touch-header-height: 64px !default;
$listbox-touch-header-font-weight: bold !default;
$listbox-touch-header-font-size: 20px !default;
$listbox-touch-header-line-height: 30px !default;
$listbox-touch-header-icon-margin-top: 2px !default;
$listbox-checkbox-right-margin: -2px 0 0 10px !default;
$listbox-checkbox-left-margin: -2px 10px 0 0 !default;
$listbox-rtl-checkbox-left-margin: -2px 0 0 10px !default;
$listbox-rtl-checkbox-right-margin: -2px 10px 0 0 !default;

//ListView In-built template variables
$listbox-template-padding: .2667em 1.0666em !default;
$listbox-template-avatar-padding-right: 1.0666em !default;
$listbox-template-avatar-padding-left: 4.8em !default;
$listbox-template-avatar-rightposition-padding-right: 4.8em !default;
$listbox-template-avatar-rightposition-padding-left: 1.0666em !default;
$listbox-template-avatar-size: 2.6667em !default;
$listbox-template-avatar-top: .2666em !default;
$listbox-template-avatar-left: 1.0667em !default;
$listbox-template-avatar-rightposition-right: 1.0667em !default;
$listbox-template-badge-height: 1.6666em !default;
$listbox-template-badge-width: 2.5em !default;
$listbox-template-badge-line-height: 1.8666em !default;
$listbox-template-badge-font-size: 12px !default;
$listbox-template-badge-right: 1.33em !default;
$listbox-template-avatar-badge-padding-right: 4.1333em !default;
$listbox-template-avatar-badge-padding-left: 4.8em !default;
$listbox-template-badgewithoutavatar-padding-right: 4.1333em !default;
$listbox-template-badgewithoutavatar-padding-left: 1.0666em !default;
$listbox-template-item-padding: .72em 0 !default;
$listbox-template-multiline-header-color: rgba($grey-light-font, .87) !default;
$listbox-template-multiline-content-color: rgba($grey-light-font, .54) !default;
$listbox-template-multiline-header-padding: .115em 0 !default;
$listbox-template-multiline-content-padding: .115em 0 !default;
$listbox-template-multiline-header-font-size: 15px !default;
$listbox-template-multiline-padding: 1.0666em !default;
$listbox-template-multiline-content-font-size: 13px !default;
$listbox-template-multiline-avatar-top: 1.0666em !default;

$listbox-border-color: $grey-300;
$badge-color: $accent-font !default;
$badge-bgcolor: $accent !default;

$select-all-border-color: rgba($grey-light-font, .12) !default;

$listbox-filter-small-height: calc(100% - 45px) !default;
$listbox-filter-bigger-small-height: calc(100% - 45px) !default;
$listbox-select-height: calc(100% - 36px) !default;
$listbox-select-bigger-height: calc(100% - 48px) !default;
$listbox-filterselect-height: calc(100% - 36px) !default;
$listbox-filterselect-bigger-height: calc(100% - 48px) !default;
$listbox-mat-filter-height: calc(100% - 43px) !default;
$listbox-mat-filter-bigger-height: calc(100% - 50px) !default;
$listbox-filter-height: calc(100% - 48px) !default;
$listbox-filter-bigger-height: calc(100% - 55px) !default;
$listbox-ej2-filterselect-height: calc(100% - 83px) !default;
$listbox-ej2-filterselect-bigger-height: calc(100% - 103px) !default;
