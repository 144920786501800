// Size variables
$breadcrumb-skin: 'material' !default;
$breadcrumb-last-item-font-weight: 500 !default;
$breadcrumb-font-size: 14px !default;
$breadcrumb-bigger-font-size: 16px !default;
$breadcrumb-icon-font-size: 14px !default;
$breadcrumb-icon-bigger-font-size: 16px !default;
$breadcrumb-padding: 4px 8px !default;
$breadcrumb-padding-left: 0 !default;
$breadcrumb-padding-right: 0 !default;
$breadcrumb-bigger-padding: 4px 12px !default;
$breadcrumb-separator-padding: 4px 3px !default;
$breadcrumb-separator-bigger-padding: 4px 5px !default;
$breadcrumb-icon-right-padding: 8px !default;
$breadcrumb-icon-bigger-right-padding: 8px !default;
$breadcrumb-collapsed-icon-padding: 4px 4px !default;
$breadcrumb-collapsed-icon-bigger-padding: 5px 5px !default;
$breadcrumb-collapsed-icon-font-size: 16px !default;
$breadcrumb-collapsed-icon-bigger-font-size: 18px !default;
$breadcrumb-sibling-separator-padding: 0 !default;
$breadcrumb-sibling-separator-margin-left: -4px !default;
$breadcrumb-icon-item-padding: 2px 0 !default;
$breadcrumb-scroll-mode-lineheight: 26px !default;
$breadcrumb-popup-item-padding: 0 16px !default;
$breadcrumb-popup-border-radius: 0 !default;
$breadcrumb-popup-item-height: 36px !default;
$cmenu-li-hover-outline-offset: 0 !default;
$breadcrumb-popup-item-bigger-height: 48px !default;

// Color variables
$breadcrumb-item-color: rgba($grey-light-font, .65) !default;
$breadcrumb-separator-color: rgba($grey-light-font, .54) !default;
$breadcrumb-item-hover-color: rgba($grey-light-font, .87) !default;
$breadcrumb-item-focus-color: rgba($grey-light-font, .87) !default;
$breadcrumb-item-active-color: rgba($grey-light-font, .87) !default;
$breadcrumb-last-item-font-color: rgba($grey-light-font, .87) !default;
$breadcrumb-icon-color: rgba($grey-light-font, .54) !default;
$breadcrumb-collapsed-icon-hover-bgcolor: $grey-100 !default;
$breadcrumb-collapsed-icon-active-bgcolor: $grey-200 !default;
$breadcrumb-collapsed-icon-focus-bgcolor: $grey-100 !default;
$breadcrumb-collapsed-icon-color: rgba($grey-light-font, .54) !default;
$breadcrumb-collapsed-icon-hover-color: rgba($grey-light-font, .87) !default;
$breadcrumb-disabled-item-color: rgba($grey-light-font, .26) !default;
$breadcrumb-popup-item-color: $grey-700 !default;
$breadcrumb-popup-border: none !default;
$breadcrumb-popup-bgcolor: $grey-white !default;
$breadcrumb-popup-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$breadcrumb-popup-item-hover-bgcolor: $grey-200 !default;
$breadcrumb-popup-item-hover-color: rgba($grey-light-font, .87) !default;
$breadcrumb-popup-item-hover-outline: 0 solid rgba($grey-light-font, .12) !default;
