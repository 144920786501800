$listview-font-family: $font-family !default;
$listview-text-color: rgba($grey-light-font, .87) !default;
$listview-text-disabled: $grey-light-font !default;
$listview-icon-color: rgba($grey-light-font, .54) !default;
$listview-icon-disabled: $grey-light-font !default;
$listview-icon-margin-right: 16px !default;
$listview-icon-line-height: 34px !default;
$listview-icon-back-margin: 2px !default;
$listview-rtl-icon-back-margin: -2px !default;
$listview-background: $grey-white !default;
$listview-line-color: $grey-black !default;
$listview-item-hover-bg: $grey-200 !default;
$listview-item-height: 36px !default;
$listview-item-line-height: 36px !default;
$listview-item-padding: 0 16px !default;
$listview-text-hover-color: rgba($grey-light-font, .87) !default;
$listview-item-active-bg: $grey-100 !default;
$listview-root-border-color: none;
$listview-root-border-radius: 0;
$listview-nested-icon-collapsible: 12px;
$listview-text-active-color: rgba($grey-light-font, .87) !default;
$listview-header-text-color: rgba($grey-light-font, .87) !default;
$listview-header-text-padding: 16px !default;
$listview-header-text-disabled: $grey-light-font !default;
$listview-header-bg: $grey-white !default;
$listview-header-icon-color: $grey-light-font !default;
$listview-header-icon-disabled: $grey-light-font !default;
$listview-header-border: rgba($grey-black, .12) !default;
$listview-header-font-size: 20px !default;
$listview-header-height: 48px !default;
$listview-header-font-weight: bold !default;
$listview-header-line-height: inherit !default;
$listview-header-padding-bottom: 0 !default;
$listview-touch-back-icon-padding: 0 !default;
$checkmark-bgcolor: $accent !default;
$checkmark-border-color: transparent !default;
$checkmark-color: $accent-font !default;
$listview-border-bottom: 0 !default;
$listview-border-top: 0 !default;
$listview-border-left: 0 !default;
$listview-border-right: 0 !default;
$listview-hover-border-color: transparent !default;
$listview-border-bottom-color: transparent !default;
$listview-border-top-color: transparent !default;
$listview-border-right-color: transparent !default;
$listview-border-left-color: transparent !default;
$listview-group-first-border-bottom: 0 !default;
$listview-group-border-top: 1px !default;
$listview-group-border-bottom: 0 !default;
$listview-groupheader-text-color: rgba($grey-light-font, .54) !default;
$listview-groupheader-bg: $grey-50 !default;
$listview-groupheader-border: $grey-black !default;
$listview-groupheader-icon: $grey-light-font !default;
$listview-groupheader-item-height: 36px !default;
$listview-groupheader-item-line-height: 36px !default;
$listview-groupheader-font-size: 15px !default;
$listview-border-size: 0 !default;
$listview-font-size: 15px !default;
$listview-back-padding-right: 30px !default;
$listview-back-icon-height: 1px !default;
$listview-back-icon-font-size: auto !default;
$listview-touch-item-height: 48px !default;
$listview-touch-item-line-height: 42px !default;
$listview-touch-item-font-size: 15px !default;
$listview-touch-groupheader-height: 48px !default;
$listview-touch-groupheader-line-height: 45px !default;
$listview-touch-header-height: 64px !default;
$listview-touch-header-font-weight: bold !default;
$listview-touch-header-font-size: 20px !default;
$listview-touch-header-line-height: 30px !default;
$listview-touch-header-icon-margin-top: 2px !default;
$listview-checkbox-right-margin: -2px 0 0 10px !default;
$listview-checkbox-left-margin: -2px 10px 0 0 !default;
$listview-rtl-checkbox-left-margin: -2px 0 0 10px !default;
$listview-rtl-checkbox-right-margin: -2px 10px 0 0 !default;

//ListView In-built template variables
$listview-template-padding: .2667em 1.0666em !default;
$listview-template-avatar-padding-right: 1.0666em !default;
$listview-template-avatar-padding-left: 4.8em !default;
$listview-template-avatar-rightposition-padding-right: 4.8em !default;
$listview-template-avatar-rightposition-padding-left: 1.0666em !default;
$listview-template-avatar-size: 2.6667em !default;
$listview-template-avatar-top: .2666em !default;
$listview-template-avatar-left: 1.0667em !default;
$listview-template-avatar-rightposition-right: 1.0667em !default;
$listview-template-badge-height: 1.6666em !default;
$listview-template-badge-width: 2.5em !default;
$listview-template-badge-line-height: 1.8666em !default;
$listview-template-badge-font-size: 12px !default;
$listview-template-badge-right: 1.33em !default;
$listview-template-avatar-badge-padding-right: 4.1333em !default;
$listview-template-avatar-badge-padding-left: 4.8em !default;
$listview-template-badgewithoutavatar-padding-right: 4.1333em !default;
$listview-template-badgewithoutavatar-padding-left: 1.0666em !default;
$listview-template-item-padding: .72em 0 !default;
$listview-template-multiline-header-color: rgba($grey-light-font, .87) !default;
$listview-template-multiline-content-color: rgba($grey-light-font, .54) !default;
$listview-template-multiline-header-padding: .115em 0 !default;
$listview-template-multiline-content-padding: .115em 0 !default;
$listview-template-multiline-header-font-size: 15px !default;
$listview-template-multiline-padding: 1.0666em !default;
$listview-template-multiline-content-font-size: 13px !default;
$listview-template-multiline-avatar-top: 1.0666em !default;
