@include export-module('timepicker-material-icons') {

  /*! component icons */

  .e-time-wrapper,
  #{if(&, '&', '*')}.e-control-wrapper.e-time-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      content: '\e20c';
    }
  }

  // Outline textbox icon configuration

  .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-bigger .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-time-icon,
  .e-bigger .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 24px;
  }

  .e-small .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-time-icon,
  .e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 18px;
  }

  .e-bigger.e-small .e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
  .e-bigger.e-small.e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
  .e-time-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-time-icon,
  .e-bigger.e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
    min-height: $zero-value;
    min-width: 20px;
  }

  .e-timepicker-mob-popup-wrap .e-timepicker.e-popup-expand,
  .e-datetimepicker.e-popup-expand {

    #{if(&, '&', '*')} .e-model-header {

      #{if(&, '&', '*')} .e-popup-close::before {
        content: '\e932';
        font-family: 'e-icons';
      }
    }
  }
}
