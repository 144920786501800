@include export-module('hscroll-material-icons') {

  /*! hscroll icons */
  #{&}.e-hscroll {

    &.e-rtl {

      &.e-scroll-device {

        .e-nav-right-arrow::before {
          content: '\e904';
        }

        .e-nav-left-arrow::before {
          content: '\e913';
        }
      }

      .e-nav-left-arrow::before {
        content: '\e913';
      }

      .e-nav-right-arrow::before {
        content: '\e904';
      }
    }

    &.e-scroll-device {

      .e-nav-right-arrow::before {
        content: '\e913';
      }

      .e-nav-left-arrow::before {
        content: '\e904';
      }
    }

    .e-nav-left-arrow::before {
      content: '\e904';
      line-height: normal;
    }

    .e-nav-right-arrow::before {
      content: '\e913';
      line-height: normal;
    }
  }
}
