﻿@include export-module('grid-layout') {

  /*! Grid layout */
  .e-disableuserselect {
    user-select: none;
  }

  /*! Blazor column menu custom css */
  .e-contextmenu-container.e-sfcontextmenu.e-hide-menu {
    visibility: hidden;
  }

  .e-emptyicon {
    opacity: 0;
  }

  .e-device .e-flmenu-valuediv {
    padding: $grid-flmenu-device-padding;
  }

  .e-bigger #{&}.e-grid,
  #{&}.e-grid.e-bigger  {
    font-size: $grid-bigger-font-size;

    .e-gridheader thead .e-icons:not(.e-check):not(.e-stop) {
      font-size: $grid-bigger-icon-font-size;
    }

    .e-row .e-icon-rowdragicon::before {
      font-size: $grid-bigger-icon-font-size;
    }

    .e-res-toolbar .e-toolbar-items .e-toolbar-item.e-search-wrapper.e-responsive-right{
      padding-right: $grid-res-toolbar-search-wrapper-padding-right;
    }

    .e-res-toolbar .e-toolbar-items .e-toolbar-item.e-search-wrapper {
      padding: $grid-res-toolbar-search-wrapper-padding;

      .e-input.e-search {
        width: $grid-res-toolbar-clear-icon-width;
      }

      .e-input-group-icon.e-icons.e-sicon.e-clear-icon {
        margin: $grid-res-toolbar-clear-icon-margin;
      }

      & .e-sicon.e-clear-icon::before {
        font-size: $grid-res-toolbar-search-clear-icon-font-size;
      }

      & .e-search .e-search-icon {
        padding-left: $grid-res-toolbar-search-icon-padding-left;
      }
    }

    .e-toolbar-items .e-toolbar-item.e-search-wrapper {
      padding-bottom: $grid-bigger-toolbar-search-wrapper-padding-bottom;
      padding-top: $grid-bigger-toolbar-search-wrapper-padding-top;

      & .e-search .e-search-icon {
        min-width: $grid-bigger-toolbar-search-icon-min-width;
      }
    }

    .e-toolbar .e-toolbar-item.e-search-wrapper {
      @if $grid-skin == 'material3' {
        & .e-search {
          width: $grid-bigger-toolbar-search-width;
        }
      }
      & .e-sicon.e-clear-icon {
        margin: $grid-bigger-toolbar-search-clear-icon-margin;
        min-width: $grid-bigger-toolbar-search-clear-icon-min-width;
        padding: $grid-bigger-toolbar-search-clear-icon-padding;
      }

      & .e-sicon.e-clear-icon::before {
        font-size: $grid-bigger-toolbar-search-clear-icon-font-size;
      }
    }

    &.e-rtl .e-toolbar .e-toolbar-item.e-search-wrapper {
      & .e-sicon.e-clear-icon {
        margin: $grid-rtl-bigger-toolbar-search-clear-icon-margin;
        min-width: $grid-rtl-bigger-toolbar-search-clear-icon-min-width;
        padding: $grid-rtl-bigger-toolbar-search-clear-icon-padding;
      }
    }

    .e-toolbar .e-toolbar-items .e-tbar-btn.e-btn .e-btn-icon.e-icons {
      font-size: $grid-bigger-toolbar-icon-size;
    }

    .e-flmenu-valuediv {
      padding: $grid-bigger-flmenu-padding;
    }

    .e-headercell {
      height: $grid-bigger-headercell-line-height;
      padding: $grid-bigger-headercell-top-padding $grid-bigger-headercell-padding $grid-bigger-headercell-bootom-padding;
    }

    &.e-grid-autofit .e-headercell {
      padding: $grid-bigger-headercell-autofit-padding;
    }

    .e-headercelldiv {
      font-size: $grid-bigger-header-font-size;
      height: $grid-bigger-header-height;
      line-height: $grid-bigger-headercelldiv-line-height;
    }

    .e-headercell,
    .e-detailheadercell {
      font-size: $grid-bigger-header-font-size;
    }

    .e-icons {
      font-size: $grid-bigger-icons-font-size;
    }

    .e-gridheader thead .e-icons {
      font-size: $grid-bigger-header-icons-font-size;
    }

    .e-icon-gdownarrow {
      font-size: $grid-bigger-gdownarrow-icon-font-size;
    }

    .e-icon-grightarrow {
      font-size: $grid-bigger-grightarrow-icon-font-size;
    }

    .e-toolbar .e-tbar-btn.e-btn .e-btn-icon.e-icons.e-columnchooser-btn {
      font-size: $grid-bigger-columnchooser-btn-font-size;
    }

    .e-gridheader {
      & tr th:last-child {
        padding-right: $grid-bigger-header-first-last-cell-padding;
      }

      & tr th:last-child.e-filterbarcell {
        padding-left: $grid-filterbarcell-first-last-child-padding-left;
      }

      & tr th:first-child {
        padding-left: $grid-bigger-header-first-last-cell-padding;
      }

      & tr th:first-child.e-filterbarcell {
        padding-left: $grid-filterbarcell-first-last-child-padding-left;
      }
    }

    .e-headercelldiv {
      font-size: $grid-bigger-headercell-font-size;
    }

    .e-rowcell,
    .e-summarycell,
    .e-groupcaption {
      font-size: $grid-bigger-content-font-size;
      padding: $grid-bigger-content-padding $grid-bigger-content-right-padding;
    }

    .e-summarycell.e-lastrowcell {
      border-bottom-width: 1px;
    }

    .e-rowcell .e-checkbox-wrapper,
    .e-rowcell .e-css.e-checkbox-wrapper {
      @if $grid-skin == 'material3' {
        height: 18px;
      }
    }

    .e-rowcell:first-child,
    .e-summarycell:first-child {
      padding-left: $grid-bigger-first-last-rowcell-padding;
    }

    .e-rowcell:last-child,
    .e-summarycell:last-child {
      padding-right: $grid-bigger-first-last-rowcell-padding;
    }

    .e-filterbarcell,
    .e-filterbarcelldisabled {
      height: $grid-bigger-headercell-line-height;
      @if $grid-skin == 'material3' {
        padding: 0 $grid-bigger-headercell-padding 5px;
      }
      @else {
        padding: 0 $grid-bigger-headercell-padding;
      }
    }

    .e-filterbarcell input {
      height: $grid-bigger-filterbarcell-text-input;
    }

    .e-ftrchk {
      padding-bottom: $grid-bigger-checkbox-padding-topbottom;
      padding-top: $grid-bigger-checkbox-padding-topbottom;
      @if $grid-skin == 'material3' {
        margin-left: -4px;
      }
    }

    .e-columnmenu {
      bottom: $grid-column-menu-top-touch;
      top: $grid-column-menu-bottom-touch;
    }

    &.e-device.e-noselect {
      user-select: none;
    }

    .e-dialog.e-checkboxfilter,
    .e-dialog.e-flmenu {
      min-width: 260px;
    }

    .e-dialog.e-checkboxfilter {
      min-height: $grid-bigger-checkbox-filter-min-height;
      @if $grid-skin =='material3' {
        & .e-dlg-content {
          padding-top: 16px;
          padding-right: 16px;
        }
      }
    }

    .e-checkboxfilter {
      & .e-dlg-content {
        padding-left: $grid-bigger-checkbox-content-padding-left;
      }

      & .e-ftrchk,
      & .e-searchbox {
        padding-left: $grid-bigger-checkbox-padding-topbottom;
      }
    }

    &.e-rtl .e-checkboxfilter {
      & .e-dlg-content {
        padding-left: $grid-rtl-bigger-checkbox-content-padding-left;
        padding-right: $grid-bigger-checkbox-content-padding-left;
      }

      & .e-ftrchk,
      & .e-searchbox {
        padding-left: 0;
        padding-right: $grid-bigger-checkbox-padding-topbottom;
      }
    }

    .e-filtermenudiv {
      margin: $grid-bigger-fltrdiv-margin;
    }

    .e-sortfilterdiv {
      margin: $grid-bigger-sortdiv-margin;
    }

    .e-gridheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-fltricon-sortdiv-margin;
    }

    .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
      @if $grid-skin == 'fluent' {
        padding-left: 4px;
      }
    }

    &.e-rtl .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
      padding: $grid-bigger-rtl-headercelldiv-chkbox-padding;
    }

    .e-gridheader .e-columnheader.e-wrap .e-sortfilterdiv,
    .e-wrap .e-sortfilterdiv {
      margin: $grid-bigger-wrap-sortdiv-margin;
    }

    .e-gridheader .e-columnheader.e-wrap .e-rightalign .e-sortfilterdiv .e-wrap .e-rightalign .e-sortfilterdiv {
      margin: $grid-bigger-wrap-rightalign-sortdiv-margin;
    }

    .e-gridheader .e-columnheader.e-wrap .e-fltr-icon .e-sortfilterdiv .e-wrap .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-wrap-fltricon-sortdiv-margin;
    }

    .e-gridheader .e-columnheader.e-wrap .e-rightalign.e-fltr-icon .e-sortfilterdiv,
    .e-wrap .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-wrap-rightalign-fltricon-sortdiv-margin;
    }

    &.e-wrap {
      & .e-rowcell,
      & .e-columnheader .e-stackedheadercelldiv,
      & .e-columnheader .e-headercelldiv {
        line-height: $grid-bigger-wrap-line-height;
      }
    }

    .e-gridheader .e-rightalign .e-sortfilterdiv {
      margin: $grid-bigger-sortdiv-rightalign-margin;
    }

    .e-gridheader .e-sortfilter .e-rightalign .e-headercelldiv,
    .e-gridheader .e-rightalign .e-stackedheadercelldiv {
      @if $grid-skin == 'material3' {
        margin-left: 12px;
      }
    }

    .e-gridheader .e-rightalign.e-fltr-icon .e-sortnumber {
      float: left;
      margin: $grid-bigger-fltr-sortnumber-rightalign-margin;
    }

    .e-gridheader .e-rightalign .e-sortnumber {
      float: left;
      margin: $grid-bigger-sortnumber-rightalign-margin;
    }

    .e-sortnumber {
      border-radius: $grid-sortnumber-border-radius;
      display: inline-block;
      float: right;
      font-size: $grid-sort-number-font-size;
      height: $grid-bigger-sort-number-size;
      line-height: $grid-bigger-sortnumer-line-height;
      margin: $grid-bigger-sortnumber-margin;
      text-align: center;
      width: $grid-bigger-sort-number-size;

      @if $grid-skin == 'fluent' {
        font-size: 14px;
      }
    }

    .e-gridheader .e-fltr-icon .e-sortnumber {
      margin: $grid-bigger-fltr-sortnumber-margin;
    }

    .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-fltricon-sortdiv-rightalign-margin;
    }

    &.e-rtl .e-headercell.e-leftalign .e-sortfilterdiv{
      @if $grid-skin == 'material3' {
        margin: -16px 2px;
      }
    }
  
    &.e-rtl .e-headercell .e-sortfilterdiv,
    &.e-rtl .e-detailheadercell .e-sortfilterdiv {
      margin: $grid-rtl-bigger-sortcelldiv-margin;
    }

    &.e-rtl .e-gridheader .e-rightalign .e-sortfilterdiv {
      margin: $grid-rtl-bigger-sortcelldiv-right-align-margin;
    }

    &.e-rtl .e-gridheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-rtl-bigger-fltricon-sortdiv-margin;
    }

    &.e-rtl .e-gridheader .e-leftalign .e-sortfilterdiv {
      @if $grid-skin == 'tailwind' {
        margin-bottom: -14.5px;
        margin-top: -14.5px;
      }
      @else if $grid-skin == 'bootstrap5' {
        margin-bottom: -12px;
        margin-top: -14px;
      }
    }

    &.e-rtl .e-gridheader .e-fltr-icon.e-leftalign .e-sortfilterdiv {
      @if $grid-skin == 'tailwind' {
        margin-right: -6px;
      }
    }

    &.e-rtl .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-rtl-bigger-fltricon-sortdiv-rightalign-margin;
    }

    &.e-rtl .e-filtermenudiv {
      margin: $grid-rtl-bigger-fltrdiv-margin;
    }

    &.e-rtl .e-rightalign .e-filtermenudiv {
      margin: $grid-rtl-bigger-rightalign-fltrdiv-margin;
    }

    &.e-rtl .e-headercell .e-headercelldiv .e-sortnumber,
    &.e-rtl .e-detailheadercell .e-headercelldiv .e-sortnumber {
      margin: $grid-rtl-bigger-sortnumber-margin;
    }

    &.e-rtl .e-headercell.e-rightalign .e-headercelldiv .e-sortnumber,
    &.e-rtl .e-detailheadercell.e-rightalign .e-headercelldiv .e-sortnumber {
      margin: $grid-rtl-bigger-rightalign-sortnumber-margin;
    }

    &.e-rtl .e-headercell.e-leftalign.e-headercell.e-fltr-icon .e-headercelldiv .e-sortnumber,
    &.e-rtl .e-detailheadercell.e-leftalign.e-headercell.e-fltr-icon .e-headercelldiv .e-sortnumber {
      @if $grid-skin == 'tailwind' {
        margin: 0 0 0 6px;
      }
    }

    &.e-rtl .e-headercell.e-fltr-icon .e-headercelldiv .e-sortnumber {
      margin: $grid-rtl-bigger-sortnumber-withfilter-margin;
      @if $grid-skin == 'tailwind' {
        margin-left: 0;
      }
    }

    .e-rowcell,
    .e-summarycell,
    .e-emptyrow td,
    .e-frozencontent table tr td:first-child:empty,
    .e-movablecontent table tr td:first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
      line-height: $grid-bigger-rowcell-line-height;
    }

    .e-xlsel-bottom-border.e-xlsel-top-border {
      line-height: $grid-bigger-rowcell-autofill-top-bottom-line-height;
    }
  
    .e-xlsel-bottom-border {
      line-height: $grid-bigger-rowcell-autofill-top-line-height;
    }

    .e-filterbarcell input {
      font-size: $grid-bigger-font-size;
    }

    .e-groupdroparea .e-icons {
      @if $grid-skin == 'material3' {
        border-radius: 8px;
      }
    }

    .e-groupdroparea {
      font-size: $grid-bigger-grouparea-font-size;
      min-height: $grid-bigger-group-line-height;
      padding: $grid-bigger-groupdroparea-padding;
    }

    .e-groupdroparea.e-hover {
      @if $grid-skin == 'tailwind' {
        margin-bottom: 7px;
        min-height: 38px;
        outline-offset: 4px;
        padding: 9px;
        right: -3.4px;
        top: 4px;
        width: 99.4%;
      }
      @else if $grid-skin == 'fluent' {
        padding-bottom: 13.5px;
      }
    }

    &.e-rtl .e-groupdroparea.e-hover {
      @if $grid-skin == 'tailwind' {
        right: 3.4px;
      }
    }

    .e-cloneproperties {
      padding: $grid-bigger-clone-padding;
    }

    .e-cloneproperties.e-draganddrop {
      & .e-rowcell {
        @if $grid-skin == 'material3' {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      & table,
      table .e-selectionbackground {
        @if $grid-skin == 'material3' {
          height: 40px;
        }
      }
      padding: $grid-bigger-draganddrop-clone-padding;
    }

    .e-headerclone {
      font-size: $grid-bigger-header-font-size;
    }

    .e-dropitemscount {
      @if $grid-skin == 'tailwind' {
        margin-left: 1px;
      }
    }

    &.e-rtl .e-dropitemscount {
      @if $grid-skin == 'tailwind' {
        margin-left: -8px;
      }
    }

    .e-groupdroparea.e-grouped {
      padding: $grid-bigger-groupedcell-padding;
    }

    .e-groupdroparea.e-grouped.e-hover {
      @if $grid-skin == 'tailwind' {
        padding: 0 .5px 1.5px;
      }
      @else if $grid-skin == 'fluent' {
        padding-bottom: 0;
      }
    }

    .e-groupdroparea.e-grouped.e-hover .e-groupheadercell {
      @if $grid-skin == 'tailwind' {
        margin-top: 1.5px;
      }
      @else if $grid-skin == 'fluent' {
        margin-bottom: 5px;
      }
    }

    .e-frozenheader table tr td.e-rowcell {
      height: $grid-bigger-rowcell-line-height + $grid-bigger-content-padding + $grid-bigger-content-padding -6;
    }

    /* stylelint-disable */
    .e-frozencontent table tr td:first-child:empty,
    .e-movablecontent table tr td:first-child:empty,
    .e-frozenhdrcont table tr td:first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
      height: $grid-bigger-rowcell-line-height + $grid-bigger-content-padding +  $grid-bigger-content-padding;
    }

    &.e-bigger .e-columnmenu {
      bottom: $grid-bigger-columnmenu-bottom;
      margin: $grid-bigger-column-menu-margin;
    }

    &.e-device .e-columnmenu {
        margin: $grid-bigger-device-column-menu-margin;
    }

    .e-columnmenu {
      right: $grid-column-menu-right-touch;
    }

    .e-groupheadercell,
    .e-groupheadercell:hover {
      border-radius: $grid-bigger-grouped-headercell-border-radius;
      height: $grid-bigger-group-headercell-height;
      margin: $grid-bigger-group-headercell-margin;
      @if $grid-skin != 'material3' {
        padding: $grid-bigger-group-headercell-padding;
      }
      font-size: $grouped-bigger-text-font-size;
    }

    &.e-device .e-groupheadercell {
      padding: $grid-device-group-headercell-padding;
      @if $grid-skin == 'bootstrap5' {
        padding: $grid-bigger-group-headercell-padding;
      }
    }

    .e-groupheadercell:hover {
      padding: $grid-bigger-group-headercell-hover-padding;
    }

    .e-gdclone {
      border-radius: $grid-bigger-group-clone-broder-radius;
      padding: $grid-bigger-group-clone-padding;
    }

    .e-groupheadercell span {
      height: $grid-bigger-groupcell-span-height;
      line-height: $grid-bigger-groupcell-span-line-height;
      padding: $grid-bigger-groupcell-span-padding;
    }

    .e-groupheadercell .e-ungroupbutton {
      font-size: $grid-bigger-ungroup-button-font-size;
    }

    .e-groupheadercell .e-grouptext {
      line-height: $grid-bigger-group-text-line-height;
      @if $grid-skin == 'material3' {
        padding-left: 12px;
      }
    }

    .e-row .e-input-group .e-input.e-field,
    .e-row .e-input-focus .e-input.e-field {
      font-family: $grid-font-family;
      font-size: $grid-bigger-font-size;
      padding-bottom: $grid-edit-input-bigger-padding-bottom;
      padding-top: $grid-edit-input-bigger-padding-top;
    }

    &.e-device .e-row .e-input-group .e-input.e-field,
    &.e-device .e-row .e-input-focus .e-input.e-field {
      padding-bottom: $grid-edit-input-bigger-device-padding-bottom;
      padding-top: $grid-edit-input-bigger-device-padding-top;
    }

    .e-row .e-input-group {
      @if $grid-skin == 'material3' {
        margin-top: 2px;
        line-height: 39.5px;
      }
      margin-bottom: $grid-edit-input-bigger-margin;
      vertical-align: middle;
    }

    &.e-device .e-headercell {
      padding: $grid-device-headercell-padding;
    }

    &.e-device .e-headercell:first-child {
      padding: $grid-device-headercell-fistchild-padding;
    }

    &.e-device .e-headercell:last-child {
      padding: $grid-device-headercell-lastchild-padding;
    }

    &.e-device .e-groupheadercell span {
      line-height: $grid-device-groupheadercell-span-line-height;
      padding: $grid-device-grouped-headercell-span-padding;
    }

    &.e-device .e-rowcell,
    &.e-device .e-summarycell {
      padding: $grid-device-rowcell-padding;
    }

    &:not(.e-row-responsive).e-device .e-rowcell:first-child,
    &:not(.e-row-responsive).e-device .e-summarycell:first-child {
      padding: $grid-device-rowcell-firstchild-padding;
    }

    &:not(.e-row-responsive).e-device .e-rowcell:last-child,
    &:not(.e-row-responsive).e-device .e-summarycell:last-child {
      padding: $grid-device-rowcell-lastchild-padding;
    }

    &.e-device .e-filterbarcell {
      padding: $grid-device-filterbarcell-padding;
    }

    &.e-device .e-filterbarcell:first-child {
      padding: $grid-device-filterbarcell-firstchild-padding;
    }

    &.e-device .e-filterbarcell:last-child {
      padding: $grid-device-filterbarcell-lastchild-padding;
    }

    &.e-device .e-groupheadercell .e-ungroupbutton {
      line-height: $grid-device-ungroupbutton-line-height;
    }

    &.e-device .e-normaledit .e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    &.e-device .e-editedbatchcell.e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    .e-unboundcell,
    .e-editedrow .e-normaledit .e-unboundcell,
    .e-addedrow .e-normaledit .e-unboundcell {
      padding-bottom: $grid-bigger-command-column-padding-bottom;
      padding-top: $grid-bigger-command-column-padding-top;
    }

    .e-grouptext {
      margin-right: $grid-bigger-grouptext-marign;
      width: $grid-bigger-grouptext-width;
    }
    
    .e-groupsort,
    span.e-ungroupbutton,
    .e-toggleungroup {
      margin-left: $grid-bigger-groupedcell-icons-marign-left;
      margin-top: $grid-bigger-sorticon-margin-top;
    }

    span.e-ungroupbutton.e-icons {
      font-size: $grid-bigger-ungroupbutton-icons-font-size;
      margin-left: $grid-bigger-ungroupbutton-icon-margin-left;
      padding-top: $grid-bigger-ungroupbutton-icon-padding-top;
      @if $grid-skin != 'material3' {
        margin-top: $grid-bigger-ungroupbutton-icon-margin-top;
      }
      margin-right: $grid-bigger-ungroupbutton-icon-margin-right;
      @if $grid-skin == 'fluent' {
        padding: 0 4px;
      }
    }

    &.e-device span.e-ungroupbutton.e-icons {
      margin-top: $grid-device-ungroupbutton-icon-margin-top;
      @if $grid-skin == 'bootstrap4' {
        padding-top: 0;
      }
    }

    span.e-ungroupbutton.e-icons:hover {
      @if $grid-skin == 'tailwind' {
        color: $cool-gray-700;
      }
    }

    span.e-groupsort.e-icons {
      margin-right: $group-bigger-sorticon-margin-right;
      @if $grid-skin == 'material3' {
        font-size: 16px;
        padding-top: 7.5px;
      }
      @else {
        margin-top: $grid-bigger-sorticon-margin-top;
      }
      @if $grid-skin == 'fluent' {
        margin-left: 0;
        padding: 0 4px;
      }
      @else if $grid-skin == 'bootstrap4' {
        margin-left: -4px;
      }
    }

    &.e-device span.e-groupsort.e-icons {
      margin-top: $grid-device-group-sorticon-margin-top;
    }
    
    &.e-rtl span.e-groupsort.e-icons {
      @if $grid-skin == 'fluent' {
          margin-top: 0;
          padding: 0 4px;
      }
      @else if $grid-skin == 'bootstrap4' {
        margin-left: 7px;
      }
      @else if $grid-skin == 'material3' {
        padding-top: 7.5px;
      }
  }

    &.e-rtl .e-groupheadercell,
    &.e-rtl .e-groupheadercell:hover {
      margin: $grid-bigger-rtl-group-headercell-margin;
      padding: $grid-bigger-rtl-group-headercell-padding;
    }

    &.e-rtl.e-device .e-groupheadercell {
      padding: $grid-bigger-device-rtl-group-headercell-padding;
    }

    &.e-rtl span.e-ungroupbutton.e-icons {
      margin-left: $grid-bigger-rtl-ungroup-icon-margin-left;
      margin-right: $grid-bigger-rtl-ungroup-icon-margin-right;
      margin-top: $grid-bigger-sorticon-margin-top;
      padding-left: $grid-ungroup-rtl-padding-left;
      @if $grid-skin == 'fluent' {
        margin-top: 0;
        padding: 0 4px;
      }
      @else if $grid-skin == 'material3' {
        padding-top: 7.5px;
      }
    }

    .e-groupcaption {
      line-height: $grid-bigger-groupcaption-line-height;
    }

    .e-ccdlg {
      .e-dlg-content {
        margin: $grid-bigger-columnchooser-content-margin;
        padding: $grid-bigger-columnchooser-content-padding;
      }

      .e-ccul-ele {
        padding: $grid-bigger-columnchooser-ul-padding;
      }

      .e-cc-searchdiv {
        padding-left: $grid-bigger-searchdiv-padding-left;
      }

      .e-checkbox-wrapper.e-control.e-keyboard {
        padding-left: $grid-bigger-cc-checkbox-padding-left;
      }

      li.e-cclist {
        padding: $grid-bigger-columnchooser-li-padding;
        @if $grid-skin == 'material3' {
          line-height: 24px;
        }
      }

      .e-toolbar .e-ccdiv {
        margin-top: $grid-bigger-columnchooser-toolbar-div-margin-top;
      }
    }

    .e-rowcell.e-frozen-right-border,
    .e-headercell.e-frozen-right-border,
    .e-filterbarcell.e-frozen-right-border {
      border-right: $grid-freezeline-border $grid-freezeline-right-border;
    }

    .e-rowcell.e-frozen-left-border,
    .e-headercell.e-frozen-left-border,
    .e-filterbarcell.e-frozen-left-border {
      border-left: $grid-freezeline-border $grid-freezeline-right-border;
    }

    .e-rowcell.e-freezeline,
    .e-gridheader .e-filterbarcell.e-freezeline {
      position: relative;
    }

    .e-rowcell .e-frozen-right-cursor,
    .e-rowcell .e-frozen-fixedright-cursor,
    .e-rowcell .e-frozen-left-cursor,
    .e-rowcell .e-frozen-fixedleft-cursor,
    .e-gridheader .e-headercell .e-frozen-right-cursor,
    .e-gridheader .e-headercell .e-frozen-fixedright-cursor,
    .e-gridheader .e-headercell .e-frozen-left-cursor,
    .e-gridheader .e-headercell .e-frozen-fixedleft-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-right-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-fixedright-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-fixedleft-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-left-cursor {
      cursor: move;
      height: 100%;
      position: absolute;
      top: 0;
      width: 4px;
    }

    .e-rowcell .e-frozen-left-cursor,
    .e-rowcell .e-frozen-fixedright-cursor,
    .e-gridheader .e-headercell .e-frozen-left-cursor,
    .e-gridheader .e-headercell .e-frozen-fixedright-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-fixedright-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-left-cursor {
      right: 0;
    }

    .e-rowcell .e-frozen-right-cursor,
    .e-rowcell .e-frozen-fixedleft-cursor,
    .e-gridheader .e-headercell .e-frozen-right-cursor,
    .e-gridheader .e-headercell .e-frozen-fixedleft-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-fixedleft-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-right-cursor {
      left: 0;
    }

    .e-gridheader .e-headercell .e-frozen-left-cursor.e-frozen-resize-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-left-cursor.e-frozen-resize-cursor,
    .e-rowcell .e-frozen-left-cursor.e-frozen-resize-cursor {
      right: 3px;
    }

    .e-gridheader .e-headercell .e-frozen-right-cursor.e-frozen-resize-cursor:not(.e-frozen-default-cursor),
    .e-gridheader .e-filterbarcell .e-frozen-right-cursor.e-frozen-resize-cursor:not(.e-frozen-default-cursor) {
      left: 3px;
    }

    .e-frozen-helper {
      border-left: $grid-freezeline-border $grid-freezeline-right-border;
      cursor: move;
      position: absolute;
      z-index: 2;
    }

    .e-content.e-freezeline-moving {
      user-select: none
    }

    .e-groupdroparea.e-group-animate .e-drag.e-icon-drag {
      @if $grid-skin == 'material3' {
        font-size: 16px;
        height: 30px;
        line-height: 31px;
        margin-right: -12px;
      }
    }

    &.e-rtl .e-groupdroparea.e-group-animate .e-drag.e-icon-drag {
      @if $grid-skin == 'material3' {
        line-height: 31px;
        margin-right: 0;
        margin-left: -12px;
      }
    }

    .e-gridheader .e-headercontent .e-reorderuparrow,
    .e-gridheader .e-headercontent .e-reorderdownarrow,
    .e-gridheader .e-headercontent .e-reorderuparrow-virtual,
    .e-gridheader .e-headercontent .e-reorderdownarrow-virtual {
      @if $grid-skin == 'material3' {
        margin-left: -4px;
      }
    }

    .e-gridheader .e-headercontent .e-reorderuparrow,
    .e-gridheader .e-headercontent .e-reorderuparrow-virtual {
      @if $grid-skin == 'material3' {
        margin-top: -9px;
      }
    }

    .e-gridheader .e-headercontent .e-reorderdownarrow,
    .e-gridheader .e-headercontent .e-reorderdownarrow-virtual {
      @if $grid-skin == 'material3' {
        margin-top: 2px;
      }
    }

    &.e-rtl {
      .e-gridheader .e-headercontent .e-reorderuparrow,
      .e-gridheader .e-headercontent .e-reorderdownarrow,
      .e-gridheader .e-headercontent .e-reorderuparrow-virtual,
      .e-gridheader .e-headercontent .e-reorderdownarrow-virtual {
        @if $grid-skin == 'material3' {
          margin-left: -3px;
        }
      }
    }
  }
   
  .e-bigger #{&}.e-grid:not(.sf-grid),
  #{&}.e-grid:not(.sf-grid).e-bigger {
    .e-gridheader table th[rowspan],
    &.e-device .e-gridheader table th[rowspan] {
      padding-bottom: $grid-device-gridheader-row-span-padding;
    }
  }

  #{&}.e-grid {
    border-radius: $grid-border-radius;
    border-style: none $grid-border-type $grid-border-type;
    border-width: $grid-border-size;
    display: block;
    font-family: $grid-font-family;
    font-size: $grid-font-size;
    height: auto;
    position: relative;

    .e-gridheader {
      user-select: none;
    }

    .e-groupdroparea.e-sticky,
    .e-toolbar.e-sticky,
    .e-gridheader.e-sticky {
        position: sticky;
        z-index: 10;
    }

    .e-gridheader.e-sticky .e-headercontent .e-reorderuparrow,
    .e-gridheader.e-sticky .e-headercontent .e-reorderdownarrow,
    .e-ccdlg.e-sticky {
      position: fixed;
      z-index: 10;
    }
    
    .e-groupdroparea.e-sticky {
        opacity: 1;
    }

    .e-gridheader .e-firstrow-dragborder,
    &.e-rtl .e-gridheader .e-firstrow-dragborder,
    .e-gridcontent .e-lastrow-dragborder,
    &.e-rtl .e-gridcontent .e-lastrow-dragborder {
      bottom: 0;
      background-color: $grid-e-firstrow-dragborder-bcolor;
      height: 2px;
      position: absolute;
      z-index: 5;
    }

    .e-frozenrow-border {
      background-color: $frozen-border-color;
      height: 2px;
      position: relative;
      z-index: 5;
    }

    .e-frozenrow-border.e-frozenrow-empty {
      height: 0;
    }

    &.e-top-shadow .e-frozenrow-border {
      height: 4px;
      margin-top: -4px;
      background-color: transparent !important;
      box-shadow: 0px 4px 8px $grid-frozen-row-shadow-background-color;
      clip-path: inset(0px 0px -15px 0px)
    }

    .e-grid-relative {
      position: relative;
    }

    .e-dropitemscount {
      border: 1px solid $grid-clone-dropitemscount-border;
      border-radius: 17px;
      box-sizing: content-box;
      font-size: 13px;
      line-height: normal;
      margin-left: $grid-dropitems-count-margin-left;
      @if $grid-skin == 'tailwind' {
        margin-left: -7px;
      }
      min-width: 12px;
      padding: $grid-bigger-drag-count-padding;
      position: absolute;
      text-align: center;
      top: $grid-bigger-drag-count-top;
      z-index: 5;
    }

    &.e-rtl .e-dropitemscount {
      left: $grid-rtl-mselect-dd-indicator-left !important;
    }

    &.e-rtl .e-toolbar .e-toolbar-item.e-search-wrapper{
      & .e-sicon.e-clear-icon {
          padding: $grid-rtl-toolbar-search-clear-icon-padding;
          margin: $grid-rtl-toolbar-search-clear-icon-margin;
      }
  }

    &.e-verticallines .e-cloneproperties.e-draganddrop .e-rowdragdrop,
    &.e-bothlines .e-cloneproperties.e-draganddrop .e-rowdragdrop{
      border-top: 1px solid $grid-cell-border-color;
      border-right: 1px solid $grid-cell-border-color;
      border-bottom: 0;
    }

    .e-gridcontent, .e-gridheader,
    &.e-rtl .e-gridcontent,
    &.e-rtl .e-gridheader {
        & tr.e-row:first-child .e-rowcell.e-dragborder,
        & .e-rowcell.e-dragborder,
        & .e-rowdragdrop.e-dragborder,
        & .e-detailrowcollapse.e-dragborder,
        & .e-detailrowexpand.e-dragborder {
        z-index: $grid-dragborder-z-index;
        }
    }

    .e-gridcontent, .e-gridheader,
    &.e-rtl .e-gridcontent,
    &.e-rtl .e-gridheader {
        & tr.e-row:first-child .e-rowcell.e-dragborder.e-leftfreeze,
        & tr.e-row:first-child .e-rowcell.e-dragborder.e-rightfreeze,
        & tr.e-row:first-child .e-rowcell.e-dragborder.e-fixedfreeze,
        & .e-rowcell.e-dragborder.e-leftfreeze,
        & .e-rowcell.e-dragborder.e-rightfreeze,
        & .e-rowcell.e-dragborder.e-rightfreeze,
        & .e-rowdragdrop.e-dragborder.e-leftfreeze,
        & .e-rowdragdrop.e-dragborder.e-rightfreeze,
        & .e-rowdragdrop.e-dragborder.e-fixedfreeze,
        & .e-detailrowcollapse.e-dragborder.e-leftfreeze,
        & .e-detailrowcollapse.e-dragborder.e-rightfreeze,
        & .e-detailrowcollapse.e-dragborder.e-fixedfreeze,
        & .e-detailrowexpand.e-dragborder.e-leftfreeze,
        & .e-detailrowexpand.e-dragborder.e-rightfreeze,
        & .e-detailrowexpand.e-dragborder.e-fixedfreeze {
        z-index: 6;
        }
    }

    .e-gridcontent, .e-gridheader,
    &.e-rtl .e-gridcontent,
    &.e-rtl .e-gridheader {
        & tr.e-row:first-child .e-rowcell.e-dragborder,
        & .e-rowcell.e-dragborder,
        & .e-rowdragdrop.e-dragborder,
        & .e-detailrowcollapse.e-dragborder,
        & .e-detailrowexpand.e-dragborder {
        box-shadow: $grid-dragborder-box-shadow;
        }
    }

    .e-gridcontent, .e-gridheader,
    &.e-rtl .e-gridcontent,
    &.e-rtl .e-gridheader { 
        & .e-rowcell.e-dragtop,
        & .e-rowdragdrop.e-dragtop,
        & .e-rowcell.e-dragtop.e-dragright {
          border-top: $grid-dragborder-cell-dashed;
        }
        & .e-rowdragdrop.e-dragtop.e-dragleft,
        & .e-rowdragdrop.e-dragleft,
        & .e-rowdragdrop.e-dragbottom.e-dragleft {
          border-left: $grid-dragborder-cell-dashed;
        }
        & .e-rowcell.e-dragright {
          border-right: $grid-dragborder-cell-dashed;
        }
        & .e-rowcell.e-dragbottom,
        & .e-rowcell.e-lastrowcell.e-dragbottom,
        & .e-rowcell.e-dragtop.e-dragbottom,
        & .e-rowdragdrop.e-dragbottom {
          border-bottom: $grid-dragborder-cell-dashed;
        }

    }

    .e-gridcontent, .e-gridheader,
    &.e-rtl .e-gridcontent,
    &.e-rtl .e-gridheader {
        & tr.e-row:first-child .e-rowcell.e-dragborder:not(.e-leftfreeze, .e-rightfreeze, .e-fixedfreeze),
        & .e-rowcell.e-dragborder:not(.e-leftfreeze, .e-rightfreeze, .e-fixedfreeze),
        & .e-rowdragdrop.e-dragborder:not(.e-leftfreeze, .e-rightfreeze, .e-fixedfreeze),
        & .e-detailrowcollapse.e-dragborder:not(.e-leftfreeze, .e-rightfreeze, .e-fixedfreeze)
        & .e-detailrowexpand.e-dragborder:not(.e-leftfreeze, .e-rightfreeze, .e-fixedfreeze) {
        position: $grid-dragborder-position;
        }
    }

    .e-gridheader thead .e-icons:not(.e-check):not(.e-stop) {
      font-size: $grid-icon-font-size;
    }

    .e-row .e-icon-rowdragicon::before {
      display: block;
      text-indent: 10px;
      font-size: $grid-drag-font-size;
      font-weight: $grid-drag-icon-font-weight;
      color: $grid-drap-drop-icon-color;
      opacity: $grid-drag-icon-opacity;
    }

    .e-row .e-icon-rowdragmoveicon::before {
      font-size: $grid-drag-font-size;
      font-weight: $grid-drag-icon-font-weight;
    }

    .e-row .e-icon-rowdragmoveicon {
      padding-left: $grid-drag-move-icon;
    }

    .e-draganddrop .e-rowcell, &.e-rtl .e-draganddrop .e-rowcell {
      padding-left: 6px;
      padding-right: 6px;
    }

    .e-gridcontent .e-rowdragdrop,
    .e-gridheader .e-rowdragdrop {
      border-style: $grid-dragcell-border-style;
      border-width: $grid-dragicon-cell-border;
      border-color: $grid-dragcell-border-color;
    }

    .e-gridcontent .e-rowdragdrop.e-lastrowcell,
    .e-gridcontent .e-recordpluscollapse.e-lastrowcell,
    .e-gridcontent .e-indentcell.e-lastrowcell,
    .e-gridcontent .e-groupcaption.e-lastrowcell {
      border-bottom-width: $grid-border-bottom-width;
    }

    .e-bigger & .e-gridcontent .e-groupcaptionrow .e-lastrowcell.e-recordplusexpand ~ td.e-lastrowcell,
    &.e-bigger .e-gridcontent .e-groupcaptionrow .e-lastrowcell.e-recordplusexpand ~ td.e-lastrowcell,
    .e-gridcontent .e-groupcaptionrow .e-lastrowcell.e-recordplusexpand ~ td.e-lastrowcell {
      &.e-groupcaption,
      &.e-summarycell {
        border-bottom-width: 0;
      }
    }

    & .e-cloneproperties.e-draganddrop .e-row .e-icon-rowdragicon::before {
      position: relative;
      left: -5px;
    }

    .e-icon-rowdragicon {
      font-size: $grid-group-right-arrow-icon-font-size;
      text-indent: $group-expand-icon-text-indent;
    }

    .e-toolbar {
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      border-top: $grid-toolbar-border-width $grid-header-border-color;
      border-radius: 0;

      .e-toolbar-item.e-search-wrapper{
        & .e-sicon.e-clear-icon {
            padding: $grid-toolbar-search-clear-icon-padding;
            min-width: $grid-toolbar-search-clear-icon-min-width;
            margin-right: $grid-toolbar-search-clear-icon-margin-right;
        }

        & .e-sicon.e-clear-icon::before {
            font-size: $grid-toolbar-search-clear-icon-font-size;
        }
      }
    }

    & .e-toolbar-items{
      & .e-input-group-icon.e-icons.e-sicon:hover:not(.e-clear-icon),
      & .e-input-group-icon.e-icons.e-sicon:active:not(.e-clear-icon),
      & .e-input-group-icon.e-icons.e-sicon:focus:not(.e-clear-icon) {
          background: none;
          box-shadow: none;
      }
      & .e-input-group-icon.e-icons.e-sicon:not(.e-clear-icon) {
          border: none;
      }
  }

    .e-res-toolbar {
      border-bottom: 1px solid;
      border-bottom-color: $grid-header-border-color;
      & .e-toolbar-items {
          min-height: initial;

          & .e-tbar-btn:hover,
          & .e-tbar-btn:active, 
          & .e-tbar-btn:focus,
          & .e-search-icon:hover,
          & .e-search-icon:active,
          & .e-search-icon:focus,
          & .e-sicon.e-clear-icon:hover,
          & .e-sicon.e-clear-icon:active,
          & .e-sicon.e-clear-icon:focus {
            background: none;
            & .e-icons {
              @if $grid-skin == 'bootstrap5' or $grid-skin == 'bootstrap4' {
                color: $grid-responsive-toolbar-icon-color;
              }
            }
          }

          & .e-icons {
            color: $grid-responsive-toolbar-icon-color;
          }

          & .e-toolbar-left {
            & .e-toolbar-item {
              & .e-tbar-btn {
                & .e-resfilter-icon {
                  font-size: $grid-responsive-toolbar-filter-font-size;
                }
              }
            }
          }

          & .e-responsive-right {
            width: calc(100% - 50px);

            &.e-search-wrapper {
              width: auto;
            }
            
            & .e-search-wrapper {
              & .e-input-group::before,
              & .e-input-group::after {
                background: none;
              }
            }
          }

          & .e-toolbar-right:not(.e-responsive-right) {
              & .e-search-wrapper:not(.e-responsive-right) {
                  width: 40px;
              }
          }

          & .e-toolbar-right {
            & .e-search-wrapper {
              width: 100%;
              padding-left: $grid-responsive-search-padding-left;
              padding-right: $grid-responsive-search-padding-right;

              & .e-search-icon {
                font-size: $grid-res-toolbar-search-icon-font-size;
                margin-top: 0;
              }

              & .e-input-group {
                border: 0;
                opacity: 1;
                padding: $grid-responsive-tbar-btn-bgr-padding;
                padding-right: $grid-responsive-search-icon-right;
                width: 100%;

                & .e-input {
                  margin-top: $grid-responsive-search-place-holder-margin-top;
                }

                & .e-search-icon {
                  margin-top: $grid-responsive-search-icon-margin;
                  padding-left: $grid-responsive-search-left-padding;
                } 
              }
            }
          }

          & .e-responsive-right {
            & .e-search-wrapper {
                padding-left: 0;
            }
          }
        }
      }

      &.e-bigger .e-res-toolbar {
        & .e-toolbar-items {
          & .e-tbar-btn:hover {
            background: none;
          }
          & .e-responsive-right {
            & .e-search-wrapper {
                & .e-sicon.e-clear-icon {
                    margin: $grid-responsive-toolbar-search-clear-icon-margin;
                }
    
                & .e-sicon.e-clear-icon::before {
                    font-size: $grid-responsive-toolbar-search-clear-icon-font-size;
                }
            }
          }

        }
      }

      &.e-rtl .e-res-toolbar {
        & .e-toolbar-items {
          & .e-responsive-right {
            & .e-search-wrapper {
                .e-sicon.e-clear-icon {
                    margin: $grid-rtl-responsive-toolbar-search-clear-icon-margin;
                }
                padding-left: 26px;
            }
          }
        }
      }

    .e-toolbar-items .e-toolbar-item.e-search-wrapper {
      padding-bottom: $grid-toolbar-search-wrapper-padding-bottom;
      padding-top: $grid-toolbar-search-wrapper-padding-top;

      & .e-search:focus {
        opacity: $grid-toolbar-searchbar-text-opacity;
      }

      & .e-search::placeholder {
        color: $grid-toolbar-placeholder-text-color;
      }

      & .e-search {
        margin-bottom: $grid-toolbar-search-margin-bottom;
        opacity: $grid-toolbar-text-opacity;
        width: $grid-toolbar-search-width;

        &.e-input-focus {
          opacity: 1;
        }

        .e-search-icon {
          min-width: $grid-toolbar-search-icon-min-width;
        }
      }
    }

    .e-bigger & .e-group-animator .e-groupheadercell,
    .e-bigger & .e-group-animator .e-groupheadercell:hover {
margin: $grid-group-animator-bigger-cell-margin;
        border-bottom-width: $grid-animtor-border-bottom-width;
    }

    & .e-groupdroparea.e-group-animate.e-grouped {
        height: $grid-group-animator-bigger-drop-height;
    }

    .e-bigger & .e-group-animate.e-groupdroparea .e-nextgroup {
        margin-top: $grid-animator-bigger-area-margin;
    }

    .e-bigger &.e-rtl .e-group-animate.e-groupdroparea .e-nextgroup {
      @if $grid-skin == 'material3' {
        margin-top: 18px;
      }
    }

    & .e-group-animator .e-groupheadercell,
    & .e-group-animator .e-groupheadercell:hover {
margin: $grid-group-animator-cell-margin;
        border-bottom-width: $grid-animtor-border-bottom-width;
    }

    & .e-group-animator:last-child .e-nextgroup.e-icons.e-icon-next {
        display: none;
    }

    & .e-groupdroparea.e-group-animate.e-grouped {
        padding: $grid-animator-area-padding;
        height: auto;
        border-bottom-width: $grid-animator-area-border;
    }

    &.e-rtl .e-group-animator {
        float: right;
    }

    &.e-rtl .e-group-animate .e-groupheadercell, &.e-rtl .e-group-animate .e-groupheadercell:hover {
        margin-right: 0;
        margin-left: 0;
    }

    &.e-rtl .e-group-animate.e-groupdroparea .e-nextgroup {
        transform: rotate(180deg);
        margin-top: $grid-animator-margin-top-rtl;
    }

    .e-group-animate.e-groupdroparea .e-nextgroup {
        margin-top: $grid-animator-area-margin;
    }

    .e-groupdroparea.e-group-animate .e-drag.e-icon-drag {
        display: $grid-animator-chips-display;
        height: $grid-animator-drag-icon;
        font-size: $grid-animator-font-size-drag;
        line-height: $grid-animator-drag-icon-line-height;
        padding: $grid-animator-area-border;
        vertical-align: $grid-columnchooser-toolbar-icon-vertical-align;
    }

    .e-groupdroparea.e-group-animate .e-drag.e-icon-drag:hover {
      @if $grid-skin == 'material3' {
        background: none;
      }
    }
    
    .e-group-animator .e-icon-drag.e-icons {
        margin-left: $grid-animator-drag-margin-left;
        @if $grid-skin == 'material3' {
          margin-right: -8px;
        }
    }

    &.e-rtl .e-group-animator .e-icon-drag.e-icons {
      @if $grid-skin == 'material3' {
        margin-right: $grid-animator-drag-margin-left;
        margin-left: -8px;
      }
    }

    .e-groupdroparea.e-group-animate span.e-drag.e-icons.e-icon-drag {
        cursor: move;
    }

    .e-group-animate .e-drag.e-icon-drag::before {
        opacity: $grid-animator-opacity;
    }

    .e-group-animate span.e-nextgroup.e-icons.e-icon-next {
        display: $grid-animator-chips-display;
        float: $grid-rtlfltrdiv-float;
        height: $grid-animator-drag-icon;
        line-height: $grid-animator-line-height-next;
        padding: $grid-animator-area-border;
        vertical-align: $grid-columnchooser-toolbar-icon-vertical-align;
    }

    .e-group-animate span.e-nextgroup.e-icons.e-icon-next:hover {
      @if $grid-skin == 'material3' {
        background-color: $transparent;
      }
    }

    .e-groupdroparea.e-grouped {
      padding: $grid-grouped-padding;
    }

    .e-groupdroparea.e-grouped.e-hover {
      @if $grid-skin == 'tailwind' {
        padding: 0 1px 1px 1px;
      }
      @else if $grid-skin == 'fluent' {
        padding-bottom: 0;
      }
    }

    &.e-default .e-gridheader.e-stackedfilter .e-grouptopleftcell,
    &.e-default.e-horizontallines .e-gridheader.e-stackedfilter .e-grouptopleftcell {
      border-top: 0;
    }

    &.e-default .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell,
    &.e-default.e-horizontallines .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell {
      border-bottom: 1px solid;
    }

    &.e-default.e-verticallines .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell,
    &.e-default.e-hidelines .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell {
      border-bottom: 0;
    }

    &.e-default .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell,
    &.e-default.e-horizontallines .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell {
      border-top: 1px solid;
    }

    &.e-default.e-hidelines .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell,
    &.e-default.e-verticallines .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell {
      border-top: 0;
    }

    &.e-default .e-grouptopleftcell {
      border-top: $grid-grouptopleftcell-border-top;
    }

    &.e-default .e-gridheader .e-headercell.e-firstheader,
    &.e-default.e-horizontallines .e-headercell.e-firstheader {
      border-left: 1px solid;
    }

    &.e-default.e-hidelines .e-headercell.e-firstheader {
      border-left: 0;
    }

    &.e-default.e-verticallines .e-grouptopleftcell,
    &.e-default.e-bothlines .e-grouptopleftcell,
    &.e-default.e-hidelines .e-grouptopleftcell {
      border-top-width: 0;
    }

    &.e-default.e-verticallines,
    &.e-default.e-hidelines {
      & .e-detailrowcollapse,
      & .e-detailrowexpand,
      & .e-rowdragdrop {
        border-top-width: 0;
      }
    }

    &.e-default.e-horizontallines .e-grouptopleftcell {
      border-top: 1px solid;
    }

    & .e-gridheader .e-headercell .e-rhandler,
    & .e-gridheader .e-headercell .e-rsuppress {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 4px;
    }

    & .e-gridheader .e-frozenheader .e-headercell:last-child .e-rhandler:last-of-type,
    & .e-gridheader .e-frozenheader .e-headercell:last-child .e-rsuppress:last-of-type {
      border-right: 0;
    }

    & .e-gridheader .e-headercell.e-leftfreeze.e-freezeleftborder .e-rhandler {
      border-right: 0;
    }

    &.e-rtl .e-gridheader .e-headercell.e-leftfreeze.e-freezeleftborder .e-rhandler {
      border-left: 0;
    }

    & .e-gridheader .e-frozenheader.e-frozen-right-header .e-headercell .e-rhandler,
    & .e-gridheader .e-frozenheader.e-frozen-right-header .e-headercell .e-rsuppress {
      left: 0;
    }

    &.e-device .e-gridheader .e-headercell .e-rhandler {
      width: 14px;
    }

    &.e-rtl .e-gridheader .e-headercell .e-rhandler,
    &.e-rtl .e-gridheader .e-headercell .e-rsuppress {
      left: 0;
      right: auto;
    }

    &.e-resize-lines .e-gridheader th.e-headercell.e-lastcell .e-rhandler {
      border-right-width: 0;
    }

    &.e-resize-lines .e-gridheader .e-frozen-right-header th.e-headercell .e-rhandler {
        border-right-width: 0;
    }

    &.e-rtl .e-gridheader .e-frozenheader.e-frozen-right-header .e-headercell .e-rhandler,
    &.e-rtl .e-gridheader .e-frozenheader.e-frozen-right-header .e-headercell .e-rsuppress {
      right: 0;
    }

    &.e-rtl.e-resize-lines .e-gridheader .e-frozen-right-header th.e-headercell .e-rhandler {
        border-right-width: 1px;
        border-left-width: 0;
    }

    & .e-rhelper {
      position: absolute;
      width: $grid-resize-border-width;
    }

    & .e-virtual-rhandler {
      z-index: 5;
    }

    &.e-device .e-ricon::before {
      border-bottom: 6px solid transparent;
      border-right: 6px solid ;
      border-top: 6px solid transparent;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 4px;
      top: 4px;
      width: 20px;
    }

    &.e-device .e-ricon::after {
      border-bottom: 6px solid transparent;
      border-left: 6px solid;
      border-top: 6px solid transparent;
      content: '';
      display: block;
      height: 0;
      left: 4px;
      position: absolute;
      top: 4px;
      width: 20px;
      z-index: 3;
    }

    &.e-rcursor,
    & .e-gridheader .e-stackedheadercell.e-rcursor,
    & .e-gridheader .e-rcursor {
      cursor: col-resize;
    }

    &.e-editing .e-gridheader .e-rcursor {
      cursor: default;
    }

    .e-table {
      border-collapse: separate;
      table-layout: fixed;
      width: 100%;
    }

    .e-tableborder {
      border-right: 1px solid;
    }

    .e-virtualtable {
      will-change: transform;
      z-index: 1;
    }

    .e-headercelldiv {
      border: 0 none;
      display: block;
      font-size: $grid-header-font-size;
      font-weight: $grid-header-font-weight;
      height: $grid-header-height;
      line-height: $grid-headercelldiv-line-height;
      margin: $grid-headercelldiv-margin;
      overflow: hidden;
      padding: $grid-headercell-div-padding;
      text-align: left;
      text-transform: $grid-header-text-transform;
      user-select: none;
    }

    .e-headercelldiv.e-headerchkcelldiv {
      overflow: visible;
    }

    .e-headercell.e-templatecell .e-headercelldiv {
      height: auto;
      min-height: $grid-header-height;
    }

    .e-gridheader .e-headercontent .e-rightalign .e-headercelldiv.e-headerchkcelldiv {
      padding: 0 .6em;
    }

    .e-gridheader .e-headercontent .e-centeralign .e-headercelldiv.e-headerchkcelldiv {
      padding: 0 .6em;
    }

    .e-headercell,
    .e-headercell.e-stackedheadercell,
    .e-headercell.e-defaultcursor {
      cursor: default;
    }

    .e-headercell.e-mousepointer,
    .e-headercell.e-stackedheadercell.e-mousepointer {
      cursor: pointer;
    }

    .e-gridheader .e-headercell,
    .e-gridheader .e-detailheadercell {
      user-select: none;
    }

    &:not(.e-left-shadow, .e-right-shadow) .e-gridcontent {
      & tr:first-child td {
        border-top: 0 none;
      }   
    }

    .e-gridcontent {
      & tr:first-child td.e-xlsel-top-border {
        border-top-width: 2px;
      }   
    }

    .e-gridheader {
      & tr:first-child th {
        border-top: 0 none;
      }

      & tr th:last-child.e-filterbarcell  {
        padding-right:  $grid-filterbarcell-first-last-child-padding-left;
      }

      & tr th:last-child  {
        padding-right:  $grid-header-first-last-cell-padding;
      }

      & tr th:first-child  {
        padding-left: $grid-header-first-last-cell-padding;
      }

      & tr th:first-child.e-filterbarcell {
        padding-left: $grid-filterbarcell-first-last-child-padding-left;
      }
    }

    .e-gridheader {
      border-bottom-style: $grid-border-type;
      border-bottom-width: $grid-bottom-border-size;
      border-top-style: $grid-border-type;
      border-top-width: $grid-border-size;
    }

    .e-frozenhdrcont,
    .e-frozenhdr {
      border-bottom-width: 0;
    }

    .e-frozenhdrcont table tr:not(.e-editedrow):first-child td:not(.e-xlsel-top-border) {
      border-top-style: $grid-border-type;
      border-top-width: $grid-bottom-border-size;
    }

    &.e-bothlines .e-filterbarcell,
    &.e-bothlines .e-filterbarcelldisabled {
      border-width: $grid-filtercell-both-border-width;
    }

    .e-headercell,
    .e-detailheadercell {
      border-style: $grid-border-type;
      border-width: $grid-header-border-width;
      font-size: $grid-header-font-size;
      font-weight: $grid-header-font-weight;
      height: $grid-headercell-line-height;
      overflow: hidden;
      padding: $grid-header-top-padding $grid-header-padding $grid-header-bottom-padding;
      position: relative;
      text-align: left;
    }

    &.e-device .e-headercell {
      padding: $grid-device-headercell-padding;
    }

    &.e-device .e-headercell:first-child {
      padding: $grid-device-headercell-fistchild-padding;
    }

    &.e-device .e-headercell:last-child {
      padding: $grid-device-headercell-lastchild-padding;
    }

    .e-rowcell {
      border-style: $grid-border-type;
      border-width: $grid-rowcell-broder-width;
      display: table-cell;
      font-size: $grid-content-font-size;
      line-height: $grid-rowcell-line-height;
      overflow: hidden;
      padding: $grid-content-padding $grid-content-right-padding;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;
    }

    &.e-left-shadow .e-leftfreeze.e-freezeleftborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border),
    &.e-right-shadow .e-rightfreeze.e-freezerightborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border) {
      line-height: $grid-rowcell-autofill-top-line-height;
      border-width : 1.5px;
    }

    &.e-rtl.e-left-shadow .e-leftfreeze.e-freezeleftborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border),
    &.e-rtl.e-right-shadow .e-rightfreeze.e-freezerightborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border) {
      line-height: $grid-rowcell-autofill-top-line-height;
      border-width : 1.5px;
    }

    &.e-rtl.e-default.e-left-shadow .e-leftfreeze.e-freezeleftborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border),
    &.e-rtl.e-default.e-right-shadow .e-rightfreeze.e-freezerightborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border) {
      line-height: $grid-rowcell-autofill-top-line-height;
      border-width : 1.5px;
    }

    &.e-wrap.e-left-shadow .e-leftfreeze.e-freezeleftborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border),
    &.e-wrap.e-right-shadow .e-rightfreeze.e-freezerightborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border) {
      line-height: $grid-rowcell-autofill-top-line-height;
      border-width : 1.5px;
    }

    &.e-wrap.e-left-shadow .e-leftfreeze.e-freezeleftborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border),
    &.e-wrap.e-right-shadow .e-rightfreeze.e-freezerightborder.e-focused:not(.e-menu-item,.e-xlsel-top-border,.e-xlsel-left-border,.e-xlsel-bottom-border,.e-xlsel-right-border) {
      line-height: $grid-rowcell-autofill-top-line-height;
      border-width : 1.5px;
    }

    .e-frozenheader table .e-insertedrow td.e-rowcell,
    .e-frozencontent table .e-insertedrow td.e-rowcell,
    .e-movableheader table .e-insertedrow td.e-rowcell,
    .e-movablecontent table .e-insertedrow td.e-rowcell,
    &:not(.e-grid-min-height) .e-gridcontent .e-content tr.e-insertedrow .e-rowcell:empty,
    & .e-row.e-emptyrow {
        height: $grid-rowcell-line-height + $grid-content-padding + $grid-content-padding + 1;
    }

    .e-editedrow .e-input-group input.e-input,
    .e-editedrow .e-input-group.e-control-wrapper input.e-input,
    .e-addedrow .e-input-group input.e-input,
    .e-addedrow .e-input-group.e-control-wrapper input.e-input {
        min-height: unset;
    }

    &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
      height: $grid-rowcell-line-height + $grid-content-padding + $grid-content-padding;
    }

    &.e-afenabled,
    &.e-enabledboxbdr {
        .e-movablecontent,
        .e-frozencontent,
        .e-movableheader,
        .e-frozenheader,
        .e-headercontent {
      position: relative;
      }
    }

    .e-rowcell:first-child,
    .e-summarycell:first-child {
      padding-left: $grid-first-last-rowcell-padding;
    }

    .e-rowcell:last-child,
    .e-summarycell:last-child {
      padding-right: $grid-first-last-rowcell-padding;
    }

    .e-unboundcell,
    .e-editedrow .e-normaledit .e-unboundcell,
    .e-addedrow .e-normaledit .e-unboundcell {
      padding-bottom: 4px;
      padding-top: 4px;
    }

    .e-unboundcelldiv > button {
      margin: $grid-unboundcelldiv-margin;
      @if $grid-skin == 'material3' {
        border-radius: 8px;
      }
    }

    .e-unboundcelldiv {
      margin: 0 -3.5px;
    }

    .e-summarycell {
      border-style: solid;
      border-width: 1px 0 0;
      font-size: $grid-summary-cell-font-size;
      font-weight: $grid-header-font-weight;
      height: auto;
      line-height: $grid-summary-cell-line-height;
      padding: $grid-content-padding $grid-content-right-padding;
      @if $grid-skin == 'tailwind' {
        padding: $grid-group-caption-header-padding $grid-content-right-padding;
      }
      white-space: normal;
      word-wrap: break-word;
      &.e-lastrowcell {
        border-bottom-width: 1px;
      }
    }

    .e-summarycontent {
        display: flex;
      & .e-frozenfootercontent {
        float: left;
        width: min-content;
      }

      & .e-frozen-right-footercontent {
          float: right;
          & .e-firstsummarycell {
            border-left: 1px solid;
            border-color: $grid-cell-border-color;
          }
      }

      & .e-movablefootercontent {
        height: inherit;
        overflow: hidden;
        flex: 1;
      }

      & .e-indentcell {
        border-width: 1px 0 0;
      }

      & .e-detailindentcelltop {
        border-width: 1px 0 0;
      }
    }

    &.e-device .e-rowcell,
    &.e-device .e-summarycell {
      padding: $grid-device-rowcell-padding;
    }

    &.e-device .e-rowcell:first-child,
    &.e-device .e-summarycell:first-child {
      padding: $grid-device-rowcell-firstchild-padding;
    }

    &.e-device .e-rowcell:last-child,
    &.e-device .e-summarycell:last-child {
      padding: $grid-device-rowcell-lastchild-padding;
    }

    td.e-rowcell.e-checkbox {
      padding: $grid-checkbox-cell-padding-top-bottom $grid-content-right-padding;
    }

    td.e-rowcell.e-checkbox input[type='checkbox'] {
      margin-top: $grid-td-checkbox-margin-top;
    }

    &.e-default .e-rowcell.e-lastrowcell:not(.e-xlsel-bottom-border) {
      border-bottom-width: 1px;
    }

    &.e-default .e-detailrowcollapse.e-lastrowcell {
        border-bottom-width: 1px;
    }

    &.e-default .e-detailrow .e-lastrowcell {
        border-bottom: 1px solid;
        border-bottom-color: $grid-header-border-color;
    }

    &.e-bothlines .e-rowcell.e-lastrowcell:not(.e-xlsel-bottom-border) {
      border-bottom-width: 1px;
    }

    &.e-bothlines .e-rowcell {
      border-width: $grid-rowcell-both-border-width;
    }

    &:not(.sf-grid).e-gridheader table th[rowspan],
    &:not(.sf-grid).e-device .e-gridheader table th[rowspan] {
      padding-bottom: $grid-stackedheadercell-botttom-padding;
      vertical-align: bottom;
    }

    .e-emptyrow td {
      line-height: $grid-rowcell-line-height;
      padding: .7em;
    }

    &.e-responsive .e-rowcell,
    &.e-responsive .e-headercelldiv {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.e-responsive .e-rowcell.e-gridchkbox,
    &.e-responsive .e-rowcell.e-gridchkbox-cell,
    &.e-responsive .e-headercelldiv.e-headerchkcelldiv,
    &.e-responsive .e-rowcell.e-boolcell {
      text-overflow: clip;
    }

    .e-addedrow .e-rowcell.e-boolcell .e-frame.e-check {
      color: $grid-checkmark-color;
    }

    &.e-default .e-headercell,
    &.e-default .e-detailheadercell {
      border-width: $grid-header-border-width;
    }

    [aria-selected] + tr .e-detailindentcell {
      border-top-style: $grid-border-type;
      border-top-width: $grid-border-size;
    }

    &.e-default.e-verticallines .e-headercell,
    &.e-default.e-verticallines .e-detailheadercell {
      border-width: 0 0 0 $grid-border-size;
    }

    &.e-default.e-verticallines .e-headercell.e-stackedheadercell {
      border-bottom: $grid-border-size solid;
    }

    &.e-default .e-stackedheadercell {
      border-width: $grid-stackedheadercell-border-size;
    }

    &.e-default .e-stackedheadercell.e-leftfreeze.e-freezeleftborder {
      border-right-width: 2px;
    }

    &.e-default .e-stackedheadercell.e-rightfreeze.e-freezerightborder {
      border-left-width: 2px;
    }

    &.e-default .e-stackedheadercell.e-fixedfreeze.e-freezeleftborder {
      border-left-width: 1px;
    }

    &.e-default .e-stackedheadercell.e-fixedfreeze.e-freezerightborder {
      border-right-width: 1px;
    }

    &.e-default.e-verticallines tr th:first-child:not(.e-firstcell) {
      border-left-width: 0;
    }

    &:not(.sf-grid).e-default.e-bothlines .e-headercell,
    &:not(.sf-grid).e-default.e-bothlines .e-detailheadercell {
      border-width: $grid-headercell-both-border-width;
    }

    &:not(.sf-grid, .e-rtl).e-default.e-bothlines .e-headercell.e-leftfreeze.e-freezeleftborder {
      border-right-width: 2px;
    }

    &:not(.sf-grid, .e-rtl).e-default.e-bothlines .e-headercell.e-rightfreeze.e-freezerightborder {
      border-left-width: 2px;
    }

    &:not(.sf-grid, .e-rtl).e-default.e-bothlines .e-headercell.e-fixedfreeze.e-freezerightborder {
      border-right-width: 1px;
    }

    &.e-rtl:not(.sf-grid).e-default.e-bothlines .e-headercell.e-leftfreeze.e-freezeleftborder {
      border-left-width: 2px;
    }

    &.e-rtl:not(.sf-grid).e-default.e-bothlines .e-headercell.e-rightfreeze.e-freezerightborder {
      border-right-width: 2px;
    }

    &.e-rtl:not(.sf-grid).e-default.e-bothlines .e-headercell.e-fixedfreeze.e-freezeleftborder {
      border-right-width: 1px;
    }


    &:not(.sf-grid).e-default table th[rowspan]  {
      border-width: $grid-headercell-both-border-width;
    }

    &:not(.sf-grid).e-default table th[rowspan].e-leftfreeze.e-freezeleftborder {
      border-right-width: 2px;
    }
  
    &:not(.sf-grid).e-default table th[rowspan].e-rightfreeze.e-freezerightborder {
      border-left-width: 2px;
    }
  
    &:not(.sf-grid).e-default table th[rowspan].e-fixedfreeze.e-freezeleftborder {
      border-left-width: 1px;
    }
      
    &:not(.sf-grid).e-default table th[rowspan].e-fixedfreeze.e-freezerightborder {
      border-right-width: 1px;
    }
  
    &.e-default.e-verticallines tr th:first-child:not(.e-firstcell) {
      border-left-width: 0;
    }

    tr  th.e-firstcell {
      border-left-style: $grid-border-type;
      border-left-width: $grid-border-size;
    }

    &.e-default tr {
        & th.e-detailheadercell:first-child {
          border-left-width: 0;
        }
      }
  
    &.e-default:not(.e-rtl) tr {
        & td:first-child:not(.e-xlsel-left-border, .e-fixedfreeze.e-freezeleftborder, .e-addfreezefirstchildborder),
        & th.e-headercell:first-child:not(.e-firstcell):not(.e-headercell.e-frozen-left-border),
        & th.e-filterbarcell:first-child:not(.e-filterbarcell.e-frozen-left-border) {
            border-left-width: 0;  
        }
    }

    &.e-default .e-gridheader th.e-firstcell.e-movablefirst{
      border-left-width: 0;
    }

    .e-hide {
      display: none;
    }

    .e-rowcell,
    .e-gridcontent,
    .e-gridheader,
    .e-headercontent,
    .e-groupdroparea,
    .e-gridfooter,
    .e-summarycontent {
      overflow: hidden;
      vertical-align: middle;
    }

    .e-groupdroparea.e-hover {
      @if $grid-skin == 'tailwind' {
        position: relative;
        top: 2px;
        right: -1.9px;
        margin-bottom: 4px;
        width: 99.7%;
        outline: 1px solid $cool-gray-200;
        outline-offset: 2px;
        border: 1px dashed $cool-gray-400;
        min-height: 35px;
        padding: 8px;
      }
      @else if $grid-skin == 'fluent' {
        padding-bottom: 13px;
      }
    }

    &.e-rtl .e-groupdroparea.e-hover {
      @if $grid-skin == 'tailwind' {
        right: 2px;
      }
    }

    .e-sortfilterdiv {
      float: right;
      height: $grid-sortdiv-height;
      margin: $grid-sortdiv-margin;
      padding: $grid-sortdiv-padding;
      width: $grid-sortdiv-width;
    }

    .e-gridheader .e-rightalign .e-sortfilterdiv {
      float: left;
      margin: $grid-sortdiv-right-align-margin;
    }

    .e-gridheader .e-sortfilter .e-rightalign .e-headercelldiv,
    .e-gridheader .e-rightalign .e-stackedheadercelldiv {
      padding: $grid-headercelldiv-right-align-padding;
      @if $grid-skin == 'material3' {
        margin-left: 8px;
      }
    }

    .e-gridheader .e-sortfilter .e-rightalign.e-fltr-icon .e-headercelldiv {
      margin: $grid-headercelldiv-rightalign-fltricon-margin;
    }


    .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
      margin: $grid-headercelldiv-fltricon-margin;
    }

    .e-gridheader .e-sortfilter .e-headercelldiv,
    .e-gridheader .e-stackedheadercelldiv {
      padding: $grid-headercelldiv-padding;
    }

    .e-gridheader .e-sortfilter .e-centeralign.e-headercell[aria-sort="none"],
    .e-gridheader .e-sortfilter .e-centeralign.e-headercell:not([aria-sort]) {
      .e-headercelldiv,
      .e-stackedheadercelldiv {
        padding-right: $grid-headercelldiv-sort-centeralign-padding-right;
      }
    }

    &.e-rtl .e-gridheader .e-sortfilter .e-centeralign.e-headercell[aria-sort="none"],
    &.e-rtl .e-gridheader .e-sortfilter .e-centeralign.e-headercell:not([aria-sort]) {
      .e-headercelldiv,
      .e-stackedheadercelldiv {
        padding-left: $grid-headercelldiv-sort-centeralign-padding-right;
      }
    }

    .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
      @if $grid-skin == 'bootstrap5' {
        padding-left: 8px;
      }
      @else if $grid-skin == 'fluent' {
        padding-left: 5px;
        margin-bottom: -6px;
      }
      @else {
        padding: $grid-headercelldiv-padding;
      }
    }

    &.e-rtl .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
      padding: $grid-rtl-headercelldiv-chkbox-padding;
    }

    .e-filtermenudiv {
      float: $grid-fltrdiv-float;
      margin: $grid-fltrdiv-margin;
      padding: $grid-fltrdiv-padding;
      text-align: $grid-fltrdiv-text-align;
    }

    .e-filtermenudiv:hover {
      cursor: pointer;
    }

    &.e-print-grid-layout {
      .e-pager, .e-filterbar, .e-icons:not(.e-frame), .e-grouptopleftcell, .e-recordpluscollapse, .e-indentcell, .e-recordplusexpand {
        display: none;
      }

      .e-indentcell.e-detailindentcelltop {
        display: table-cell;
      }

      .e-content {
        overflow-y: hidden;
      }

      .e-grouptext {
        width: auto;
      }

      .e-detailcell {
        padding: .8em .6em;
      }
    }

    &.e-print-grid {
      left: -1000px;
      top: -1000px;
    }

    .e-flmenu-valuediv {
      padding: $grid-flmenu-padding;
    }

    .e-flbldcontent {
      padding: $grid-flmenu-boolean-content-padding;
    }

    .e-flblbtn {
      width: $grid-flmenu-boolean-button-width;
    }


    .e-sortnumber {
      border-radius: $grid-sortnumber-border-radius;
      display: inline-block;
      float: right;
      font-size: $grid-sortnumber-font-size;
      height: $grid-sort-number-size;
      line-height: $grid-sortnumer-line-height;
      margin: $grid-sortnumber-margin;
      text-align: center;
      width: $grid-sort-number-size;
    }

    .e-gridheader .e-fltr-icon .e-sortnumber {
      margin: $grid-fltr-sortnumber-margin;
    }

    .e-gridheader .e-rightalign.e-fltr-icon .e-sortnumber {
      float: left;
      margin: $grid-fltr-sortnumber-rightalign-margin;
    }

    .e-gridheader .e-rightalign .e-sortnumber {
      float: left;
      margin: $grid-sortnumber-rightalign-margin;
    }

    .e-gridheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-sortdiv-filtericon-margin;
    }

    .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-sortdiv-filtericon-rightalign-margin;
    }


    &.e-wrap .e-gridheader .e-rightalign .e-sortnumber {
      margin: $grid-textwrap-srotnumber-rightalign-margin;
    }

    &.e-wrap .e-gridheader .e-sortnumber {
      margin: $grid-textwrap-srotnumber-margin;
    }

    &.e-wrap .e-gridheader .e-sortfilterdiv {
      margin: $grid-textwrap-sorticon-margin;
    }

    .e-movableheader .e-editcell.e-normaledit,
    .e-frozenheader .e-editcell.e-normaledit {
      border-top-color: transparent;
    }

    .e-columnmenu {
      position: absolute;
      right: 23px;
      float: right;
      margin: $grid-column-menu-margin;
      padding: $grid-column-menu-padding;
    }

    &.e-wrap .e-columnmenu {
      line-height: $grid-wrap-line-height;
    }

    &.e-rtl .e-columnmenu {
      left: $grid-rtl-column-menu-left;
      right: auto;
      margin: $grid-rtl-column-menu-margin;
      @if $grid-skin == 'tailwind' {
        margin: -13px 4px -13px -18px;
      }
    }

    &.e-wrap {
      &.e-rtl  .e-columnmenu {
        line-height: $grid-wrap-line-height;
      }
    }

    [class^='e-'] {
      box-sizing: border-box;
    }

    .e-rowdragdropcell {
      border-style: $grid-border-type;
      border-width: $grid-rowcell-broder-width;
    }

    .e-detailrowcollapse,
    .e-detailrowexpand {
      border-style: $grid-border-type;
      border-width: $grid-rowcell-broder-width;
      cursor: pointer;
      @if $grid-skin == 'material3' {
        padding-top: 6px;
      }
    }

    .e-detailindentcell {
      border-right-style: $grid-border-type;
      border-right-width: $grid-border-size;
    }

    .e-detailcell {
      border-top-style: $grid-border-type;
      border-top-width: $grid-border-size;
      padding: .3em;
      font-weight: normal;
      text-align: left;
    }

    th.e-detailcell .e-rowcell,
    th.e-detailcell .e-gridpager {
      text-align: left;
    }

    &.e-rtl th.e-detailcell .e-rowcell,
    &.e-rtl th.e-detailcell .e-gridpager,
    th.e-detailcell .e-rtl .e-rowcell,
    th.e-detailcell .e-rtl .e-gridpager {
      text-align: right;
    }

    &.e-verticallines .e-rowcell,
    &.e-verticallines .e-filterbarcell {
      border-width: 0 0 0 $grid-border-size;
    }

    &.e-hidelines .e-rowcell,
    &.e-horizontallines .e-headercell,
    &.e-hidelines .e-headercell,
    &.e-horizontallines .e-detailheadercell,
    &.e-hidelines .e-detailheadercell,
    &.e-hidelines tr th.e-firstcell,
    &.e-hidelines .e-filterbarcell {
      border-width: 0;
    }

    &.e-horizontallines .e-headercell.e-stackedheadercell {
      border-width: 0 0  $grid-border-size  $grid-border-size;
    }

    &.e-horizontallines .e-rowcell {
      border-width: $grid-border-size 0 0;
    }

    &.e-horizontallines .e-filterbarcell {
      border-width: $grid-bottom-border-size 0 0;
    }

    &.e-horizontallines,
    &.e-verticallines,
    &.e-hidelines {
      & .e-rowcell.e-lastrowcell:not(.e-xlsel-bottom-border) {
        border-bottom-width: $grid-border-size;
      }
    }

    &.e-horizontallines,
    &.e-verticallines,
    &.e-hidelines,
    &.e-bothlines {
      & .e-detailrowvisible {
        border-left-width: 0;
      }
    }

    &.e-verticallines,
    &.e-hidelines {
      & .e-firstchildrow .e-rowcell,
      & .e-firstchildrow .e-detailrowcollapse,
      & .e-firstchildrow .e-detailrowexpand {
        border-top-width: 1px;
      }
    }

    .e-filterbarcell .e-icons::before {
      display: block;
      margin: 0 auto;
    }

    .e-filterbarcell .e-filtertext::-webkit-search-cancel-button,
    .e-search input::-webkit-search-cancel-button {
      display: none;
    }

    .e-filterbarcell .e-filtertext::-ms-clear,
    .e-search input::-ms-clear{
      display: none;
    }

    /* stylelint-enable */
    .e-filterbarcell,
    .e-filterbarcelldisabled {
      border-collapse: collapse;
      border-style: $grid-border-type;
      border-width: $grid-filterbarcell-border-width;
      cursor: default;
      height: $grid-headercell-line-height;
      overflow: hidden;
      padding: $grid-filterbarcell-padding;
      vertical-align: middle;
    }

    .e-rowdragheader {
      border-color: $grid-header-border-color;
      border-style: $grid-border-type;
      border-width: $grid-filterbarcell-border-width;
    }

    .e-filterbarcell input {
      border-radius: $grid-filterbar-border-radius;
      border-style: solid;
      border-width: $grid-filterbar-input-border-width;
      font-size: $grid-font-size;
      font-weight: normal;
      height: $grid-filterbarcell-text-input;
      padding-right: 24px;
      text-indent: $grid-filterbarcell-text-indent;
      width: 100%;
    }

    &.e-device .e-filterbarcell {
      padding: $grid-device-filterbarcell-padding;
    }

    &.e-device .e-filterbarcell:first-child {
      padding: $grid-device-filterbarcell-firstchild-padding;
    }

    &.e-device .e-filterbarcell:last-child {
      padding: $grid-device-filterbarcell-lastchild-padding;
    }

    .e-searchclear {
      float: right;
      position: relative;
    }

    &.e-rtl .e-searchclear {
      float: left;
      position: relative;
    }

    .e-checkboxlist {
      height: 200px;
      margin-top: 5px;
      min-height: 160px;
      overflow-y: auto;
      &.e-checkbox-infinitescroll {
        border-top-style: solid;
        border-top-color: $grid-columnchooser-footer-border-color;
        border-top-width: 1px;
        margin-top: 0;
        overflow-x: hidden;
      }
    }

    .e-checkfltrnmdiv {
      height: 60px;
      padding: 23px;
      text-align: center;
    }

    .e-checkboxlist > span {
      padding-left: $grid-checkbox-padding-topbottom;
    }

    .e-chk-hidden {
      -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      height: 1px;
      opacity: 0;
      width: 1px;
    }

    .e-checkselect,
    .e-checkselectall {
      margin: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    .e-rowcell .e-checkbox-wrapper,
    .e-rowcell .e-css.e-checkbox-wrapper {
      -webkit-user-select: auto; /* stylelint-disable-line property-no-vendor-prefix */
      height: $grid-checkbox-wrapper-height;
      line-height: $grid-checkbox-wrapper-line-height;
      position: relative;
      top: $grid-checkbox-wrapper-top;
      @if $grid-skin == 'fluent' {
        border: 0;
      }
      user-select: auto;
    }

    .e-dialog.e-checkboxfilter,
    .e-dialog.e-flmenu {
      min-width: 250px;

      & .e-dlg-content {
        border-radius: $grid-filter-border-radius;
      }
    }

    .e-dialog.e-checkboxfilter {
      min-height: $grid-checkbox-filter-min-height;
    }

    .e-checkboxfilter .e-searchbox {
      display: block;
    }

    .e-checkboxfilter {
      & .e-dlg-content {
        padding-left: $grid-checkbox-content-padding-left;
        @if $grid-skin == 'material3' {
          padding-top: 16px;
          padding-right: 16px;
        }
      }

      & .e-ftrchk,
      & .e-searchbox {
        padding-left: $grid-checkbox-padding-topbottom;
      }
    }

    .e-ftrchk {
      padding-bottom: $grid-checkbox-padding-topbottom;
      padding-top: $grid-checkbox-padding-topbottom;
      @if $grid-skin == 'material3' {
        margin-left: -4px;
      }
    }

    .e-filterdiv,
    .e-fltrtempdiv {
      padding: $grid-filterdiv-padding;
      position: relative;
      text-align: center;
      width: 100%;
    }

    .e-pager {
      border-bottom: transparent;
      border-left: transparent;
      border-right: transparent;
    }

    .e-gridpopup {
      font-weight: normal;
      position: absolute;
      user-select: none;
      z-index: 99999;
    }

    .e-gridpopup .e-content {
      border-radius: 4px;
      border-style: solid;
      border-width: $grid-border-size;
      font-size: 14px;
      padding: 4px;
    }

    .e-footerpadding .e-lastsummarycell {
      border-left: none;
      border-right: 1px solid;
    }

    &.e-rtl .e-footerpadding tr.e-summaryrow {
      & td.e-lastsummarycell:last-child {
        border-right: none;
        border-left: 1px solid $grid-cell-border-color;
      }
    }

    .e-footerpadding {
      padding-left: 0;
      padding-right: 14px; //need to consider from javascript
    }

    &.e-rtl .e-footerpadding {
      padding-left: 14px; //need to consider from javascript
      padding-right: 0;
    }

    .e-gridpopup span {
      border: 1px solid transparent;
      cursor: pointer;
      display: inline-block;
      height: 26px;
      padding: 4px;
      width: 26px;
    }

    .e-gridpopup .e-tail::before,
    .e-gridpopup .e-tail::after {
      border: 10px solid transparent;
      content: '';
      height: 0;
      @if $grid-skin == 'material3' {
        left: 12px;
      }
      @else {
        left: 8px;
      }
      position: absolute;
      width: 0;
    }

    .e-gridpopup .e-downtail::after {
      top: 34px;
    }

    .e-gridpopup .e-uptail::after {
      top: -17px;
    }

    .e-gridpopup .e-uptail::before {
      top: -19px;
    }

    .e-gridpopup .e-sortdirect,
    .e-gridpopup .e-rowselect {
      @if $grid-skin == 'material3' {
        font-size: 20px;
        padding: 2px 6px;
        line-height: 20px;
        width: 34px;
      }
      @else {
        line-height: 18px;
      }
      text-indent: $grid-rowselect-text-indent;
    }

    .e-cloneproperties {
      border-style: solid;
      border-width: $grid-border-size;
      box-shadow: $grid-group-clone-box-shadow;
      font-size: $grid-header-font-size;
      font-weight: $grid-drag-clone-font-weight;
      opacity: $grid-drag-clone-opacity;
      overflow: hidden;
      padding: $grid-clone-padding;
      text-align: $grid-group-clone-text-align;
      @if $grid-skin == 'tailwind' {
        text-transform: uppercase;
      }
      @else if $grid-skin == 'material3' {
        border-radius: 4px;
      }
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      z-index: 10;
    }

    .e-cloneproperties.e-draganddrop {
      border-spacing: 0;
      font-size: $grid-header-font-size;
      font-weight: normal;
      overflow: visible;
    }

    .e-cloneproperties.e-draganddrop table {
      border-spacing: 0;
    }

    .e-defaultcur {
      cursor: default;
    }

    .e-notallowedcur {
      cursor: not-allowed;
    }

    .e-grabcur {
      cursor: grabbing;
    }

    .e-cloneproperties.e-headerclone table {
      border-spacing: 0;
    }

    .e-headerclone {
      border-radius: $group-header-border-radius;
      font-size: $grid-header-font-size;
      font-weight: $grid-drag-clone-font-weight;
      line-height: 29px;
    }

    .e-draganddrop {
      border-width: 0 1px 1px;
      font-weight: normal;
      padding: 0;
    }

    .e-draganddrop .e-rowcell {
      opacity: .95;
    }

    &.e-default .e-gridheader th.e-firstcell,
    &.e-default .e-gridheader th.e-laststackcell {
      border-left-style: $grid-border-type;
      border-left-width: $grid-border-size;
    }

    &:not(.sf-grid).e-bothlines .e-gridheader th.e-stackedheadercell {
      border-bottom-style: $grid-border-type;
      border-bottom-width: $grid-border-size;
      border-left-style: $grid-border-type;
      border-left-width: $grid-border-size;
    }

    &.e-default.e-hidelines .e-gridheader th.e-firstcell {
      border-left-width: 0;
    }

    .e-gridheader .e-headercontent .e-reorderuparrow,
    .e-gridheader .e-headercontent .e-reorderdownarrow,
    .e-gridheader .e-headercontent .e-reorderuparrow-virtual,
    .e-gridheader .e-headercontent .e-reorderdownarrow-virtual {
      font-size: $grid-reorderarrow-font-size;
      margin-left: $grid-reorderarrow-margin-left;
      margin-top: $grid-reorderarrow-margin-top;
      position: absolute;
      z-index: 10;
    }

    &.e-rtl {
      .e-gridheader .e-headercontent .e-reorderuparrow,
      .e-gridheader .e-headercontent .e-reorderdownarrow,
      .e-gridheader .e-headercontent .e-reorderuparrow-virtual,
      .e-gridheader .e-headercontent .e-reorderdownarrow-virtual {
        @if $grid-skin == 'material3' {
          margin-left: -1.3px;
        }
      }
    }

    .e-gridheader .e-headercontent .e-reorderuparrow {
      margin-top: $grid-reorderarrow-margin-top;
    }

    .e-gridheader .e-headercontent .e-reorderdownarrow {
      margin-top: $grid-reorderdownarrow-margin-top;
    }

    td.e-active {
      font-weight: $grid-selection-font-weight;
    }

    &:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child td.e-cellselectionbackground,
    td.e-cellselectionbackground {
      font-weight: $grid-selection-font-weight;
      @if $grid-skin == 'material3' {
        border: 1px solid rgba($content-text-color);
        padding-bottom: 7px;
        padding-left: 7.5px;
        padding-right: 7.5px;
      }
    }

    &.e-grid-min-height td.e-cellselectionbackground {
      @if $grid-skin == 'material3' {
        padding-bottom: 0;
      }
    }

    &.e-bothlines td.e-rowcell.e-cellselectionbackground,
    &.e-bothlines.e-rtl td.e-rowcell.e-cellselectionbackground,
    .e-bigger &.e-bothlines.e-rtl td.e-rowcell.e-cellselectionbackground,
    &.e-bothlines.e-bigger.e-rtl td.e-rowcell.e-cellselectionbackground,
    &.e-rtl td.e-rowcell.e-cellselectionbackground,
    .e-bigger &.e-rtl td.e-rowcell.e-cellselectionbackground,
    &.e-bigger.e-rtl td.e-rowcell.e-cellselectionbackground {
      @if $grid-skin == 'material3' {
        border: 1px solid rgba($content-text-color);
      }
    }

    &:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child td.e-cellselectionbackground {
      @if $grid-skin == 'material3' {
        padding-top: 7px;
      }
    }

    .e-bigger &:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child td.e-cellselectionbackground,
    .e-bigger & td.e-cellselectionbackground,
    &.e-bigger:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child td.e-cellselectionbackground,
    &.e-bigger td.e-cellselectionbackground {
      @if $grid-skin == 'material3' {
        padding-bottom: 13px;
        padding-left: 11.5px;
        padding-right: 11.5px;
      }
    }

    .e-bigger &:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child td.e-cellselectionbackground,
    &.e-bigger:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child td.e-cellselectionbackground {
      @if $grid-skin == 'material3' {
        padding-top: 13px;
      }
    }

    .e-groupdroparea {
      height: auto;
      line-height: normal;
      min-height: $grid-group-height;
    }

    .e-griddragarea {
      border: $grid-drag-area-border;
      opacity: .6;
      position: absolute;
    }

    .e-gdclone {
      border-radius: $grid-gd-clone-border-radius;
      padding: 2px;
    }

    .e-content {
      -webkit-overflow-scrolling: touch; /* stylelint-disable-line property-no-vendor-prefix */
      overflow-x: auto;
      overflow-y: scroll;
      position: relative;
    }

    &.e-ungroupdrag .e-columnheader,
    &.e-ungroupdrag .e-groupdroparea,
    &.e-rowdrag .e-columnheader,
    &.e-rowdrag .e-groupdroparea {
      cursor: not-allowed;
    }

    .e-groupdroparea,
    &.e-ungroupdrag .e-gridcontent,
    &.e-rowdrag .e-gridcontent {
      cursor: default;
    }

    .e-groupdroparea {
      border-style: solid none;
      border-width: 1px 0 0;
    }

    .e-groupdroparea {
      border-top-width: $grid-border-size;
      font-size: $grid-grouparea-font-size;
      font-weight: $grid-grouparea-font-weight;
      opacity: $grid-conent-font-opacity;
      padding: $group-area-padding;
      text-align: $group-text-align;
      text-indent: 1pt;
      user-select: none;
      width: 100%;
    }

    .e-grouptext {
      display: inline-block;
      margin-right: $grid-grouptext-margin-right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: $grid-group-text-width;
      @if $grid-skin == 'material3' {
        font-weight: 500;
      }
    }

    .e-grid-icon {
      float: left;
    }

    .e-groupheadercell,
    .e-groupheadercell:hover {
      border: $group-border-width;
      border-collapse: collapse;
      border-radius: $group-header-border-radius;
      cursor: pointer;
      font-size: $grouped-text-font-size;
      font-weight: normal;
      height: $grid-headecell-height;
      margin: $grid-grouparea-margin;
      overflow: hidden;
      @if $grid-skin != 'material3' {
        padding: $grid-grouped-headercell-padding;
      }
      vertical-align: middle;
    }

    .e-groupheadercell:hover {
      border: $group-hover-border;
      @if $grid-skin != 'material3' {
        padding: $grid-grouped-headercell-hover-padding;
      }
    }

    .e-groupdroparea.e-grouped.e-hover .e-groupheadercell {
      @if $grid-skin == 'tailwind' {
        margin-top: 2px;
      }
      @else if $grid-skin == 'fluent' {
        margin-bottom: 3px;
      }
    }

    .e-groupheadercell span {
      display: inline-block;
      float: left;
      height: $grid-headecell-span-height;
      line-height: $grid-group-headercell-line-height;
      padding: $grid-grouped-headercell-span-padding;
      vertical-align: middle;
    }

    .e-groupheadercell .e-grouptext {
      line-height: $grid-group-text-line-height;
      @if $grid-skin == 'bootstrap4' {
        padding-top: 3px;
      }
    }

    .e-groupheadercell .e-ungroupbutton {
      font-size: $grid-ungroup-button-font-size;
      line-height: $grid-ungroup-button-line-height;
    }

    &.e-device .e-groupheadercell span {
      line-height: $grid-touch-device-hdrcell-span-line-height;
    }

    &.e-device .e-groupheadercell .e-ungroupbutton {
      line-height: $grid-touch-device-hdrcell-ungroup-line-height;
    }

    &.e-device .e-groupheadercell .e-groupsort {
      margin-top: $grid-device-groupsort-margin-top;
    }

    .e-groupheadercell .e-cancel {
      padding-right: $group-sorticon-font-size;
    }

    .e-groupheadercell .e-icons::before {
      display: inline;
    }

    .e-groupsort,
    .e-ungroupbutton,
    .e-toggleungroup {
      font-size: $group-sorticon-font-size;
      margin-left: $group-sorticon-margin-left;
    }

    .e-groupsort {
      margin-right: $group-sorticon-margin-right;
      margin-top: $group-sorticon-margin-top;
      @if $grid-skin == 'material3' {
        padding-top: 6px;
      }
    }

    span.e-ungroupbutton.e-icons {
      margin-left: $group-unpgrouicon-margin-left;
      margin-top: $group-sorticon-margin-top;
      padding-top: $grid-group-unpgroupicon-padding-top;
      padding-right: $grid-group-unpgroupicon-padding-right;
    }

    span.e-ungroupbutton.e-icons:hover {
      @if $grid-skin == 'tailwind' {
        color: $cool-gray-700;
      }
    }

    .e-grptogglebtn {
      padding: 7px;
    }

    .e-icon-gdownarrow {
      font-size: $grid-group-down-arrow-icon-font-size;
      padding: $grid-group-down-arrow-icon-padding;
      text-decoration: none;
    }

    .e-icon-grightarrow {
      font-size: $grid-group-right-arrow-icon-font-size;
      padding: $grid-group-right-arrow-icon-padding;
      text-decoration: none;
    }

    .e-recordplusexpand {
      padding-top: 5px;
    }

    .e-indentcell {
      border-style: $grid-border-type;
      border-width: 0 $grid-border-size 0 0;

      &.e-indentcelltop,
      &.e-detailindentcelltop {
        border-width: $grid-border-size 0 0;
      }
    }

    .e-recordplusexpand,
    .e-recordpluscollapse {
      border-style: $grid-border-type;
      border-width: $grid-border-size 0 0;
      cursor: pointer;
      @if $grid-skin == 'material3' {
        padding-top: 6px;
      }
    }

    .e-disablepointer {
      cursor: default;
    }

    .e-groupcaption {
      border-style: $grid-border-type;
      border-width: $grid-border-size 0 0;
      display: table-cell;
      font-size: $grid-group-caption-font-size;
      @if $grid-skin == 'material3' {
        font-weight: 500;
      }
      line-height: $grid-group-captioncell-line-height;
      overflow: hidden;
      padding: $grid-group-caption-header-padding;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;
    }

    .e-virtualtable .e-groupcaption {
      line-height: $grid-rowcell-line-height;
      padding: $grid-content-padding $grid-content-right-padding;
    }

    .e-autofill {
      border: 1px solid;
      height: 8px;
      position: absolute;
      user-select: none;
      width: 8px;
    }

    .e-headercontent {
      border-style: solid;
      border-width: 0;
    }

    .e-stackedheadercell {
      border-width: $grid-stackedheadercell-border-size;
      white-space: nowrap;
    }

    .e-noselect {
      user-select: none;
    }

    .e-toolbar .e-tbar-btn.e-btn .e-btn-icon.e-icons.e-columnchooser-btn {
      font-size: $grid-columnchooser-btn-font-size;
    }

    .e-toolbar .e-toolbar-items .e-toolbar-right .e-cc-toolbar .e-tbar-btn .e-columnchooser-btn {
      margin-top: $grid-cc-margin-top;
    }

    .e-toolbar-item.e-cc.e-ccdiv.e-cc-toolbar {
      margin-top: $grid-columnchooser-toolbardiv-margin-top;
      padding: $grid-columnchooser-toolbardiv-padding;
    }

    .e-edit-dialog .e-dlg-content {
      position: relative;
    }

    .e-edit-dialog {
      min-height: 350px;
    }

    /* stylelint-disable */
    .e-griderror label {
      display: inline !important;
    }
    /* stylelint-enable */

    .e-tooltip-wrap.e-griderror {
      z-index: 1000;
    }

    .e-tooltip-wrap.e-griderror .e-arrow-tip.e-tip-top {
      left: 44%;
      top: -8px;
    }

    .e-normaledit .e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    &.e-device .e-normaledit .e-rowcell {
      padding-bottom: $grid-edit-cell-padding;
      padding-top: $grid-edit-cell-padding;
    }

    .e-gridcontent .e-normaledit .e-rowcell.e-lastrowadded {
      border-bottom: $grid-border-size $grid-border-type $grid-cell-border-color;
      border-top: 0 none $grid-cell-border-color;
    }

    .e-normaledit {
      border-top: 0;
      padding: 0;
    }

    .e-gridcontent table tbody .e-normaledit .e-rowcell {
      border-top: $grid-border-size $grid-border-type;
    }

    &:not(.e-row-responsive, .e-left-shadow, .e-right-shadow) .e-gridcontent tr.e-row:first-child .e-rowcell:not(.e-xlsel-top-border) {
      border-top: 0;
    }

    &.e-device .e-editedbatchcell.e-rowcell,
    .e-editedbatchcell.e-rowcell,
    &.e-device .e-gridcontent .e-normaledit .e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    .e-editedbatchcell,
    td.e-boolcell {
      padding-left: $grid-content-right-padding;
      padding-right: $grid-content-right-padding;
    }

    td.e-boolcell input {
      margin: 3px 3px 3px 4px;
    }

    td.e-boolcell.e-rowcell {
      padding-bottom: 5px;
      padding-top: 5px;
    }

    .e-dialog .e-gridform .e-rowcell {
      border: 0;
      padding: 0;
    }
    .e-row .e-input-group .e-input.e-field,
    .e-row .e-input-focus .e-input.e-field {
      font-family: $grid-font-family;
      font-size: $grid-font-size;
      padding-bottom: $grid-edit-input-padding-bottom;
      padding-top: $grid-edit-input-padding-top;
    }

    .e-row .e-input-group {
      margin-bottom: $grid-edit-input-margin;
      margin-top: $grid-edit-input-margin-top;
      vertical-align: middle;
      @if $grid-skin == 'material3' {
        line-height: 28.5px;
      }
    }

    .e-defaultcell.e-ralign,
    .e-editedrow .e-defaultcell.e-ralign,
    .e-defaultcell.e-ralign:focus,
    .e-editedrow .e-defaultcell.e-ralign:focus {
      padding-right: 10px;
    }

    .e-dlg-content .e-defaultcell.e-ralign,
    .e-dlg-content .e-defaultcell.e-ralign:focus {
      padding-right: 0;
    }

    .e-hiddenrow {
      display: none;
    }

    .e-columnchooserdiv {
      float: right;
      margin: -12px;
    }

    .e-bigger & .e-ccdlg .e-checkbox-wrapper span.e-label,
    &.e-bigger .e-ccdlg .e-checkbox-wrapper span.e-label {
      @if $grid-skin == 'material3' {
        font-size: $text-base;
      }
    }

    .e-ccdlg {

      .e-dlg-content {
        margin: $grid-columnchooser-content-margin;
        overflow: visible;
        padding: $grid-columnchooser-content-padding;
      }

      .e-checkbox-wrapper.e-control.e-keyboard {
        padding-left: $grid-cc-checkbox-padding-left;
      }

      .e-main-div {
        box-sizing: border-box;
        position: relative;
      }

      .e-ccul-ele {
        margin: $grid-columnchooser-ul-margin;
        padding: $grid-columnchooser-ul-padding;
      }

      li.e-cclist {
        padding: $grid-columnchooser-li-padding;
      }

      .e-checkbox-wrapper .e-frame {
        margin-left: 0;
      }

      label.e-cc {
        margin: 0;
      }

      .e-footer-content {
        border-style: solid;
        border-width: $grid-column-chooser-footdiv;
      }

      .e-cc-contentdiv {
        height: 196px;
        overflow-y: auto;
      }

      .e-cc-searchdiv {
        border-style: solid;
        border-width: $grid-column-chooser-searchdiv;
        left: $grid-column-chooser-searchdiv-left;
        opacity: $grid-column-chosser-searchdiv-opactiy;
        position: absolute;
        right: 0;
        top: $grid-column-chosser-searchdiv-top;

        span.e-ccsearch-icon.e-icons {
          float: right;
          opacity: .6;
          padding: $grid-column-chooser-search-icon-padding;
        }

        span.e-ccsearch-icon.e-cc-cancel {
          font-size: $grid-column-chooser-cancel-icon-size;
          padding: $grid-column-chooser-cancel-icon-padding;
        }
      }

      .e-cc-searchdiv.e-input-focus {
        opacity: $grid-column-chosser-searchdiv-fopactiy;

        span.e-ccsearch-icon.e-icons {
          opacity: $grid-columnchooser-cancel-icon-content-f-opactiy;
        }
      }

      .e-innerdiv.e-ccnmdiv {
        height: 60px;
        padding: 23px;
        text-align: center;
      }

      .e-checkbox-wrapper .e-label {
        text-overflow: ellipsis;
      }

      .e-cc-chbox {
        margin: 3px;
        vertical-align: middle;
      }

      .e-cc-lab-name {
        padding: 7px;
        text-overflow: ellipsis;
        vertical-align: middle;
      }

      .e-cc.e-input,
      .e-cc.e-input:focus {
        border: $grid-columnchooser-input-border;
        padding-bottom: $grid-columnchooser-input-padding-bottom;
        padding-left: $grid-columnchooser-input-padding-left;
        padding-top: $grid-columnchooser-input-padding-top;
      }
    }

    .e-checkboxfiltertext {
      width: $grid-checkboxfiltertext-width;
      word-break: normal;
      /* stylelint-disable */
      @if $grid-skin == 'fluent' {
        margin-left: 12px !important;
      }
      /* stylelint-enable */
    }

    .e-frozenhdrcont .e-headercontent > .e-table,
    .e-frozenhdrcont .e-frozenheader > .e-table,
    .e-frozenhdrcont .e-movableheader > .e-table,
    .e-frozenhdrcont .e-headercontent .e-virtualtable > .e-table {
      border-bottom: $frozen-border;
    }

    .e-frozenheader > .e-table,
    .e-frozencontent > .e-table,
    .e-frozenheader .e-virtualtable > .e-table,
    .e-frozencontent .e-virtualtable > .e-table {
      border-left: 0;
      border-right: $frozen-border;
    }

    .e-frozenheader.e-frozen-right-header > .e-table,
    .e-frozencontent.e-frozen-right-content > .e-table,
    .e-rowcell .e-frozen-default-cursor,
    .e-gridheader .e-headercell .e-frozen-default-cursor,
    .e-gridheader .e-filterbarcell .e-frozen-default-cursor {
      border-left: $frozen-border;
      border-right: 0;
    }

    .e-tooltip-wrap.e-griderror.e-unfreeze {
      z-index: 1;
    }

    &.e-lib .e-leftfreeze,
    &.e-lib.e-grid .e-headercell.e-leftfreeze,
    &.e-lib.e-grid .e-headercell.e-rightfreeze,
    &.e-lib .e-rightfreeze,
    .e-leftfreeze,
    .e-rightfreeze {
      position: sticky;
      z-index: 2;
    }

    &.e-lib .e-fixedfreeze,
    &.e-lib.e-grid .e-headercell.e-fixedfreeze,
    .e-fixedfreeze {
      position: sticky;
      z-index: 3;
    }

    .e-xlsel-top-border,
    &.e-rtl.e-default .e-rowcell.e-xlsel-top-border {
      border-top-width: 2px;
      line-height: $grid-rowcell-autofill-top-line-height;
    }
    
    .e-xlsel-left-border,
    .e-fixedfreeze.e-freezeleftborder.e-xlsel-left-border,
    &.e-rtl.e-default .e-rowcell.e-xlsel-left-border,
    &.e-rtl.e-default .e-rowcell.e-fixedfreeze.e-freezeleftborder.e-xlsel-left-border {
      border-left-width: 2px;
    }

    .e-xlsel-right-border,
    .e-fixedfreeze.e-freezerightborder.e-xlsel-right-border,
    &.e-rtl.e-default .e-rowcell.e-xlsel-right-border,
    &.e-rtl.e-default .e-rowcell.e-fixedfreeze.e-freezerightborder.e-xlsel-right-border {
      border-right-width: 2px;
    }
    &.e-wrap {
      .e-xlsel-bottom-border.e-xlsel-top-border {
        line-height: $grid-rowcell-autofill-top-height;
      }
      .e-xlsel-bottom-border {
        line-height: $grid-rowcell-autofill-top-bottom-height;
      }
    }

    &.e-rtl.e-wrap {
      .e-xlsel-bottom-border.e-xlsel-top-border {
        line-height: $grid-rowcell-autofill-top-height;
      }
      .e-xlsel-bottom-border {
        line-height: $grid-rowcell-autofill-top-bottom-height;
      }
    }

    .e-xlsel-bottom-border.e-xlsel-top-border {
      line-height: $grid-rowcell-autofill-top-bottom-line-height;
    }

    &.e-rtl.e-default .e-rowcell.e-xlsel-bottom-border.e-xlsel-top-border {
      line-height: $grid-rowcell-autofill-top-bottom-line-height;
    }

    .e-xlsel-bottom-border,
    &.e-rtl.e-default .e-rowcell.e-xlsel-bottom-border {
      border-bottom-width: 2px;
      line-height: $grid-rowcell-autofill-top-line-height;
    }

    .e-virtual-content.e-content {
      overflow-x: hidden;
    }

    &.e-lib.e-default .e-headercell.e-leftfreeze.e-freezeleftborder,
    &.e-lib .e-leftfreeze.e-freezeleftborder,
    .e-leftfreeze.e-freezeleftborder {
      border-right-width: 2px;
    }

    &.e-left-shadow .e-leftfreeze.e-freezeleftborder:not(.e-dragborder) {
      clip-path: inset(0 -15px 0 0);
      box-shadow: 4px 0 8px $grid-frozen-shadow-background-color;
    }

    &.e-left-shadow .e-leftfreeze.e-freezeleftborder:not(.e-xlsel-right-border) {
      border-right-color: transparent;
    }

    &.e-left-shadow .e-leftfreeze.e-freezeleftborder.e-dragborder {
      box-shadow: $grid-dragborder-box-shadow, 4px 0 8px $grid-frozen-shadow-background-color;
    }

    &.e-rtl.e-left-shadow .e-leftfreeze.e-freezeleftborder:not(.e-dragborder) {
      clip-path: inset(0 0 0 -15px);
      box-shadow: -4px 0 8px $grid-frozen-shadow-background-color;
    }

    &.e-rtl.e-left-shadow .e-leftfreeze.e-freezeleftborder:not(.e-xlsel-left-border) {
      border-left-color: transparent;
    }

    &.e-rtl.e-left-shadow .e-leftfreeze.e-freezeleftborder.e-dragborder {
      box-shadow: $grid-dragborder-box-shadow, -4px 0 8px $grid-frozen-shadow-background-color;
    }
  
    &.e-lib.e-default .e-headercell.e-rightfreeze.e-freezerightborder,
    &.e-lib .e-rightfreeze.e-freezerightborder,
    .e-rightfreeze.e-freezerightborder {
      border-left-width: 2px;
    }

    &.e-right-shadow .e-rightfreeze.e-freezerightborder:not(.e-dragborder) {
      clip-path: inset(0 0 0 -15px);
      box-shadow: -4px 0 8px $grid-frozen-shadow-background-color;
    }

    &.e-right-shadow:not(.e-rtl) .e-rightfreeze.e-freezerightborder:not(.e-xlsel-left-border) {
      border-left-color: transparent;
    }

    &.e-right-shadow .e-rightfreeze.e-freezerightborder.e-dragborder {
      box-shadow: $grid-dragborder-box-shadow, -4px 0 8px $grid-frozen-shadow-background-color;
    }

    &.e-rtl.e-right-shadow .e-rightfreeze.e-freezerightborder:not(.e-dragborder) {
      clip-path: inset(0 -15px 0 0);
      box-shadow: 4px 0 8px $grid-frozen-shadow-background-color;
    }

    &.e-rtl.e-right-shadow .e-rightfreeze.e-freezerightborder:not(.e-xlsel-right-border) {
      border-right-color: transparent;
    }

    &.e-rtl.e-right-shadow .e-rightfreeze.e-freezerightborder.e-dragborder {
      box-shadow: $grid-dragborder-box-shadow, 4px 0 8px $grid-frozen-shadow-background-color;
    }

    &.e-lib.e-default:not(.e-rtl) .e-headercell.e-fixedfreeze.e-freezeleftborder:not(.e-removefreezeleftborder),
    &.e-lib:not(.e-rtl) .e-fixedfreeze.e-freezeleftborder:not(.e-removefreezeleftborder),
    &:not(.e-rtl) .e-fixedfreeze.e-freezeleftborder:not(.e-removefreezeleftborder) {
      border-left-width: 1px;
    }

    &.e-lib.e-default:not(.e-rtl) .e-headercell.e-fixedfreeze.e-freezerightborder:not(.e-removefreezerightborder),
    &.e-lib:not(.e-rtl) .e-fixedfreeze.e-freezerightborder:not(.e-removefreezerightborder),
    &:not(.e-rtl) .e-fixedfreeze.e-freezerightborder:not(.e-removefreezerightborder) {
      border-right-width: 1px;
    }

    .e-frozenheader {
      float: left;
      width: min-content;
    }

    .e-frozenheader.e-frozen-right-header {
      float: right;
    }

    &.e-rtl .e-frozenheader > .e-table,
    &.e-rtl .e-frozencontent > .e-table,
    &.e-rtl .e-frozenheader .e-virtualtable > .e-table,
    &.e-rtl .e-frozencontent .e-virtualtable > .e-table {
      border-left: $frozen-border;
      border-right: 0;
    }

    &.e-rtl .e-frozenheader.e-frozen-right-header > .e-table,
    &.e-rtl .e-frozencontent.e-frozen-right-content > .e-table {
      border-left: 0;
      border-right: $frozen-border;
    }

    &.e-rtl .e-frozenheader {
      float: right;
    }

    &.e-rtl .e-frozenheader.e-frozen-right-header {
      float: left;
    }

    &.e-rtl .e-frozencontent {
      float: right;
    }

    &.e-rtl .e-frozen-right-content {
      float: left;
    }

    &.e-rtl .e-frozenfootercontent {
      float: right;
    }

    &.e-rtl .e-frozen-right-footercontent {
      float: left;
    }

    .e-movableheader {
      overflow: hidden;
    }

    .e-frozenhdrcont {
      -ms-touch-action: none; /* stylelint-disable-line property-no-vendor-prefix */
    }

    .e-frozencontent {
      -ms-touch-action: none; /* stylelint-disable-line property-no-vendor-prefix */
      border-bottom-width: $grid-border-size;
      float: left;
      width: min-content;
    }

    .e-frozen-right-content {
      float: right;
    }

    .e-movablecontent {
      // Internet Explorer 10+
      -ms-overflow-style: none; /* stylelint-disable-line property-no-vendor-prefix */
      flex: 1;
      overflow-x: auto;
      overflow-y: hidden;
    }

    // Safari and Chrome
    &:not(.sf-grid) .e-movablecontent::-webkit-scrollbar { /* stylelint-disable-line property-no-vendor-prefix */
      display: none;
    }

    // Safari with Mac OS
    .e-content.e-mac-safari::-webkit-scrollbar { /* stylelint-disable-line property-no-vendor-prefix */
      width: 7px;
    }

    // Safari with Mac OS
    .e-content.e-mac-safari::-webkit-scrollbar-thumb { /* stylelint-disable-line property-no-vendor-prefix */
      background-color: $grid-frozen-mac-scrollbar-background;
      border-radius: 4px;
    }

    .e-frozenscrollbar {
      border-top: 1px solid $grid-cell-border-color;
    }

    .e-movablescrollbar {
      flex: 1;
      overflow: hidden;
      overflow-x: scroll;
    }

    .e-columnchooser::before {
      line-height: $grid-columnchooser-toolbar-icon-line-height;
    }

    .e-toolbar .e-ccdiv .e-columnchooser.e-cctbn-icon {
      font-size: $grid-columnchooser-toolbar-icon-font-size;
      vertical-align: $grid-columnchooser-toolbar-icon-vertical-align;
    }

    .e-toolbar .e-ccdiv {
      margin-top: $grid-columnchooser-toolbar-div-margin-top;
      padding: $grid-columnchooser-toolbar-div-padding;
    }

    &.e-rtl {
      .e-tableborder {
        border-left: 1px solid;
        border-right: 0;
      }

      .e-checkboxfilter {
        & .e-dlg-content {
          padding-left: $grid-rtl-checkbox-content-padding-left;
          padding-right: $grid-checkbox-content-padding-left;
        }

        & .e-ftrchk,
        & .e-searchbox {
          padding-left: 0;
          padding-right: $grid-checkbox-padding-topbottom;
        }
      }

      &.e-lib.e-default .e-headercell.e-leftfreeze.e-freezeleftborder,
      &.e-lib .e-leftfreeze.e-freezeleftborder,
      .e-leftfreeze.e-freezeleftborder {
        border-left-width: 2px;
      }
    
      &.e-lib.e-default .e-headercell.e-rightfreeze.e-freezerightborder,
      &.e-lib .e-rightfreeze.e-freezerightborder,
      .e-rightfreeze.e-freezerightborder {
        border-right-width: 2px;
      }
  
      &.e-lib.e-default .e-headercell.e-fixedfreeze.e-freezeleftborder:not(.e-removefreezerightborder),
      &.e-lib .e-fixedfreeze.e-freezeleftborder:not(.e-removefreezerightborder),
      .e-fixedfreeze.e-freezeleftborder:not(.e-removefreezerightborder) {
        border-right-width: 1px;
      }
  
      &.e-lib.e-default .e-headercell.e-fixedfreeze.e-freezerightborder:not(.e-removefreezeleftborder),
      &.e-lib .e-fixedfreeze.e-freezerightborder:not(.e-removefreezeleftborder),
      .e-fixedfreeze.e-freezerightborder:not(.e-removefreezeleftborder) {
        border-left-width: 1px;
      }

      & .e-headercell,
      & .e-detailheadercell {
        border-width: $grid-rtl-headercell-border-width;
        text-align: right;

        &.e-fltr-icon {
          & .e-headercelldiv,
          .e-headercelldiv.e-headerchkcelldiv {
            padding: $grid-rtl-headercelldiv-padding;
            text-align: right;

            & .e-sortnumber {
              float: left;
              margin: $grid-fltr-rtl-sortnumber-margin;
            }
          }
        }

        & .e-headercelldiv,
        .e-headercelldiv.e-headerchkcelldiv {
          padding: $grid-rtl-headercelldiv-padding;
          text-align: right;

          & .e-sortnumber {
            float: left;
            margin: $grid-rtl-sortnumber-margin;
          }
        }

        & .e-filterbarcell input {
          border-width: $grid-filterbar-input-border-width;
        }

        & .e-sortfilterdiv {
          float: left;
          margin: $grid-rtl-sortcelldiv-margin;
        }

        &.e-leftalign.e-headercell.e-fltr-icon {
          & .e-headercelldiv {
            margin-left: $grid-rtl-headercelldiv-left-align-margin;

            & .e-sortnumber {
              margin: $grid-rtl-sort-num-left-align-margin;
              @if $grid-skin == 'bootstrap4' {
                margin: 4px -2px 0 0;
              }
            }
          }

          & .e-filtermenudiv {
            margin-left: $grid-rtl-filtermenudiv-left-align-margin;
          }
        }

        &.e-leftalign {
          & .e-sortfilterdiv {
            float: right;
            margin: $grid-bigger-sort-margin;
            @if $grid-skin == 'tailwind' {
              margin-bottom: -13px;
              margin-top: -13px;
            }
          }

          & .e-headercelldiv {
            padding: 0 25px 0 .7em;

            & .e-sortnumber {
              float: right;
              margin: $grid-bigger-sort-right-margin;
            }
          }
        }

        &.e-fltr-icon.e-rightalign {
          & .e-sortnumber {
            float: left;
            margin: $grid-fltr-rtl-sortnumber-rightalign-margin;
          }
        }

        &.e-rightalign {
          & .e-sortnumber {
            float: left;
            margin: $grid-rtl-sortnumber-rightalign-margin;
          }
        }
      }

      .e-rowcell:first-child,
      .e-summarycell:first-child {
        padding-right: $grid-header-first-last-cell-padding;
      }

      .e-rowcell:last-child,
      .e-summarycell:last-child {
        padding-left: $grid-header-first-last-cell-padding;
      }

      &.e-wrap .e-gridheader .e-rightalign .e-sortnumber {
        margin: $grid-rtl-textwrap-srotnumber-rightalign-margin;
      }

      &.e-wrap .e-gridheader .e-sortnumber {
        margin: $grid-rtl-textwrap-srotnumber-margin;
      }

      &.e-wrap .e-gridheader .e-sortfilterdiv {
        margin: $grid-rtl-textwrap-sorticon-margin;
      }

      &.e-wrap .e-gridheader .e-rightalign .e-sortfilterdiv {
        margin: $grid-rtl-textwrap-sorticon-rightalign-margin;
      }

      & .e-gridheader .e-fltr-icon .e-sortfilterdiv {
        margin: $grid-rtl-sortdiv-filtericon-margin;
      }

      & .e-gridheader .e-fltr-icon.e-leftalign .e-sortfilterdiv {
        @if $grid-skin == 'tailwind' {
          margin-right: -5px;
        }
      }

      & .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
        margin: $grid-rtl-sortdiv-filtericon-rightalign-margin;
      }

      & .e-gridheader .e-sortfilter .e-rightalign.e-fltr-icon .e-headercelldiv {
        margin: $grid-rtl-headercelldiv-rightalign-fltricon-margin;
      }

      &.e-wrap .e-columnheader .e-rightalign.e-fltr-icon .e-headercelldiv,
      & .e-columnheader.e-wrap .e-rightalign.e-fltr-icon .e-headercelldiv{
        height: auto;
        margin-bottom: $grid-wrap-margin-bottom;
        margin-top: $grid-wrap-margin-top;
      }

      & .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
        margin: $grid-rtl-headercelldiv-fltricon-margin;
      }

      &.e-verticallines .e-grouptopleftcell,
      &.e-bothlines .e-grouptopleftcell,
      &.e-hidelines .e-grouptopleftcell {
        border-top: 0;
      }

      & .e-grouptopleftcell {
        border-top: $grid-grouptopleftcell-border-top;
      }

      & .e-groupheadercell span.e-grouptext {
        margin-left: $grid-rtl-group-text-margin-left;
        margin-right: $grid-rtl-group-text-margin-right;
        @if $grid-skin == 'bootstrap4' {
          padding-top: 3px;
        }
      }

      .e-bigger & .e-groupheadercell span.e-grouptext {
        @if $grid-skin == 'material3' {
          padding-right: 12px;
          margin-left: -8px;
        }
      }

      & .e-groupheadercell span {
        float: right;
        padding: $grid-rtl-group-span-padding;
      }

      &.e-horizontallines .e-grouptopleftcell {
        border-top: 1px solid;
      }

      & .e-rowcell {
        border-width: $grid-rowcell-broder-width;
      }

      & .e-rowcell.e-leftfreeze.e-freezeleftborder {
        border-left-width: 2px;
      }

      & .e-rowcell.e-rightfreeze.e-freezerightborder {
        border-right-width: 2px;
      }

      & .e-rowcell.e-fixedfreeze.e-freezeleftborder:not(.e-xlsel-right-border, .e-removefreezerightborder) {
        border-right-width: 1px;
      }

      & .e-rowcell.e-fixedfreeze.e-freezerightborder:not(.e-xlsel-left-border, .e-removefreezeleftborder) {
        border-left-width: 1px;
      }

      & .e-stackedheadercell.e-leftfreeze.e-freezeleftborder {
        border-left-width: 2px;
      }
  
      & .e-stackedheadercell.e-rightfreeze.e-freezerightborder {
        border-right-width: 2px;
      }
  
      & .e-stackedheadercell.e-fixedfreeze.e-freezeleftborder {
        border-right-width: 1px;
      }
  
      & .e-stackedheadercell.e-fixedfreeze.e-freezerightborder {
        border-left-width: 1px;
      }

      & .e-summarycell.e-rightfreeze.e-freezerightborder,
      & .e-summarycell.e-fixedfreeze.e-freezeleftborder {
        border-left-width: 0;
      }

      & .e-summarycell.e-leftfreeze.e-freezeleftborder,
      & .e-summarycell.e-fixedfreeze.e-freezerightborder {
        border-right-width: 0;
      }

      & .e-filterbarcell,
      & .e-filterbarcelldisabled {
        border-width: $grid-filterbarcell-border-width;
      }

      & .e-lastrowcell {
        border-width: $grid-border-size $grid-border-size $grid-border-size 0;
      }

      & .e-gridheader .e-rightalign .e-sortfilterdiv {
        margin: $grid-rtl-sortcelldiv-right-align-margin;
      }

      & .e-cloneproperties {
        border-width: $grid-border-size $grid-border-size 3px;
      }

      & tr {
        & td:first-child,
        & th:first-child {
          border-left-width: $grid-rtl-th-firstcell-border-left;
        }
      }

      &.e-default.e-bothlines tr {
        & td:first-child:not(.e-summarycell, .e-freezeleftborder),
        & th:first-child {
          border-left-width: 1px;
        }
      }

      &.e-default.e-bothlines tr td:first-child.e-detailrowcollapse,
      &.e-default.e-bothlines tr td:first-child.e-detailrowexpand {
        border-left-width: 0;
      }

      &.e-default {
        & tr td:last-child:not(.e-xlsel-left-border, .e-addfreezefirstchildborder),
        & .e-gridheader table tr th:last-child {
          border-left: 0;
        }
      }

      &.e-default.e-verticallines tr th:last-child {
        border-left: 1px solid;
      }

      &.e-default .e-gridheader {
        & tr th:last-child  {
          padding-left: $grid-header-first-last-cell-padding;
          padding-right: $grid-header-first-last-cell-padding;
        }

        & tr th:first-child  {
          padding-left: $grid-header-first-last-cell-padding;
          padding-right: $grid-header-first-last-cell-padding;
        }
      }

      &.e-default th:first-child &.e-default .e-headercell,
      &.e-default .e-detailheadercell {
        border-width: $grid-header-border-width;
      }

      &.e-default .e-rowcell {
        border-width: $grid-rowcell-broder-width;
      }

      &.e-default.e-verticallines .e-headercell,
      &.e-default.e-verticallines .e-rowcell,
      &.e-default.e-verticallines .e-filterbarcell,
      &.e-default.e-verticallines .e-detailheadercell,
      &.e-default.e-verticallines .e-gridheader th.e-firstcell {
        border-width: 0 0  0 $grid-border-size;
      }

      &.e-default.e-verticallines tr th:first-child:not(.e-firstcell) {
        border-left-width: $grid-border-size;
      }

      &.e-default .e-stackedheadercell,
      &.e-default.e-horizontallines .e-stackedheadercell {
        border-width: $grid-rtl-stackedheadercell-border-size;
      }

      &.e-default .e-gridheader th.e-firstcell,
      &.e-default.e-horizontallines .e-gridheader th.e-firstcell {
        border-left-width: $grid-rtl-stackedhader-firstcell-left-border-size;
        border-right-width: $grid-rtl-stackedhader-firstcell-right-border-size;
      }

      &.e-default.e-bothlines .e-gridheader th.e-firstcell {
        border-left-width: $grid-rtl-bothlines-stackedhader-firstcell-left-border-size;
        border-right-width: $grid-rtl-bothlines-stackedhader-firstcell-right-border-size;
      }

      &.e-default .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-horizontallines .e-headercell.e-firstheader {
        border-left: 0;
      }

      &.e-default.e-hidelines .e-gridheader th.e-firstcell {
        border-left: 0;
        border-right: 0;
      }

      &.e-default .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-horizontallines .e-gridheader .e-headercell.e-firstheader {
        border-right: 1px solid;
      }

      &.e-default.e-verticallines .e-gridheader .e-headercell.e-firstheader {
        border-right: 0;
      }

      &.e-default.e-verticallines .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-verticallines .e-gridheader th.e-grouptopleftcell.e-lastgrouptopleftcell {
        border-left: 1px solid;
      }

      &.e-default.e-verticallines .e-headercell.e-stackedheadercell {
        border-bottom: 1px solid;
      }

      &.e-default .e-detailcell,
      &.e-default.e-bothlines .e-detailcell {
        border-right-style: solid;
        border-right-width: 1px;
        text-align: right;
      }

      & .e-cc-searchdiv span.e-ccsearch-icon.e-icons {
        float: left;
      }

      & .e-groupsort,
      & .e-ungroupbutton,
      & .e-toggleungroup {
        margin-left: $grid-rtl-group-sorticon-margin-left;
        margin-right: $grid-rtl-group-sorticon-margin-right;
      }

      & span.e-ungroupbutton.e-icons {
        margin-left: $grid-ungroup-rtl-margin-left;
        margin-right: $grid-ungroup-rtl-padding-right;
        padding-top: $grid-ungroup-rtl-padding-top;
      }

      &:not(.sf-grid).e-default.e-bothlines .e-headercell,
      &:not(.sf-grid).e-default.e-bothlines .e-detailheadercell {
        border-width: $grid-headercell-both-border-width;
      }

      &.e-default.e-bothlines .e-dragheadercell,
      &.e-default.e-bothlines .e-rowdragheader,
      &.e-default.e-bothlines .e-cloneproperties.e-draganddrop td.e-rowdragdrop,
      &.e-default.e-verticallines .e-cloneproperties.e-draganddrop td.e-rowdragdrop {
        border-left: 1px solid $grid-header-border-color;
        padding-left: 3px;
      }

      & .e-cloneproperties.e-draganddrop .e-row .e-icon-rowdragicon::before {
        left: 4px;
        position: relative;
      }

      &.e-default.e-bothlines .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-bothlines .e-gridheader th.e-grouptopleftcell.e-lastgrouptopleftcell {
        border-left: 1px solid;
        border-right: 0;
      }

      &:not(.sf-grid).e-bothlines .e-gridheader th.e-stackedheadercell {
        border-bottom-style: $grid-border-type;
        border-bottom-width: $grid-border-size;
      }

      &.e-bothlines .e-filterbarcell,
      &.e-bothlines .e-filterbarcelldisabled {
        border-width: $grid-filtercell-both-border-width;
      }

      &.e-bothlines .e-rowcell,
      &.e-bothlines .e-rowcell.e-lastrowcell {
        border-width: $grid-rowcell-both-border-width;
      }

      &.e-verticallines .e-rowcell,
      &.e-verticallines .e-filterbarcell {
        border-width: 1px 0 0 $grid-border-size;
      }

      &.e-hidelines .e-rowcell,
      &.e-hidelines .e-headercell,
      &.e-hidelines .e-detailheadercell,
      &.e-hidelines .e-filterbarcell {
        border-width: 0;
      }

      &.e-horizontallines .e-rowcell {
        border-width: $grid-border-size 0 0;
      }

      &.e-horizontallines .e-filterbarcell {
        border-width: $grid-bottom-border-size 0 0;
      }

      &.e-horizontallines,
      &.e-verticallines,
      &.e-hidelines {
        & .e-rowcell.e-lastrowcell:not(.e-xlsel-bottom-border) {
          border-bottom-width: $grid-border-size;
        }
      }

      &.e-verticallines,
      &.e-hidelines {
        & .e-firstchildrow .e-rowcell {
          border-top-width: 1px;
        }
      }

      & .e-groupheadercell .e-icons::before {
        display: inline-block;
      }

      & .e-groupheadercell .e-cancel {
        padding-left: 23px;
        padding-right: 0;
        padding-top: 2px;
      }

      & .e-groupheadercell,
      & .e-groupheadercell:hover {
        margin-left: $grid-rtl-group-headercell-margin-left;
        margin-right: $grid-rtl-group-headercell-margin-right;
        @if $grid-skin != 'material3' {
          padding: $grid-rtl-grouped-headercell-padding;
        }
      }

      & .e-groupheadercell,
      & .e-groupheadercell:hover {
        float: right;
      }

      & .e-groupdroparea {
        text-align: $grid-groupdroparea-rtl-text-align;
      }

      & .e-ungroupbutton {
        float: left;
      }

      & .e-gridcontent table tr:not(.e-summaryrow) td.e-indentcell {
        border-style: $grid-border-type;
        border-width: 0 0 0 $grid-border-size;
      }

      & .e-defaultcell.e-ralign,
      & .e-row .e-input.e-defaultcell.e-ralign,
      & .e-defaultcell.e-ralign:focus,
      & .e-editedrow .e-defaultcell.e-ralign:focus {
        padding-left: 10px;
      }

      & .e-detailindentcell {
        border-left-style: $grid-border-type;
        border-left-width: $grid-border-size;
        border-right-width: 0;
      }

      & .e-filtermenudiv {
        float: $grid-rtlfltrdiv-float;
        margin: $grid-rtl-fltrdiv-margin;
        padding: $grid-rtl-fltrdiv-padding;
      }
    }

    &:not(.sf-grid).e-rtl{
      &.e-default table th[rowspan]  {
        border-width: $grid-rtl-headercell-both-border-width;
      }

      &.e-default table th[rowspan].e-leftfreeze.e-freezeleftborder {
        border-left-width: 2px;
      }
    
      &.e-default table th[rowspan].e-rightfreeze.e-freezerightborder {
        border-right-width: 2px;
      }
    
      &.e-default table th[rowspan].e-fixedfreeze.e-freezeleftborder {
        border-right-width: 1px;
      }
        
      &.e-default table th[rowspan].e-fixedfreeze.e-freezerightborder {
        border-left-width: 1px;
      }
    }

    &.e-wrap {
      & .e-rowcell,
      & .e-columnheader .e-stackedheadercelldiv,
      & .e-columnheader .e-headercelldiv,
      & .e-columnheader .e-headercell.e-fltr-icon .e-headercelldiv {
        height: Auto;
        line-height: $grid-rowcell-wrap-height;
        overflow-wrap: break-word;
        text-overflow: clip;
        white-space: normal;
        word-wrap: break-word;
      }

      & .e-stackedheader .e-columnheader .e-stackedheadercelldiv,
      & .e-stackedheader .e-columnheader .e-headercelldiv {
        max-height: $grid-rowcell-wrap-max-height;
      }
    }

    &.e-wrap .e-columnheader .e-headercelldiv,
    &.e-wrap .e-columnheader .e-headercell.e-fltr-icon .e-headercelldiv,
    & .e-columnheader.e-wrap .e-headercelldiv,
    & .e-columnheader.e-wrap .e-headercell.e-fltr-icon .e-headercelldiv {
      margin-bottom: $grid-wrap-margin-bottom;
      margin-top: $grid-wrap-margin-top;
    }

    &.e-wrap .e-columnheader .e-filtermenudiv,
    &.e-wrap .e-columnheader .e-sortfilterdiv .e-filtermenudiv  {
      line-height: $grid-wrap-line-height;
    }

    & .e-columnheader.e-wrap .e-filtermenudiv,
    & .e-columnheader.e-wrap .e-sortfilterdiv .e-filtermenudiv {
      line-height: $grid-wrap-line-height;
    }

    & .e-columnheader.e-wrap .e-headercelldiv,
    & .e-columnheader.e-wrap .e-headercell.e-fltr-icon .e-headercelldiv,
    & .e-columnheader.e-wrap .e-stackedheadercelldiv,
    & .e-gridcontent.e-wrap .e-rowcell,
    & .e-frozenhdrcont.e-wrap .e-rowcell {
      height: Auto;
      line-height: $grid-rowcell-wrap-height;
      overflow-wrap: break-word;
      text-overflow: clip;
      white-space: normal;
      word-wrap: break-word;
    }

    & .e-frozenhdrcont.e-wrap  {
      .e-xlsel-bottom-border.e-xlsel-top-border {
        line-height: $grid-rowcell-autofill-top-bottom-height;
      }
      .e-xlsel-bottom-border {
        line-height: $grid-rowcell-autofill-top-height;
      }
    }

    & .e-stackedheadercelldiv {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .e-columnheader.e-wrap .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-sortfilterdiv {
      margin: $grid-header-wrap-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-rightalign .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-rightalign .e-sortfilterdiv {
      margin: $grid-header-wrap-rightalign-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-fltr-icon .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-header-wrap-filtericon-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-fltr-icon.e-rightalign .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-fltr-icon.e-rightalign .e-sortfilterdiv {
      margin: $grid-header-wrap-filtericon-rightalign-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-icon-group::before,
    &.e-wrap .e-columnheader .e-icon-group::before {
      display: inline-block;
    }

    &.e-responsive .e-rowcell.e-gridclip,
    &.e-responsive .e-gridclip .e-headercelldiv,
    &.e-responsive .e-gridclip .e-stackedheadercelldiv {
      text-overflow: clip;
    }

    .e-clipboard {
      cursor: default;
      height: 1px;
      left: -1000px;
      overflow: hidden;
      position: fixed;
      resize: none;
      top: -1000px;
      width: 1px;
    }

    &.e-resize-lines,
    &.e-resize-lines.e-rtl {

      & th.e-headercell.e-stackedheadercell:not(.e-freezerightborder, .e-freezeleftborder),
      & th.e-headercell.e-firstcell:not(.e-freezerightborder, .e-freezeleftborder) {
        border-left: 0;
        border-right: 0;
        border-top: 0;
      }

      & tr.e-columnheader th:last-child.e-stackedheadercell .e-rhandler.e-rcursor :not(.e-laststackcell, .e-freezerightborder, .e-freezeleftborder) {
        border-right: 0;
      }
    }

    &:not(.sf-grid).e-resize-lines,
    &:not(.sf-grid).e-resize-lines.e-rtl {
      &.e-default table th[rowspan]:not(.e-freezerightborder, .e-freezeleftborder) {
        border-left: 0;
      }
    }

    &.e-resize-lines.e-rtl {

      & tr.e-columnheader th:last-child.e-stackedheadercell .e-rhandler.e-rcursor,
      & tr.e-columnheader th.e-lastcell .e-rhandler.e-rcursor,
      & tr.e-columnheader th.e-laststackcell .e-rhandler.e-rcursor {
        border-left: 0;
      }
    }

    &:not(.sf-grid).e-resize-lines.e-rtl {
      &.e-default table th[rowspan] {
        border-right: 0;
      }
    }

    & .e-mask {
      display: inline-block;
      height: 10px;
      width: 80%;

      &.e-mask-group-intent {
        margin-left: 7px;
        width: 16px;
      }

      &.e-mask-detail-intent {
        width: 16px;
      }

      &.e-mask-checkbox-intent {
        margin-left: 3px;
        margin-right: 16px;
        width: 20px;
      }

      &.e-mask-checkbox-filter-intent {
        width: 13px;
      }

      &.e-mask-checkbox-filter-span-intent {
        margin-left: 8px;
      }
      
      &.e-mask-drag-intent {
        margin-left: 12px;
        width: 12px;
      }
    }

    &.sf-grid {
      span.e-ungroupbutton.e-icons {
        margin-left: $grid-group-ungroupicon-margin-left;
        @if $grid-skin == 'material3' {
          margin-left: $group-unpgrouicon-margin-left;
        }
      }

      .e-rowdragdrop {
        @if $grid-skin == 'material3' or $grid-skin == 'material3-dark' or $grid-skin == 'material' or $grid-skin == 'material-dark' or $grid-skin == 'bootstrap4' {
          border-width: $grid-rowcell-broder-width;
          border-color: $grid-dragcell-border-color;
        }
      }

      .e-rowcell.e-frozen-right-border,
      .e-headercell.e-frozen-right-border,
      .e-filterbarcell.e-frozen-right-border {
        border-right: $grid-freezeline-border $grid-freezeline-right-border;
      }

      .e-rowcell.e-frozen-left-border,
      .e-headercell.e-frozen-left-border,
      .e-filterbarcell.e-frozen-left-border {
        border-left: $grid-freezeline-border $grid-freezeline-right-border;
      }

      .e-rowcell.e-freezeline,
      .e-gridheader .e-filterbarcell.e-freezeline {
        position: relative;
      }

      .e-rowcell .e-frozen-right-cursor,
      .e-rowcell .e-frozen-fixedright-cursor,
      .e-rowcell .e-frozen-left-cursor,
      .e-rowcell .e-frozen-fixedleft-cursor,
      .e-gridheader .e-headercell .e-frozen-right-cursor,
      .e-gridheader .e-headercell .e-frozen-fixedright-cursor,
      .e-gridheader .e-headercell .e-frozen-left-cursor,
      .e-gridheader .e-headercell .e-frozen-fixedleft-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-right-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-fixedright-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-fixedleft-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-left-cursor {
        cursor: move;
        height: 100%;
        position: absolute;
        top: 0;
        width: 4px;
      }

      .e-rowcell .e-frozen-left-cursor,
      .e-rowcell .e-frozen-fixedright-cursor,
      .e-gridheader .e-headercell .e-frozen-left-cursor,
      .e-gridheader .e-headercell .e-frozen-fixedright-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-fixedright-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-left-cursor {
        right: 0;
      }

      .e-rowcell .e-frozen-right-cursor,
      .e-rowcell .e-frozen-fixedleft-cursor,
      .e-gridheader .e-headercell .e-frozen-right-cursor,
      .e-gridheader .e-headercell .e-frozen-fixedleft-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-fixedleft-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-right-cursor {
        left: 0;
      }

      .e-gridheader .e-headercell .e-frozen-left-cursor.e-frozen-resize-cursor,
      .e-gridheader .e-filterbarcell .e-frozen-left-cursor.e-frozen-resize-cursor,
      .e-rowcell .e-frozen-left-cursor.e-frozen-resize-cursor {
        right: 3px;
      }

      .e-gridheader .e-headercell .e-frozen-right-cursor.e-frozen-resize-cursor:not(.e-frozen-default-cursor),
      .e-gridheader .e-filterbarcell .e-frozen-right-cursor.e-frozen-resize-cursor:not(.e-frozen-default-cursor) {
        left: 3px;
      }

      .e-frozen-helper {
        border-left: $grid-freezeline-border $grid-freezeline-right-border;
        cursor: move;
        position: absolute;
        z-index: 2;
      }

      .e-content.e-freezeline-moving {
        user-select: none;
      }

      .e-gridheader .e-headercontent .e-reorderuparrow {
        margin-top: $grid-reorder-arrow-top-margin;
      }

      .e-gridheader .e-headercontent .e-reorderuparrow-virtual {
        margin-top: $grid-reorder-virtualarrow-top-margin;
      }

      .e-gridheader .e-headercontent .e-reorderdownarrow {
        margin-top: $grid-reorder-downarrow-top-margin;
      }
      .e-gridheader .e-headercontent .e-reorderdownarrow-virtual {
        margin-top: $grid-reorder-virtualdownarrow-top-margin;
      }

      .e-masked-cell.e-rowcell .e-virtualcell {
        background-color: $grid-maskedcell-virtual-background-color;
        display: inline-block;
        height: 10px;
        width: 80%;
      }

      .e-movablecontent {
        height: inherit;
        overflow-x: auto;
        scrollbar-width: none; /* firefox */
      }

      .e-movablecontent::-webkit-scrollbar {
        display: none; /* safari, chrome and edge */
      }

      .e-frozencontent {
        border-bottom-style: $grid-border-type;
        height: inherit;
      }

      .e-gridcontent .e-content,
      .e-gridcontent .e-content .e-movablecontent {
        overflow-y: auto;
      }

      .e-gridcontent .e-content,
      .e-detailrowcollapse.e-dragborder,
      .e-detailrowexpand.e-dragborder{
        position: $grid-dragborder-position;
      }
      .e-gridcontent .e-content.e-yscroll {
        overflow-y: scroll;
      }

      .e-gridcontent .e-content .e-movablecontent.e-yscroll {
        overflow-y: hidden;
      }

      .e-gridcontent .e-content.e-noscroll,
      .e-gridcontent .e-content .e-movablecontent.e-noscroll {
        overflow-y: hidden;
      }

      .e-label.e-fltrcheck,
      .e-label.e-choosercheck {
        width: $grid-filter-checkbox-width;
      }

      .e-checkboxlist .e-fltrcheck,
      .e-cc-contentdiv .e-choosercheck {
        white-space: $grid-filter-checkbox-white-space;
      }

      .e-filterdiv .e-multiselect.e-input-group:not(.e-rtl),
      .e-fltrtempdiv .e-multiselect.e-input-group:not(.e-rtl){
        text-align: left;
      }
      
      &.e-default table th:not([rowspan = '1']):not(.e-detailcell,.e-detailindentcell,.e-stackedheadercell,.e-detailheadercell,.e-rowdragheader)  {
        border-width: $grid-headercell-both-border-width;
      }

      .e-gridheader table th:not([rowspan = '1']),
      .e-device .e-gridheader table th:not([rowspan = '1']) {
        padding-bottom: $grid-stackedheadercell-botttom-padding;
        vertical-align: bottom;
      }

      &.e-default table th:not([rowspan = '1']).e-leftfreeze.e-freezeleftborder {
        border-right-width: 2px;
      }
    
      &.e-default table th:not([rowspan = '1']).e-rightfreeze.e-freezerightborder {
        border-left-width: 2px;
      }
    
      &.e-default table th:not([rowspan = '1']).e-fixedfreeze.e-freezeleftborder {
        border-left-width: 1px;
      }
        
      &.e-default table th:not([rowspan = '1']).e-fixedfreeze.e-freezerightborder {
        border-right-width: 1px;
      }
      
      &.e-rtl.e-default table th:not([rowspan = '1']):not(.e-detailcell,.e-detailindentcell,.e-stackedheadercell,.e-detailheadercell,.e-rowdragheader)  {
        border-width: $grid-rtl-headercell-both-border-width;
      }

      &.e-rtl.e-default table th:not([rowspan = '1']).e-leftfreeze.e-freezeleftborder {
        border-left-width: 2px;
      }
    
      &.e-rtl.e-default table th:not([rowspan = '1']).e-rightfreeze.e-freezerightborder {
        border-right-width: 2px;
      }
    
      &.e-rtl.e-default table th:not([rowspan = '1']).e-fixedfreeze.e-freezeleftborder {
        border-right-width: 1px;
      }
        
      &.e-rtl.e-default table th:not([rowspan = '1']).e-fixedfreeze.e-freezerightborder {
        border-left-width: 1px;
      }

      &.e-resize-lines,
      &.e-resize-lines.e-rtl {
        &.e-default table th:not([rowspan = '1']):not(.e-freezerightborder, .e-freezeleftborder) {
          border-left: 0;
        }
      }
      
      &.e-resize-lines.e-rtl {
        &.e-default table th:not([rowspan = '1']) {
          border-right: 0;
        }
      }

      &.e-rtl.e-bothlines .e-gridheader th.e-stackedheadercell {
        border-bottom-style: $grid-border-type;
        border-bottom-width: $grid-border-size;
      }

      &.e-rtl.e-default.e-bothlines .e-headercell,
      &.e-rtl.e-default.e-bothlines .e-detailheadercell {
        border-width: $grid-headercell-both-border-width;
      }

      &.e-resize-lines,
      &.e-resize-lines.e-rtl {

        & th.e-headercell.e-stackedheadercell:not(.e-freezerightborder, .e-freezeleftborder),
        & th.e-headercell.e-firstcell:not(.e-freezerightborder, .e-freezeleftborder) {
          border-left: 0;
          border-right: 0;
          border-top: 0;
        }
      }

      &.e-default.e-bothlines .e-headercell,
      &.e-default.e-bothlines .e-detailheadercell {
        border-width: $grid-headercell-both-border-width;
      }

      &.e-default.e-bothlines .e-headercell.e-leftfreeze.e-freezeleftborder,
      &.e-default.e-verticallines .e-headercell.e-leftfreeze.e-freezeleftborder {
        border-right-width: 2px;
      }

      &.e-default.e-bothlines .e-headercell.e-rightfreeze.e-freezerightborder,
      &.e-default.e-verticallines .e-headercell.e-rightfreeze.e-freezerightborder {
        border-left-width: 2px;
      }

      &.e-bothlines .e-gridheader th.e-stackedheadercell {
        border-bottom-style: $grid-border-type;
        border-bottom-width: $grid-border-size;
        border-left-style: $grid-border-type;
        border-left-width: $grid-border-size;
      }

      .e-bigger #{&}.e-grid.sf-grid,
      #{&}.e-grid.sf-grid.e-bigger {
        .e-gridheader table th:not([rowspan = '1']),
        &.e-device .e-gridheader table th:not([rowspan = '1']) {
          padding-bottom: $grid-device-gridheader-row-span-padding;
        }
      }
    }
  }
}

.e-bigger .e-grid,
.e-bigger.e-grid {
  .e-gridheader .e-headercontent .e-reorderuparrow,
  .e-gridheader .e-headercontent .e-reorderdownarrow {
    font-size: $grid-biggerreorderarrow-font-size;
  }

  .e-gridheader .e-headercontent .e-reorderuparrow {
    @if $grid-skin == 'fluent' or $grid-skin == 'bootstrap5' {
      margin-top: -2px;
    }
  }

  .e-gridheader thead .e-icons {
    font-size: $grid-bigger-check-select-all-font-size;
  }

  .e-ccdlg {
    .e-cc-searchdiv {
      padding-left: 0;
      padding-right: $grid-bigger-searchdiv-padding-left;
    }

    span.e-ccsearch-icon.e-cc-cancel {
      @if $grid-skin == 'fluent' {
        font-size: 14px;
      }
      @else if $grid-skin == 'tailwind' or $grid-skin == 'bootstrap5' {
        font-size: 16px;
      }
    }
  }

  .e-columnmenu {
    margin: $grid-bigger-column-menu-margin;
  }

  &.e-rtl .e-columnmenu {
    margin: $grid-bigger-rtl-cm-margin;
  }

  &.e-rtl.e-device .e-columnmenu {
    margin: $grid-bigger-device-rtl-cm-margin;
  }

  &.e-rtl .e-groupheadercell span {
    padding: $grid-bigger-rtl-group-span-padding;
  }

  &.e-device.e-rtl .e-groupheadercell span {
    padding: $grid-device-rtl-grouped-headercell-span-padding;
  }
}

#{&}.e-grid-min-height {
  .e-rowcell,
  .e-icon-grightarrow,
  .e-icon-gdownarrow {
    line-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .e-gridheader .e-headercell,
  .e-gridheader .e-detailheadercell,
  .e-gridheader .e-headercell:not(.e-fltr-icon, .e-sort-icon) .e-headercelldiv {
    height: auto;
  }

  .e-gridcontent .e-groupcaption {
    line-height: normal;
    padding: 0 .7em;
  }

  .e-summarycell {
    line-height: normal;
    padding: 0 8px;
  }
}

#{&}.e-grid-min-height .e-grid-height {
  .e-rowcell {
    line-height: $grid-rowcell-line-height;
    padding: $grid-content-padding $grid-content-right-padding;
  }

  .e-gridheader .e-headercell,
  .e-gridheader .e-detailheadercell,
  .e-gridheader .e-headercell .e-headercelldiv {
    height: $grid-header-height;
  }

  .e-gridcontent .e-groupcaption {
    line-height: $grid-group-captioncell-line-height;
    padding: .7em;
  }

  .e-summarycell {
    line-height: $grid-summary-cell-line-height;
    padding: $grid-content-padding $grid-content-right-padding;
  }
}

.e-device.e-grid-min-height .e-grid-height .e-rowcell {
  padding: $grid-device-rowcell-padding;
}

.e-device.e-grid-min-height .e-grid-height .e-rowcell:first-child {
  padding: $grid-device-rowcell-firstchild-padding;
}

.e-device.e-grid-min-height .e-grid-height .e-rowcell:last-child {
  padding: $grid-device-rowcell-lastchild-padding;
}

.e-device.e-grid-min-height .e-rowcell,
.e-device.e-grid-min-height .e-rowcell:first-child,
.e-device.e-grid-min-height .e-rowcell:last-child {
  padding-bottom: 0;
  padding-top: 0;
}

.e-bigger .e-grid.e-grid-min-height .e-grid-height .e-rowcell {
  line-height: $grid-bigger-rowcell-line-height;
  padding: $grid-bigger-content-padding $grid-bigger-content-right-padding;
}

.e-bigger .e-grid.e-grid-min-height .e-grid-height .e-rowcell:first-child {
  line-height: $grid-bigger-first-last-rowcell-padding;
  padding-left: $grid-bigger-first-last-rowcell-padding;
}

.e-bigger .e-grid.e-grid-min-height .e-grid-height .e-rowcell:last-child {
  line-height: $grid-bigger-first-last-rowcell-padding;
  padding-right: $grid-bigger-first-last-rowcell-padding;
}

.e-bigger .e-grid.e-grid-min-height {
  .e-rowcell,
  .e-rowcell:first-child,
  .e-rowcell:last-child {
    line-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.e-bigger .e-wrap.e-grid-min-height .e-grid-height {
  .e-rowcell {
    line-height: $grid-bigger-wrap-line-height;
  }

  .e-frozencontent table tr td:first-child:empty,
  .e-movablecontent table tr td:first-child:empty,
  .e-frozenhdrcont table tr td:first-child:empty,
  &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
  &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
    height: $grid-bigger-rowcell-line-height + $grid-bigger-content-padding + $grid-bigger-content-padding;
  }
}

.e-bigger .e-wrap.e-grid-min-height {
  .e-rowcell {
    line-height: 18px;
  }

  .e-frozencontent table tr td:first-child:empty,
  .e-movablecontent table tr td:first-child:empty,
  .e-frozenhdrcont table tr td:first-child:empty,
  &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
  &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
    height: 18px;
  }
}

.e-rtl {
  & .e-grid {
    & .e-headercell,
    & .e-detailheadercell,
    & .e-headercelldiv,
    & .e-headercelldiv.e-headerchkcelldiv {
      text-align: right;
    }
  }
}

.e-edit-dialog .e-gridform .e-table {
  border-collapse: separate;
  border-spacing: 11px;
  width: 100%;
}

.e-edit-dialog .e-dlg-content {
  position: relative;
}

.e-ccdlg.e-cc .e-dlg-header-content {
  @if $grid-skin == 'fluent' {
    padding: 11px 24px 13px;
  }
}

.e-filter-popup.e-excelfilter .e-dlg-content {
  @if $grid-skin == 'fluent' {
    padding-bottom: 12px;
  }
}

.e-bigger {
  .e-edit-dialog.e-dlg-modal {
    .e-dlg-header-content{
      @if $grid-skin == 'fluent' {
        padding: 16px;
        .e-btn.e-dlg-closeicon-btn{
          bottom: 4px;
        }
      }
    }
  }
  .e-ccdlg.e-cc .e-dlg-header-content {
    @if $grid-skin == 'fluent' {
      padding: 14px 28px 17px;
    }
  }
  .e-filter-popup.e-excelfilter .e-dlg-content {
    @if $grid-skin == 'fluent' {
      padding-bottom: 0;
    }
  }
}

.e-bigger {
  &.e-grid.sf-grid.e-row-responsive,
  .e-grid.sf-grid.e-row-responsive {
    & .e-gridcontent,
    & .e-summarycontent {
      & td::before{
        @if $grid-skin == 'fluent' {
          overflow: hidden;
          bottom: 0;
        }
      }
    }
  }
}

.e-bigger .e-grid.e-row-responsive,
.e-bigger.e-grid.e-row-responsive {

  & .e-toolbar {
    .e-tbar-btn:hover,
    .e-tbar-btn:active,
    .e-tbar-btn:focus {
      background: none;

      .e-icons {
        padding-bottom: $grid-adaptive-toolbar-icon-hover-bottom;
        @if $grid-skin == 'bootstrap5' or $grid-skin == 'bootstrap4' {
          color: $grid-responsive-toolbar-icon-color;
        }
      }
    }

    .e-search-icon {
      background: none;
    }
  }

  & .e-responsive-header {
    border-top: 1px solid;
    border-top-color: $grid-header-border-color;
  }

  & .e-gridcontent,
  & .e-summarycontent {
    & td::before {
      content: attr(data-cell);
      font-weight: 500;
      left: 13px;
      padding-right: 10px;
      position: absolute;
      top: $grid-bigger-adaptive-cell-position-top;
      white-space: nowrap;
      text-overflow: ellipsis;
      @if $grid-skin == 'fluent' {
        overflow: visible;
      }
      @else {
        overflow: hidden;
      }
      width: 45%;
    }

    & .e-verticalwrap td::before {
      line-height: $grid-rowcell-wrap-height;
      white-space: normal;
      word-wrap: break-word;
    }

    & td[data-cell] {
      padding-bottom: $grid-adaptive-cell-padding-bottom;
      padding-left: 55%;
      padding-top: $grid-adaptive-cell-padding-top;
      position: relative;
    }

    & .e-input {
      display: block;
    }

    & td {
      border: 0;
    }

    & .e-row {
      border-bottom: 1px solid $grid-cell-border-color;
    }

    & .e-normaledit .e-rowcell {
      height: auto;
      padding-left: 1%;
      padding-right: 1%;
    }

    & td.e-rowcell {
      border-bottom: 0;
      border-bottom-color: $grid-header-border-color;
      height: auto;
    }

    & .e-emptyrow {
      & td.e-lastrowcell {
        border-bottom: 0;
      }
    }

    & .e-emptyrow.e-show-added-row {
      & td.e-lastrowcell {
        border-bottom: $grid-border-bottom-width;
      }
    }

    & .e-normaledit :not(.e-responsive-editcell).e-rowcell {
      border-top-width: 0;
    }

    & .e-normaledit .e-rowcell::before {
      bottom: 6px;
      position: static;
    }
  }

  & .e-table {
    display: block;

    tbody,
    tr,
    td:not(.e-hide),
    tfoot {
      display: block;
    }
  }

  & > .e-gridheader {
    display: none;
  }

  & .e-gridfooter {
    padding-right: 0;
  }
}

.e-bigger .e-grid.e-row-responsive.e-hidelines,
.e-bigger.e-grid.e-row-responsive.e-hidelines,
.e-bigger.e-grid.e-row-responsive.e-verticallines,
.e-bigger .e-grid.e-row-responsive.e-verticallines {
  & .e-gridcontent {
    & .e-rowcell:last-child {
      border: 0;
    }
  }
}

.e-bigger .e-responsive-dialog.e-customfilter,
.e-bigger.e-responsive-dialog.e-customfilter {
  .e-responsivecoldiv {
    cursor: pointer;
  }
}

.e-bigger .e-responsive-dialog,
.e-bigger.e-responsive-dialog {
  .e-btn.e-ripple {
    overflow: initial;
  }

  .columnmenudiv {
    .e-responsiveascendingdiv .e-resascending-icon,
    .e-responsivedescendingdiv .e-resdescending-icon,
    .e-responsivegroupdiv .e-resgroup-icon,
    .e-responsiveungroupdiv .e-resungroup-icon,
    .e-responsivecoldiv .e-ressort-icon,
    .e-responsivefilterdiv .e-resfilter-icon {
      margin-top: $grid-responsive-column-menu-icon-margin-top;
      position: absolute;
    }

    /* stylelint-disable */
    .e-responsivecoldiv .e-res-header-text.e-rescolumn-menu {
      margin-left: 33px !important;
    }
    /* stylelint-enable */
  }

  .e-mainfilterdiv {
    /* stylelint-disable */
    .e-ccdlg.e-cc.e-control.e-dialog {
      left: 0 !important;
      max-height: 100% !important;
      width: 100% !important;
    }
    /* stylelint-enable */

    .e-ccdlg {
      li.e-cclist {
        padding: 13px 0 13px 6px;
      }

      .e-main-div.e-cc {
        height: 100%;
      }

      /* stylelint-disable */
      .e-cc-contentdiv {
        height: 90% !important;
        min-height: 160px;
        overflow-y: auto !important;
      }
      /* stylelint-enable */

      .e-dlg-content {
        padding: 8px 16px 8px 8px;
        overflow-y: hidden;
      }

      .e-ccul-ele {
        margin: 13px 0;
        padding: 0;
      }

      .e-chk-hidden {
        -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
        height: 1px;
        opacity: 0;
        width: 1px;
      }
    }
    /* stylelint-disable */
      .e-cc-contentdiv {
        height: 90% !important;
        min-height: 160px;
        overflow-y: auto !important;
      }
    /* stylelint-enable */
    
    .e-cc-searchBox {
      padding-left: 8px;

      .e-ccsearch-icon:hover,
      .e-cc-cancel:hover {
        background: none;
        @if $grid-skin == 'bootstrap5' {
          color: $grid-responsive-btn-background;
        }
      }
    }

    .e-ccsearch-icon,
    .e-cc-cancel,
    .e-search-icon,
    .e-chkcancel-icon {
      font-size: 20px;
      @if $grid-skin == 'material' {
        font-size: 16px;
      }
    }
  }

  &.e-dialog .e-dlg-header-content {
    @if $grid-skin == 'material3' {
      padding: $grid-bigger-res-dlg-hdr-padding;
    }
  }

  &.e-dialog .e-dlg-header-content {
    @if $grid-skin == 'bootstrap5' {
      padding: $grid-bigger-res-dlg-hdr-padding;
    }
  }

  & .e-dlg-header-content {
    background: $grid-responsive-header-background;
    border-bottom: 1px solid;
    border-bottom-color: $grid-header-border-color;
    padding: $grid-bigger-res-dlg-hdr-padding;
    width: 100%;

    & .e-btn {
      background: none;
      box-shadow: none;
      outline: none;
      position: initial;
      @if $grid-skin == 'material3' {
        padding-bottom: 0;
        line-height: 40px;
      }

      &.e-dlg-closeicon-btn {
        background-color: transparent;
        @if $grid-skin == 'tailwind' {
          padding: .5px 15px;
        }
      }

      & .e-btn-icon {
        color: $grid-responsive-close-icon-color;
        font-size: $grid-responsive-btn-icon-font-size;
        margin-top: $grid-responsive-btn-top;
      }

      & .e-btn-icon.e-icon-filter-clear {
        @if $grid-skin == 'fluent' {
          margin-top: 4px;
        }
        @else if $grid-skin == 'tailwind' {
          margin-top: -6px;
        }
      }
    }

    & .e-btn:hover,
    & .e-btn:focus {
      .e-btn-icon {
        color: $grid-responsive-toolbar-icon-color;
      }
    }

    & .e-btn.e-dlg-closeicon-btn {
      float: left;
      @if $grid-skin == 'fluent' {
        padding-top: 6px;
      }
      @else if $grid-skin == 'material3' {
        line-height: 35px;
        padding-top: 5px;
      }
      padding-left: 0;
      padding-right: 40px;
    }

    & .e-dlg-header {
      width: auto;

      & .e-res-custom-element {
        display: flex;

        & .e-dlg-custom-header {
          padding-top: $grid-responsive-header-padding-top;
          width: 100%;
          @if $grid-skin == 'material3' {
            font-weight: $font-weight-normal;
            line-height: 20px;
          }
          @else {
            font-weight: 500;
          }
        }

        & .e-res-filter-clear-btn {
          color: $grid-responsive-filter-reset-color;
          margin-top: $grid-responsive-filter-clear-margin;
          padding: 0;
          @if $grid-skin == 'material3' {
            line-height: 32px;
          }

          & .e-icon-filter-clear {
            font-size: $grid-responsive-icon-filter-clear;
          }
        }

        & .e-res-sort-clear-btn {
          color: $grid-responsive-filter-reset-color;
          font-size: $grid-responsive-sort-clear-btn-font-size;
          font-weight: 500;
          padding-top: $grid-adaptive-sort-clear-button-padding;
          @if $grid-skin == 'tailwind' {
            margin-top: -7px;
          }
        }

        & .e-res-apply-btn {
          font-size: $grid-responsive-res-apply-btn-font-size;
          font-weight: 500;
          padding: 0;
          @if $grid-skin == 'material3' {
            padding-left: 11px;
          }
          @else {
            padding-left: 20px;
          }
          padding-right: 0;
          padding-top: $grid-responsive-apply-btn-top;

          @if $grid-skin == 'fluent' {
            padding-bottom: 3px;
          }
          @else if $grid-skin == 'tailwind' {
            margin-top: -2px;
          }
          @else if $grid-skin == 'bootstrap5' {
            margin-bottom: -2px;
          }
        }

        & .e-res-apply-btn:disabled {
          color: $grid-btn-disabled-bgcolor;
        }

        & .e-btn {
          background: none;
          border: 0;
          color: $grid-responsive-custom-header-btn-color;
        }
      }
    }

    & .e-res-back-btn {
      background: none;
      box-shadow: none;
      padding-left: 0;

      @if $grid-skin == 'fluent' {
        padding-top: 6px;
      }
      @else if $grid-skin == 'tailwind' {
        padding-top: 1.5px;
      }
      @else if $grid-skin == 'material3' {
        padding-top: 8.5px;
        padding-bottom: .5px;
        line-height: 31px;
      }
    }
  }

  &.e-rtl .e-dlg-header-content .e-res-back-btn {
    padding-left: 15px;
    padding-right: 0;
  }

  & .e-dlg-content {
    padding: 16px;
    padding-top: 0;

    .e-checkfltrnmdiv,
    .e-ccnmdiv {
      text-align: center;
      padding-top: 40px;
    }

    & .e-filtersetdiv {
      float: right;
      margin-right: 1%;
      @if $grid-skin == 'material3' {
        margin: -1px;
        font-size: 20px;
      }
      @else {
        margin-top: 1%;
      }

      & .e-filterset {
        color: $grid-responsive-filter-reset-color;
      }
    }

    & .e-dialog {
      box-shadow: none;
    }

    & .e-excelfilter {
      border: transparent;

      & .e-dlg-content {
        background-color: $grid-responsive-header-background;
        padding: 8px;
        padding-right: 16px;
      }

      .e-checkboxlist > span {
        padding-left: 9px;
      }
    }

    & .e-ccdlg {
      border: transparent;
    }

    & .e-xlflmenu {
      border: transparent;

      & .e-dlg-content {
        padding: 16px;
      }
    }

    & .e-ressortbutton-parent {
      float: right;
      margin-top: -9px;

      & .e-ressortbutton {
        background: $grid-responsive-btn-background;
        width: 120px;
      }
    }

    & .e-responsivecoldiv {
      font-size: 16px;
      margin-bottom: 26px;
      margin-top: 26px;
      width: 100%;
    }

    & .e-mainfilterdiv {
      & .e-dialog {
        bottom: 0;
        box-shadow: none;
        width: 100%;

        // sass-lint:disable-all
        & .e-contextmenu-wrapper {
          ul {
            background-color: $grid-responsive-header-background;
            max-width: 100%;

            li {
              color: $grid-rowcell-color;
            }

            & .e-submenu.e-selected {
              background-color: $grid-responsive-header-background;
            }

            & .e-submenu {
              padding: 0;

              & .e-menu-icon {
                margin-right: 4px;
                margin-left: 15px;
              }

              & .e-caret {
                padding: 0;
                @if $grid-skin == 'material3' {
                  padding-right: 1px;
                }
                @else {
                  padding-right: 8px;
                }
              }
            }
          }
        }

        & .e-searchcontainer {
          height: 88%;

          & .e-searchbox {
            padding-left: 10px;

            & .e-search-icon:focus,
            & .e-search-icon:active,
            & .e-search-icon:hover,
            & .e-chkcancel-icon:focus,
            & .e-chkcancel-icon:active,
            & .e-chkcancel-icon:hover {
              background: none;
              @if $grid-skin == 'bootstrap5' {
                color: $grid-responsive-btn-background;
              }
            }
          }

          & .e-spinner {
            height: 100%;

            & .e-chk-hidden {
              -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
              height: 1px;
              opacity: 0;
              width: 1px;
            }

            & .e-checkboxlist {
              height: 100%;
              min-height: 160px;
              overflow-y: auto;

              & .e-ftrchk {
                padding-bottom: $grid-bigger-checkbox-padding-topbottom;
                padding-top: $grid-bigger-checkbox-padding-topbottom;
              }
            }
          }
        }
      }
    }

    & .e-checkboxfilter {
      & .e-dlg-content {
        padding: 8px;

        & .e-searchcontainer {
          padding-left: 8px;
          margin-right: 7px;
          & .e-searchbox {
            padding-left: 0;
          }
          & .e-spinner {
            & .e-checkboxlist {
              margin-left: -7px;
            }
          }
        }
      }
    }
  }

  & .e-res-contextmenu-wrapper {
    & .e-contextmenu {
      background-color: $grid-responsive-header-background;
      border: transparent;
      box-shadow: none;
      margin-top: 23px;
      max-width: 100%;
      min-width: 100%;
      overflow-y: auto;
      padding: 16px;
      padding-top: 0;

      & .e-menu-item {
        padding: 0;
      }

      & .e-menu-item.e-focused {
        background-color: transparent;
      }
    }
  }

  & .e-defaultcell.e-ralign,
  & .e-editedrow .e-defaultcell.e-ralign,
  & .e-insertedrow .e-defaultcell.e-ralign,
  & .e-defaultcell.e-ralign:focus,
  & .e-insertedrow .e-defaultcell.e-ralign:focus & .e-editedrow .e-defaultcell.e-ralign:focus {
    padding-right: 10px;
  }
}

.e-bigger .e-responsive-dialog.e-ressortdiv,
.e-bigger.e-responsive-dialog.e-ressortdiv {
  .e-dlg-header-content .e-dlg-header .e-res-custom-element .e-res-apply-btn {
    padding-top: 0;
    @if $grid-skin == 'material3' {
      padding-top: 1px;
    }
    @if $grid-skin == 'fluent' {
      padding-top: 3px;
    }
    @if $grid-skin == 'bootstrap4' or $grid-skin == 'material' {
      padding-top: 2px;
    }
    @if $grid-skin == 'tailwind' {
      margin-top: -8px;
    }
    @else if $grid-skin == 'bootstrap5' {
      margin-bottom: 2px;
    }
  }
}

.e-bigger .e-responsive-dialog.e-rtl,
.e-bigger.e-responsive-dialog.e-rtl {
  & .e-btn.e-dlg-closeicon-btn {
    float: right;
    @if $grid-skin == 'fluent' {
      padding-top: 6px;
    }
    padding-left: 40px;
    padding-right: 0;
  }

  & .e-res-apply-btn {
    padding-left: 0;
    padding-right: 20px;
  }

  & .e-ressortbutton-parent {
    float: left;
  }

  .columnmenudiv .e-responsivecoldiv .e-res-header-text.e-rescolumn-menu {
    margin-right: 33px;
  }
}

 /* stylelint-disable */
.e-grid-toolbarmenu .e-responsivetoolbar-menu .e-menu-item.e-focused {
  background-color: transparent !important;
}
/* stylelint-enable */

/* stylelint-disable */
.e-ddl.e-popup.e-popup-flmenu .e-dropdownbase,
.e-ddl.e-popup.e-popup-flbar .e-dropdownbase {
  max-height: $grid-fltrmnu-dd-max-height !important;
}
/* stylelint-enable */

/* Apply styles for Firefox only */
/* stylelint-disable function-url-quotes */
@-moz-document url-prefix() {
  #{&}.e-grid-min-height {
    .e-rowcell,
    .e-icon-grightarrow,
    .e-icon-gdownarrow {
      line-height: normal;
    }
  }
  .e-bigger .e-grid.e-grid-min-height {
    .e-rowcell,
    .e-rowcell:first-child,
    .e-rowcell:last-child {
      line-height: normal;
    }
  }
}
/* stylelint-enable function-url-quotes */
