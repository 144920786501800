@use 'sass:math';

$group-row-bg-stcolor: #ececec !default;
$group-row-bg-color: $grey-50 !default;
$grid-font-size: 13px !default;
$grid-icon-color: $default-icon-color !default;
$grid-command-icon-color: $default-icon-color !default;
$grid-icon-font-size: 10px !default;
$grid-border-type: $border-type !default;
$grid-border-size: $border-size !default;
$grid-bottom-border-size: $border-size !default;
$grid-horizontal-border-size: $border-size !default;
$grid-font-family: $font-family !default;
$grid-maskedcell-virtual-background-color: rgba(0, 0, 0, .1) !default;
$grid-frozen-shadow-background-color: rgba(0, 0, 0, .12) !default;
$grid-frozen-row-shadow-background-color: rgba(0, 0, 0, .12) !default;

$grid-headercell-div-padding: 0 .6em !default;
$grid-grouped-headercell-span-padding: 0 !default;
$grid-group-unpgroupicon-padding-right: 0 !default;
$grid-group-text-width: auto !default;

$grid-content-bg-color: $content-bg-color !default;
$grid-popup-bg-color: $content-bg-color !default;
$grid-border-radius: 0 !default;

$grid-sortnumber-bg-color: #bbbdc0 !default;
$grid-sortnumber-color: $content-font-color !default;
$grid-table-background-color: $content-bg-color !default;

$group-droparea-hover-color: $active-font-color !default;

$group-header-hover-color: $header-font-color !default;
$grid-group-hover-broder-color: $content-border-color !default;
$grid-group-headercell-line-height: 26px !default;
$grid-group-text-line-height: 24px !default;
$grid-group-down-arrow-icon-font-size: 11px !default;
$grid-group-down-arrow-icon-padding: 14px 9px 12px 9px !default;
$grid-group-right-arrow-icon-font-size: 10px !default;
$grid-group-right-arrow-icon-padding: 14px 9px 14px 10px !default;
$grid-group-clone-text-align: center !default;
$grid-group-unpgroupicon-padding-top: 0 !default;
$grid-ungroup-rtl-padding-right: 6px !default;
$filter-text-color: $content-font-color !default;
$grid-wrap-line-height: 3px !default;
$grid-wrap-margin-bottom: 2px !default;
$grid-wrap-margin-top: 0 !default;

$grid-filterbarcell-first-last-child-padding-left: 21px !default;
$grid-filterbar-border-radius: 0 !default;
$grid-ungroup-button-line-height: 26px !default;
$grid-ungroup-button-font-size: 13px !default;
$grid-clone-padding: 4px 5px !default;
$group-sorticon-margin-right: 0 !default;
$group-sorticon-margin-top: 0 !default;
$group-ungroupicon-padding: 5px 3px !default;
$grid-rtl-group-sorticon-margin-left: 0 !default;
$grouped-text-font-size: 13px !default;
$grid-summary-row-bg-color: lighten($group-row-bg-stcolor, 4%) !default;
$grid-filtercell-both-border-width: 1px 0 0 1px !default;
$grid-headercelldiv-margin: -7px !default;
$grid-rtl-headercelldiv-padding: 0 .6em 0 1.8em !default;
$grid-rtl-headercelldiv-chkbox-padding: 0 .6em 0 .55em !default;
$grid-bigger-rtl-headercelldiv-chkbox-padding: 0 .6em 0 1.15em !default;
$grid-headercelldiv-padding: 0 1.8em 0 .6em !default;
$grid-headercelldiv-sort-centeralign-padding-right: .6em !default;
$grid-headercelldiv-right-align-padding: 0 .6em 0 1.9em !default;
$grid-filterbarcell-text-input: 26px !default;
$grid-summary-cell-line-height: 18px !default;
$grid-td-checkbox-margin-top: 5px !default;
$grid-gd-clone-padding: 2px !default;
$grid-toolbar-border-width: 1px solid !default;
$grid-sortdiv-margin: -16px 10px !default;
$grid-sortdiv-right-align-margin: -16px 5px !default;
$grid-filterdiv-padding: 0 !default;
$grid-headercelldiv-height: 29px !default;
$grid-filterbarcell-input-height: 26px !default;
$grid-sortdiv-height: 10px !default;
$grid-sortdiv-width: 10px !default;
$grid-sortdiv-padding: 3px !default;
$grid-sortnumer-line-height: 16px !default;
$grid-bigger-sortnumer-line-height: $grid-sortnumer-line-height !default;
$grid-sortnumber-border-radius: 65% !default;
$grid-textwrap-srotnumber-rightalign-margin: 1px 2px 0 15px !default;
$grid-textwrap-srotnumber-margin: 2px -5px 0 2px !default;
$grid-textwrap-sorticon-margin: -9px 10px !default;
$grid-columnchooser-header-padding: 11px 18px 15px !default;
$grid-cc-checkbox-padding-left: 9px !default;
$grid-columnchooser-toolbar-button-padding: 0 12px !default;
$grid-columnchooser-ul-padding: 0 !default;
$grid-columnchooser-li-padding: 9px 0 !default;
$grid-column-chooser-searchdiv: 0 0 2px !default;
$grid-column-chosser-searchdiv-opactiy: .6 !default;
$grid-column-chosser-searchdiv-fopactiy: 1 !default;
$grid-columnchooser-search-border-color: #212121 !default;
$grid-columnchooser-search-border-fcolor: #ff4081 !default;
$grid-columnchooser-toolbar-div-padding: 0 10px !default;
$grid-columnchooser-toolbar-icon-line-height: 1.9 !default;
$grid-columnchooser-toolbar-icon-font-size: 15px !default;
$grid-columnchooser-btn-font-size: 9px !default;
$grid-bigger-columnchooser-btn-font-size: 12px !default;
$grid-columnchooser-toolbardiv-padding: 3px 0 0 !default;
$grid-columnchooser-toolbardiv-margin-top: 0 !default;
$grid-bigger-columnchooser-toolbartxt-padding: 0 6px 0 !default;
$grid-columnchooser-toolbartxt-padding: 0 6px 0 !default;
$grid-columnchooser-toolbar-icon-vertical-align: middle !default;
$grid-columnchooser-cancel-icon-content-f-opactiy: 1 !default;
$grid-columnchooser-toolbar-div-margin-top: -1px !default;
$grid-columnchooser-input-border: 0 !default;
$grid-columnchooser-input-padding-top: 4px !default;
$grid-column-chooser-search-icon-padding: 5px 2px !default;
$grid-column-chooser-cancel-icon-padding: 5px 2px 6px !default;
$grid-columnchooser-input-padding-bottom: 8px !default;
$grid-columnchooser-input-padding-left: 4px !default;
$grid-column-chooser-searchdiv-left: 4px !default;
$grid-columnchooser-ul-margin: 13px 0 !default;
$grid-column-chooser-dlg-footer-padding: 8px !default;
$grid-rtl-bigger-sortnumber-margin: 6px 0 0 10px !default;
$grid-rtl-bigger-sortnumber-withfilter-margin: $grid-rtl-bigger-sortnumber-margin !default;
$grid-rtl-bigger-rightalign-sortnumber-margin: $grid-rtl-bigger-sortnumber-margin !default;
$grid-rtl-sortcelldiv-margin: -16px 10px !default;
$grid-rtl-sortcelldiv-right-align-margin: -16px 10px !default;
$grid-rtl-bigger-sortcelldiv-right-align-margin: -17px 10px !default;
$grid-rtl-bigger-sortcelldiv-margin: -17px 10px !default;
$grid-rtl-group-text-marign-right: 0 !default;
$grid-rtl-group-text-margin-left: 3px !default;
$grid-rtl-group-text-margin-right: 0 !default;
$grid-rtl-textwrap-srotnumber-margin: 3px 5px 0 2px !default;
$grid-header-wrap-sortfilter-div-margin: -18px 10px !default;
$grid-rtl-textwrap-sorticon-margin: -17px 4px !default;
$grid-rtl-textwrap-sorticon-rightalign-margin: -17px 5px !default;
$grid-rtl-headercell-both-border-width:  0 1px 0 0 !default;
$grid-rtl-textwrap-srotnumber-rightalign-margin: 3px 5px 0 2px !default;
$grid-rtl-stackedhader-firstcell-left-border-size: 0 !default;
$grid-rtl-stackedhader-firstcell-right-border-size: 1px !default;
$grid-rtl-bothlines-stackedhader-firstcell-left-border-size: 1px !default;
$grid-rtl-bothlines-stackedhader-firstcell-right-border-size: 0 !default;
$grid-bigger-headercell-font-size: 12px !default;
$grid-bigger-font-size: 13px !default;
$grid-bigger-content-font-size: 13px !default;
$grid-bigger-icons-font-size: 14px !default;
$grid-bigger-header-icons-font-size: 16px !default;
$grid-bigger-gdownarrow-icon-font-size: 12px !default;
$grid-bigger-grightarrow-icon-font-size: 12px !default;
$grid-bigger-sortdiv-margin: -17px 8px !default;
$grid-bigger-sortdiv-rightalign-margin: -16px 4px !default;
$group-bigger-sorticon-margin-right: 0 !default;
$grid-bigger-groupdroparea-padding: 22px 24px !default;
$grid-bigger-groupedcell-padding: 11px 0 12px !default;
$grid-bigger-grouped-headercell-border-radius: 16px !default;
$grid-bigger-group-headercell-margin: 4px 0 0 24px !default;
$grid-bigger-group-headercell-padding: 0 8px 0 12px !default;
$grid-device-group-headercell-padding: 0 8px 0 12px !default;
$grid-bigger-group-clone-padding: 6px !default;
$grid-bigger-grouptext-width: 76px !default;
$grid-bigger-ungroup-button-font-size: 16px !default;
$grid-bigger-ungroupbutton-icon-margin-top: 0 !default;
$grid-device-ungroupbutton-icon-margin-top: 0 !default;
$grid-bigger-group-text-line-height: 32px !default;
$grid-bigger-ungroupbutton-icons-font-size: 16px !default;
$grid-bigger-groupcaption-line-height: 32px !default;
$grid-bigger-grouparea-font-size: 19px !default;
$grid-bigger-rtl-group-headercell-margin: 3px 24px 0 0 !default;
$grid-bigger-rtl-ungroup-icon-margin-right: 6px !default;
$grid-bigger-textwrap-srotnumber-rightalign-margin: 3px 2px 0 5px !default;
$grid-bigger-textwrap-sorticon-rightalign-margin: -9px 5px !default;
$grid-bigger-textwrap-sorticon-margin: -9px 10px !default;
$grid-bigger-textwrap-srotnumber-margin: 3px 5px 0 2px !default;
$grid-bigger-columnchooser-header-padding: 24px 24px 38px !default;
$grid-bigger-columnchooser-ul-padding: 0 !default;
$grid-bigger-columnchooser-li-padding: 11px 0 !default;
$grid-bigger-columnchooser-toolbar-div-margin-top: 0 !default;
$grid-bigger-cc-checkbox-padding-left: 12px !default;
$grid-bigger-searchdiv-padding-left: 6px !default;
$grid-bigger-sorticon-margin-top: 0 !default;
$grid-device-group-sorticon-margin-top: 0 !default;
$grid-bigger-groupcell-span-padding: 0 !default;
$grid-device-grouped-headercell-span-padding: 0 !default;
$grid-bigger-ungroupbutton-icon-margin-left: 0 !default;
$grid-device-groupheadercell-span-line-height: 30px !default;
$grid-device-ungroupbutton-line-height: 30px !default;
$grid-device-groupsort-margin-top: 0;
$grid-device-gridheader-row-span-padding: 20px !default;
$grid-touch-device-hdrcell-span-line-height: 26px !default;
$grid-touch-device-hdrcell-ungroup-line-height: 25px !default;
$grid-edit-input-margin-top: 0 !default;
$frozen-border: 2px solid !default;
$grid-fltrdiv-float: right !default;
$grid-fltrdiv-text-align: right !default;
$grid-fltrdiv-margin: -18px -7px !default;
$grid-fltrdiv-padding: 6px !default;
$grid-rtl-fltrdiv-padding: 6px !default;
$grid-flmenu-padding: 24px 0 0 !default;
$grid-flmenu-boolean-content-padding: 18px 18px 0 !default;
$grid-flmenu-boolean-button-width: 110px !default;
$grid-flmenu-device-padding: 24px 0 0 !default;
$grid-fltrmnu-dd-max-height: 298px !default;
$grid-rtl-fltrdiv-margin: -18px 0 -18px -11px !default;
$grid-rtl-bigger-fltrdiv-margin: -20px 0 -18px -11px !default;
$grid-rtl-headercelldiv-rightalign-fltricon-margin: -7px !default;
$grid-rtl-headercelldiv-fltricon-margin: -7px !default;
$grid-headercelldiv-fltricon-margin: -7px 15px -7px -7px !default;
$grid-headercelldiv-rightalign-fltricon-margin: -7px 15px -7px -7px !default;
$grid-rtlfltrdiv-float: left !default;
$grid-checkbox-padding-topbottom: 9px !default;
$grid-checkbox-content-padding-left: 9px !default;
$grid-rtl-checkbox-content-padding-left: 18px !default;
$grid-checkbox-wrapper-height: 20px !default;
$grid-checkbox-wrapper-line-height: 20px !default;
$grid-checkbox-wrapper-top: 0 !default;
$grid-bigger-checkbox-padding-topbottom: 13px !default;
$grid-bigger-checkbox-content-padding-left: 11px !default;
$grid-rtl-bigger-checkbox-content-padding-left: 24px !default;
$grid-bigger-fltrdiv-margin: -18px -7px !default;
$grid-sortdiv-filtericon-margin: -16px 24px !default;
$grid-sortdiv-filtericon-rightalign-margin: -16px 5px !default;
$grid-rtl-sortdiv-filtericon-rightalign-margin: -16px 0 0 11px !default;
$grid-rtl-sortdiv-filtericon-margin: -16px 0 0 11px !default;
$grid-bigger-fltricon-sortdiv-rightalign-margin: -15px -4px !default;
$grid-bigger-fltricon-sortdiv-margin: -15px 24px !default;
$grid-rtl-headercelldiv-fltricon-padding: 0 1.6em 0 10px !default;
$grid-sortnumber-filtericon-rightalign-margin: 7px 2px 0 12px !default;
$grid-bigger-checkbox-filter-min-height: 352px !default;
$grid-checkbox-filter-min-height: 322px !default;
$grid-rtl-bigger-fltricon-sortdiv-margin: -17px 30px 0 14px !default;
$grid-rtl-bigger-fltricon-sortdiv-rightalign-margin: -17px 14px !default;
$grid-rows-bg-color: $content-bg-color !default;
$grid-resize-border-width: 1px !default;
$grid-filterbarcell-input-padding: 0 26px 0 10px !default;
$grid-header-wrap-rightalign-sortfilter-div-margin: -18px 5px !default;
$grid-header-wrap-filtericon-sortfilter-div-margin: -20px 18px !default;
$grid-bigger-wrap-sortdiv-margin: -20px 8px !default;
$grid-bigger-wrap-rightalign-sortdiv-margin: -20px 2px !default;
$grid-bigger-wrap-fltricon-sortdiv-margin: -10px 18px !default;
$grid-bigger-wrap-rightalign-fltricon-sortdiv-margin: -12px 2px !default;
$grid-header-wrap-filtericon-rightalign-sortfilter-div-margin: -20px 14px !default;
$grid-header-padding: 21px !default;
$grid-header-first-last-cell-padding: 21px !default;
$grid-header-bottom-padding: 0 !default;
$grid-header-top-padding: 0 !default;
$grid-header-bg-color: $grey-white !default;
$grid-header-color: rgba($grey-light-font, .54) !default;
$grid-sorted-header-color: $grey-light-font !default;
$gridcontent-font-color: $grey-light-font !default;
$grid-header-border-color: $grey-300 !default;
$grid-headerconent-font-opacity: .54 !default;
$grid-content-right-padding: 21px !default;
$grid-first-last-rowcell-padding: 21px !default;
$grid-filterbarcell-padding: 0 21px !default;
$grid-vertical-border-size: 0 !default;
$grid-content-padding: 8px !default;
$grid-rowcell-line-height: 20px !default;
$grid-rowcell-autofill-top-line-height: 18px !default;
$grid-rowcell-autofill-top-bottom-line-height: 16px !default;
$grid-headercell-line-height: 42px !default;
$grid-filtercell-line-height: 42px !default;
$grid-cell-border-color: $grey-300 !default;
$grid-content-font-color: $grey-light-font !default;
$grid-hover-content-font-color: $grey-light-font !default;
$grid-conent-font-opacity: .87 !default;
$grid-row-selection-bg-color: $grey-300 !default;
$grid-row-selection-color: $grey-light-font !default;
$grid-hover-bg-color: $grey-200 !default;
$grid-cell-selection-bg-color: $grey-300 !default;
$grid-cell-selection-color: $grey-light-font !default;
$grid-filterbar-input-border-width: 0 0 2px 0 !default;
$grid-filterbarcell-border-width: 1px 0 0 !default;
$grid-group-height: 48px !default;
$grid-group-captioncell-line-height: 20px !default;
$group-header-color: $primary-font !default;
$group-header-border-radius: 12px !default;
$grid-drag-clone-bg-color: $grey-200 !default;
$grid-drag-clone-border-color: $grey-300 !default;
$grid-drag-clone-opacity: 1 !default;
$grid-drag-clone-color: rgba($grey-light-font, .54) !default;
$group-droparea-bg-color: $primary-50 !default;
$group-droparea-color: $grey-light-font !default;
$grid-grouparea-font-size: 14px !default;
$group-header-bg-color: $primary !default;
$group-header-hover-bg-color: $primary !default;
$group-border-width: 0 !default;
$grouped-droparea-bg-color: $primary-300 !default;
$group-droparea-hover-bg-color: $primary-100 !default;
$group-expand-icon-text-indent: 12px !default;
$group-collapse-icon-text-indent: 12px !default;
$grid-group-broder-color: $grey-300 !default;
$grid-header-font-weight: 500 !default;
$grid-header-font-size: 12px !default;
$grid-bigger-header-font-size: $grid-header-font-size !default;
$grid-content-font-size: 13px !default;
$grid-header-height: 29px !default;
$grid-bigger-header-height: $grid-header-height !default;
$grid-header-icon-color: $grey-white !default;
$grid-hover-icon-color: $grey-white !default;
$grid-grouparea-margin: 3px 0 0 18px !default;
$group-area-padding: 14px 24px !default;
$group-text-padding-right: 9px !default;
$group-unpgrouicon-margin-left: 6px !default;
$grid-group-ungroupicon-margin-left: 6px !default;
$group-sorticon-margin-left: 6px !default;
$grid-rtl-group-sorticon-margin-right: 6px !default;
$grid-grouptopleftcell-border-top: 1px solid !default;
$grid-grouped-padding: 9px 0 10px !default;
$grid-ungroup-icon-opactiy: .7 !default;
$grid-headecell-height: 24px !default;
$group-sorticon-font-size: 10px !default;
$grid-grouped-headercell-padding: 0 6px 0 9px !default;
$grid-rtl-grouped-headercell-padding: 0 9px 0 6px !default;
$grid-group-caption-font-size: 12px !default;
$grid-group-caption-header-padding: .7em !default;
$grid-default-color: $grey-white !default;
$group-text-align: left !default;
$grid-icon-default-color: $grey-900 !default;
$grid-right-border: 0 !default;
$grid-stackedheadercell-border-size: 0 0 1px 1px !default;
$grid-stackedheadercell-botttom-padding: 13px !default;
$grid-grouparea-first-cell-margin: 0 6px 0 12px !default;
$grid-summary-cell-font-size: 13px !default;
$grid-summary-template-row-bg-color: $group-row-bg-color !default;
$grid-header-border-width: 0 !default;
$grid-rowcell-broder-width: 1px 0 0 0 !default;
$grid-headercell-both-border-width: 0 0 0 1px !default;
$grid-rowcell-both-border-width: 1px 0 0 1px !default;
$grid-indentcell-border-width: 0 1px 0 0 !default;
$grid-headercelldiv-line-height: 30px !default;
$grid-bigger-headercelldiv-line-height: $grid-headercelldiv-line-height !default;
$grid-filterbarcell-text-indent: 0 !default;
$grid-grouptext-margin-right: 6px !default;
$grid-groupdroparea-rtl-text-align: right !default;
$grid-rtl-th-firstcell-border-left: 0 !default;
$grid-gd-clone-border-radius: 13px !default;
$grid-checkbox-cell-padding-top-bottom: 6px !default;
$grid-rtl-headercell-border-width: 0 !default;
$grid-rowselect-text-indent: 7% !default;
$grid-ungroup-rtl-padding-top: 0 !default;
$grid-bigger-ungroup-rtl-padding-top: $grid-ungroup-rtl-padding-top !default;
$grid-fltr-sortnumber-rightalign-margin: 7px 2px 0 10px !default;
$grid-sortnumber-rightalign-margin: 7px 2px 0 5px !default;
$grid-columnchooser-footer-border-color: rgba($grey-light-font, .12) !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-columnchooser-content-padding: 16px 18px 13px 9px !default;
$grid-bigger-columnchooser-content-padding: 17px 24px 5px 12px !default;
$grid-bigger-columnchooser-content-margin: 20px 0 0 !default;
$grid-columnchooser-content-margin: 20px 0 0 !default;
$grid-fltr-rtl-sortnumber-margin: 7px 0 0 5px !default;
$grid-rtl-sortnumber-margin: $grid-fltr-rtl-sortnumber-margin !default;
$grid-sortnumber-margin: 7px 5px 0 2px !default;
$grid-fltr-sortnumber-margin: 7px 5px 0 2px !default;
$grid-fltr-rtl-sortnumber-rightalign-margin: 7px 0 0 5px !default;
$grid-rtl-sortnumber-rightalign-margin: $grid-fltr-rtl-sortnumber-rightalign-margin !default;
$grid-bigger-rtl-group-headercell-padding: 0 12px 0 8px !default;
$grid-bigger-device-rtl-group-headercell-padding: 0 12px 0 8px !default;
$group-caption-font-color: $grey-light-font !default;
$grid-rtl-group-headercell-margin-left: 10px !default;
$grid-rtl-group-headercell-margin-right: 18px !default;
$grid-rtl-stackedheadercell-border-size: 0 1px 1px 0 !default;
$grid-bigger-groupcell-span-line-height: 32px !default;
$grid-bigger-groupcell-span-height: $grid-bigger-groupcell-span-line-height !default;
$grid-bigger-headercell-padding: round(math.div($grid-header-padding, .75)) !default;
$grid-bigger-headercell-bootom-padding: round(math.div($grid-header-bottom-padding, .75)) !default;
$grid-bigger-headercell-line-height: round(math.div($grid-headercell-line-height, .75)) !default;
$grid-bigger-rhandler-height: $grid-bigger-headercell-line-height !default;
$grid-bigger-header-first-last-cell-padding: round(math.div($grid-header-first-last-cell-padding, .75)) !default;
$grid-bigger-content-padding: round(math.div($grid-content-padding, .75)) !default;
$grid-bigger-content-right-padding: round(math.div($grid-content-right-padding, .75)) !default;
$grid-bigger-rowcell-line-height: round(math.div($grid-rowcell-line-height, .75)) !default;
$grid-bigger-rowcell-autofill-top-line-height: 25px !default;
$grid-bigger-rowcell-autofill-top-bottom-line-height: 23px !default;
$grid-bigger-first-last-rowcell-padding: round(math.div($grid-first-last-rowcell-padding, .75)) !default;
$grid-bigger-group-line-height: round(math.div($grid-group-height, .75)) !default;
$grid-bigger-group-headercell-height: round(math.div($grid-headecell-height, .75)) !default;
$grid-bigger-group-clone-broder-radius: round(math.div($grid-gd-clone-border-radius, .75)) !default;
$grid-bigger-grouptext-marign: round(math.div($grid-grouptext-margin-right, .75)) !default;
$grid-bigger-groupedcell-icons-marign-left: round(math.div($group-sorticon-margin-left, .75)) !default;
$grid-bigger-filterbarcell-text-input: 26px !default;
$grid-device-headercell-padding: 0 21px $grid-header-bottom-padding !default;
$grid-device-headercell-fistchild-padding: 0 12px $grid-header-bottom-padding 16px !default;
$grid-device-headercell-lastchild-padding: 0 16px $grid-header-bottom-padding 12px !default;
$grid-device-rowcell-padding: $grid-content-padding 12px !default;
$grid-device-rowcell-firstchild-padding: $grid-content-padding 12px $grid-content-padding 16px !default;
$grid-device-rowcell-lastchild-padding: $grid-content-padding 16px $grid-content-padding 12px !default;
$grid-device-filterbarcell-padding: $grid-content-padding 12px !default;
$grid-device-filterbarcell-firstchild-padding: $grid-device-rowcell-firstchild-padding !default;
$grid-device-filterbarcell-lastchild-padding: $grid-device-rowcell-lastchild-padding !default;
$grid-edit-cell-padding: 4px !default;
$grid-edit-input-padding-top: 9px !default;
$grid-edit-input-padding-bottom: 6px !default;
$grid-edit-input-margin: 2px !default;
$grid-batch-updated-bg-color: #d7f9c7 !default;
$grid-val-error-color: #f44336 !default;
$grid-val-error-bg-color: #fcdbe4 !default;
$grid-edit-input-bigger-padding-bottom: 9px !default;
$grid-edit-input-bigger-padding-top: 13px !default;
$grid-edit-input-bigger-margin: 5px !default;
$grid-edit-input-bigger-device-padding-bottom: 5px !default;
$grid-edit-input-bigger-device-padding-top: 12px !default;
$grid-resize-helper-color: $grey-light-font !default;
$grid-headecell-span-height: 24px !default;
$grid-edit-checkbox-bigger-padding-left: 21px !default;
$grid-edit-checkbox-bigger-padding-top: 5px !default;
$frozen-border-color: $accent !default;
$grid-frozenborder-disabled-color: $content-bg-color !default;
$grid-freezeline-right-border: rgba($accent, .6) !default;
$grid-freezeline-border: 2px solid !default;
$grid-cell-focus-shadow: 0 0 0 1px $grey-500 inset !default;
$grid-cell-focus-border-color: $grey-500 !default;
$grid-filtered-color: $accent !default;
$grid-autofill-color: $accent !default;
$grid-e-firstrow-dragborder-bcolor: $accent !default;
$grid-column-menu-sub-menu-box-shadow: 0 8px 10px 0 rgba($shadow, .24) !default;
$grid-column-menu-margin: -18.5px -18px !default;
$grid-column-menu-padding: 6px !default;
$grid-textwrap-column-menu-margin: -13px -13px -23px -23px !default;
$grid-bigger-column-menu-margin: -18.5px -18px !default;
$grid-bigger-device-column-menu-margin: -18px !default;
$grid-column-menu-top-touch: 31px !default;
$grid-column-menu-bottom-touch: 35px !default;
$grid-column-menu-right-touch: 23px !default;
$grid-column-chooser-footdiv: 1px 0 0 !default;
$grid-toolbar-search-margin-bottom: 0 !default;
$grid-toolbar-search-width: 230px !default;
$grid-bigger-toolbar-search-width: $grid-toolbar-search-width !default;
$grid-toolbar-search-wrapper-padding-top: 1px !default;
$grid-toolbar-search-wrapper-padding-bottom: 6px !default;
$grid-toolbar-search-icon-min-width: 18px !default;
$grid-toolbar-search-clear-icon-min-width: 18px !default;
$grid-toolbar-search-clear-icon-font-size: 12px !default;
$grid-toolbar-search-clear-icon-padding: 0 !default;
$grid-toolbar-search-clear-icon-margin-right: 8px !default;
$grid-rtl-toolbar-search-clear-icon-padding: 0 !default;
$grid-rtl-toolbar-search-clear-icon-margin: 4px 0 4px 6px !default;
$grid-bigger-toolbar-search-clear-icon-padding: 0 !default;
$grid-bigger-toolbar-search-clear-icon-min-width: 20px !default;
$grid-bigger-toolbar-search-clear-icon-margin: 0 8px 0 0 !default;
$grid-bigger-toolbar-search-clear-icon-font-size: 12px !default;
$grid-rtl-bigger-toolbar-search-clear-icon-padding: 0 !default;
$grid-rtl-bigger-toolbar-search-clear-icon-min-width: 20px !default;
$grid-rtl-bigger-toolbar-search-clear-icon-margin: 6px 0 5px 6px !default;
$grid-responsive-toolbar-search-clear-icon-margin: 14px 8px 5px 0 !default;
$grid-responsive-toolbar-search-clear-icon-font-size: 18px !default;
$grid-rtl-responsive-toolbar-search-clear-icon-margin: 14px 0 5px 8px !default;
$grid-res-toolbar-clear-icon-width: 100% !default;
$grid-res-toolbar-clear-icon-margin: 13px 14px 5px 0 !default;
$grid-res-toolbar-search-clear-icon-font-size: 17px !default;
$grid-res-toolbar-search-icon-font-size: 18px !default;
$grid-res-toolbar-search-wrapper-padding: 5px !default;
$grid-res-toolbar-search-wrapper-padding-right: 5px !default;
$grid-res-toolbar-search-icon-padding-left: 0 !default;
$grid-bigger-toolbar-search-icon-min-width: 20px !default;
$grid-bigger-toolbar-search-wrapper-padding-bottom: 8px !default;
$grid-bigger-toolbar-search-wrapper-padding-top: 2px !default;
$grid-bigger-command-column-padding-bottom: 6px !default;
$grid-bigger-command-column-padding-top: 6px !default;
$grid-selection-font-weight: normal !default;
$group-hover-border: $group-border-width !default;
$grid-grouped-headercell-hover-padding: $grid-grouped-headercell-padding !default;
$grid-drag-clone-font-weight: $grid-header-font-weight !default;
$grid-content-batch-font-color: $grid-content-font-color !default;
$grid-bigger-group-headercell-hover-padding: $grid-bigger-group-headercell-padding !default;

$grid-drag-font-size: 12px !default;
$grid-drag-icon-font-weight: bold !default;
$grid-drag-move-icon: 10px !default;
$grid-drag-area-border: 1px solid !default;
$grid-dragdrop-padding: 2px !default;
$grid-dragborder-box-shadow: 0 2px 0 0 $accent !default;
$grid-dragborder-cell-dashed: 1px dashed $accent !default;
$grid-dragborder-position: relative !default;
$grid-dragborder-z-index: 5 !default;
$grid-dragicon-cell-border: 1px 0 0 0 !default;
$grid-border-bottom-width: 1px !default;
$grid-dragcell-border-style: solid !default;
$grid-dragcell-border-color: $grey-300 !default;
$grid-e-firstrow-dragborder-bcolor: $accent !default;
$grid-drap-drop-icon-color: $grey-light-font !default;
$grid-clone-dropitemscount-color: $primary-font !default;
$grid-clone-dropitemscount-bg-color: $accent !default;
$grid-clone-row-border-color: $grey-300 !default;
$grid-dropitemscount-color: $primary-font !default;
$grid-clone-prop-box-shadow: 0 3px 8px 0 rgba($grey-black, .26) !default;
$grid-drag-icon-opacity: .54 !default;
$grid-clone-dropitemscount-border: $primary-font !default;
$grid-clone-dragdrop-bg-color: $grid-table-background-color !default;
$grid-group-clone-box-shadow: 0 0 !default;
$grid-unboundcelldiv-margin: 0 3.5px !default;
$grid-bigger-flmenu-padding: $grid-flmenu-padding !default;
$grid-rowcell-wrap-height: 18px !default;
$grid-rowcell-autofill-top-height: 16px !default;
$grid-rowcell-autofill-top-bottom-height: 14px !default;
$grid-rowcell-wrap-max-height: 36px !default;
$grid-bigger-fltr-sortnumber-rightalign-margin: 7px 2px 0 5px !default;
$grid-bigger-sortnumber-rightalign-margin: $grid-bigger-fltr-sortnumber-rightalign-margin !default;
$grid-bigger-columnmenu-bottom: 16px !default;
$grid-bigger-sortnumber-margin: $grid-sortnumber-margin !default;
$grid-bigger-fltr-sortnumber-margin: $grid-bigger-sortnumber-margin !default;
$grid-rtl-searchclear: 0 !default;
$grid-column-chosser-searchdiv-top: -46px !default;
$grid-reorderarrow-font-size: 8px !default;
$grid-bigger-wrap-line-height: 18px !default;
$grid-bigger-wrap-autofill-top-line-height: 16px !default;
$grid-bigger-wrap-autofill-top-bottom-line-height: 14px !default;
$grid-bigger-clone-padding: $grid-clone-padding !default;
$grid-bigger-draganddrop-clone-padding: 0 !default;
$grouped-bigger-text-font-size: $grouped-text-font-size !default;
$grid-bigger-ungroupbutton-icon-margin-right: 0 !default;
$grid-bigger-ungroupbutton-icon-padding-top: 0 !default;
$grid-bigger-font-size: $grid-font-size !default;
$grid-rtl-bigger-rightalign-fltrdiv-margin: $grid-rtl-bigger-fltrdiv-margin !default;
$grid-bigger-rtl-ungroup-icon-margin-left: 0 !default;
$grid-grouparea-font-weight: lighter !default;
$grid-ungroup-rtl-margin-left: 0 !default;
$grid-rtl-group-sorticon-margin-left: 0 !default;
$grid-column-menu-padding: 6px !default;
$grid-rtl-column-menu-left: 5px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-rtl-column-menu-margin: -18px -18px -18px 3px !default;
$grid-rtl-textwrap-column-menu-margin: -13px -13px -23px -3px !default;
$grid-filter-checkbox-width: 0 !default;
$grid-filter-checkbox-white-space: nowrap !default;

// group animator
$grid-group-animator-bigger-drop-height: 54px !default;
$grid-group-animator-bigger-cell-margin: 15px 0 15px 0 !default;
$grid-animator-bigger-area-margin: 15px !default;
$grid-group-animator-drop-height: 48px !default;
$grid-group-animator-cell-margin: 12px 0 12px 0 !default;
$grid-animtor-border-bottom-width: 1px !default;
$grid-animator-area-padding: 0 12px 0 !default;
$grid-animtor-area-border-width: 1px !default;
$grid-animator-area-border: 0 !default;
$grid-animator-area-margin: 9px !default;
$grid-animator-chips-display: inline-block !default;
$grid-animator-drag-icon: 24px !default;
$grid-animator-drag-icon-line-height: 25px !default;
$grid-animator-margin-left: 8px !default;
$grid-animator-padding-bottom: 10px !default;
$grid-animator-line-height: 21px !default;
$grid-animator-line-height-next: 32px !default;
$grid-animator-font-size-drag: 11px !default;
$grid-animator-opacity: .7 !default;
$grid-animator-drag-margin-left: 0 !default;
$grid-animator-first-group: 12px 0 12px 16px !default;
$grid-animator-rtl-first-group: 12px 16px 12px 0 !default;
$grid-animator-margin-top-rtl: 16px !default;
$grid-filterbar-dropdown: 15px !default;

$grid-responsive-close-icon-margin: 0 !default;
$grid-responsive-custom-header-margin: 18px 8px 18px 8px !default;
$grid-responsive-custom-header-btn-color: $grid-filtered-color !default;
$grid-responsive-custom-header-right-margin: 6px 4px 6px 4px !default;
$grid-responsive-filter-clear-font: 18px !default;
$grid-responsive-search-icon-margin: 14px !default;
$grid-responsive-search-padding-right: 0 !default;
$grid-responsive-search-icon-right: 9px !default;
$grid-responsive-search-padding-left: 18px !default;
$grid-responsive-header-background: $grey-white !default;
$grid-bigger-res-dlg-hdr-padding: 16px !default;
$grid-responsive-header-search: 8px !default;
$grid-responsive-filter-reset-color: $grid-filtered-color !default;
$grid-responsive-toolbar-icon-color: $grid-icon-color !default;
$grid-responsive-close-icon-color: $grid-icon-color !default;
$grid-responsive-btn-background: none !default;
$grid-responsive-res-apply-btn-font-size: 15px !default;
$grid-responsive-res-apply-btn-margin-top: 0 !default;
$grid-responsive-sort-clear-btn-font-size: 15px !default;
$grid-responsive-sort-clear-margin-top: 0 !default;
$grid-responsive-icon-filter-clear: 18px !default;
$grid-responsive-icon-filter-clear-bottom: 6px !default;
$grid-responsive-btn-icon-font-size: 14px !default;
$grid-responsive-back-btn-bottom: 6px !default;
$grid-responsive-header-padding-top: 5px !default;
$grid-responsive-search-place-holder-margin-top: 8px !default;
$grid-responsive-search-width: 18px !default;
$grid-responsive-search-left-padding: 0 !default;
$grid-responsive-filter-clear-margin: 3px !default;
$grid-responsive-toolbar-filter-font-size: 22px !default;
$grid-responsive-tbar-zero-value: 0 !default;
$grid-responsive-tbar-btn-bgr-padding: $grid-responsive-tbar-zero-value 6px !default;
$grid-responsive-btn-top: 0 !default;
$grid-responsive-apply-btn-top: 4px !default;
$grid-responsive-column-menu-icon-margin-top: 0 !default;

$grid-rtl-headercelldiv-left-align-margin: 10px !default;
$grid-rtl-filtermenudiv-left-align-margin: -5px !default;
$grid-rtl-sort-num-left-align-margin: 6px -4px 0 0 !default;
$grid-frozen-mac-scrollbar-background: rgba(0, 0, 0, .5) !default;
$grid-rowcell-color: rgba($grid-content-font-color, $grid-conent-font-opacity) !default;
$grid-reorderarrow-margin-top: -4.5px !default;
$grid-reorderdownarrow-margin-top: 4.5px !default;
$grid-reorder-arrow-top-margin: 1px !default;
$grid-reorder-downarrow-top-margin: -1px !default;
$grid-reorder-virtualarrow-top-margin: 1px !default;
$grid-reorder-virtualdownarrow-top-margin: -1px !default;
$grid-sortnumber-font-size: 9px !default;
$grid-header-text-transform: none !default;
$grid-bigger-toolbar-icon-size: 18px !default;
$grid-reorderarrow-margin-left: -1px !default;
$grid-column-chooser-cancel-icon-size: 11px !default;

// bootstrap5 support
$grid-outter-border: $grid-header-border-color !default;
$grid-btn-disabled-bgcolor: rgba($grey-black, .12) !default;
$grid-sort-number-size: 15px !default;
$grid-bigger-sort-number-size: $grid-sort-number-size !default;
$grid-sort-number-font-size: 9px !default;
$grid-filter-border-radius: 0 !default;
$grid-bigger-filter-border-radius: $grid-filter-border-radius !default;
$grid-adaptive-toolbar-icon-hover-bottom: 0 !default;
$grid-adaptive-cell-padding-bottom: 5px !default;
$grid-adaptive-cell-padding-top: 5px !default;
$grid-bigger-adaptive-cell-position-top: 6px !default;
$grid-bigger-sort-margin: -16px 3px !default;
$grid-bigger-sort-right-margin: 6px 2px 0 0 !default;
$grid-skin: 'material' !default;
$grid-biggerreorderarrow-font-size: $grid-reorderarrow-font-size !default;
$grid-bigger-drag-count-padding: 3px 7px 4px !default;
$grid-bigger-drag-count-top: -10px !default;
$grid-bigger-check-select-all-font-size: $grid-bigger-header-icons-font-size !default;
$grid-adaptive-sort-clear-button-padding: 5px !default;
$grid-cc-margin-top: 1px !default;
$grid-bigger-rtl-cm-margin: -19px -18px -18px 3px !default;
$grid-bigger-device-rtl-cm-margin: $grid-bigger-rtl-cm-margin !default;
$grid-bigger-rtl-group-span-padding: $grid-bigger-groupcell-span-padding !default;
$grid-device-rtl-grouped-headercell-span-padding: $grid-device-grouped-headercell-span-padding !default;
$grid-rtl-group-span-padding: $grid-bigger-groupcell-span-padding !default;
$grid-ungroup-rtl-padding-left: 0 !default;
$grid-column-reorder-icon-color: $grid-icon-default-color !default;
$grid-bigger-headercell-top-padding: 0 !default;
$grid-bigger-headercell-autofit-padding: 0 28px 0 !default;
$grid-bigger-icon-font-size: $grid-icon-font-size !default;
$grid-rtl-mselect-dd-indicator-left: -6px !default;
$grid-toolbar-text-opacity: 1 !default;
$grid-toolbar-placeholder-text-color: rgba($grey-light-font, .42) !default;
$grid-toolbar-searchbar-text-opacity: 1 !default;
$grid-dropitems-count-margin-left: -8px !default;
$grid-checkmark-color: $accent-font !default;
