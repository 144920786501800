﻿@include export-module('listbox-layout') {
  /* stylelint-disable property-no-vendor-prefix */
  .e-listbox-wrapper,
  .e-listbox-container,
  .e-listboxtool-wrapper {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    position: relative;
    user-select: none;
    width: 100%;

    * {
      box-sizing: border-box;
    }

    &.e-listboxtool-container .e-list-wrap {
      @if $skin-name == 'FluentUI' or $skin-name == 'bootstrap5' or $skin-name == 'tailwind' {
        width: calc(100% - 16%) !important; /* stylelint-disable-line declaration-no-important */
      }
      @else if $skin-name == 'material' or $skin-name == 'bootstrap4' or $skin-name == 'bootstrap' {
        width: calc(100% - 14%) !important; /* stylelint-disable-line declaration-no-important */
      }
      @else if $skin-name == 'fabric' {
        width: calc(100% - 14%) !important; /* stylelint-disable-line declaration-no-important */
      }
      @else if $skin-name == 'highcontrast' {
        width: calc(100% - 13%) !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    &:focus {
      outline: none;
    }

    &.e-disabled {
      cursor: default;
      pointer-events: none;
    }

    &:not(.e-list-template) .e-list-nrt {
      text-align: center;
    }

    &:not(.e-list-template) .e-list-item,
    .e-list-nrt,
    .e-selectall-parent {
      @if $skin-name == 'bootstrap5' {
        height: $listbox-item-height;
      }
      @else {
        height: $listbox-item-height;
      }
      line-height: $listbox-item-line-height;
      padding: $listbox-item-padding;
      position: relative;
    }

    .e-list-parent {
      height: 100%;
      min-height: $listbox-item-height;
    }

    & .e-list-item {
      border-bottom: $listbox-border-size solid;
      outline: none;

      &.e-disabled {
        pointer-events: none;
      }
    }

    .e-disable {
      opacity: .7;
    }

    & .e-list-parent {
      margin: 0;
      padding: 0;
    }

    & .e-list-header .e-text.header {
      display: none;
    }

    & .e-icon-back {
      margin-top: $listbox-icon-back-margin;
    }

    & .e-list-header .e-headertemplate-text.nested-header {
      display: none;
    }

    & .e-list-header {
      align-items: center;
      border-bottom: 1px solid;
      display: flex;
      font-weight: $listbox-header-font-weight;
      height: $listbox-header-height;
      padding: 0 $listbox-header-text-padding;
    }

    & .e-has-header > .e-view {
      top: 45px;
    }

    & .e-but-back {
      cursor: pointer;
      padding-right: $listbox-back-padding-right;
    }

    & .e-list-group-item:first-child {
      border: 0;
      border-bottom: $listbox-group-first-border-bottom solid $listbox-border-bottom-color;
    }

    & .e-list-group-item {
      border-bottom: $listbox-group-border-bottom solid $listbox-border-bottom-color;
      border-top: $listbox-group-border-top solid;
      @if $skin-name == 'Material3' {
        font-weight: 500;
      }
      @else {
        font-weight: 600;
      }
      height: $listbox-groupheader-item-height;
      line-height: $listbox-groupheader-item-line-height;
      padding: $listbox-item-padding;
    }

    & .e-icon-collapsible {
      cursor: pointer;
      font-size: 12px;
      position: absolute;
      right: 0%;
      top: 50%;
      transform: translateY(-50%);
    }

    & .e-text-content {
      height: 100%;
      position: relative;
      vertical-align: middle;
    }

    & .e-text-content * {
      display: inline-block;
      vertical-align: middle;
    }

    & .e-text-content.e-checkbox .e-list-text {
      width: calc(100% - 40px);
    }

    & .e-text-content.e-checkbox.e-checkbox-left .e-list-icon + .e-list-text {
      width: calc(100% - 90px);
    }

    & .e-text-content.e-checkbox.e-checkbox-right .e-list-icon + .e-list-text {
      width: calc(100% - 80px);
    }

    & .e-list-item.e-checklist.e-has-child {
      .e-text-content.e-checkbox.e-checkbox-right {
        .e-list-icon + .e-list-text {
          width: calc(100% - 92px);
        }
      }
    }

    & .e-checkbox .e-checkbox-left {
      margin: $listbox-checkbox-left-margin;
    }

    & .e-checkbox .e-checkbox-right {
      margin: $listbox-checkbox-right-margin;
    }

    & .e-list-text {
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: top;
      white-space: nowrap;
      width: 100%;
    }

    & .e-list-icon + .e-list-text {
      width: calc(100% - 60px);
    }

    & .e-icon-wrapper .e-list-text {
      width: calc(100% - 60px);
    }

    & .e-icon-wrapper.e-text-content.e-checkbox .e-list-text {
      width: calc(100% - 60px);
    }

    & .e-list-icon {
      height: 30px;
      margin-right: $listbox-icon-margin-right;
      width: 30px;
    }

    & .e-content {
      overflow: hidden;
      position: relative;
    }

    & .e-list-header .e-text {
      cursor: pointer;
      text-indent: 0;
    }

    & .e-text .e-headertext {
      display: inline-block;
      line-height: $listbox-header-line-height;
    }

    &.e-rtl {
      direction: rtl;

      & .e-list-icon {
        margin-left: 16px;
        margin-right: 0;
      }

      & .e-icon-collapsible {
        left: 0%;
        right: initial;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
      }

      & .e-list-header .e-text {
        cursor: pointer;
      }

      & .e-but-back {
        transform: rotate(180deg);
      }

      & .e-icon-back {
        margin-top: $listbox-rtl-icon-back-margin;
      }

      & .e-checkbox .e-checkbox-left {
        margin: $listbox-rtl-checkbox-left-margin;
      }

      & .e-checkbox .e-checkbox-right {
        margin: $listbox-rtl-checkbox-right-margin;
      }

      .e-checkbox-wrapper {
        margin: $listbox-rtl-checkbox-left-margin;
      }
    }

    .e-checkbox-wrapper {
      margin: $listbox-checkbox-left-margin;
      text-indent: 0;
      vertical-align: middle;
      @if $skin-name == 'Material3' {
        position: relative;
      }
    }

    &.e-checkbox-right {
      .e-checkbox-wrapper {
        position: absolute;
        right: 0;
        top: 30%;
      }
    }

    .e-input-group {
      @if $skin-name != 'tailwind' {
        padding: 4px 8px;
      }
    }

    .e-input-focus {
      @if $skin-name != 'tailwind' {
        padding: 4px 4px 4px 8px;
      }
    }

    .e-hidden-select {
      height: 1px;
      opacity: 0;
      position: absolute;
      width: 100%;
    }

    .e-placeholder {
      background-color: $badge-bgcolor;
      display: block;
      height: 1px;
    }
  }

  ejs-listbox {
    display: block;
  }

  .e-listbox-wrapper:not(.e-listbox-container) {
    overflow: auto;
  }

  .e-listbox-wrapper {

    &.e-filter-list {
      & .e-list-parent {
        overflow: auto;
      }
    }
  }

  .e-listbox-wrapper.e-sortableclone,
  .e-listbox-container.e-sortableclone,
  .e-listboxtool-wrapper.e-sortableclone {
    border-width: 0;
    overflow: visible;

    .e-list-item {
      list-style-type: none;
    }

    .e-ripple {
      overflow: visible;

      .e-ripple-element {
        display: none;
      }
    }

    .e-list-badge {
      align-items: center;
      background-color: $badge-bgcolor;
      border: 1px solid $badge-color;
      border-radius: 50%;
      color: $badge-color;
      display: flex;
      font-size: 12px;
      height: 22px;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: -10px;
      width: 22px;
    }
  }

  .e-listboxtool-wrapper.e-sortableclone{
    display: block;
  }

  .e-listboxtool-wrapper,
  .e-listboxtool-container {
    cursor: pointer;
    display: flex;

    * {
      box-sizing: border-box;
    }

    &.e-disabled {
      cursor: default;
      pointer-events: none;
    }

    .e-listbox-wrapper {
      flex: 1;
    }

    &.e-right {
      .e-listbox-tool {
        margin-left: 15px;
      }
    }

    &.e-left {
      .e-listbox-tool {
        margin-right: 15px;
      }
    }

    .e-listbox-tool {
      border: 1px solid $listbox-border-color;
      overflow: auto;
      padding: 8px;

      .e-btn {
        display: list-item;
        list-style-type: none;
        margin-bottom: 10px;
      }
    }

    &.e-checkbox-right {
      .e-checkbox-wrapper {
        position: absolute;
        right: 0;
        top: 30%;
      }
    }
  }

  .e-rtl.e-listboxtool-wrapper,
  .e-rtl.e-listboxtool-container {
    &.e-right {
      .e-listbox-tool {
        margin-right: 15px;
      }
    }

    &.e-left {
      .e-listbox-tool {
        margin-left: 15px;
      }
    }
  }

  .e-bigger .e-listbox-wrapper,
  .e-listbox-wrapper.e-bigger,
  .e-bigger .e-listbox-container,
  .e-listbox-container.e-bigger {
    @if ($skin-name == 'bootstrap4') {
      font-size: 16px;
    }

    &.e-listboxtool-container .e-list-wrap {
      @if $skin-name == 'FluentUI' or $skin-name == 'bootstrap5' or $skin-name == 'tailwind' {
        width: calc(100% - 20%) !important; /* stylelint-disable-line declaration-no-important */
      }
      @else if $skin-name == 'material' or $skin-name == 'bootstrap4' or $skin-name == 'bootstrap' {
        width: calc(100% - 15%) !important; /* stylelint-disable-line declaration-no-important */
      }
      @else if $skin-name == 'fabric' or $skin-name == 'highcontrast' {
        width: calc(100% - 17%) !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    .e-list-item {
      border-bottom: $listbox-border-bottom solid $listbox-border-bottom-color;
      border-left: $listbox-border-left solid $listbox-border-left-color;
      border-right: $listbox-border-right solid $listbox-border-right-color;
      border-top: $listbox-border-top solid $listbox-border-top-color;
    }

    &:not(.e-list-template) .e-list-item,
    .e-selectall-parent,
    .e-list-group-item,
    .e-list-header {
      padding: $listbox-bigger-item-padding;
    }

    &:not(.e-list-template) .e-list-item,
    .e-selectall-parent {
      height: $listbox-touch-item-height;
      line-height: $listbox-touch-item-line-height;
      position: relative;
      @if ($skin-name == 'bootstrap4') {
        padding: 12px 20px;
      }
    }

    .e-list-parent {
      min-height: $listbox-touch-item-height;
    }

    .e-text-content {
      font-size: $listbox-touch-item-font-size;
    }

    .e-list-group-item {
      height: $listbox-touch-groupheader-height;
      line-height: $listbox-touch-groupheader-line-height;
      @if ($skin-name == 'bootstrap4') {
        font-size: 16px;
        padding: 12px 20px;
      }
    }

    .e-list-header {
      align-items: center;
      display: flex;
      font-weight: $listbox-touch-header-font-weight;
      height: $listbox-touch-header-height;
      @if ($skin-name == 'bootstrap4') {
        font-size: 20px;
        line-height: 1.2;
        padding: 0 0 0 20px;
      }
    }

    .e-list-header .e-text.header {
      display: none;
    }

    .e-list-header .e-headertemplate-text.nested-header {
      display: none;
    }

    .e-list-header .e-text {
      font-size: $listbox-touch-header-font-size;
    }

    .e-but-back {
      @if ($skin-name == 'bootstrap4') {
        margin-top: -3px;
        padding-right: 12px;
      }
    }

    .e-list-icon {
      @if ($skin-name == 'bootstrap4') {
        margin-right: 12px;
      }
    }

    .e-icon-collapsible {
      @if ($skin-name == 'bootstrap4') {
        font-size: 12px;
      }
    }

    .e-checkbox-wrapper {
      @if ($skin-name == 'bootstrap4') {
        margin: 0 12px 0 0;
      }
    }

    &.e-rtl {
      .e-checkbox-wrapper {
        @if ($skin-name == 'bootstrap4') {
          margin: 0 0 0 12px;
        }
      }
    }
  }
}
