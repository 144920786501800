$drop-down-btn-arrow-content: '\e969' !default;
$drop-down-btn-vertical-btn-padding: 12px 12px !default;
$drop-down-btn-vertical-bigger-padding: 16px 16px !default;
$drop-down-btn-bigger-font-size: 15px !default;
$drop-down-btn-bigger-li-height: 48px !default;
$drop-down-btn-bigger-max-width: 280px !default;
$drop-down-btn-bigger-min-width: 112px !default;
$drop-down-btn-color: rgba($grey-light-font, .87) !default;
$drop-down-btn-disable-text: rgba($grey-light-font, .38) !default;
$drop-down-btn-font-size: 14px !default;
$drop-down-btn-font-weight: normal !default;
$drop-down-btn-icon-font-size: 14px !default;
$drop-down-btn-icon-margin-right: 10px !default;
$drop-down-btn-bigger-icon-margin-right: 10px !default;
$drop-down-btn-li-bgcolor: $grey-300 !default;
$drop-down-btn-li-box-shadow: none !default;
$drop-down-btn-li-border-color: rgba($grey-light-font, .12) !default;
$drop-down-btn-li-border-style: solid !default;
$drop-down-btn-li-border-width: 1px !default;
$drop-down-btn-li-focus-bgcolor: $grey-300 !default;
$drop-down-btn-li-height: 36px !default;
$drop-down-btn-li-padding: 0 16px !default;
$drop-down-btn-bigger-li-padding: 0 16px !default;
$drop-down-btn-small-li-padding: 0 16px !default;
$drop-down-btn-li-selection-bgcolor: $grey-400 !default;
$drop-down-btn-li-selection-font-color: rgba($grey-light-font, .87) !default;
$drop-down-btn-max-width: 240px !default;
$drop-down-btn-menu-icon-bigger-font-size: 16px !default;
$drop-down-btn-menu-icon-color: rgba($grey-black, .87) !default;
$drop-down-btn-menu-icon-disabled-color: rgba($grey-light-font, .38) !default;
$drop-down-btn-menu-icon-font-size: 14px !default;
$drop-down-btn-min-width: 120px !default;
$drop-down-btn-parent-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$drop-down-btn-seperator-padding: 8px 0 !default;
$drop-down-btn-selected-color: rgba($grey-light-font, .87) !default;
$drop-down-btn-sub-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$drop-down-btn-ul-bgcolor: $grey-white !default;
$drop-down-btn-ul-border-radius: 0 !default;
$drop-down-btn-ul-border: none !default;
$drop-down-btn-ul-padding: 8px 0 !default;
$drop-down-btn-ul-bigger-padding: 8px 0 !default;
$drop-down-btn-ul-small-padding: 8px 0 !default;
$drop-down-btn-caret-icon-font-size: 8px !default;
$drop-down-btn-icon-font-size-bigger: 16px !default;
$drop-down-btn-caret-icon-font-size-bigger: 10px !default;
$drop-down-btn-box-shadow: none !default;
$drop-down-btn-popup-margin-top: 0 !default;
$drop-down-btn-bigger-popup-margin-top: 0 !default;
