﻿@include export-module('pager-layout') {
  
  /*! Pager layout */
  .e-bigger  #{&}.e-pager,
  #{&}.e-pager.e-bigger {
    font-size: $pager-bigger-font-size;
    padding: $pager-bigger-padding;

    div.e-icons {
      font-size: $pager-bigger-icon-font-size;
    }

    div.e-parentmsgbar {
      padding-bottom: $pager-bigger-messagebar-padding-bottom;
      padding-right: $pager-bigger-messagebar-padding-right;
      padding-top: $pager-bigger-messagebar-padding-top;
      @if $pager-skin == 'tailwind' {
        line-height: 22px;
      }
    }

    .e-pagerdropdown {
      height: $pager-bigger-dropdown-heigh;
      margin: $pager-bigger-dropdown-margin;
      margin-top: $pager-bigger-dropdown-margin-top;
      width: $pager-bigger-dropdown-width;
    }

    .e-pagercontainer .e-firstpage,
    .e-pagercontainer .e-prevpage,
    .e-pagercontainer .e-firstpagedisabled,
    .e-pagercontainer .e-prevpagedisabled,
    .e-pagercontainer .e-nextpage,
    .e-pagercontainer .e-lastpage,
    .e-pagercontainer .e-nextpagedisabled,
    .e-pagercontainer .e-lastpagedisabled {
      padding: $pager-bigger-pagecontainer-icon-padding;
    }

    &.e-rtl .e-pagercontainer .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 11px 9px;
      }
    }

    &.e-rtl .e-pagercontainer .e-prevpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 11px 9px 10px;
      }
    }

    &.e-rtl .e-pagercontainer .e-firstpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 10px 9px;
      }
    }

    &.e-rtl .e-pagercontainer .e-lastpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 10px 9px;
      }
    }

    .e-pagerconstant {
      margin-bottom: $pager-bigger-dropdown-constant-margin;
      margin-left: $pager-bigger-constant-margin-left;
      @if $pager-skin == 'tailwind' {
        line-height: 22px;
      }
    }

    .e-pagercontainer {
      margin: $pager-bigger-pagercontainer-margin;
    }

    &.e-rtl .e-pagercontainer {
      margin: $pager-bigger-new-container-rtl-margin;
    }

    @media (max-width: 769px) {
      padding: $pager-device-padding;

      &.e-rtl div.e-parentmsgbar {
        margin-right: 0;
      }

      div.e-parentmsgbar {
        padding: $pager-device-messabar-padding;
        @if $pager-skin == 'material3' {
          vertical-align: top;
          margin-top: 5px;
        }
      }
    }

    .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: $pager-bigger-nexticon-marign-left;
    }

    &.e-rtl .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    &.e-rtl e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: 0;
      margin-right: $pager-bigger-rtl-nexticon-marign-right;
    }

    &.e-rtl div.e-parentmsgbar {
      margin-left: $pager-bigger-rtl-parentmsgbar-margin-left;
      margin-top: $pager-bigger-rtl-parentmsgbar-margin-top;
    }

    .e-numericitem {
      margin: $pager-bigger-numeric-item-margin;
      @if $pager-skin == 'tailwind' {
        line-height: 22px;
      }
    }

    .e-numericitem.e-currentitem {
      @if $pager-skin == 'tailwind' {
        padding: 6px 14px 5.5px 13px;
      }
      @else if $pager-skin == 'bootstrap5' {
        padding: 9px 13px 10px 12px;
      }
    }

    .e-numericitem.e-currentitem:hover {
      @if $pager-skin == 'tailwind' {
        padding: 6px 14px 5.5px 13px;
      }
      @else if $pager-skin == 'bootstrap5' {
        padding: 9px 13px 10px 12px;
      }
    }

    &.e-rtl .e-numericitem.e-currentitem {
      @if $pager-skin == 'tailwind' {
        padding: 6px 14px 6px 13px;
      }
      @else if $pager-skin == 'bootstrap5' {
        padding: 9px 13px 10px 12px;
      }
      @else if $pager-skin == 'material3' {
        padding: 10px 15px;
      }
    }

    &.e-rtl .e-numericitem.e-currentitem:hover {
      @if $pager-skin == 'tailwind' {
        padding: 6px 14px 6px 13px;
      }
      @else if $pager-skin == 'bootstrap5' {
        padding: 9px 13px 10px 12px;
      }
      @else if $pager-skin == 'material3' {
        padding: 10px 15px;
      }
    }

    .e-pp.e-spacing,
    .e-np.e-spacing,
    .e-pp.e-spacing:hover,
    .e-np.e-spacing:hover {
      padding: $pager-bigger-letter-spacing;
    }

    .e-np.e-spacing.e-focused,
    .e-pp.e-spacing.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 11px 12px 10.5px 11.5px;
      }
    }

    &.e-rtl .e-np.e-spacing.e-focused,
    &.e-rtl .e-pp.e-spacing.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 13px 13px 11px 12px;
      }
    }

    .e-spacing,
    .e-numericitem:hover,
    .e-currentitem {
      border-radius: $pager-bigger-current-item-border-radius;
      padding: $pager-bigger-current-item-padding;
    }

    &.e-rtl {
      & .e-pp.e-spacing.e-numericitem,
      & .e-np.e-spacing.e-numericitem,
      & .e-pp.e-spacing.e-numericitem:hover,
      & .e-np.e-spacing.e-numericitem:hover {
        font-size: $pager-rtl-bigger-tripledot-font-size;
        margin-top: $pager-tripledot-rtl-bigger-margin-top;
        @if $pager-skin == 'tailwind' {
          padding: 7px 12.6px;
        }
        @else if $pager-skin == 'bootstrap5' {
          padding: 14px 14px 13px;
        }
        @else if $pager-skin == 'material3' {
          &.e-focused {
            padding: 10px 12px;
          }
          padding: 10px 12px;
        }
      }

      .e-spacing,
      .e-numericitem:hover,
      .e-currentitem {
        @if $pager-skin == 'material3' {
          padding: $pager-bigger-current-item-padding;
        }
      }

      & .e-pagerdropdown {
        margin: $pager-rtl-pagerdropdown-bigger-margin;
      }

      & .e-pagerconstant {
        margin: $pager-rtl-pagerconstant-bigger-margin-top;
      }
    }

    .e-pagercontainer .e-firstpage,
    .e-pagercontainer .e-prevpage,
    .e-pagercontainer .e-firstpagedisabled,
    .e-pagercontainer .e-prevpagedisabled,
    .e-pagercontainer .e-nextpage,
    .e-pagercontainer .e-lastpage,
    .e-pagercontainer .e-nextpagedisabled,
    .e-pagercontainer .e-lastpagedisabled {
      margin-right: $pager-bigger-pagercontainer-icons-marign-right;
      @if $pager-skin == 'tailwind' {
        margin-top: -4px;
      }
      padding: $pager-bigger-pagercontainer-icons-padding;
    }

    .e-pagercontainer .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 11px 9px 10px;
      }
    }

    .e-pagercontainer .e-prevpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 11px 9px 10px;
      }
    }

    .e-pagercontainer .e-firstpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 11px 9px 10px;
      }
    }

    .e-pagercontainer .e-lastpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding: 10px 10px 9px;
      }
    }

    .e-pp,
    .e-np,
    .e-pp:hover,
    .e-np:hover {
      font-size: $pager-bigger-tripledot-font-size;
    }
    &.e-adaptive {
      div.e-parentmsgbar {
        margin-top: $pager-bigger-adaptive-pagermsgbar-margin-top;
      }
      .e-pagerdropdown {
        margin: $pager-bigger-adaptive-dropdown-margin;
      }
      .e-pagerconstant.e-page-all {
        top: $pager-bigger-adaptive-constant-pagesizeall-top;
      }
    }

    &.e-rtl {
      &.e-adaptive.e-rtl {
        div.e-parentmsgbar {
          margin-top: $pager-rtl-e-bigger-adaptive-pagermsgbar-margin-top;
        }
        div.e-pagerdropdown {
          margin: $pager-rtl-e-bigger-adaptive-dropdown-margin;
        }
        div.e-pagerconstant.e-page-all {
          top: $pager-rtl-e-bigger-adaptive-constant-pagesizeall-top;
        }
      }
    }
  }

  .e-bigger .e-responsive  #{&}.e-pager,
  .e-responsive #{&}.e-pager.e-bigger {
    div.e-icons {
      @if $pager-skin == 'material3' {
        border-radius: 4px;
      }
    }
  }

  #{&}.e-pager {
    border-style: $pager-border-type;
    border-width: 1px;
    display: inline-block;
    font-size: $pager-font-size;
    padding: $pager-padding;
    white-space: normal;
    width: 100%;

    [class ^= 'e-'] {
      box-sizing: border-box;
    }

    .e-pager-default.e-hide,
    .e-pagesizes.e-hide,
    .e-hide {
      display: none;
    }

    div.e-parentmsgbar {
      float: right;
      padding-bottom: $pager-messagebar-padding-bottom;
      padding-right: $pager-messagebar-padding-right;
      padding-top: $pager-messagebar-padding-top;
      @if $pager-skin == 'tailwind' {
        line-height: 18px;
      }
    }

    .e-pagesizes {
      display: inline;
    }

    .e-pagerdropdown {
      display: inline-block;
      height: $pager-dropdown-height;
      margin-left: $pager-dropdown-margin-left;
      margin-right: $pager-dropdown-margin-right;
      margin-top: $pager-dropdown-margin-top;
      overflow: $pager-dropdown-overflow;
      vertical-align: middle;
      width: $pager-dropdown-width;
    }

    .e-pagerconstant {
      @if $pager-skin == 'fluent' {
        font-weight: 600;
      }
      display: inline-block;
      @if $pager-skin == 'tailwind' {
        line-height: 18px;
      }
      margin: $pager-constant-margin;
      overflow: hidden;
      width: auto;
    }

    .e-icons {
      font-size: $pager-icon-size;
    }

    .e-numericitem {
      border-right-style: $pager-border-type;
      border-right-width: $pager-border-size;
      display: inline-block;
      line-height: $pager-numeric-font-line-height;
      margin-right: $pager-numericitem-margin-right;
      min-width: $pager-numericitem-min-width;
      padding: $pager-numericitem-padding;
      text-align: center;
      @if $pager-skin == 'material3' {
        vertical-align: middle;
      }
    }

    .e-numericitem.e-currentitem {
      @if $pager-skin == 'tailwind' {
        border: 1px solid $primary;
        padding: 3.5px 11px 6px 10px;
      }
      @else if $pager-skin == 'bootstrap5' {
        padding: 7.4px 10.5px 8.4px 9.5px;
      }
    }

    .e-numericitem.e-currentitem:hover {
      @if $pager-skin == 'tailwind' {
        padding: 3.5px 11px 6px 10px;
      }
      @else if $pager-skin == 'bootstrap5' {
        padding: 7.4px 10.5px 8.4px 9.5px;
      }
    }

    &.e-rtl .e-numericitem.e-currentitem {
      @if $pager-skin == 'tailwind' {
        border-left-color: $primary;
        border-left-style: solid;
        border-left-width: 1px;
        padding: 3.5px 11px 4px 10px;
      }
      @else if $pager-skin == 'bootstrap5' {
        border-left-color: $primary-light;
        border-left-style: solid;
        border-left-width: 3px;
        padding: 7px 10.5px 8px 9.5px;
      }
    }

    &.e-rtl .e-numericitem.e-currentitem:hover {
      @if $pager-skin == 'tailwind' {
        padding: 3.5px 11px 4px 10px;
      }
      @else if $pager-skin == 'bootstrap5' {
        padding: 7px 10.5px 8px 9.5px;
      }
    }

    div.e-pagermsgdiv {
      padding-right: 6px;
      padding-top: 4px;
      text-align: right;
    }

    .e-firstpagedisabled,
    .e-prevpagedisabled,
    .e-nextpagedisabled,
    .e-lastpagedisabled {
      opacity: $pager-disabled-icons-opacity;
    }

    .e-spacing,
    .e-numericitem:hover,
    .e-currentitem {
      border-radius: $pager-numericitem-border-radius;
      cursor: pointer;
      padding: $pager-numeric-icon-padding;
      text-decoration: none;
    }

    .e-currentitem {
      @if $pager-skin == 'fluent' {
        border-bottom: $pager-active-item-border-bottom;
      }
      @if $pager-skin == 'bootstrap5' {
        border: 3px solid $primary-light;
      }
      @if $pager-skin == 'material3' {
        border-radius: 4px;
      }
      font-style: normal;
      font-variant: normal;
      font-weight: $pager-current-item-font-weight;
    }

    div,
    a {
      display: inline;
      user-select: none;
    }

    .e-icons::before {
      display: inline-block;
    }

    .e-pagercontainer .e-firstpage,
    .e-pagercontainer .e-prevpage,
    .e-pagercontainer .e-firstpagedisabled,
    .e-pagercontainer .e-prevpagedisabled,
    .e-pagercontainer .e-nextpage,
    .e-pagercontainer .e-lastpage,
    .e-pagercontainer .e-nextpagedisabled,
    .e-pagercontainer .e-lastpagedisabled {
      border-right-style: $pager-border-type;
      border-right-width: $pager-border-size;
      display: inline-block;
      margin-right: $pager-navigationicon-right;
      margin-top: $pager-navigationicon-top;
      min-width: 26px;
      padding: $pager-pagecontainer-icon-padding;
      @if $pager-skin == 'tailwind' {
        position: relative;
        top: 3px;

        &.e-focused {
          border: 0;
          border-right-style: none;
          padding: 8.9px 7px 8px;
        }
      }
      @else if $pager-skin == 'material3' {
        vertical-align: middle;
        &.e-focused {
          border-radius: 4px;
        }
      }
    }

    .e-pagercontainer .e-nextpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding-right: 8px;
      }
    }

    .e-pagercontainer .e-prevpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding-left: 7px;
        padding-right: 8px;
      }
    }

    .e-pagercontainer .e-firstpage.e-focused {
      @if $pager-skin == 'tailwind' {
        padding-right: 8px;
      }
    }

    .e-pagercontainer .e-lastpage,
    .e-pagercontainer .e-lastpagedisabled {
      border-right: $pager-last-border-size;
    }

    .e-firstpage:hover,
    .e-prevpage:hover,
    .e-lastpage:hover,
    .e-nextpage:hover {
      cursor: pointer;
      text-decoration: none;
    }

    a.e-nextprevitemdisabled {
      display: none;
      margin-left: $pager-border-size;
      margin-right: $pager-border-size;
      padding-left: 5px;
      padding-right: 5px;
      text-decoration: none;
    }

    .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: $pager-next-icon-margin-left;
    }

    .e-pagercontainer {
      border-radius: $pager-container-border-radius;
      border-style: $pager-border-type;
      border-width: $pager-border-size;
      display: inline-block;
      margin: $pager-container-margin;
      overflow: hidden;
    }

    .e-lastpage:hover {
      border-radius: $pager-last-page-hover-border-radius;
    }

    .e-firstpage:hover {
      border-radius: $pager-first-page-hover-border-radius;
    }

    .e-pagermessage,
    .e-pagerexternalmsg {
      display: block;
      margin: $pager-external-msg-padding;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .e-mfirst,
    .e-mprev,
    .e-mnext,
    .e-mlast {
      display: none;
    }

    .e-mprev {
      text-indent: -3px;
    }

    .e-mnext {
      text-indent: -2px;
    }

    /* stylelint-disable */
    .e-mfirst,
    .e-mprev,
    .e-mnext,
    .e-mlast {
      -webkit-tap-highlight-color: $pager-bg-color;
    }

    /* stylelint-enable */

    .e-pp,
    .e-np,
    .e-pp:hover,
    .e-np:hover {
      font-size: $pager-tripledot-font-size;
      font-weight: $pager-numeric-font-weight;
      letter-spacing: $pager-letter-spacing;
      padding: $pager-tripledot-padding;
    }

    .e-np.e-focused,
    .e-pp.e-focused {
      line-height: $pager-focus-tripledot-line-height;
      padding: $pager-focus-tripledot-padding;
    }
    @if $pager-skin == 'material3' {
      &.e-rtl .e-np.e-focused,
      &.e-rtl .e-pp.e-focused {
        padding: $pager-focus-tripledot-padding;
      }
    }
    
    &.e-adaptive {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $pager-bigger-adaptive-pager-padding;

      div.e-parentmsgbar {
        margin: 0 5px;
        margin-top: $pager-adaptive-pagermsgbar-margin-top;
        flex-grow: 1;
        min-width: 10px;
        white-space: nowrap;
      }
      .e-pagesizes {
        display: inline-flex;
        width: auto;
      }
      .e-pagerdropdown {
        margin: $pager-adaptive-dropdown-margin;
        width: $pager-bigger-adaptive-dropdown-min-width;
        min-width: $pager-bigger-adaptive-dropdown-min-width;
        order: 2;
        height: 40px;
        padding-right: 5px;
      }
      .e-pagerconstant {
        font-size: 12px;
        position: relative;
        top: $pager-bigger-adaptive-constant-top;
        order: 1;
        min-width: 52px;
        @if $pager-skin == 'tailwind' {
          max-height: 42px;
        }
        @else {
          max-height: 34px;
        }
        margin: 0 8px 0 10px;
      }
      .e-pagerconstant.e-page-all {
        top: $pager-adaptive-constant-pagesizeall-top;
        text-align: center;
      }

      .e-mfirst,
      .e-mprev,
      .e-mnext,
      .e-mlast {
        padding: 1% 2%;
        width: 5%;
      }

      .e-mfirst,
      .e-mlast {
        width: calc(5% + 11px);
      }
    }

    @media (max-width: 769px) {
      padding: 13px 0;

      div.e-parentmsgbar {
        box-sizing: border-box;
        display: inline-block;
        float: initial;
        padding-bottom: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        width: calc(60% - 48px);
      }

      .e-pagesizes {
        display: none;
      }

      .e-pagecountmsg {
        display: none;
      }

      .e-pagercontainer {
        display: none;
      }

      .e-icons {
        font-size: $pager-mobile-icon-size;
      }

      .e-mfirst,
      .e-mprev,
      .e-mnext,
      .e-mlast {
        border: 0;
        box-sizing: border-box;
        display: inline-block;
        padding: 1% 5%;
      }

      .e-mfirst {
        margin-right: 4px;
        text-align: right;
        width: calc(10% + 11px);
      }

      .e-mprev {
        margin: 0 4px;
        text-align: right;
        width: 10%;
      }

      .e-mnext {
        margin: 0 4px;
        text-align: left;
        width: 10%;
      }

      .e-mlast {
        margin-left: 4px;
        text-align: left;
        width: calc(10% + 11px);
      }
    }

    &.e-rtl {
      direction: rtl;

      .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        margin-left: 0;
        margin-right: $pager-rtl-nxtpage-margin;
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' {
          border-left-style: solid;
          border-left-width: 1px;
          border-right-style: solid;
          border-right-width: 1px;
        }
      }

      & .e-pagercontainer,
      & div,
      & a {
        float: initial;
      }

      & .e-parentmsgbar {
        float: left;
        margin-left: $pager-rtl-parentmsgbar-margin-left;
        padding-top: $pager-rtl-parentmsgbar-padding-top;
      }

      & .e-pagerdropdown {
        margin: $pager-rtl-pagerdropdown-margin;
      }

      & .e-pagerconstant {
        margin: $pager-rtl-pagerconstant-margin-top;
      }

      & .e-pagerexternalmsg {
        float: none;
      }

      & .e-pagercontainer {
        float: none;
        margin: $pager-container-rtl-margin;
        padding: 0;
      }

      & .e-firstpage,
      & .e-prevpage,
      & .e-firstpagedisabled,
      & .e-prevpagedisabled,
      & .e-nextpage,
      & .e-lastpage,
      & .e-nextpagedisabled,
      & .e-lastpagedisabled {
        padding: $pager-rtl-navigation-icon-padding;
      }

      & .e-next.e-icons.e-icon-next.e-nextpage.e-focused {
        @if $pager-skin == 'tailwind' {
          border: 0;
          padding: 6.5px 8px 6px;
        }
      }

      & .e-prevpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 6.5px 8px 6px 7px;
        }
      }

      & .e-lastpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 6.5px 7px 6px;
        }
      }

      & .e-firstpage.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: 6.5px 7px 6px;
        }
      }

      & .e-firstpage::before,
      & .e-firstpage:hover,
      & .e-firstpagedisabled::before {
        content: '\e701';
      }

      & .e-prevpage::before,
      & .e-prevpage:hover,
      & .e-prevpagedisabled::before {
        content: '\e848';
      }

      & .e-nextpage::before,
      & .e-nextpage:hover,
      & .e-nextpagedisabled::before {
        content: '\e84b';
      }

      & .e-lastpage::before,
      & .e-lastpage:hover,
      & .e-lastpagedisabled::before {
        content: '\e716';
      }

      & .e-nextpage,
      & .e-nextpagedisabled,
      & .e-prevpage,
      & .e-prevpagedisabled,
      & .e-firstpage,
      & .e-firstpagedisabled {
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' {
          border-left-style: none;
          border-left-width: 0;
        }
        @else {
          border-left-style: $pager-border-type;
          border-left-width: $pager-border-size;
        }
      }

      & .e-nextpage,
      & .e-nextpagedisabled,
      & .e-prevpage,
      & .e-prevpagedisabled {
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' {
          border-left-style: none;
          border-left-width: 0;
        }
        @else {
          border-right-style: none;
          border-right-width: 0;
        }
      }

      & .e-firstpage,
      & .e-firstpagedisabled {
        border-right: medium none;
      }

      & .e-firstpage:hover {
        border-radius: $pager-rtl-first-last-icon-hover-border-radius;
      }

      & .e-lastpage:hover {
        border-radius: $pager-rtl-first-last-icon-hover-border-radius;
      }

      & .e-numericcontainer {
        float: none;
      }

      & .e-numericitem {
        @if $pager-skin == 'tailwind' or $pager-skin == 'bootstrap5' {
          @if $pager-skin == 'bootstrap5' {
            border-right-style: solid;
          }
          @else {
            border-left-style: none;
            border-left-width: 0;
            border-right-style: solid;
            border-right-width: 1px;
          }
        }
        @else {
          border-left-style: $pager-border-type;
          border-left-width: $pager-border-size;
          border-right-style: none;
          border-right-width: 0;
        }
        min-width: 26px;
      }

      & .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
      &.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        margin-left: 0;
        margin-right: $pager-next-icon-margin-left;
      }

      & .e-spacing,
      & .e-numericitem:hover,
      & .e-currentitem {
        margin: $pager-rtl-numeric-margin;
        padding: $pager-activeitem-padding;
      }

      & .e-pp,
      & .e-np,
      & .e-pp:hover,
      & .e-np:hover {
        margin-top: $pager-tripledot-rtl-margin-top;
        padding: $pager-tripledot-rtl-padding;
      }

      & .e-np.e-focused,
      & .e-pp.e-focused {
        @if $pager-skin == 'tailwind' {
          padding: $pager-focus-tripledot-padding;
        }
      }

      &.e-adaptive.e-rtl {
        div.e-parentmsgbar {
          margin: 0 5px;
          margin-top: $pager-rtl-adaptive-pagermsgbar-margin-top;
          padding-top: 0;
        }
        div.e-pagerconstant {
          margin: $pager-rtl-adaptive-constant-top;
        }
        div.e-pagerconstant.e-page-all {
          top: $pager-rtl-adaptive-constant-pagesizeall-top;
        }
        div.e-pagerdropdown {
          min-width: 92px;
          margin: $pager-rtl-adaptive-dropdown-margin;
          padding: 0 0 0 5px;
        }
        .e-mfirst,
        .e-mprev,
        .e-mnext,
        .e-mlast {
          padding: 1% 2%;
          width: 5%;
        }

        .e-mfirst,
        .e-mlast {
          width: calc(5% + 11px);
        }
      }

      @media (max-width: 769px) {
        .e-mfirst,
        .e-mprev,
        .e-mnext,
        .e-mlast {
          border: 0;
        }

        &.e-rtl div.e-parentmsgbar {
          float: initial;
          margin-left: 0;
          margin-top: $pager-rtl-pagermsgbar-device-margin-top;
        }

        .e-parentmsgbar {
          float: right;
        }
      }
    }

    &.sf-pager {
      .e-pagercontainer .e-first,
      .e-pagercontainer .e-prev,
      .e-pagercontainer .e-next,
      .e-pagercontainer .e-last {
        font-size: $pager-icon-size;
      }
    }

    &.sf-pager {
      .e-numericcontainer a {
        font-size: $pager-font-size;
      }
    }

    &.sf-pager {
      .e-pagercontainer {
        font-size: 0;
      }
    }

    &.sf-pager {
      .e-link,
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        margin-left: 0;
      }
    }
  }
}
