$cbox-bgcolor: #fff !default;
$cbox-bigger-check-fontsize: 16px !default;
$cbox-bigger-font-size: 14px !default;
$cbox-bigger-height: 22px !default;
$cbox-bigger-indeterminate-fontsize: 12px !default;
$cbox-bigger-indeterminate-lineheight: 14px !default;
$cbox-bigger-lineheight: 14px !default;
$cbox-bigger-margin: 12px !default;
$cbox-bigger-small-check-fontsize: 12px !default;
$cbox-bigger-small-font-size: 14px !default;
$cbox-bigger-small-height: 20px !default;
$cbox-bigger-small-indeterminate-fontsize: 10px !default;
$cbox-bigger-small-indeterminate-lineheight: 12px !default;
$cbox-bigger-small-lineheight: 12px !default;
$cbox-bigger-small-width: 20px !default;
$cbox-bigger-width: 22px !default;
$cbox-border: 2px solid !default;
$cbox-border-color: $grey-600 !default;
$cbox-border-radius: 2px !default;
$cbox-check-fontsize: 12px !default;
$cbox-checked-ripple-bgcolo: rgba($accent, .26) !default;
$cbox-checkmark-bgcolor: $accent !default;
$cbox-checkmark-border-color: transparent !default;
$cbox-checkmark-color: $accent-font !default;
$cbox-checkmark-disabled-bgcolor: $grey-400 !default;
$cbox-checkmark-disabled-border-color: $grey-400 !default;
$cbox-checkmark-disabled-color: $grey-dark-font !default;
$cbox-checkmark-hover-bgcolor: $accent !default;
$cbox-checkmark-hover-border-color: transparent !default;
$cbox-checkmark-hover-color: $accent-font !default;
$cbox-color: rgba($grey-light-font, .87) !default;
$cbox-disabled-bgcolor: #fff !default;
$cbox-disabled-border-color: $grey-400 !default;
$cbox-disabled-color: $grey-400 !default;
$cbox-focus-outline: $cbox-bgcolor 0 solid !default;
$cbox-focus-outline-offset: 0 !default;
$cbox-focussed-bgcolor: #fff !default;
$cbox-focussed-border-color: $grey-600 !default;
$cbox-focussed-box-shadow: none !default;
$cbox-focussed-checkmark-bgcolor: $accent !default;
$cbox-focussed-checkmark-border-color: transparent !default;
$cbox-focussed-checkmark-color:  $accent-font !default;
$cbox-focussed-color: rgba($grey-light-font, .87) !default;
$cbox-focussed-indeterminate-color: $grey-600 !default;
$cbox-font-size: 13px !default;
$cbox-height: 18px !default;
$cbox-hover-bgcolor: #fff !default;
$cbox-hover-border-color: $grey-600 !default;
$cbox-hover-color: rgba($grey-light-font, .87) !default;
$cbox-indeterminate-bgcolor: #fff !default;
$cbox-indeterminate-border-color: $grey-600 !default;
$cbox-indeterminate-color: $grey-600 !default;
$cbox-indeterminate-content: '\e934' !default;
$cbox-indeterminate-disabled-bgcolor: #fff !default;
$cbox-indeterminate-disabled-border-color: $grey-400 !default;
$cbox-indeterminate-disabled-color: $grey-400 !default;
$cbox-indeterminate-fontsize: 10px !default;
$cbox-indeterminate-hover-color: $grey-600 !default;
$cbox-indeterminate-lineheight: 10px !default;
$cbox-key-focussed-bgcolor: rgba($grey-black, .12) !default;
$cbox-lineheight: 10px !default;
$cbox-margin: 10px !default;
$cbox-padding: 2px 0 !default;
$cbox-ripple-bgcolor: rgba($grey-black, .26) !default;
$cbox-ripple-bigger-size: -9px !default;
$cbox-ripple-bigger-small-size: -9px !default;
$cbox-ripple-size: -9px !default;
$cbox-ripple-small-size: -9px !default;
$cbox-ripple-height: 36px !default;
$cbox-ripple-width: 36px !default;
$cbox-ripple-small-height: 32px !default;
$cbox-ripple-small-width: 32px !default;
$cbox-ripple-bigger-small-height: 38px !default;
$cbox-ripple-bigger-small-width: 38px !default;
$cbox-ripple-bigger-height: 40px !default;
$cbox-ripple-bigger-width: 40px !default;
$cbox-small-check-fontsize: 10px !default;
$cbox-small-font-size: 13px !default;
$cbox-small-height: 14px !default;
$cbox-small-indeterminate-fontsize: 8px !default;
$cbox-small-indeterminate-lineheight: 6px !default;
$cbox-small-lineheight: 6px !default;
$cbox-small-width: 14px !default;
$cbox-width: 18px !default;
$cbox-border-style: solid !default;
