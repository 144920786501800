/*! TreeView's material theme wise override definitions and variables */
/* stylelint-disable */
$treeview-font-size: 13px !default;
$treeview-icon-font-size: 10px !default;
$treeview-image-font-size: 18px !default;

$treeview-big-font-size: 14px !default;
$treeview-big-icon-font-size: 12px !default;

$treeview-icon-color: rgba($grey-light-font, .54) !default;
$treeview-text-color: rgba($grey-light-font, .87) !default;
$treeview-item-border-color: transparent !default;
$treeview-item-active-bg: $grey-200 !default;
$treeview-icon-active-color: rgba($grey-light-font, .54) !default;
$treeview-text-active-color: $accent !default;
$treeview-item-active-border-color: $grey-200 !default;
$treeview-item-hover-bg: $grey-100 !default;
$treeview-icon-hover-color: rgba($grey-light-font, .54) !default;
$treeview-text-hover-color: rgba($grey-light-font, .87) !default;
$treeview-item-hover-border-color: $grey-100 !default;
$treeview-item-active-hover-bg: $grey-300 !default;
$treeview-icon-active-hover-color: rgba($grey-light-font, .54) !default;
$treeview-text-active-hover-color: $accent !default;
$treeview-item-active-hover-border-color: $grey-300 !default;
$treeview-text-disable-color: rgba($grey-light-font, .54) !default;
$treeview-icon-disable-color: rgba($grey-light-font, .87) !default;
$treeview-drag-line-bg: $accent !default;
$treeview-drag-line-color: rgba($grey-light-font, .54) !default;
$treeview-popup-bg-color: $grey-white !default;
$treeview-popup-border-color: $grey-200 !default;
$treeview-drop-count-bg: $accent !default;
$treeview-drop-count-border: $accent-font !default;
$treeview-drop-count-color: $accent-font !default;
$treeview-drag-item-bg: $grey-200 !default;
$treeview-drag-item-color: rgba($grey-light-font, .54) !default;
$treeview-drag-icon-color: rgba($grey-light-font, .54) !default;

$treeview-item-height: 32px !default;
$treeview-text-height: 30px !default;
$treeview-input-height: 30px !default;
$treeview-root-ul-padding: 0 0 0 24px !default;
$treeview-rtl-root-ul-padding: 0 24px 0 0 !default;
$treeview-child-ul-padding: 0 0 0 24px !default;
$treeview-rtl-child-ul-padding: 0 24px 0 0 !default;
$treeview-text-wrap-padding: 0 0 0 24px !default;
$treeview-rtl-text-wrap-padding: 0 24px 0 0 !default;
$treeview-icon-size: 24px !default;
$treeview-icon-margin: 0 0 0 -24px !default;
$treeview-rtl-icon-margin: 0 -24px 0 0 !default;
$treeview-icon-padding: 7px !default;
$treeview-text-padding: 0 5px !default;
$treeview-text-margin: 0 !default;
$treeview-image-size: 18px !default;
$treeview-image-margin: 0 0 0 5px !default;
$treeview-navigable-image-icon-margin: 0 10px 0 12px !default;
$treeview-navigable-uncheck-image-margin: 0 10px 0 0 !default;
$treeview-navigable-image-icon-rtl-margin: 0 12px 0 10px !default;
$treeview-navigable-uncheck-image-rtl-margin: 0 0 0 10px !default;
$treeview-navigable-icon-image-margin: 0 8px 0 0 !default;
$treeview-navigable-icon-image-margin-reverse: 0 0 0 8px !default;
$treeview-navigable-check-margin-bigger: 0 0 0 12px !default;
$treeview-navigable-icon-image-anchor-margin-bigger: 0 10px 0 6px !default;
$treeview-navigable-icon-image-anchor-margin-reverse-bigger: 0 6px 0 10px !default;
$treeview-navigable-icon-image-anchor-margin: 0 10px 0 2px !default;
$treeview-navigable-icon-image-anchor-margin-reverse: 0 2px 0 10px !default;
$treeview-navigable-rtl-margin-reverse: 0 12px 0 0 !default;
$treeview-rtl-image-margin: 0 5px 0 0 !default;
$treeview-input-padding: 0 7px !default;
$treeview-image-text-padding: 0 10px !default;
$treeview-icon-image-margin: 0 0 0 10px !default;
$treeview-rtl-icon-image-margin: 0 10px 0 0 !default;
$treeview-check-margin: 0 0 0 5px !default;
$treeview-rtl-check-margin: 0 5px 0 0 !default;
$treeview-check-text-padding: 0 10px !default;
$treeview-check-image-margin: 0 0 0 12px !default;
$treeview-rtl-check-image-margin: 0 12px 0 0 !default;
$treeview-drop-count-border-size: 1px !default;
$treeview-drop-count-font-size: 13px !default;
$treeview-edit-wrap-width: calc(100% - 2px) !default;
$treeview-check-wrap-width: calc(100% - 22px) !default;
$treeview-check-icon-wrap-width: calc(100% - 59px) !default;
$treeview-check-icon-img-wrap-width: calc(100% - 87px) !default;
$treeview-icon-wrap-width: calc(100% - 29px) !default;
$treeview-icon-img-wrap-width: calc(100% - 57px) !default;

$treeview-big-item-height: 40px !default;
$treeview-big-text-height: 38px !default;
$treeview-big-input-height: 38px !default;
$treeview-big-text-padding: 0 10px !default;
$treeview-big-input-padding: 0 9px !default;
$treeview-big-icon-padding: 6px !default;
$treeview-big-image-margin: 0 0 0 10px !default;
$treeview-big-rtl-image-margin: 0 10px 0 0 !default;
$treeview-big-icon-image-margin: 0 0 0 10px !default;
$treeview-big-rtl-icon-image-margin: 0 10px 0 0 !default;
$treeview-big-check-margin: 0 0 0 10px !default;
$treeview-big-rtl-check-margin: 0 10px 0 0 !default;
$treeview-big-check-image-margin: 0 0 0 16px !default;
$treeview-big-rtl-check-image-margin: 0 16px 0 0 !default;
$treeview-big-check-wrap-width: calc(100% - 29px) !default;
$treeview-big-check-icon-wrap-width: calc(100% - 70px) !default;
$treeview-big-check-icon-img-wrap-width: calc(100% - 98px) !default;
$treeview-big-icon-wrap-width: calc(100% - 34px) !default;
$treeview-big-icon-img-wrap-width: calc(100% - 62px) !default;

$treeview-font-family: $font-family !default;
$treeview-drag-icon-font-size: 12px !default;
$treeview-drag-icon-padding: 6px !default;

$ripple-size: -7px !default;
$ripple-height: 32px !default;
$ripple-width: 32px !default;
