@include export-module('uploader-material-icons') {
  .e-upload .e-upload-files .e-file-remove-btn.e-icons::before {
    content: '\e945';
  }

  .e-upload .e-upload-files .e-icons.e-file-pause-btn::before {
    content: '\e326';
  }

  .e-upload .e-upload-files .e-icons.e-file-reload-btn::before {
    content: '\e99d';
  }

  .e-upload .e-upload-files .e-icons.e-file-play-btn::before {
    content: '\e324';
  }

  .e-upload .e-upload-files .e-file-delete-btn.e-icons::before {
    content: '\e94a';
  }

  .e-upload .e-upload-files .e-file-abort-btn.e-icons::before {
    content: '\e974';
  }

  .e-upload .e-upload-files .e-icons.e-msie::before {
    position: relative;
    right: $ie-icons-position-value;
  }

  .e-upload .e-upload-files .e-icons.e-file-abort-icon.e-msie::before {
    right: $ie-abort-icon-position-value;
  }
}
