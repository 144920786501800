@include export-module('drop-down-button-material-icons') {
  .e-dropdown-btn,
  .e-dropdown-btn.e-btn {
    .e-caret {
      &::before {
        content: '\e969';
      }
    }
  }
}
