$timepicker-skin-name: 'material' !default;
$timepicker-default-text-indent: 16px !default;
$timepicker-list-bigger-line-height: 48px !default;
$timepicker-list-normal-line-height: 36px !default;
$timepicker-list-normal-font-size: 13px !default;
$timepicker-list-bigger-font-size: 14px !default;
$timepicker-list-normal-text-indent: $timepicker-default-text-indent !default;
$timepicker-list-bigger-text-indent: 16px !default;
$timepicker-popup-border-radius: 2px !default;
$timepicker-list-font-weight: normal !default;
$timepicker-popup-shadow: 0 5px 5px -3px rgba($grey-light-font, .2), 0 8px 10px 1px rgba($grey-light-font, .14), 0 3px 14px 2px rgba($grey-light-font, .12) !default;
$timepicker-list-default-font-color: rgba($grey-light-font, .87) !default;
$timepicker-list-default-border-style: none !default;
$timepicker-list-border-color: none !default;
$timepicker-list-hover-border-color: none !default;
$timepicker-list-bg-color: $grey-white !default;
$timepicker-list-active-bg-color: $grey-200 !default;
$timepicker-list-active-font-color: $accent !default;
$timepicker-list-active-icon-color: $accent !default;
$timepicker-list-hover-bg-color: $grey-200 !default;
$timepicker-list-hover-font-color: $grey-light-font !default;
$timepicker-list-popup-icon-active-color: $accent !default;
$timepicker-list-active-hover-bg-color: $grey-200 !default;
$timepicker-list-active-hover-font-color: $accent !default;
$timepicker-list-normal-padding: 0 !default;
$timepicker-list-bigger-padding: 0 !default;
$timepicker-font-icon: '\e20c' !default;
$timepicker-icon-normal-font-size: 16px !default;
$timepicker-icon-bigger-font-size: 18px !default;
$timepicker-normal-input-min-height: 20px !default;
$timepicker-normal-input-min-width: 20px !default;
$timepicker-bigger-input-min-height: 22px !default;
$timepicker-bigger-input-min-width: 22px !default;
$timepicker-disable-text: rgba($grey-light-font, .38) !default;
$timepicker-disable-opacity: 1 !default;
$timepicker-default-overlay: rgba($grey-black, .6) !default;

// mouse small
$timepicker-list-small-font-size: 12px !default;
$timepicker-list-small-line-height: 26px !default;
$timepicker-list-small-text-indent: 12px !default;
$timepicker-list-small-font-color: $grey-light-font !default;

// mouse small icon
$timepicker-icon-small-font-size: 14px !default;

// Touch small
$timepicker-list-bigger-small-font-size: 13px !default;
$timepicker-list-bigger-small-line-height: 40px !default;
$timepicker-list-bigger-small-text-indent: 16px !default;

// Touch small icon
$timepicker-icon-bigger-small-font-size: 18px !default;

// modal dialog colors
$modal-header-bg-color: $primary !default;
$modal-header-text-color: $grey-white !default;

// modal dialog portrait dimensions
$modal-header-height: 10% !default;
$modal-header-padding: 2.5vh 2.5vw 2.5vh 1.5vw !default;
$modal-header-display-style: flex !default;
$modal-header-content-align: center !default;
$modal-header-portrait-font-size: 2vh !default;
$modal-close-icon-float: left !default;
$modal-portrait-content-padding: 1vh 2vw !default;
$modal-content-height: 90% !default;
$modal-content-overflow: auto !default;
$modal-header-title-transform: uppercase !default;
$modal-header-border-bottom: none !default;
$modal-list-line-height: 5vh !default;
$modal-landscape-list-line-height: 10vh !default;

// modal dialog landscape dimensions
$modal-header-landscape-height: 15% !default;
$modal-content-landscape-height: 85% !default;
$modal-header-landscape-font-size: 2vw !default;
$modal-landscape-padding: 1vh 1vw !default;
$modal-list-item-padding: 1vh 0 !default;
$modal-mobile-font-size: 14px !default;
$modal-tablet-font-size: 24px !default;
