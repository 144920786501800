// Variables for the default Angular theme
@use 'angular-material-css-vars' as mat-css-vars;

@include mat-css-vars.init-material-css-vars();

@import "./css/default-theme";
@import "./css/syncfusion.scss";

:root {
    --navbar-height: 64px;
    --navbar-padding: 16px;
    // height of navbar + padding (top and bottom)
    --full-navbar-height: calc( var(--navbar-height) + (var(--navbar-padding) * 2) );
    --main-page-height: calc(100vh - var(--navbar-height) - ( var(--navbar-padding) * 2 ) );
}

html,
body {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: var(--background-color);
}

.screen-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.content-spinner {
    position: relative;
    top: 50%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%, 0%);
}

.full-width {
    width: 100%;
}

.hidden {
    display: none;
}

.full-page-table-container {
    height: var(--main-page-height);
}

button.mat-mdc-button .mat-mdc-button-touch-target {
    height: 100%;
}

// The current theme can override anything
@import "./css/current-theme";
