/*! component's theme wise override definitions and variables */
$hscroll-skin: 'material' !default;
$hscroll-hover-font: $hover-font-color !default;
$hscroll-active-font-color: $active-font-color !default;
$hscroll-default-bg: $grey-50 !default;
$hscroll-press-bg: $grey-400 !default;
$hscroll-active-bg: $grey-400 !default;
$hscroll-border-size: 1px !default;
$hscroll-nav-nrml-minheight: 42px !default;
$hscroll-nav-bgr-minheight: 56px !default;
$hscroll-mob-nav-nrml-width: 50px !default;
$hscroll-nav-nrml-width: 40px !default;
$hscroll-nav-bgr-width: 50px !default;
$hscroll-nrml-padding: 0 $hscroll-nav-nrml-width !default;
$hscroll-bgr-padding: 0 $hscroll-nav-bgr-width !default;
$hscroll-border-type: $border-type !default;
$hscroll-box-shadow: none !default;
$hscroll-hover-bg: rgba($grey-black, .12) !default;
$hscroll-hover-border-color: rgba($grey-black, .12) !default;
$hscroll-default-icon-color: rgba($grey-light-font, .54) !default;
$hscroll-focus-border: 0 !default;
$hscroll-active-border: 0 !default;
$hscroll-hover-border: 0 !default;
$hscroll-active-box-shadow: none !default;
$hscroll-overlay-opacity: .5 !default;
$hscroll-overlay-bg: $grey-50 !default;
$hscroll-overlay-start: rgba($hscroll-overlay-bg, 0) !default;
$hscroll-overlay-end: rgba($hscroll-overlay-bg, 1) !default;
$hscroll-right-bg: linear-gradient(-270deg, $hscroll-overlay-start 0%, $hscroll-overlay-end 100%) !default;
$hscroll-left-bg:  linear-gradient(-270deg, $hscroll-overlay-end 0%, $hscroll-overlay-start 100%) !default;

$hscroll-device-arrow-box-shadow: -4px 0 8px 0 rgba($shadow, .06) !default;
$hscroll-device-arrow-rtl-box-shadow: 4px 0 8px 0 rgba($shadow, .06) !default;
$hscroll-device-arrow-bg: $grey-50 !default;
$hscroll-device-arrow-border-size: 1px !default;
$hscroll-device-arrow-border-color: rgba($grey-black, .12) !default;
$hscroll-device-arrow-color: $accent !default;
$hscroll-device-arrow-size: 14px !default;
$hscroll-device-arrow-icon-size: 12px !default;
$hscroll-device-arrow-size-bigger: 14px !default;
$hscroll-device-arrow-width: 56px !default;

$hscroll-default-border: $hscroll-hover-border-color !default;
$hscroll-ribble-animation-border-frame: rgba(255, 255, 255, .5) !default;
$hscroll-ribble-animation-shadow-frame: 0 0 0 0 $hscroll-ribble-animation-border-frame !default;
$hscroll-ribble-animation-shadow-frame-end: 0 0 0 200px rgba(255, 255, 255, .12) !default;

$hscroll-nav-hover-press-bg: $hscroll-hover-bg !default;
$hscroll-nav-hover-icons-color: $hscroll-default-icon-color !default;
$hscroll-nav-focus-bg-color: $hscroll-hover-bg !default;
$hscroll-nav-focus-border: $hscroll-focus-border !default;
$hscroll-nav-active-color: $hscroll-default-icon-color !default;

@mixin hscroll-btn-animation {
  background-color: transparent;
  border-radius: 50%;
  border-width: 1px;
  box-sizing: border-box;
  content: '';
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  visibility: hidden;
  width: 1px;
}

@mixin hscroll-btn-animation-after {
  animation: hscroll-popup-shadow .6s ease-out 0ms;
  visibility: visible;
}

@keyframes hscroll-popup-shadow {
  0% {
    border-color: $hscroll-ribble-animation-border-frame;
    box-shadow: $hscroll-ribble-animation-shadow-frame;
  }

  100% {
    box-shadow: $hscroll-ribble-animation-shadow-frame-end;
  }
}
