@import 'definition/material.scss';

@if not variable-exists('is-roboto-loaded') {
  //sass-lint:disable no-url-protocols,no-url-domains
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');// stylelint-disable-line no-invalid-position-at-import-rule
}

$is-roboto-loaded: 'true' !default;

$font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont' !default;
$font-size: 12px !default;
$font-weight: normal !default;
$font-path: 'common-images' !default;
$header-bg-color: $grey-white !default;
$header-gradient-color: none !default;
$header-border-color: $grey-black !default;
$header-font-color: $grey-light-font !default;
$header-icon-color: $grey-light-font !default;
$content-bg-color: $grey-white !default;
$content-border-color: $grey-black !default;
$content-font-color: $grey-light-font !default;
$default-bg-color: $grey-white !default;
$default-gradient-color: none !default;
$default-border-color: $grey-black !default;
$default-font-color: $grey-light-font !default;
$default-icon-color: $grey-light-font !default;
$hover-bg-color: $grey-200 !default;
$hover-gradient-color: none !default;
$hover-border-color: $grey-black !default;
$hover-font-color: $grey-light-font !default;
$hover-icon-color: $grey-light-font !default;
$active-bg-color: $grey-100 !default;
$active-gradient-color: none !default;
$active-border-color: $grey-black !default;
$active-font-color: $grey-light-font !default;
$active-icon-color: $grey-light-font !default;
$error-font-color: $error-font !default;
$warning-font-color: #ffca1c !default;
$success-font-color: #22b24b !default;
$information-font-color: #489bd5 !default;
$overlay-bg-color: #383838 !default;
$shadow-color: rgba(0, 0, 0, .4) !default;
$border-size: 1px !default;
$border-type: solid !default;
$border-radius: 0 !default;
$border-left-radius: 0 !default;
$border-right-radius: 0 !default;
$border-top-radius: 0 !default;
$border-bottom-radius: 0 !default;
