$split-btn-zindex: 0 !default;
$split-btn-border-radius: 2px !default;
$split-btn-focus-border-color: transparent !default;
$split-btn-focus-vertical-border-color: transparent !default;
$split-btn-focus-outline-offset: 0 !default;
$split-btn-vertical-secondary-icon-line-height: .417em !default;
$split-btn-seperator-border: 1px solid !default;
$split-btn-seperator-border-color: transparent !default;
$split-btn-seperator-default-border-color: transparent !default;
$split-btn-seperator-vertical-border-color: transparent !default;
$split-btn-hover-border-color: transparent !default;
$split-btn-hover-vertical-border-color: transparent !default;
$split-btn-active-border-color: transparent !default;
$split-btn-active-vertical-border-color: transparent !default;
$split-btn-disabled-vertical-border-color: transparent !default;
$split-btn-active-box-shadow: none !default;
$split-btn-disabled-left-border-color: transparent !default;
$split-btn-hover-left-border-color: transparent !default;
$split-btn-focus-left-border-color: transparent !default;
$split-btn-active-left-border-color: transparent !default;
$split-btn-sec-btn-margin-left: 0 !default;
$split-btn-icon-btn-padding: 6px !default;
$split-btn-icon-btn-padding-bigger: 9px !default;
