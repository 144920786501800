$tooltip-drop-shadow: none !default;
$tooltip-content-bg-color: $grey-700 !default;
$tooltip-content-border-color: $grey-700 !default;
$tooltip-content-font-color: $grey-dark-font !default;
$tooltip-default-icon-color: $grey-700 !default;
$tooltip-icon-bg-normal: $primary-font !default;
$tooltip-hover-icon-color: $grey-700 !default;
$tooltip-icon-bg-hover: $primary-font !default;
$tooltip-font-size: 11px !default;
$tooltip-mobile-font-size: 14px !default;
$tooltip-border-radius: 2px !default;
$tooltip-opacity: .9 !default;
$tooltip-arrow-outer-border: 8px !default;
$tooltip-arrow-inner-border: 7px !default;
$tooltip-horizontal-padding: 3px !default;
$tooltip-vertical-padding: 6px !default;
$tooltip-mobile-horizontal-padding: 5px !default;
$tooltip-mobile-vertical-padding: 8px !default;
$tooltip-line-height: 16px !default;
$tooltip-arrow-icon-font-size: 16px !default;
$tooltip-icon-border-color: transparent !default;

$tooltip-tip-bottom-height: 8px !default;
$tooltip-tip-bottom-left: 50% !default;
$tooltip-tip-bottom-top: 100% !default;
$tooltip-tip-bottom-width: 16px !default;

$tooltip-tip-top-height: 8px !default;
$tooltip-tip-top-left: 50% !default;
$tooltip-tip-top-top: -9px !default;
$tooltip-tip-top-width: 16px !default;

$tooltip-tip-left-height: 16px !default;
$tooltip-tip-left-left: -9px !default;
$tooltip-tip-left-top: 48% !default;
$tooltip-tip-left-width: 8px !default;

$tooltip-tip-right-height: 16px !default;
$tooltip-tip-right-left: 100% !default;
$tooltip-tip-right-top: 50% !default;
$tooltip-tip-right-width: 8px !default;
