$cmenu-back-icon: '\e977' !default;
$cmenu-back-icon-margin: 16px !default;
$cmenu-bigger-caret-font-size: 12px !default;
$cmenu-bigger-font-size: 15px !default;
$cmenu-bigger-li-height: 48px !default;
$cmenu-bigger-max-width: 280px !default;
$cmenu-bigger-min-width: 112px !default;
$cmenu-caret-font-size: 11px !default;
$cmenu-caret-color: rgba($grey-light-font, .54) !default;
$cmenu-caret-icon: '\e956' !default;
$cmenu-caret-icon-rtl: '\e937' !default;
$cmenu-caret-li-padding: 36px !default;
$cmenu-caret-right: 8px !default;
$cmenu-caret-blank-icon: 16px !default;
$cmenu-color: rgba($grey-light-font, .87) !default;
$cmenu-disable-text: rgba($grey-light-font, .38) !default;
$cmenu-font-size: 14px !default;
$cmenu-font-weight: normal !default;
$cmenu-icon-margin-right: 10px !default;
$cmenu-li-bgcolor: $grey-200 !default;
$cmenu-li-border-color: rgba($grey-light-font, .12) !default;
$cmenu-li-border-style: solid !default;
$cmenu-li-border-width: 1px !default;
$cmenu-li-height: 36px !default;
$cmenu-li-padding: 0 16px !default;
$cmenu-li-right-padding: 16px !default;
$cmenu-li-selection-bgcolor:  $grey-200 !default;
$cmenu-li-selection-font-color: rgba($grey-light-font, .87) !default;
$cmenu-li-hover-outline-offset: 0 !default;
$cmenu-li-selected-outline: 0 solid $cmenu-li-selection-bgcolor !default;
$cmenu-li-selected-outline-offset: 0 !default;
$cmenu-max-width: 240px !default;
$cmenu-icon-color: rgba($grey-light-font, .54) !default;
$cmenu-icon-disabled-color: rgba($grey-light-font, .38) !default;
$cmenu-icon-font-size: 14px !default;
$cmenu-icon-bigger-font-size: 16px !default;
$cmenu-min-width: 120px !default;
$cmenu-parent-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$cmenu-seperator-padding: 8px 0 !default;
$cmenu-seperator-bigger-padding: 8px 0 !default;
$cmenu-selected-color: rgba($grey-light-font, .87) !default;
$cmenu-sub-ul-box-shadow: 0 5px 5px -3px rgba($grey-black, .2), 0 8px 10px 1px rgba($grey-black, .14), 0 3px 14px 2px rgba($grey-black, .12) !default;
$cmenu-ul-bgcolor: $grey-white !default;
$cmenu-ul-border-radius: 0 !default;
$cmenu-ul-border: none !default;
$cmenu-ul-padding: 0 !default;
$cmenu-ul-bigger-padding: 8px 0 !default;
$cmenu-li-hover-outline: 0 solid $cmenu-li-border-color !default;
$cmenu-caret-hover-color: $cmenu-caret-color !default;
$cmenu-hover-icon-color: $cmenu-icon-color !default;
