@use 'sass:math';

$pager-font-size: 13px !default;
$pager-font-line-height: 1 !default;
$pager-numeric-font-line-height: $pager-font-line-height !default;
$pager-font-family: sans-serif !default;
$pager-border-type: $border-type !default;
$pager-mobile-icon-size: 11px !default;
$pager-icon-color: $default-icon-color !default;
$pager-hover-color: $hover-font-color !default;
$pager-mhover-color: $default-font-color !default;
$page-content-color: $default-font-color !default;
$pager-pagermessage-weight: normal !default;
$pager-last-border-size: 0 !default;
$pager-bigger-icons-align: '' !default;
$pager-numeric-font-weight: normal !default;
$pager-messagebar-padding-bottom: 9px !default;
$pager-bigger-messagebar-padding-bottom: 8px !default;
$pager-letter-spacing: -.1em !default;
$pager-numeric-hover-bg-color: unset !default;
$pager-numeric-txt-bg-color: unset !default;
$pager-bigger-new-container-rtl-margin: 5px 0 5px 0 !default;
$pager-pagecontainer-icon-padding: 11px 9px 8px !default;
$pager-bigger-pagecontainer-icon-padding: 11px 9px 8px !default;
$pager-last-page-hover-border-radius:  0 4px 4px 0 !default;
$pager-first-page-hover-border-radius: 4px 0 0 4px !default;
$pager-item-hover-borders-width: 0 !default;
$pager-rtl-parentmsgbar-margin-left: 6px !default;
$pager-rtl-parentmsgbar-padding-top: 18px !default;
$pager-bigger-padding: 4px 0 2px !default;
$pager-bigger-icon-font-size: 13px !default;
$pager-bigger-font-size: 13px !default;
$pager-bigger-pagercontainer-margin: 5px 8px 5px 24px !default;
$pager-bigger-numeric-item-margin: 0 8px !default;
$pager-bigger-current-item-padding: 10px 13px !default;
$pager-bigger-pagercontainer-icons-marign-right: 16px !default;
$pager-bigger-pagercontainer-icons-padding: 6px !default;
$pager-bigger-rtl-nexticon-marign-left: 0 !default;
$pager-device-padding: 19px 0 !default;
$pager-device-messabar-padding: 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-left: 6px !default;
$pager-bigger-letter-spacing: 10px 13px !default;
$pager-numericitem-min-width: 26px !default;
$pager-bg-color: $grey-white !default;
$pager-active-bg-color: $accent !default;
$pager-hover-bg-color: $grey-white !default;
$pager-number-hover-bg-color: $grey-200 !default;
$pager-border-size: 0 !default;
$pager-icon-width: 18px !default;
$pager-icon-height: 18px !default;
$pager-container-margin: 5px 6px 5px 18px !default;
$pager-navigationicon-padding: 5px !default;
$pager-navigationicon-right: 12px !default;
$pager-navigationicon-top: 5px !default;
$pager-numericitem-padding: 5px 8px !default;
$pager-numericitem-margin-right: 4px !default;
$pager-numericitem-border-radius: 12px !default;
$pager-icon-size: 9px !default;
$pager-messagebar-padding-top: 14px !default;
$pager-messagebar-padding-right: 18px !default;
$pager-padding: 0 !default;
$pager-border-color: $grey-300 !default;
$pager-color: $grey-light-font !default;
$pager-active-color: $accent-font !default;
$pager-content-opacity: .87 !default;
$pager-numeric-icon-padding: 6px !default;
$pager-container-rtl-margin: 5px 6px 5px 18px !default;
$pager-rtl-navigation-icon-padding: 9px 8px !default;
$pager-activeitem-padding: 6px !default;
$pager-bigger-activeitem-padding: $pager-activeitem-padding !default;
$pager-bigger-rtl-activeitem-padding: 10px 13px !default;
$pager-rtl-numeric-margin: 11px 4px 0 0 !default;
$pager-next-icon-margin-left: 9px !default;
$pager-tripledot-font-size: 22px !default;
$pager-tripledot-padding: 0 6px !default;
$pager-first-content-icon: 'e896' !default;
$pager-prev-content-icon: 'e904' !default;
$pager-next-content-icon: 'e913' !default;
$pager-last-content-icon: 'e897' !default;
$pager-dropdown-margin-left: 12px !default;
$pager-dropdown-margin-top: -26px !default;
$pager-dropdown-margin-right: 0 !default;
$pager-dropdown-overflow: hidden !default;
$pager-bigger-dropdown-constant-margin: 15px !default;
$pager-bigger-dropdown-margin: -40px  0 0 16px !default;
$pager-dropdown-height: 32px !default;
$pager-dropdown-width: 90px !default;
$pager-bigger-dropdown-width: 90px !default;
$pager-constant-margin: 0 0 11px 12px !default;
$pager-tripledot-rtl-margin-top: -2px !default;
$pager-tripledot-rtl-padding: 3px 11px 9px !default;
$pager-rtl-pagerconstant-margin-top: 14px !default;
$pager-rtl-pagerdropdown-margin: -33px 18px 0 10px !default;
$pager-tripledot-rtl-bigger-margin-top: -10px !default;
$pager-bigger-rtl-numeric-margin: 0 8px !default;
$pager-bigger-container-rtl-margin: 5 0 -4px 0 !default;
$pager-rtl-pagerdropdown-bigger-margin: -35px 18px 0 0 !default;
$pager-rtl-pagerconstant-bigger-margin-top: 8px 16px 16px 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-top: -6px !default;
$pager-bigger-rtl-pagercontainer-margin-top: 5px !default;
$pager-bigger-device-rtl-parentmsgbar-margin-top: 5px !default;
$pager-rtl-pagermsgbar-device-margin-top: 7px !default;
$pager-bigger-constant-margin-left: 16px !default;
$pager-rtl-last-content-icon: 'e896' !default;
$pager-rtl-first-content-icon: 'e897' !default;
$pager-rtl-prev-content-icon: 'e913' !default;
$pager-rtl-next-content-icon: 'e904' !default;
$pager-rtl-first-last-icon-hover-border-radius: 0 4px 4px 0 !default;
$pager-bigger-messagebar-padding-right: math.div($pager-messagebar-padding-right, .75) !default;
$pager-bigger-messagebar-padding-top: 20px !default;
$pager-bigger-nexticon-marign-left: math.div($pager-next-icon-margin-left, .75) !default;
$pager-bigger-rtl-nexticon-marign-right: math.div($pager-next-icon-margin-left, .75) !default;
$pager-bigger-current-item-border-radius: math.div($pager-numericitem-border-radius, .75) !default;
$pager-item-hover-border-width: 0 !default;
$pager-item-hover-border-color: $pager-border-color !default;
$pager-bigger-current-item-hover-padding: $pager-bigger-current-item-padding !default;
$pager-numeric-icon-hover-padding: $pager-numeric-icon-padding !default;
$pager-bigger-rtl-activeitem-hover-padding:  $pager-bigger-rtl-activeitem-padding !default;
$pager-activeitem-hover-padding: $pager-activeitem-padding !default;
$pager-bigger-dropdown-margin-top: -36px !default;
$pager-rtl-nxtpage-margin: 6px !default;
$pager-disabled-icons-opacity: .3 !default;
$pager-bigger-dropdown-heigh: 40px !default;
$pager-external-msg-padding: 6px !default;
$pager-rtl-bigger-tripledot-font-size: $pager-tripledot-font-size !default;
$pager-current-item-font-weight: normal !default;
$pager-skin: 'material' !default;
$pager-container-border-radius: 4px !default;
$pager-bigger-tripledot-font-size: $pager-tripledot-font-size !default;
$pager-focus-bg-color: $grey-200 !default;
$pager-focus-tripledot-padding: 2.5px 0 9px !default;
$pager-focus-tripledot-line-height: 14px !default;
$pager-focus-active-box-shadow: none !default;
$pager-focus-shadow: 0 0 0 1px $grey-500 inset !default;
$pager-bigger-adaptive-pagermsgbar-margin-top: 0 !default;
$pager-bigger-adaptive-dropdown-margin: 2px 2px 0 4px !default;
$pager-bigger-adaptive-dropdown-min-width: 77px !default;
$pager-bigger-adaptive-constant-top: 9px !default;
$pager-rtl-adaptive-constant-top: 0 10px 0 8px !default;
$pager-rtl-e-bigger-adaptive-dropdown-margin: 2px 4px 0 2px !default;
$pager-rtl-e-bigger-adaptive-pagermsgbar-margin-top: 0 !default;
$pager-bigger-adaptive-pager-padding: 6px !default;
$pager-adaptive-pagermsgbar-margin-top: -4px !default;
$pager-adaptive-dropdown-margin: 6px 2px 0 4px !default;
$pager-rtl-adaptive-pagermsgbar-margin-top: -4px !default;
$pager-rtl-adaptive-dropdown-margin: 8px 4px 0 2px !default;
$pager-adaptive-constant-pagesizeall-top: 14px !default;
$pager-bigger-adaptive-constant-pagesizeall-top: 14px !default;
$pager-rtl-adaptive-constant-pagesizeall-top: 15px !default;
$pager-rtl-e-bigger-adaptive-constant-pagesizeall-top: 14px !default;
