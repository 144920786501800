/*! Form material theme wise override definitions and variables */

// form field styles
$field-label-color: rgba($grey-light-font, .54) !default;
$field-label-font-size: 12px !default;
$field-label-margin-bottom: 4px !default;
$field-label-font-weight: 400 !default;
$form-layout-margin-top: 20px !default;
$field-label-right-margin-top: 6px !default;

// group title styles
$group-title-color: rgba($grey-light-font, .54) !default;
$group-title-border-color: #c4c7c5 !default;
$form-group-border-none: none !default;
$form-padding-zero: 0 !default;
$form-margin-zero: 0 !default;
$form-group-title-position: relative !default;
$form-group-title-display: inline-block !default;
$form-group-title-padding-bottom: 8px !default;
$form-group-title-margin-bottom: 4px !default;
$form-group-title-font-size: 16px !default;
$form-group-title-border-position: absolute !default;
$form-group-title-border-bottom: 0 !default;
$form-group-title-border-left: 0 !default;
$form-group-title-border-width: 100% !default;
$form-group-title-border-height: 1px !default;
$form-group-title-border-bottom-style: 1px solid $group-title-border-color !default;

//tooltip styles
$form-tooltip-background-color: lighten($error-font, 35%) !default;
$form-tooltip-color: darken($error-font, 20%) !default;
$form-validation-message-font-size: 12px;

// form common styles
$form-display-flex: flex !default;
$form-label-top-direction: column !default;
$form-label-text-align: center !default;
$form-button-left-justify: flex-start !default;
$form-button-right-justify: flex-end !default;
$form-button-center-justify: center !default;
$form-button-border-radius: 20px !default;
$form-button-padding: 7px 16px !default;
