:root {
    /* non material values */
    --background-color-R: 211;
    --background-color-G: 211;
    --background-color-B: 211;
    --background-color: rgb(var(--background-color-R), var(--background-color-G), var(--background-color-B));  /* #d3d3d3 */

    --success-color-R: 40;
    --success-color-G: 167;
    --success-color-B: 69;
    --success-color: rgb(var(--success-color-R), var(--success-color-G), var(--success-color-B));

    /* *material values */
    /* material values aren't only used by material and light/dark versions of color aren't directly used in material theme */
    --primary-color-R: 63;
    --primary-color-G: 81;
    --primary-color-B: 181;
    --primary-color: var(--palette-primary-500); /* #3f51b5 */

    --accent-color-R: 255;
    --accent-color-G: 64;
    --accent-color-B: 129;
    --accent-color: var(--palette-accent-500); /* #ff4081 */

    --warning-color-R: 244;
    --warning-color-G: 67;
    --warning-color-B: 54;
    --warning-color: var(--palette-warn-500); /* #f44336 */
}
