/*! Carousel component material theme definitions and variables */

// COLORS VARIABLES
$carousel-navigator-btn-bg: transparent !default;
$carousel-navigator-btn-bg-hover: rgba($grey-white, .4) !default;
$carousel-navigator-icon-color: $grey-white !default;
$carousel-indicator-bar-bg: $grey-white !default;
$carousel-indicator-bar-border: 1px solid rgba($grey-white, .4) !default;
$carousel-indicator-bar-bg-active: $accent !default;
$carousel-indicator-border-color-focus: $grey-black !default;
$carousel-indicator-progress-bg: rgba($carousel-indicator-bar-bg-active, .4) !default;

// ANIMATION VARIABLES
$carousel-animation-duration: .6s !default;
$carousel-animation-timing-function: ease-in-out !default;

// DIMENSION VARIABLES
$carousel-navigator-btn-border-radius-hover: 50% !default;
$carousel-indicator-padding: 6px !default;
$carousel-indicator-height: 48px !default;
$carousel-indicator-bar-border-radius: 50% !default;
$carousel-indicator-bar-height: 12px !default;
$carousel-indicator-bar-width: 12px !default;
$carousel-indicator-dynamic-minheight: 36px !default;
$carousel-indicator-fraction-minheight: 36px !default;
$carousel-indicator-progress-minheight: 4px !default;
$carousel-indicator-progress-height: 4px !default;
