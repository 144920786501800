@include export-module('list-box-material-icons') {
  .e-listbox-tool .e-moveup::before {
    content: '\e651';
  }

  .e-listbox-tool .e-movedown::before {
    content: '\e652';
  }

  .e-listbox-tool .e-moveto::before {
    content: '\e653';
  }

  .e-listbox-tool .e-movefrom::before {
    content: '\e654';
  }

  .e-listbox-tool .e-moveallto::before {
    content: '\e655';
  }

  .e-listbox-tool .e-moveallfrom::before {
    content: '\e656';
  }
}
